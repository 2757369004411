import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Tabs, Tab, Alert, IconButton, Grid, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel, Select, MenuItem, Checkbox, FormGroup, FormControlLabel, CircularProgress } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert';
import verificareToken from './VerificareToken';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
function ExportDosareExcel({ open, onClose, dosare, tip, startDate, endDate, dateFilterType }) { 
    const { token } = verificareToken();
  const [activeTab, setActiveTab] = useState('dosar'); // 'dosar' sau 'stadiu'
  const [loading, setLoading] = useState(false);
  const [elementeDosar, setElementeDosar] = useState({
    parintePrincipal: [],
    subgrupuri: {},
    byClass: {},
  });
  
  const [elementeStadiu, setElementeStadiu] = useState({
    parintePrincipal: [],
    subgrupuri: {},
    byClass: {},
  });
  const [elementeTotale, setElementeTotale] = useState([]);
  const [elemente, setElemente] = useState([]); 
  const [sabloaneExport, setSabloaneExport] = useState([]);
  const [sablonSelectat, setSablonSelectat] = useState({});
  const [selectedElements, setSelectedElements] = useState(new Set()); 
  const [selectAll, setSelectAll] = useState(false);
  console.log("Component Render:", selectedElements, selectAll);
  const [isCreatingNewSablon, setIsCreatingNewSablon] = useState(false);
  const [newSablonName, setNewSablonName] = useState('');
  const [isPrinting, setIsPrinting] = useState(false);
  const [elementeFormat, setElementeFormat] = useState([]);
  const [selectedFormatOptions, setSelectedFormatOptions] = useState(new Set());

  const [activSablon, setActivSablon] = useState(false);

  const handleFormatOptionChange = (optionId) => {
    setSelectedFormatOptions((prevSelectedOptions) => {
        const updatedSelectedOptions = new Set(prevSelectedOptions);
        if (updatedSelectedOptions.has(optionId)) {
            updatedSelectedOptions.delete(optionId);
        } else {
            updatedSelectedOptions.add(optionId);
        }
        return updatedSelectedOptions;
    });
};

 
    const handleSelectAllChange = (event) => {
      const isChecked = event.target.checked;
      setSelectAll(isChecked);
    
      if (isChecked) {
        // Selectăm toate elementele
        const allElementIds = elementeTotale.map(element => element.id);
        setSelectedElements(new Set(allElementIds));
        setSelectAll(true);
      } else {
        // Deselectăm toate elementele
        setSelectedElements(new Set());
        setSelectAll(false);
      }
    };
  
    
 useEffect(() => {
  console.log("SelectedElements & SelectAll State Update:", selectedElements, selectAll);
}, [selectedElements, selectAll]);
      
  const handleStartCreateNewSablon = () => {
    setIsCreatingNewSablon(true);
    setNewSablonName('');
  };

  const handleCancelCreateNewSablon = () => {
    setIsCreatingNewSablon(false);
  };

  const handleSaveNewSablon = async () => {
    if (!newSablonName.trim()) {
      Swal("Eroare!", "Numele șablonului nu poate fi gol.", "error");
      return;
    }
  
    const newSablon = await handleCreateSablon(newSablonName, selectedElements);
  
    if (newSablon) {
      await fetchElemente(activeTab);
  
      setSablonSelectat(newSablon);
    }
  
    setIsCreatingNewSablon(false);
    setNewSablonName('');
  };
 

  function groupElements(elements) {
    const parintePrincipal = [];
    const subgrupuri = {};
    const byClass = {}; 
  
    const elementsArray = Object.values(elements);
    console.log('elementsArray', elementsArray);
    elementsArray.forEach(element => {
      if (element.clasa === "" && element.pentruGrupare) {
        // Elementele care sunt părinte principal
        parintePrincipal.push(element);
      } else if (element.clasa && element.pentruGrupare && !element.titlu_excel) {
        // Elementele care sunt subgrupuri
        if (!subgrupuri[element.clasa]) {
          subgrupuri[element.clasa] = [];
        }
        subgrupuri[element.clasa].push(element);
      } else if (element.clasa) {
        // Elementele care fac parte dintr-un grup
        if (!byClass[element.clasa]) {
          byClass[element.clasa] = [];
        }
        byClass[element.clasa].push(element);
      }
    });
  
    return { parintePrincipal, subgrupuri, byClass };
  }
  
  const fetchElemente = async (grup) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}elementeSabloane?grup=${grup}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      const elementeGrupate = groupElements(data.data.elementeSabloane);
  
      
      setElementeFormat(data.data.elementeFormat);
      if (grup === 'dosar') {
        setElementeDosar(elementeGrupate);
      } else {
        setElementeStadiu(elementeGrupate); 
      }

      const allElements = data.data.elementeSabloane.flatMap(element => {
        if (element.children && element.children.length > 0) {
          return [element, ...element.children.flatMap(child => [child, ...child.children || []])];
        }
        return element;
      });
      setElementeTotale(allElements);
      console.log('elementeTotale ', allElements );
      setElemente(data.data.elementeSabloane || []);

    } catch (error) {
      console.error('There was an error!', error);
    } finally {
      setLoading(false);
    }
  };
 
 
  function RenderElements({ elements }) {
    const [selectedIds, setSelectedIds] = useState(new Set());
  
    const renderElement = (element, depth = 0) => {
      let gridValue;
      let className;
      let classNameElement;
      
      // Verificăm dacă elementul are copii
      const areCopii = element.children && element.children.length > 0;
      // Determinăm dacă oricare dintre copii are la rândul său copii (nepoți pentru elementul curent)
      const areNepoti = areCopii && element.children.some(child => child.children && child.children.length > 0);
 
      if (!areCopii && depth === 0) { 
        gridValue = 2;
        className = "elementExportSingur";
        classNameElement = "ELM_ExportSingur";
      } else if (areCopii && depth === 0) { 
        gridValue = areNepoti ? 12 : 4;
        className = areNepoti ? "elementCuCopiiNepoti" : "elementCuCopii";
        classNameElement = areNepoti ? "ELM_CuCopiiNepoti" : "ELM_CuCopii";
      } else if (depth === 1) { 
        gridValue = areCopii ? 4 : 12;
        className = "elementCopil"; 
        classNameElement = "ELM_Copil"; 
      } else { 
        gridValue = 12;
        className = "elementNepot"; 
        classNameElement = "ELM_Nepot"; 
      }
  const isChecked = selectedElements.has(element.id) || element.id === 2; 
  const isDisabled = element.id === 2;  
      return (
        <Grid item xs={gridValue} className={className} key={element.id}>
          <FormControlLabel className={classNameElement}
            control={
              <Checkbox
                  size='small'
                  name={element.id.toString()}  
                  checked={isChecked}
                  onChange={isDisabled ? null : handleCheckboxChange}
                  disabled={isDisabled}
              />

            }
            label={element.titlu}
          />
          {areCopii && (
            <Grid container spacing={1}>
              {element.children.map((child) => renderElement(child, depth + 1))}
            </Grid>
          )}
        </Grid>
      );
    };
    
    const renderAllElements = () => {
      return (
        <Grid container spacing={1}>
          {elements
            .filter((element) => !element.parent_id)
            .map((element) => renderElement(element, 0))}
        </Grid>
      );
    };
    
    return <>{renderAllElements()}</>;
  }
 
  const handleTabChange = async (event, newValue) => {
    const newActiveTab = newValue === 0 ? 'dosar' : 'stadiu';
    setActiveTab(newActiveTab);
  
    setSelectAll(false);
    setSelectedElements(new Set());
  
    if (sablonSelectat && sablonSelectat.id) {
      await loadSablonDetails(sablonSelectat.id, newActiveTab);
    } else {
      fetchElemente(newActiveTab);
    }
  };
  
  const loadSablonDetails = async (sablonId, grup) => {
    const detaliiSablon = await fetchDetaliiSablon(sablonId);
    if (detaliiSablon) {
      const elementeIds = detaliiSablon.map(element => element.id);
      setSelectedElements(new Set(elementeIds));
    }
  
    fetchElemente(grup);
  };
  
  const handleCreateSablon = async (denumire, elementeIds) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}sabloaneExport`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              denumire,
              tip:"export_excel",
              elemente_ids: Array.from(elementeIds)
            })
          });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }  else {
            setIsCreatingNewSablon(false);
          setNewSablonName('');
          
          }
          
          setLoading(true);
          Promise.all([ 
              fetchAPI('sabloaneExport?tip=export_excel')
          ]).then(([sabloaneData]) => {  
              setSabloaneExport(sabloaneData);
          })
          .catch((error) => console.error('Error:', error))
          .finally(() => setLoading(false));

          const sablonData = await response.json();
          const newSablonId = sablonData.data;
      
          await fetchElemente(activeTab);
       
          setSablonSelectat(newSablonId); 
          setActivSablon(true);
          
          return sablonData.data; 
        } catch (error) {
          console.error('There was an error!', error);
          return null;
        }
      };
      
      const handleEditSablon = async () => {
        //if (!sablonSelectat.id) return;
        const sablonId = sablonSelectat.sablonExport?.id || sablonSelectat.id;
        if (!sablonId) return;
 
        try {
          const elementeIds = Array.from(selectedElements);
          const params = new URLSearchParams();
          params.append('denumire', sablonSelectat.denumire);
          elementeIds.forEach(id => params.append('elemente_ids[]', id));
      
          const response = await fetch(`${process.env.REACT_APP_API_URL}sabloaneExport/${sablonId}?${params.toString()}`, {
            method: 'PATCH',
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
      
          await fetchElemente(activeTab);
          Swal("Succes!", `Sablonul ${sablonSelectat.denumire} a fost salvat cu succes!`, "success");
        } catch (error) {
          console.error('There was an error!', error);
          Swal("Eroare!", "A apărut o problemă la salvarea șablonului.", "error");
        }
      };
      

      const handleDeleteSablon = () => {
        Swal({
          title: 'Sigur doriți ștergerea?',
          text: 'Această acțiune nu poate fi anulată!',
          icon: 'warning',
          buttons: ['Anulează', 'Da, șterge!'],
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            handleDeleteSablonOK();
          }
        });
      };
      const handleDeleteSablonOK = async () => {
        //if (!sablonSelectat.id) return;
        const sablonId = sablonSelectat.sablonExport?.id || sablonSelectat.id;
        if (!sablonId) return;

        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}sabloaneExport/${sablonId}`, {
            method: 'DELETE',
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          });
    
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
          await fetchElemente(activeTab);
          setSablonSelectat({}); 
          
          setLoading(true);
          Promise.all([ 
              fetchAPI('sabloaneExport?tip=export_excel')
          ]).then(([sabloaneData]) => {  
              setSabloaneExport(sabloaneData);
          })
          .catch((error) => console.error('Error:', error))
          .finally(() => setLoading(false));

          setSelectedElements(new Set());

          setActivSablon(false);

        } catch (error) {
          console.error('There was an error!', error);
        }
      };


  const fetchAPI = (endpoint) => {
    return fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    })
    .then((data) => {
        return endpoint.includes('sabloaneExport') ? data.data.sabloaneExport : data.data.elementeSabloane;
      });
    
  };

  useEffect(() => {
    if (open) {
      setActiveTab('dosar');  
      fetchElemente('dosar'); 
      setLoading(true);
      Promise.all([ 
        fetchAPI('sabloaneExport?tip=export_excel')
      ]).then(([sabloaneData]) => {  
        setSabloaneExport(sabloaneData);
      })
      .catch((error) => console.error('Error:', error))
      .finally(() => setLoading(false));
    }
    console.log('dosare',dosare);
  }, [open]);
  
  const fetchDetaliiSablon = async (sablonId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}sabloaneExport/${sablonId}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const sablonData = await response.json();
      return sablonData.data.sablonExport.elemente;
    } catch (error) {
      console.error('There was an error fetching the sablon details:', error);
    }
  };

  const handleSablonChange = async (event) => {
    const sablonId = event.target.value;
    const detaliiSablon = await fetchDetaliiSablon(sablonId);
    if (detaliiSablon) {
      const elementeIds = new Set(detaliiSablon.map(element => element.id));
      setSelectedElements(elementeIds);
  
      const sablonAles = sabloaneExport.find(sablon => sablon.id === parseInt(sablonId, 10));
      setSablonSelectat(sablonAles || {});
      
      setActivSablon(true);
      //console.log('setSablonSelectat', sablonAles);
    }
  };
  
  const handleCheckboxChange = (event) => {
    const id = parseInt(event.target.name, 10);
    const isChecked = event.target.checked;
  
    setSelectedElements(prevSelectedElements => {
      const updatedSelectedElements = new Set(prevSelectedElements);
  
      if (isChecked) {
        updatedSelectedElements.add(id);
        
        const addAllChildren = (parentId) => {
          elementeTotale.forEach(element => {
            if (element.parent_id === parentId) {
              updatedSelectedElements.add(element.id);
              addAllChildren(element.id); 
            }
          });
        };
        addAllChildren(id);
      } else {
        updatedSelectedElements.delete(id);
        
        const removeAllChildren = (parentId) => {
          elementeTotale.forEach(element => {
            if (element.parent_id === parentId) {
              updatedSelectedElements.delete(element.id);
              removeAllChildren(element.id);
            }
          });
        };
        removeAllChildren(id);
      }
  
      return updatedSelectedElements;
    });
};

  const addChildrenToSelected = (parentId, selectedSet) => {
    const children = getChildren(parentId);
    children.forEach((childId) => {
      selectedSet.add(childId);
      addChildrenToSelected(childId, selectedSet);
    });
  };
  
  const removeChildrenFromSelected = (parentId, selectedSet) => {
    const children = getChildren(parentId); 
    children.forEach((childId) => {
      selectedSet.delete(childId);
      removeChildrenFromSelected(childId, selectedSet);
    });
  };
  
const getChildren = (parentId, elements) => {
  let foundChildren = [];
  if (Array.isArray(elements)) {
    elements.forEach(element => {
    if (element.parent_id === parentId) {
      foundChildren.push(element.id);
      const childrenOfChild = getChildren(element.id, elements);
      foundChildren = [...foundChildren, ...childrenOfChild];
    }
  });
}
  return foundChildren;
};
 
const handleExport = async () => {
  setIsPrinting(true);
  const elementeIds = Array.from(new Set([...selectedElements, ...selectedFormatOptions]));
  //fortez adaugarea elementului 2 - numar dosar
  if (!elementeIds.includes(2)) {
    elementeIds.push(2); 
  }

  const dosareIds = dosare.map(dosar => dosar.id);

  const queryParams = new URLSearchParams();

  elementeIds.forEach(id => queryParams.append('elemente_ids[]', id));
    // Trimite cererea
    fetch(`${process.env.REACT_APP_API_URL}export_excel?${queryParams.toString()}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 
        dosare_ids: dosareIds,
        dateExport: {
          startDate: startDate || "",  
          endDate: endDate || "",   
          dateFilterType: dateFilterType || "",  
          denumireSablon: sablonSelectat?.denumire || ""  
        }
      }) 
    }).catch(error => {
      console.error('A apărut o eroare la generarea exportului:', error);
    });
  
    
      /*
      const now = new Date();
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      const formattedDate = now.toISOString().split('T')[0]; 
      const formattedTime = now.toTimeString().split(' ')[0].replace(/:/g, '-'); 

      link.download = `Export Dosare JuristPro_${formattedDate}_${formattedTime}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      */
   // Afișează imediat Swal
   setIsPrinting(false);
   Swal({
     title: "Exportul este în curs de generare",
     text: "Generați un alt export sau mergeți în zona de exporturi generate pentru a le vizualiza pe cele finalizate.",
     icon: "success",
     buttons: {
       anotherExport: {
         text: "Doresc alt export",
         value: "another"
       },
       viewExports: {
         text: "Exporturi generate",
         value: "view"
       }
     }
   }).then((value) => {
     switch (value) {
       case "another":
         break;
       case "view":
         window.location.href = "/export-dosare";
         break;
       default:
         break;
     }
   });
 };
  useEffect(() => {
    console.log('activSablon', activSablon);
    console.log('sablonSelectat', sablonSelectat);
    console.log('isCreatingNewSablon', isCreatingNewSablon);
  }, [activSablon, sablonSelectat, isCreatingNewSablon]);
  


  return (
     <Dialog open={open} onClose={() => {}} maxWidth="lg" fullWidth disableBackdropClick disableEscapeKeyDown className='modalExport'>
      <DialogTitle className='titluModal'>
        Export dosare detaliat {tip === 'excel' ? 'Excel' : 'Word'}
        <IconButton sx={{ position: 'absolute', top: 10, right: 10, color:'#FFF' }} onClick={onClose}>
      <CloseIcon />
    </IconButton> 
    </DialogTitle>
      <DialogContent className='pt-2'>
        <Alert severity='info'>Bifați informațiile pe care doriți să le regăsiți în documentul de exportat în format {tip === 'excel' ? 'Excel' : 'Word'}. După alegerea elementelor dorite, salvați un șablon pentru ca ulterior să îl puteți exporta, la orice moment doriți, cu informații actualizate. Șabloanele pot fi create, modificate sau șterse.</Alert>
        <Grid container spacing={2} alignItems="center" className='mt-1'>
          {isCreatingNewSablon ? (
            <Grid item xs={8}>
              <TextField 
                fullWidth 
                label="Nume șablon nou" 
                value={newSablonName}
                onChange={(e) => setNewSablonName(e.target.value)}
                size="small"
              />
            </Grid>
          ) : (
            <Grid item xs={8}>
              <FormControl fullWidth  alignItems="center">
                <InputLabel>Șablon Export</InputLabel>
                <Select size="small" value={sablonSelectat.id || sablonSelectat.sablonExport?.id || ''} label="Șablon imprimare" onChange={handleSablonChange}>
                  {sabloaneExport.map((sablon) => (
                    <MenuItem key={sablon.id} value={sablon.id}>{sablon.denumire}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={4}  alignItems="center">
            {isCreatingNewSablon ? (
              <>
                <Button color="primary" onClick={handleSaveNewSablon}>Salvează</Button>
                <Button color="secondary" onClick={handleCancelCreateNewSablon}>Anulează</Button>
              </>
            ) : (
              <Button color="success" onClick={handleStartCreateNewSablon}>Șablon nou</Button>
            )}
          </Grid>
        </Grid> <hr></hr>
            {loading ? (
              <div className="text-center">
                <CircularProgress size="1.2rem" /> Se încarcă ... 
              </div>
            ) : (
              <></>
            )}
   <Grid container spacing={1} justifyContent='center' className='elementExport mb-3'>
<FormControlLabel
  control={
    <Checkbox
      checked={selectAll}
      onChange={handleSelectAllChange} 
      size='small'
    />
  }
  label="Selectați toate opțiunile"
/>
</Grid>
<Tabs value={activeTab === 'dosar' ? 0 : 1} onChange={handleTabChange} centered>
          <Tab label="Export dosare" />
          <Tab label="Export stadii" />
        </Tabs>
        <Grid container spacing={1}  className='elementeExport'>
        <RenderElements elements={elemente} />
            </Grid>
           

            <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
           
              {isPrinting ? (
                  <Button
                      variant="contained"
                      color="secondary"
                      disabled={true}
                      size='small'
                  >
                      Vă rugăm așteptați...
                  </Button>
              ) : (
                  <Button
                      variant="contained"
                      color="primary"
                      onClick={handleExport}
                      disabled={selectedElements.size === 0}
                      size='small'
                  >
                      Exportare
                  </Button>
              )}
               {isPrinting && (
              <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
                <Alert severity="warning" style={{ marginBottom: '1rem' }}>
                    Vă rugăm așteptați ...
                </Alert>
                </Grid>
            )}

            </Grid>

            <Grid container spacing={2} direction="row" justify="center" alignItems="center" style={{justifyContent:'center'}}>
              {elementeFormat.map((optiune) => (
                  <Grid item key={optiune.id} xs={2}>
                      <FormControlLabel
                          control={
                              <Checkbox
                                  size='small'
                                  checked={selectedFormatOptions.has(optiune.id)}
                                  onChange={() => handleFormatOptionChange(optiune.id)}
                              />
                          }
                          label={optiune.titlu}
                      />
                  </Grid>
              ))}
          </Grid>

            <Grid container spacing={2} justifyContent="center" alignItems="center" style={{}}>
               
            </Grid>

      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={onClose}>Anulare</Button>
        <Button color='success'onClick={handleEditSablon} disabled={isCreatingNewSablon || !activSablon}>Salvare șablon selectat</Button>
        <Button color='error' onClick={handleDeleteSablon} disabled={isCreatingNewSablon || !activSablon}>Ștergere șablon selectat</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ExportDosareExcel;
