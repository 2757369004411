import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  TextField, Button, List, ListItem, ListItemText, ListItemSecondaryAction, Typography,
  IconButton, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress, Box, MenuItem
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Swal from 'sweetalert';
import Alert from '@mui/material/Alert';
import verificareToken from '../VerificareToken';
import DrepturiUtilizator from '../utilizator/drepturiUtilizator'; 

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { ro } from 'date-fns/locale';

function formateazaData(dataString) {
    const data = new Date(dataString);
    const zi = data.getDate().toString().padStart(2, '0');
    const luna = (data.getMonth() + 1).toString().padStart(2, '0');  
    const an = data.getFullYear();
    return `${zi}-${luna}-${an}`;
  }

 
  function formateazaTimp(timp) {
    const ore = Math.floor(timp);  
    const minute = Math.round((timp - ore) * 100);  
  
    if (ore === 0 && minute > 0) {
      return `${minute} minute`;
    } else if (ore > 0 && minute === 0) {
      return `${ore} ore`;
    } else if (ore > 0 && minute > 0) {
      return `${ore} ore și ${minute} minute`;
    } else {
      return '';
    }
  }

  
function ActivitatiDosar() {
  const { areDrept } = DrepturiUtilizator(); 
  const DreptDeModificareDosare = 'DreptDeModificareDosare';
  
    const { token } = verificareToken();
  const { id: iddosar } = useParams();
  const [activitati, setActivitati] = useState([]);
  
  const [editareId, setEditareId] = useState(null);

  const [dateFormular, setDateFormular] = useState({
      data: new Date(),
      text: '',
      timp: '',
      minute: ''
  });
  const formateazaDataPentruBackend = (data) => {
    const offset = data.getTimezoneOffset() * 60000;  
    const dataLocala = new Date(data.getTime() - offset);
    return dataLocala.toISOString().split('T')[0];
  };
  
  const [modalDeschis, setModalDeschis] = useState(false);
  const [incarca, setIncarca] = useState(false);

  useEffect(() => {
    setIncarca(true);
    fetch(`${process.env.REACT_APP_API_URL}activitati/${iddosar}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setActivitati(data.data.activitatiDosar);
        setIncarca(false);
      })
      .catch(eroare => {
        console.error('A apărut o eroare!', eroare);
        setIncarca(false);
      });
  }, [iddosar]);


  //calcul total ore si minute
  const [oreTotale, setOreTotale] = useState(0);
  const [minuteTotale, setMinuteTotale] = useState(0);
  
  useEffect(() => {
    let totalOre = 0;
    let totalMinute = 0;

    activitati.forEach(activitate => {
        const ore = Math.floor(activitate.timp);
        const minute = Math.round((activitate.timp - ore) * 100); 
        
        totalOre += ore;
        totalMinute += minute;
    });

    totalOre += Math.floor(totalMinute / 60);
    totalMinute = totalMinute % 60;

    setOreTotale(totalOre);
    setMinuteTotale(totalMinute);
}, [activitati]);

  
 //final calcul

 const formatMinute = (minute) => {
  return ('0' + minute).slice(-2);  
}

 
const handleMinuteChange = (e) => {
  const valoareIntrodusa = parseInt(e.target.value, 10);

  if (valoareIntrodusa < 0 || valoareIntrodusa > 59) {
    Swal('Atenție!', 'Minutele trebuie să fie între 0 și 59!', 'warning');
    return;
  }

  const minuteCorecte = (valoareIntrodusa / 100).toFixed(2);

  setDateFormular((prev) => ({ ...prev, minute: minuteCorecte }));
};

  const handleAdauga = () => {
    setIncarca(true);
  
    const ore = (dateFormular.timp || 0);
    const minute = formatMinute(dateFormular.minute || 0);  
  
    //console.log('ore', ore);
    //console.log('min', minute);
    const timpFinal = (parseFloat(ore) + parseFloat(minute) / 100).toFixed(2);  
  
    //console.log('timpFinal', timpFinal);
 
    const dataFormata = formateazaDataPentruBackend(dateFormular.data);
    fetch(`${process.env.REACT_APP_API_URL}activitati?data=${dataFormata}&iddosar=${iddosar}&text=${dateFormular.text}&timp=${timpFinal}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => response.json())  
    .then(responseData => {
        setActivitati(prevActivitati => [...prevActivitati, responseData.data.activitateDosar]);
        setDateFormular({
            data: '',
            text: '',
            timp: ''
        });
        setModalDeschis(false);
        setIncarca(false);
        Swal('Succes!', 'Activitate adăugată cu succes!', 'success');
    })
    .catch(eroare => {
        setIncarca(false);
        Swal('Atenție!', 'A apărut o eroare!', 'warning');
        console.error('A apărut o eroare!', eroare);
    });
}


const editeazaActivitate = (id) => {
  setIncarca(true);

 

  const ore = (dateFormular.timp || 0);
  const minute = formatMinute(dateFormular.minute || 0);  

  //console.log('oreED', ore);
  //console.log('minED', minute);
  const timpFinal = (parseFloat(ore) + parseFloat(minute) / 100).toFixed(2);  

  const dataFormata = dateFormular.data instanceof Date ? formateazaDataPentruBackend(dateFormular.data) : dateFormular.data;
  //console.log('timpFinalED', timpFinal); 
  fetch(`${process.env.REACT_APP_API_URL}activitati/${id}?text=${dateFormular.text}&data=${dataFormata}&timp=${timpFinal}&iddosar=${iddosar}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  })
    .then(response => response.json())
    .then(responseData => {
      const activitateActualizata = responseData.data.activitateDosar;
      const activitatiActualizate = activitati.map(item => item.id === id ? activitateActualizata : item);
      setActivitati(activitatiActualizate);
      Swal('Succes!', 'Activitate actualizată cu succes!', 'success');
      setModalDeschis(false);
      setIncarca(false);
    })
    .catch(eroare => {
      Swal('Atenție!', 'A apărut o eroare!', 'warning');
      console.error('A apărut o eroare!', eroare);
      setIncarca(false);
    });
};


    const stergeActivitate = (id) => {
        Swal({
            title: 'Sunteți sigur?',
            text: 'Odată ștearsă, nu se mai poate recupera această activitate!',
            icon: 'warning',
            buttons: ['Anulează', 'Șterge'],
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                fetch(`${process.env.REACT_APP_API_URL}activitati/${id}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                })
                .then(() => {
                    const activitatiActualizate = activitati.filter(item => item.id !== id);
                    setActivitati(activitatiActualizate);
                    Swal('Ștearsă!', 'Activitatea a fost ștearsă.', 'success');
                })
                .catch(eroare => {
                    Swal('Atenție!', 'A apărut o eroare!', 'warning');
                    console.error('A apărut o eroare!', eroare);
                });
            } else {
                Swal('Activitatea nu a fost ștearsă!');
            }
        });
    }
  
    const deschideModalAdaugare = () => {
        setDateFormular({
          data: new Date(),
          text: '',
          timp: ''
        });
        setEditareId(null);
        setModalDeschis(true);
      };
    
      const deschideModalEditare = (activitate) => {
        const ore = Math.floor(activitate.timp);
        const minute = Math.round((activitate.timp - ore) * 100);
        //const dataActivitate = activitate.data.split(' ')[0];
        //const dataActivitate = formateazaDataPentruBackend(activitate.data);
        const dataActivitate = new Date(activitate.data); 
        setDateFormular({
          data: dataActivitate,
          text: activitate.text,
          timp: ore,
          minute: minute,
        });
        
        setEditareId(activitate.id);
        setModalDeschis(true);
      };
      
    
      const handleSalvare = () => {
        if (editareId) {
          editeazaActivitate(editareId);
        } else {
          handleAdauga();
        }
      };

 
      const isFormularValid = dateFormular.data && 
      dateFormular.text.trim() !== '' && 
      (dateFormular.timp !== undefined && dateFormular.timp.toString().trim() !== '') && 
      (dateFormular.minute !== undefined && dateFormular.minute.toString().trim() !== '');

const timpValid = Number(dateFormular.timp) !== NaN && isFinite(Number(dateFormular.timp));
const minuteValid = Number(dateFormular.minute) !== NaN && isFinite(Number(dateFormular.minute)) && parseInt(dateFormular.minute, 10) >= 0 && parseInt(dateFormular.minute, 10) < 60;

const isFormularCompletValid = isFormularValid && timpValid && minuteValid;

const oreOptions = Array.from({ length: 24 }, (_, i) => (
  <MenuItem key={i} value={i}>
    {i}
  </MenuItem>
));
const minuteOptions = Array.from({ length: 60 }, (_, i) => (
  <MenuItem key={i} value={i}>
    {i < 10 ? `0${i}` : i}
  </MenuItem>
));


      
      return (
       <div fullWidth> 
     
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Typography style={{fontWeight: 'bold', fontSize:'1.3em'}}>
                Timp total alocat: {oreTotale} ore și {minuteTotale} minute
            </Typography>
            {areDrept(DreptDeModificareDosare) ? ( 
              <Button disabled={!areDrept(DreptDeModificareDosare)} color="secondary" size="small" variant="contained" startIcon={<AddIcon />} onClick={deschideModalAdaugare}>
                  Adăugare activitate
              </Button>
            ) : null } 
        </div>

      <Dialog open={modalDeschis} onClose={() => setModalDeschis(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className='titluModal mb-3'>Adăugare / Editare activitate</DialogTitle>
        <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Data"
              value={dateFormular.data}
              onChange={(newValue) => {
                setDateFormular((prev) => ({ ...prev, data: newValue }));
              }}
              renderInput={(params) => <TextField {...params} />}
              inputFormat="dd/MM/yyyy" 
              format="dd/MM/yyyy" 
              mask="__/__/____"
              className='maxWidth mt-3'
            />
          </LocalizationProvider>
          <TextField margin="dense" label="Descriere" type="text" fullWidth multiline value={dateFormular.text} onChange={e => setDateFormular(prev => ({ ...prev, text: e.target.value }))} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              select
              label="Timp (ore)"
              margin="dense"
              value={dateFormular.timp}
              onChange={e => setDateFormular(prev => ({ ...prev, timp: e.target.value }))}
              style={{ flex: 1, marginRight: 10 }}
              SelectProps={{
                MenuProps: {
                  style: { maxHeight: 300 }, 
                },
              }}
            >
              {oreOptions}
            </TextField>

              <TextField
                select
                label="Timp (minute)"
                margin="dense"
                value={dateFormular.minute}
                onChange={handleMinuteChange}  
                style={{ flex: 1 }}
                SelectProps={{
                  MenuProps: {
                    style: { maxHeight: 300 }, 
                  },
                }}
              >
                {minuteOptions}
              </TextField>


          </div>


        </DialogContent>
        <DialogActions>
            <Button onClick={() => setModalDeschis(false)} color="error">Anulare</Button>
            <Button onClick={handleSalvare} color="success" disabled={!isFormularCompletValid}>{editareId ? "Actualizare" : "Adăugare"}</Button>
        </DialogActions>
      </Dialog>
 
       
            {incarca ? (
                <CircularProgress />
            ) : activitati.length === 0 ? (
                <Alert severity="info" className='mt-3'>Nu există activități înregistrate pentru acest dosar.</Alert>
            ) : (
                <List>
                {activitati.map(activitate => (
                    <Box key={activitate.id} mb={2} margin="5px 0" padding="1px" border="1px solid gray" borderRadius="5px">
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <ListItem>
                        <div style={{ flex: 9 }}>
                           <ListItemText 
                            primary={activitate.text} 
                            secondary={`Utilizator: ${activitate.denumireUser}, Dată activitate: ${formateazaData(activitate.data)}, Timp alocat: ${formateazaTimp(activitate.timp)}`} 
                          />
                        </div>
                        <div style={{ flex: 3 }}>
                        {areDrept(DreptDeModificareDosare) ? (
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="edit" disabled={!areDrept(DreptDeModificareDosare)} onClick={() => deschideModalEditare(activitate)}>
                               <EditIcon />
                            </IconButton>
                            <IconButton edge="end" aria-label="delete" disabled={!areDrept(DreptDeModificareDosare)} onClick={() => stergeActivitate(activitate.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                        ) : null }
                        </div>
                    </ListItem>
                    </div>
                    </Box>
                ))}
            </List>
            )} 


        </div>
    );
}

export default ActivitatiDosar;
