import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Box, TextField, Select, MenuItem, InputLabel, FormControl, CircularProgress, Button, Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { BounceLoader } from 'react-spinners';
import verificareToken from '../../VerificareToken';

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ro } from 'date-fns/locale'; 

export default function AdaugaDocumente({ setCurrentTab }) {
  const [refreshKey, setRefreshKey] = useState(0);
  const [isLoadingTipuri, setIsLoadingTipuri] = useState(false);

  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [dataDocument, setDataDocument] = useState(new Date());
  const [calitati, setCalitati] = useState([]);
  const [tipuri, setTipuri] = useState([]);
  const [selectedCalitate, setSelectedCalitate] = useState('');
  const [selectedTip, setSelectedTip] = useState('');
  const [descriere, setDescriere] = useState('');
  const [numeCalitate, setNumeCalitate] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [tipToAdd, setTipToAdd] = useState('');
  const [tipToEdit, setTipToEdit] = useState(null);
  const [editedTip, setEditedTip] = useState('');
  const [tipToDelete, setTipToDelete] = useState(null);
  const [idStadiu, setIdStadiu] = useState(null);

  const [tipDosarData, setTipDosarData] = useState(null);
    const { token } = verificareToken();

useEffect(() => {
  const fetcTiphDosar = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}getTipDosar/${id}`, {
         method: 'GET',
         headers: {
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${token}`,
         },
      });

      if (!response.ok) {
        throw new Error('Error fetching dosar data');
      }

      const data = await response.json();
      setTipDosarData(data.data.instanta);
    } catch (error) {
      console.error(error);
    }
  };

  
  fetcTiphDosar(); 
}, [id, token]);

  useEffect(() => {
    console.log("Id-urile calitatilor:", calitati.map(calitate => calitate.id));
  }, [calitati]);

  useEffect(() => {
    const fetchDocumenteDosar = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}infoDocumenteDosar/${id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        const {data} = await response.json();
        setCalitati(data.calitati);
        setTipuri(data.tipuriDocumente);
        setIdStadiu(data.idStadiu);
      } catch (error) {
        console.error("A problem occurred while fetching the data: ", error);
      }
      setIsLoading(false);
    };

    fetchDocumenteDosar();
  }, [id, token]);

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

      
  function formatDataForBackend(date) {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  }
  
  const handleSubmit = async () => {
    if (!tipDosarData) {
      if (!dataDocument || !selectedTip || !descriere) {
        Swal({
          icon: 'warning',
          title: 'Atenționare',
          text: 'Vă rugăm completați toate câmpurile obligatorii!',
        });
        return;
      }
    } else {
      if (!dataDocument || !selectedCalitate || !selectedTip || !descriere) {
        Swal({
          icon: 'warning',
          title: 'Atenționare',
          text: 'Vă rugăm completați toate câmpurile obligatorii!',
        });
        return;
      }
    }

    if (!selectedFile) {
      Swal({
        icon: 'warning',
        title: 'Atenționare',
        text: 'Vă rugăm selectați un fișier!',
      });
      return;
    }

    
    const formData = new FormData();
    formData.append('fisier', selectedFile);
    formData.append('idTipDocument', selectedTip);
    formData.append('idDosar', id);
    formData.append('data', new Date().toISOString().split('T')[0]);
    formData.append('descriere', descriere);
    formData.append('dataDocument', formatDataForBackend(dataDocument));
    if (tipDosarData) { formData.append('idcalitatedocument', Number(selectedCalitate)); }
    formData.append('numecalitate', numeCalitate);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}adaugaDocumentDosar`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });
      const {data} = await response.json();
      console.log(data);
      Swal({
        icon: 'success',
        title: 'Succes',
        text: 'Fișierul a fost încărcat cu succes!',
      });
      setCurrentTab(0);
      setRefreshKey((prevKey) => prevKey + 1);
    } catch (error) {
      console.error("A problem occurred while uploading the file: ", error);
    }
  };

  const handleAddTip = async (event) => {
    event.preventDefault();
  
    setIsLoadingTipuri(true);  
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}adaugaTip`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          idstadiu: idStadiu,
          denumire: tipToAdd,
        }),
      });
  
      if (!response.ok) {
        throw new Error('A avut loc o problemă la adăugarea tipului de document.');
      }
  
      const data = await response.json();
      setTipuri((prevTipuri) => [...prevTipuri, data.data.tipDocument]);
    } catch (error) {
      console.error('A apărut o eroare: ', error);
    } finally {
      setIsLoadingTipuri(false);   
    }
  };
  

 const handleEditTip = (tip) => {
    setTipToEdit(tip);
    setEditedTip(tip.denumire);
  };

  const saveEditedTip = async () => {
    if (editedTip.trim() === '') {
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}editeazaTip/${tipToEdit.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            idstadiu: idStadiu,
          denumire: editedTip,
        }),
      });
      const data = await response.json();
      console.log(data);
      setTipuri((prevTipuri) =>
        prevTipuri.map((tip) => (tip.id === tipToEdit.id ? data.data.tipDocument : tip))
      );
      setTipToEdit(null);
      setEditedTip('');
    } catch (error) {
      console.error("A problem occurred while saving the edited tip: ", error);
    }
  };
  

 
  const handleDeleteTip = async (tip) => {
    Swal({
      title: "Sunteți sigur?",
      text: "Odată șters, nu veți mai putea recupera acest tip de document!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}stergeTip/${tip.id}`, {
              method: 'DELETE',
              headers: {
              'Authorization': `Bearer ${token}`,
              },
          });
          console.log("Raspuns stergere: ", response);
          setTipuri((prevTipuri) => prevTipuri.filter((t) => t !== tip));
          Swal("Tipul de document a fost șters.", {
            icon: "success",
          });
        } catch (error) {
          console.error("Problema stergere tipDocument: ", error);
          Swal("A apărut o eroare la ștergerea tipului de document.", {
            icon: "error",
          });
        }
      } else {
        Swal("Tipul de document nu a fost șters!");
      }
    });
  };
  




  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };
 
  
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="10vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box key={refreshKey}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
        <DatePicker
          label="Data document"
          value={dataDocument}
          onChange={(newValue) => {
            setDataDocument(newValue);
          }}
          renderInput={(params) => <TextField {...params} fullWidth required />}
          inputFormat="dd/MM/yyyy" 
          format="dd/MM/yyyy" 
          mask="__/__/____"
          className='maxWidth'
        />
      </LocalizationProvider>

      {tipDosarData && [
            <FormControl fullWidth style={{ marginTop: '10px' }}>
              <InputLabel id="calitate-label">Parte document *</InputLabel>
              <Select
                labelId="calitate-label"
                id="calitate"
                value={selectedCalitate}
                label="Parte document"
                onChange={(e) => setSelectedCalitate(e.target.value)}
                required
              >
                {calitati.map((calitate) => (
                  <MenuItem key={calitate.id} value={calitate.id}>
                    {calitate.denumire}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
      ].filter(Boolean)} 
      <Box> 
        <b>
          Adăugare documente
        </b>
        <Box {...getRootProps()} textAlign="center" p={4} border="1px dashed grey" style={{cursor: 'pointer'}}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography>Trageți fișierul aici...</Typography>
          ) : (
            <Typography>
              Trageți fișierul aici sau apăsați pentru a selecta un fișier
            </Typography>
          )}
        </Box>
        {selectedFile && (
            <Box mt={2}>
                <Typography variant="subtitle1">Fișier selectat: {selectedFile.name}</Typography>
                <Typography
                variant="subtitle2"
                style={{ cursor: 'pointer', color: 'blue' }}
                onClick={() => setSelectedFile(null)}
                >
                Alegeți alt fișier
                </Typography>
            </Box>
        )}

      </Box>

      <Box style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
        <FormControl fullWidth style={{ marginTop: '10px' }}>
            <InputLabel id="tip-label">Tip document *</InputLabel>
            <Select
                required
                labelId="tip-label"
                id="tip"
                value={selectedTip}
                label="Tip document"
                onChange={(e) => setSelectedTip(e.target.value)}
            >
                {tipuri.length > 0 ? (
                    tipuri.map((tip) => (
                        <MenuItem key={tip.id} value={tip.id}>
                            {tip.denumire}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem value="" onClick={handleModalOpen}>
                        Nu aveți nici un tip document definit, definiți acum
                    </MenuItem>
                )}
            </Select>
        </FormControl>

        <Button
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<EditIcon />}
          style={{ marginLeft: '10px', marginTop: '10px' }}
          onClick={handleModalOpen}
        >
          Configurare Tipuri Documente
        </Button>
      </Box>

      <TextField
        fullWidth
        style={{ marginTop: '10px' }}
        required
        id="descriere"
        label="Descriere"
        multiline
        rows={4}
        value={descriere}
        onChange={(e) => setDescriere(e.target.value)}
      />

      <Button
        variant="contained"
        color="success"
        onClick={handleSubmit}
        style={{ marginTop: '10px' }}
        fullWidth
      >
        Salvare
      </Button>

      <Dialog open={openModal} onClose={handleModalClose}>
        <DialogTitle className='titluModal'>Administrare tipuri documente</DialogTitle>
        <DialogContent>
          
          <Box display="flex" alignItems="center">
                <TextField
                label="Adăugare tip document"
                value={tipToAdd}
                onChange={(e) => setTipToAdd(e.target.value)}
                fullWidth
                variant="outlined"
                margin="dense"
                size="small"
                disabled={isLoadingTipuri} // Dezactivează câmpul în timpul încărcării
                />

                <IconButton color="primary" onClick={handleAddTip} disabled={isLoadingTipuri}>
                {isLoadingTipuri ? (
                    <CircularProgress size={24} /> // Afișează loader în timpul încărcării
                ) : (
                    <CheckIcon />
                )}
                </IconButton>
            </Box>


        <Box style={{overflow: 'auto', maxHeight: '300px'}}>
            {tipuri.map((tip) => (
                <Box key={tip.id} display="flex" alignItems="center">
                    {tipToEdit === tip ? (
                        <>
                            <TextField
                            value={editedTip}
                            onChange={(e) => setEditedTip(e.target.value)}
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            size="small"
                            />
                            <IconButton color="primary" onClick={saveEditedTip}>
                            <CheckIcon />
                            </IconButton>
                            <IconButton color="error" onClick={() => {setTipToEdit(null); setEditedTip('');}}>
                            <CloseIcon /> 
                            </IconButton>
                        </>
                    ) : (
                        <>
                            <IconButton color="primary" onClick={() => handleEditTip(tip)}>
                            <EditIcon />
                            </IconButton>

                            <Typography>{tip.denumire}</Typography>
                        
                            <IconButton color="error" onClick={() => handleDeleteTip(tip)}>

                            <DeleteIcon />
                            </IconButton>
                        
                        </>
                    )}

                </Box>
            ))}

        </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>Renunțare</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
