import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Tab, Tabs, CircularProgress, Grid, Button, IconButton, Tooltip, Backdrop, Menu, MenuItem } from '@mui/material';

import InfoDosar from './infoDosar';
import StareDosar from './stareDosar';
import StadiuDosar from './stadiuDosar';
import PartiDosar from './partiDosar';
import TermeneDosar from './termeneDosar';
import LegislatieconexaDosar from './legislatieconexaDosar';
import DocumenteDosar from './documenteDosar';
import { Redirect } from 'react-router-dom';
import verificareToken from '../VerificareToken';
import ActivitatiDosar from './activitatiDosar';
import CosturiDosar from './costuriDosar';
import IstoricDosar from './istoricDosar';
import ContracteDosar from './contracteDosar';
import DocumentePrealabileDosar from './documentePrealabileDosar';
import DosarElectronic from './dosarElectronic';
import AsigurariDosar from './asigurariDosar';
import InfoProvenientaDosar from './infoProvenientaDosar';
import InfoActeContestate from './infoActeContestate';
import InfoObiecteDosar from './infoObiecteDosar';
import InfoObiecteDosarConsultanta from './infoObiecteDosarConsultanta';
import ConexareDosar from './conexareDosar';
import TransformareDosarInstanta from './TransformareDosarInstanta';
import FirmeBPI from './firmeBPI';
import Swal from 'sweetalert';
import SyncIcon from '@mui/icons-material/Sync';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import DrepturiUtilizator from '../utilizator/drepturiUtilizator'; 
import SincronizareManuala from './SincronizareManuala';
import SincronizareManualaSCJ from './SincronizareManualaSCJ';
import NotificationsIcon from '@mui/icons-material/CircleNotifications';
import NotificareDosar from './notificareDosar';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import CampuriCustomDosar from './campuriCustomDosar';

function EditareDosar() {

  //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
  window.dispatchEvent(new Event('authChange'));
  const [rowMonitorizat, setRowMonitorizat] = useState(null);
  const [rowSincronizat, setRowSincronizat] = useState(null);
  const [dosare, setDosare] = useState('');
  const [loadingInfo, setLoadingInfo] = useState(false);

  const [createModalOpen, setCreateModalOpen] = useState(false); 
  const handleCreateModalOpen = () => {
    setCreateModalOpen(true);
  };
 
  const handleCreateModalClose = () => {
    setCreateModalOpen(false);
  };
 

 //aduc hook-ul de drepturi
 const { areDrept } = DrepturiUtilizator();
 
 //setez drepturile pe aceasta zona
 const VizualizareCalitatiParti = 'VizualizareCalitatiParti';
 const VizualizareActeContestateDosar = 'VizualizareActeContestateDosar';
 const VizualizareActeDosar = 'VizualizareActeDosar';
 const VizualizareActivitateDosar = 'VizualizareActivitateDosar';
 const VizualizareAsigurariDosar = 'VizualizareAsigurariDosar';
 const VizualizareAtentionariDosar = 'VizualizareAtentionariDosar';
 const VizualizareContinutDosar = 'VizualizareContinutDosar';
 const VizualizareContracteDosar = 'VizualizareContracteDosar';
 const VizualizareCosturiStudiuDosare = 'VizualizareCosturiStudiuDosare';
 const VizualizareDosareElectronic = 'VizualizareDosareElectronic';
 const VizualizareDosarePrealabil = 'VizualizareDosarePrealabil';
 const VizualizareLegislatieDosar = 'VizualizareLegislatieDosar';
 const VizualizareObiecteDosar = 'VizualizareObiecteDosar';
 const VizualizarePartiStadiuDosar = 'VizualizarePartiStadiuDosar';
 const VizualizareProvenientaDosar = 'VizualizareProvenientaDosar';
 const VizualizareStadiiDosar = 'VizualizareStadiiDosar';
 const VizualizareTermeneStadiuDosar = 'VizualizareTermeneStadiuDosar'; 
 const VizualizareFirmeBPIDosar = 'VizualizareFirmeBPIDosar'; 
 const VizualizareIstoricActivitateDosar = 'VizualizareIstoricActivitateDosar'; 
  
 const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const changeTab = (index) => {
      setValue(index);
  };
  
  const { id } = useParams();
  const [dosarData, setDosarData] = useState(null);
  const [tipDosarData, setTipDosarData] = useState(null);
  
    const { token } = verificareToken();

  if (!token) {
    window.location.href = '/autentificare?er=201';
  }


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDosarId, setSelectedDosarId] = useState(null); 

  const handleOpenModal = (dosarId) => {
    setSelectedDosarId(dosarId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const fetchDosar = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}dosare/${id}`, {
         method: 'GET',
         headers: {
         'Content-Type': 'application/json',
         'Authorization': `Bearer ${token}`,
         },
      });

      if (!response.ok) {
        if (response.status === 401) {
          // daca rasp este 401 unauthorized, redirectionez utilizatorul
          window.location.href = '/lista-dosare';
        }
        throw new Error('Error fetching dosar data');
      }

      const data = await response.json();
      setDosare(data.data);
      setDosarData(data.data.sumarDosar);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {

    const fetcTiphDosar = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}getTipDosar/${id}`, {
           method: 'GET',
           headers: {
           'Content-Type': 'application/json',
           'Authorization': `Bearer ${token}`,
           },
        });

        if (!response.ok) {
          throw new Error('Error fetching dosar data');
        }

        const data = await response.json();
        setTipDosarData(data.data.instanta);
      } catch (error) {
        console.error(error);
      }
    };

    
    fetcTiphDosar();
    fetchDosar();
  }, [id, token]);


  const handleMonitorizareDosar = async (idDosar) => {
    const dosar = dosare;
    if (!dosar) {
      return;
    }

    const monitorizat = dosar.monitorizat;

    const monitorizareAPIURL = `${process.env.REACT_APP_API_URL}monitorizari/${id}?monitorizat=${monitorizat === 1 ? 0 : 1}`;
    const monitorizareAPIResponse = await fetch(monitorizareAPIURL, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (monitorizareAPIResponse.ok) {
      const mesajAlerta = monitorizat === 1 ? 'Dosarul a fost scos de la lista de monitorizare' : 'Dosarul a fost adăugat la lista de monitorizare';
      Swal('Succes', mesajAlerta, 'success');
      if (monitorizat === 1) {
        setRowMonitorizat(0);
      } else {
        setRowMonitorizat(1);
      }
      fetchDosar();
      const updatedDosar = {
        ...dosare,
        monitorizat: monitorizat === 1 ? 0 : 1,
      };
    
      setDosare(updatedDosar);
 
    } else {
      Swal('Atenționare', 'A apărut o eroare la monitorizarea dosarului!', 'warning');
    }
    
  };

  const handleSincronizareDosar = async (idDosar) => {
    const dosar = dosare;
    if (!dosar) {
      return;
    }
  
    const sincronizat = dosar.sincronizat;
  
    const sincronizareAPIURL = `${process.env.REACT_APP_API_URL}monitorizari/${id}?sincronizat=${sincronizat === 1 ? 0 : 1}`;
    const sincronizareAPIResponse = await fetch(sincronizareAPIURL, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  
    if (sincronizareAPIResponse.ok) {
      const mesajAlerta = sincronizat === 1 ? 'Dosarul a fost scos de la lista de sincronizare' : 'Dosarul a fost adăugat la lista de sincronizare';
      Swal('Succes', mesajAlerta, 'success');
      if (sincronizat === 1) {
        setRowSincronizat(0);
      } else {
        setRowSincronizat(1);
      }
      fetchDosar();
        const updatedDosar = {
          ...dosare,
          sincronizat: sincronizat === 1 ? 0 : 1,
        };
      
        setDosare(updatedDosar);
  
      
    } else {
      Swal('Atenționare', 'A apărut o eroare la sincronizarea dosarului!', 'warning');
    }
  };
  
  const sumarDosar = dosarData;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogOpenSCJ, setIsDialogOpenSCJ] = useState(false);
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    window.location.reload();
  };
  const handleOpenDialogSCJ = () => {
    setIsDialogOpenSCJ(true);
  };

  const handleCloseDialogSCJ = () => {
    setIsDialogOpenSCJ(false);
    window.location.reload();
  };

  //inregistrez ultimul dosar deschis
  useEffect(() => {
  const updateUltimDosar = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}setUltimulDosarDeschis`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ idDosar: id }),
      });

      if (!response.ok) {
        throw new Error('Eroare la actualizarea ultimului dosar');
      }

      const data = await response.json();
      console.log('Ultimul dosar actualizat cu succes:', data); // log pentru datele primite
    } catch (error) {
      console.error(error);
    }
  };  

   fetchDosar();
   updateUltimDosar();
 }, [id]); 
 

 const [anchorEl, setAnchorEl] = useState(null);

 const handleClick = (event) => {
   setAnchorEl(event.currentTarget);
 };

 const handleClose = () => {
   setAnchorEl(null);
 };

 const handleSyncSCJ = () => {
   console.log("Sincronizare SCJ", id);
   handleClose();
 };

 const handleSyncJustRo = () => {
   console.log("Sincronizare just.ro", id);
   handleClose();
 };

  if (!dosarData) {
    return <div className="card container mx-auto p-5 mt-5" style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '10vh',
      width: '100% !important' 
      }}> 
      <CircularProgress />
      </div>;  
  }


  return (
    <>
    <div  className="card container mx-auto p-4 mt-5">
        
        <Grid container style={{ display: 'flex', alignItems: 'center' }}>
            {tipDosarData && [<Grid item xs={3} style={{fontWeight:'bold'}}>
              Editare dosar nr: {sumarDosar.nrdosar}
              {sumarDosar.nrinterndosar && sumarDosar.nrinterndosar !== "--" && ` (Nr. intern: ${sumarDosar.nrinterndosar})`}
            </Grid>].filter(Boolean)}
            {!tipDosarData && [<Grid item xs={4} style={{fontWeight:'bold'}}>Editare dosar de consultanță nr: {sumarDosar.nrinterndosar}</Grid>].filter(Boolean)}
       
            {tipDosarData && [
               <Grid item xs={1} style={{ display: 'flex'}}>
                <Tooltip title="Sincronizați acum dosarul" placement='top'>
                  <IconButton size="small" onClick={handleClick}>
                    <CloudSyncIcon color='inherit' />
                  </IconButton>
                  <Menu
                    id="sync-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem size="small" onClick={() => handleOpenDialog(id)}>Sincronizare cu just.ro</MenuItem>
                    <MenuItem  onClick={() => handleOpenDialogSCJ(id)}>Sincronizare cu SCJ</MenuItem>
                  </Menu>
                </Tooltip>
                <SincronizareManuala
                  id={id}
                  nrDosar={sumarDosar.nrdosar}
                  open={isDialogOpen}
                  onClose={handleCloseDialog}
                  token={token}
                />
                
                <SincronizareManualaSCJ
                  id={id}
                  nrDosar={sumarDosar.nrdosar}
                  open={isDialogOpenSCJ}
                  onClose={handleCloseDialogSCJ}
                  token={token}
                />
              <Tooltip title={dosare.monitorizat === 1 ? "Eliminare dosar de la monitorizare" : "Monitorizare dosar"}>
                <IconButton size="small" onClick={() => handleMonitorizareDosar(id)}>
                  <RemoveRedEyeIcon color={dosare.monitorizat === 1 ? 'primary' : 'inherit'} />
                </IconButton>
              </Tooltip>
              <Tooltip title={dosare.sincronizat === 1 ? "Dezactivare sincronizare automată" : "Activare sincronizare automată"}>
                <IconButton size="small" onClick={() => handleSincronizareDosar(id)}>
                  <SyncIcon color={dosare.sincronizat === 1 ? 'primary' : 'inherit'} />
                </IconButton>
              </Tooltip>

             
            <Tooltip title="Editare notificări dosar">
              <IconButton variant="text" size="small" onClick={handleCreateModalOpen} >
                <EditNotificationsIcon color={'inherit'} />
              </IconButton>
            </Tooltip>
            <NotificareDosar open={createModalOpen} onClose={handleCreateModalClose} />
 
            </Grid>
           ].filter(Boolean)}

          {!tipDosarData && [
               <Grid item xs={1} style={{ display: 'flex'}}> 
            <Tooltip title="Editare notificări dosar">
              <IconButton variant="text" size="small" onClick={handleCreateModalOpen} >
                <EditNotificationsIcon color={'inherit'} />
              </IconButton>
            </Tooltip>
            <NotificareDosar open={createModalOpen} onClose={handleCreateModalClose} />
 
            </Grid>
           ].filter(Boolean)}

          <Grid item xs={7}> {/*style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}*/}
            {tipDosarData && [<><ConexareDosar /></>].filter(Boolean)}
            {!tipDosarData && [
             <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
              <Button onClick={() => handleOpenModal(id)}>Transformare dosar în instanță</Button>
                <TransformareDosarInstanta
                  open={isModalOpen}
                  onClose={handleCloseModal}
                  token={token}
                  idDosar={id}
                />
             </div>
            ].filter(Boolean)}
            
          </Grid> 

        </Grid>
        <hr></hr>
    
    <Box sx={{ display: 'flex', height: '100%' }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Meniu editare dosar"
          sx={{
            borderRight: 1,
            borderColor: 'divider',
            position: 'sticky',  
            top: '10px'  
          }}
        >  
          <Tab key={0}  label="Fișă dosar" value={0} />
          <Tab key={18} changeTab={changeTab} label="Câmpuri personalizate" value={18} />,

          <Tab key={1} label="Stare dosar" value={1} />,
          {tipDosarData && [
            areDrept(VizualizarePartiStadiuDosar) && <Tab key={3} label="Părți" value={3} />,
            areDrept(VizualizareStadiiDosar) && <Tab key={2} label="Stadiu" value={2} />,
            areDrept(VizualizareTermeneStadiuDosar) && <Tab key={4} label="Termene" value={4} />,
          ].filter(Boolean)}
          
          {!tipDosarData && [
              areDrept(VizualizareObiecteDosar) && <Tab key={16} label="Obiecte" value={16} />
          ].filter(Boolean)}          
          {tipDosarData && [
              areDrept(VizualizareObiecteDosar) && <Tab key={15} label="Obiecte" value={15} />
          ].filter(Boolean)}          

          {tipDosarData && [
            areDrept(VizualizareLegislatieDosar) && <Tab key={5} label="Legislație" value={5} />,
            areDrept(VizualizareFirmeBPIDosar) && <Tab key={6} label="Firme / BPI" value={6} />
          ].filter(Boolean)}

          {tipDosarData && [
            areDrept(VizualizareDosareElectronic) && <Tab key={19} label="Dosar electronic" value={19} />,  
          ].filter(Boolean)}

          {tipDosarData && [
            areDrept(VizualizareDosarePrealabil) && <Tab key={11} label="Dosar prealabil" value={11} />,  
          ].filter(Boolean)}

          {areDrept(VizualizareContinutDosar) ? ( <Tab  key={7} label="Conținut"  value={7} /> ) : null }

          {tipDosarData && [ 
            areDrept(VizualizareProvenientaDosar) && <Tab key={13} label="Proveniență" value={13} />, 
          ].filter(Boolean)}

          {tipDosarData && [  
            areDrept(VizualizareActeContestateDosar) && <Tab key={14} label="Acte contestate" value={14} />
          ].filter(Boolean)}

          {areDrept(VizualizareContracteDosar) ? ( <Tab  key={10} label="Contracte"  value={10} /> ) : null } 
          
          {tipDosarData && [
            areDrept(VizualizareCosturiStudiuDosare) && <Tab key={8} label="Costuri" value={8} />,
          ].filter(Boolean)}
          
          {areDrept(VizualizareActivitateDosar) ? ( <Tab  key={9} label="Activități"  value={9} /> ) : null }

          {tipDosarData && [ 
            //areDrept(VizualizareAsigurariDosar) && <Tab key={12} label="Asigurări" value={12} />,  
          ].filter(Boolean)}
 

          {areDrept(VizualizareIstoricActivitateDosar) && (
            <Tab key={17} label="Istoric activitate" value={17} />
          )}

   
      </Tabs>
       
        <TabPanel value={value} index={0}>
          <Box component="div"><InfoDosar changeTab={changeTab} /></Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box component="div"><StareDosar /></Box>
        </TabPanel>
        {areDrept(VizualizareStadiiDosar) ? (
          <TabPanel value={value} index={2}>
            <Box component="div"><StadiuDosar /></Box>
          </TabPanel>
        ) : null}
        {areDrept(VizualizarePartiStadiuDosar) ? (
          <TabPanel value={value} index={3}>
            <Box component="div"><PartiDosar /></Box>
          </TabPanel>
        ) : null}
        {areDrept(VizualizareTermeneStadiuDosar) ? (
          <TabPanel value={value} index={4}>
            <Box component="div"><TermeneDosar /></Box>
          </TabPanel>
        ) : null}
        {areDrept(VizualizareLegislatieDosar) ? (
          <TabPanel value={value} index={5}>
            <Box component="div"><LegislatieconexaDosar /></Box>
          </TabPanel>
        ) : null}
        {areDrept(VizualizareFirmeBPIDosar) ? (
          <TabPanel value={value} index={6}>
            <Box component="div"><FirmeBPI /></Box>
          </TabPanel>
        ) : null}
        {areDrept(VizualizareContinutDosar) ? (
          <TabPanel value={value} index={7}>
            <Box component="div"><DocumenteDosar /></Box>
          </TabPanel>
        ) : null}
        {areDrept(VizualizareCosturiStudiuDosare) ? (
          <TabPanel value={value} index={8}>
            <Box component="div"><CosturiDosar /></Box>
          </TabPanel>
        ) : null}
        {areDrept(VizualizareActivitateDosar) ? (
          <TabPanel value={value} index={9}>
            <Box component="div"><ActivitatiDosar /></Box>
          </TabPanel>
        ) : null}
        {areDrept(VizualizareContracteDosar) ? (
          <TabPanel value={value} index={10}>
            <Box component="div"><ContracteDosar /></Box>
          </TabPanel>
        ) : null}
        {areDrept(VizualizareDosareElectronic) ? (
          <TabPanel value={value} index={19}>
            <Box component="div"><DosarElectronic /></Box>
          </TabPanel>
        ) : null}
        {areDrept(VizualizareDosarePrealabil) ? (
          <TabPanel value={value} index={11}>
            <Box component="div"><DocumentePrealabileDosar /></Box>
          </TabPanel>
        ) : null}
        {areDrept(VizualizareAsigurariDosar) ? (
          <TabPanel value={value} index={12}>
            <Box component="div"><AsigurariDosar /></Box>
          </TabPanel>
        ) : null}
        {areDrept(VizualizareProvenientaDosar) ? (
          <TabPanel value={value} index={13}>
            <Box component="div"><InfoProvenientaDosar /></Box>
          </TabPanel>
        ) : null}
        {areDrept(VizualizareActeContestateDosar) ? (
          <TabPanel value={value} index={14}>
            <Box component="div"><InfoActeContestate /></Box>
          </TabPanel>
        ) : null}
         {tipDosarData && [
              areDrept(VizualizareObiecteDosar) && 
              <TabPanel value={value} index={15}>
                 <Box component="div"><InfoObiecteDosar /></Box>
              </TabPanel>
          ].filter(Boolean)} 
        {!tipDosarData && [
              areDrept(VizualizareObiecteDosar) && 
              <TabPanel value={value} index={16}>
                 <Box component="div"><InfoObiecteDosarConsultanta /></Box>
              </TabPanel>
          ].filter(Boolean)} 
        {areDrept(VizualizareIstoricActivitateDosar) ? (
          <TabPanel value={value} index={17}>
            <Box component="div"><IstoricDosar /></Box>
          </TabPanel>
        ) : null}

          <TabPanel value={value} index={18}>
            <Box component="div"><CampuriCustomDosar /></Box>
          </TabPanel>
    </Box>
   
 
    </div>
      {loadingInfo && (
        <Backdrop
          style={{ position: 'absolute', color: '#fff', zIndex: 1 }}
          open
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
    
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
 
}

export default EditareDosar;