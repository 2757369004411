
import React, { useEffect, useState, useCallback } from 'react';
import verificareToken from '../VerificareToken';
const DateCont = () => {
  const [dateCont, setDateCont] = useState({});
  //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
  window.dispatchEvent(new Event('authChange'));
   useEffect(() => {
    window.dispatchEvent(new Event('authChange'));
  }, []); 
    const { token } = verificareToken();
  
    const fetchData = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}dateCont`, {
        method: 'GET', 
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const {data} = await response.json();
      setDateCont(data);
      console.log('[Api] -> DateCont:', data);
    } catch (error) {
      console.error(error);
    }
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
 
 
  
   return (
    <div className=" container card p-4 ">
      <table className="table table-responsive table-striped table-condensed table-hover align-middle" cellSpacing="2" cellPadding="2">
 
        <tbody>
          <tr>
            <td className="col-6 align-middle">Licență aplicație</td>
            <td className="col-6 align-middle"><strong>{dateCont.status?.serialno}</strong></td>
          </tr>
          <tr>
            <td className="col-6 align-middle">Denumire cont</td>
            <td className="col-6 align-middle">{dateCont.status?.company}</td>
          </tr>
          <tr>
            <td className="col-6 align-middle">Versiune aplicație</td>
            <td className="col-6 align-middle"><strong>{dateCont.status?.appversion}</strong></td>
          </tr>
          <tr>
            <td className="col-6 align-middle">Versiune baze de date</td>
            <td className="col-6 align-middle"><strong>{dateCont.status?.dbversion}</strong></td>
          </tr>
          <tr>
            <td className="col-6 align-middle">Total dosare</td>
            <td className="col-6 align-middle">{dateCont.countAllDosare}</td>
          </tr>
          <tr>
            <td className="col-6 align-middle">Dosare Active</td>
            <td className="col-6 align-middle">{dateCont.countDosareActive}</td>
          </tr>
          <tr>
            <td className="col-6 align-middle">Dosare Suspendate</td>
            <td className="col-6 align-middle">{dateCont.countDosareSuspendate}</td>
          </tr>
          <tr>
            <td className="col-6 align-middle">Dosare Redeschise</td>
            <td className="col-6 align-middle">{dateCont.countDosareRedeschise}</td>
          </tr>
          <tr>
            <td className="col-6 align-middle">Dosare Perimate</td>
            <td className="col-6 align-middle">{dateCont.countDosarePerimate}</td>
          </tr>
          <tr>
            <td className="col-6 align-middle">Dosare Finalizate</td>
            <td className="col-6 align-middle">{dateCont.countDosareFinalizate}</td>
          </tr>
          <tr>
            <td className="col-6 align-middle">Dosare sincronizate</td>
            <td className="col-6 align-middle">{dateCont.countDosareSincronizate}</td>
          </tr>
          <tr>
            <td className="col-6 align-middle">Acces monitorizare dosare</td>
            <td className="col-6 align-middle"><strong>{dateCont.dateLicenta?.date?.mMonitDosare === '1' ? 'DA' : 'NU'}</strong></td>
          </tr>
          <tr>
            <td className="col-6 align-middle">Dosare monitorizate / Maxim monitorizabile</td>
            <td className="col-6 align-middle">{dateCont.countDosareMonitorizate} / <strong>{dateCont.dateLicenta?.date?.nrDosareMon}</strong></td>
          </tr>
          <tr>
            <td className="col-6 align-middle">Acces monitorizare B.P.I.</td>
            <td className="col-6 align-middle"><strong>{dateCont.dateLicenta?.date?.mBPI === '1' ? 'DA' : 'NU'}</strong></td>
          </tr>
          <tr>
          <td className="col-6 align-middle">Firme monitorizate B.P.I. / Maxim monitorizabile</td>
          <td className="col-6 align-middle">{dateCont.countFirmeMonitorizate} / <strong>{dateCont.dateLicenta?.date?.nrFirmeBPI}</strong></td>
          </tr>
          <tr>
            <td className="col-6 align-middle">Acces Legislație / Jurisprudență / Practică judiciară</td>
            <td className="col-6 align-middle"><strong>{dateCont.dateLicenta?.date?.mActeOficiale === '1' ? 'DA' : 'NU'}</strong> / <strong>{dateCont.dateLicenta?.date?.mJurisprudenta === '1' ? 'DA' : 'NU'}</strong> / <strong>{dateCont.dateLicenta?.date?.mPracticaJudiciara === '1' ? 'DA' : 'NU'}</strong></td>
          </tr>
          <tr>
            <td className="col-6 align-middle">Alerte SMS</td>
            <td className="col-6 align-middle"><strong>{dateCont.dateLicenta?.date?.mAlerteSMS === '1' ? 'DA' : 'NU'}</strong></td>
          </tr>
        </tbody>
        </table>
        
        </div>
        
  );
};

export default DateCont;
