import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { List, ListItem, ListItemText, CircularProgress, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, TextareaAutosize, Button, Box, Typography, Alert } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert';
import verificareToken from '../VerificareToken';
import DrepturiUtilizator from '../utilizator/drepturiUtilizator'; 

function AsigurariDosar() {
    const { areDrept } = DrepturiUtilizator(); 
    const DreptDeModificareDosare = 'DreptDeModificareDosare';
    
    const { id } = useParams();
    const [asigurari, setAsigurari] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [currentAsigurare, setCurrentAsigurare] = useState(null);
    const [formState, setFormState] = useState({
        clasaAsigurare: '',
        rezervaDosar: '',
        pretentiiMateriale: '',
        pretentiiMorale: '',
        altePretentii: ''
    });
    const [isAddingOrEditing, setIsAddingOrEditing] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;
      const { token } = verificareToken();

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}infoAsigurariDosar/${id}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            setAsigurari(data.data.asigurariDosar);
        } catch (error) {
            console.error('A apărut o eroare!', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id, token, apiUrl]);

    const handleOpen = (asigurare) => {
        if (asigurare) {
            setFormState({
                clasaAsigurare: asigurare.clasaAsigurare || '',
                rezervaDosar: asigurare.rezervaDosar || '',
                pretentiiMateriale: asigurare.pretentiiMateriale || '',
                pretentiiMorale: asigurare.pretentiiMorale || '',
                altePretentii: asigurare.altePretentii || ''
            });
        } else {
            setFormState({
                clasaAsigurare: '',
                rezervaDosar: '',
                pretentiiMateriale: '',
                pretentiiMorale: '',
                altePretentii: ''
            });
        }
        setCurrentAsigurare(asigurare);
        setOpen(true);
    };

    const handleClose = () => {
        setCurrentAsigurare(null);
        setFormState({
            clasaAsigurare: '',
            rezervaDosar: '',
            pretentiiMateriale: '',
            pretentiiMorale: '',
            altePretentii: ''
        });
        setOpen(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleAdaugaSauEditeaza = async () => {
        setIsAddingOrEditing(true);
    
        const queryParams = new URLSearchParams({
            clasaAsigurare: formState.clasaAsigurare,
            rezervaDosar: formState.rezervaDosar,
            pretentiiMateriale: formState.pretentiiMateriale,
            pretentiiMorale: formState.pretentiiMorale,
            altePretentii: formState.altePretentii,
        });
    
        try {
            if (currentAsigurare) {
                const response = await fetch(`${apiUrl}asigurariDosar/${currentAsigurare.id}?${queryParams}`, {
                    method: 'PATCH',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setAsigurari((prevAsigurari) => {
                    return prevAsigurari.map((asigurare) => {
                        if (asigurare.id === currentAsigurare.id) {
                            return data.data.asigurareDosar;
                        }
                        return asigurare;
                    });
                });
            } else {
                const response = await fetch(`${apiUrl}asigurariDosar?idDosar=${id}&${queryParams}`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setAsigurari((prevAsigurari) => [...prevAsigurari, data.data.asigurareDosar]);
            }
            handleClose();
            fetchData();
        } catch (error) {
            console.error('A apărut o eroare!', error);
        } finally {
            setIsAddingOrEditing(false);
        }
    };
    
    const handleSterge = async (asigurareId) => {
        setIsAddingOrEditing(true);

        Swal({
      title: 'Sigur doriți ștergerea?',
      text: 'Această acțiune nu poate fi anulată!',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                try {
                    await fetch(`${apiUrl}asigurariDosar/${asigurareId}`, {
                        method: 'DELETE',
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    setAsigurari(asigurari.filter((item) => item.id !== asigurareId));
                    Swal('Șters!', 'Asigurarea a fost ștearsă.', 'success');
                    fetchData();
                } catch (error) {
                    console.error('A apărut o eroare la ștergere!', error);
                } finally {
                    setIsAddingOrEditing(false);
                }
            } else {
                setIsAddingOrEditing(false);
            }
        });
    };

    if (loading) {
        return <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}><CircularProgress /></Box>;
    }

    return (
        <div>
            <div className='mb-3' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography style={{ fontWeight: 'bold', fontSize: '1.3em' }}>Asigurări dosar</Typography>
                {areDrept(DreptDeModificareDosare) ? ( 
                    <Button
                        disabled={!areDrept(DreptDeModificareDosare)}
                        onClick={() => handleOpen(null)}
                        variant="contained"
                        size="small"
                        color="secondary"
                        className='pull-right'
                        startIcon={<AddIcon />}
                    >
                        Adăugare asigurare nouă
                    </Button>
                ) : null } 
            </div>
            {asigurari.length === 0 ? (
                <Alert severity="info">Nu există asigurări atașate dosarului.</Alert>
            ) : (
                <List>
                    {asigurari.map((asigurare, index) => (
                        <ListItem
                        key={asigurare.id}
                        className={index % 2 === 0 ? 'list-item striped' : 'list-item'}
                    >
                          
                                <ListItemText
                                    primary={asigurare.clasaAsigurare}
                                    secondary={
                                        <Typography variant="body2">
                                            <div>
                                                Rezervă dosar: {asigurare.rezervaDosar}
                                            </div>
                                            <div>
                                                Pretentii materiale: {asigurare.pretentiiMateriale}
                                            </div>
                                            <div>
                                                Pretentii morale: {asigurare.pretentiiMorale}
                                            </div>
                                            <div>
                                                Alte pretentii: {asigurare.altePretentii}
                                            </div>
                                        </Typography>
                                    }
                                /> 

                                {areDrept(DreptDeModificareDosare) ? ( 
                                    <>
                                        <Tooltip title="Editare">
                                        <IconButton disabled={!areDrept(DreptDeModificareDosare)} aria-label="editeaza" onClick={() => handleOpen(asigurare)}>
                                            <EditIcon />
                                        </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Ștergere">
                                        <IconButton disabled={!areDrept(DreptDeModificareDosare)} aria-label="sterge" onClick={() => handleSterge(asigurare.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                        </Tooltip>
                                    </>
                                ) : null } 
                        </ListItem>
                    ))}
                </List>
            )}
            {/* dialog  de adaugare si editare */}
            <Dialog maxWidth="xl" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle className='titluModal' id="form-dialog-title">{currentAsigurare ? 'Editare asigurare' : 'Adăugare asigurare'}</DialogTitle>
                <DialogContent className='mt-3' style={{minWidth:'300px'}}>
                    <div className="input-section">
                    <Typography variant="h6" className="input-section-title">Clasa Asigurare</Typography>
                    <TextareaAutosize
                        autoFocus
                        id="clasaAsigurare"
                        placeholder="Clasa Asigurare"
                        minRows={3}
                        maxRows={6}
                        fullWidth
                        value={formState.clasaAsigurare}
                        onChange={handleInputChange}
                        name="clasaAsigurare"
                        className="textarea-style"
                    />
                    </div>
                    <div className="input-section">
                    <Typography variant="h6" className="input-section-title">Rezervă Dosar</Typography>
                    <TextareaAutosize
                        id="rezervaDosar"
                        placeholder="Rezervă Dosar"
                        minRows={3}
                        maxRows={6}
                        fullWidth
                        value={formState.rezervaDosar}
                        onChange={handleInputChange}
                        name="rezervaDosar"
                        className="textarea-style"
                    />
                    </div>
                    <div className="input-section">
                    <Typography variant="h6" className="input-section-title">Pretentii Materiale</Typography>
                    <TextareaAutosize
                        id="pretentiiMateriale"
                        placeholder="Pretentii Materiale"
                        minRows={3}
                        maxRows={6}
                        fullWidth
                        value={formState.pretentiiMateriale}
                        onChange={handleInputChange}
                        name="pretentiiMateriale"
                        className="textarea-style"
                    />
                    </div>
                    <div className="input-section">
                    <Typography variant="h6" className="input-section-title">Pretentii Morale</Typography>
                    <TextareaAutosize
                        id="pretentiiMorale"
                        placeholder="Pretentii Morale"
                        minRows={3}
                        maxRows={6}
                        fullWidth
                        value={formState.pretentiiMorale}
                        onChange={handleInputChange}
                        name="pretentiiMorale"
                        className="textarea-style"
                    />
                    </div>
                    <div className="input-section">
                    <Typography variant="h6" className="input-section-title">Alte Pretentii</Typography>
                    <TextareaAutosize
                        id="altePretentii"
                        placeholder="Alte Pretentii"
                        label="Alte Pretentii"
                        minRows={3}
                        maxRows={6}
                        fullWidth
                        value={formState.altePretentii}
                        onChange={handleInputChange}
                        name="altePretentii"
                        className="textarea-style"
                    />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                    onClick={handleAdaugaSauEditeaza}
                    color="primary"
                    disabled={isAddingOrEditing}
                    >
                    {isAddingOrEditing ? 'Se procesează...' : (currentAsigurare ? 'Salvare' : 'Adăugare')}
                    </Button>
                    <Button onClick={handleClose} color="primary">
                    Anulare
                    </Button>
                </DialogActions>
                </Dialog>

        </div>
    );
}

export default AsigurariDosar;
