import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { List, ListItem, Box, ListItemText, CircularProgress, Grid, FormControl, FormLabel, Typography, Button, Dialog, DialogTitle, DialogContent, TextField, RadioGroup, FormControlLabel, Radio, IconButton, DialogActions } from '@mui/material';
import verificareToken from '../VerificareToken';
import { DataGridPro, roRO } from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-license-pro';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Swal from 'sweetalert';
import { Alert } from '@mui/material';
import DrepturiUtilizator from '../utilizator/drepturiUtilizator'; 

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { ro } from 'date-fns/locale';

LicenseInfo.setLicenseKey('ac62ac97b94addeb6dbf2b2473e35ecbTz05MjE3MSxFPTE3NDk2MjQ2NjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

function ContracteDosar() {
  const { areDrept } = DrepturiUtilizator(); 
  const DreptDeModificareDosare = 'DreptDeModificareDosare';
  
  const { id } = useParams();
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedContract, setSelectedContract] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const currentContractRef = useRef(null);
  const [currentContractId, setCurrentContractId] = useState(null);
  const [updateKey, setUpdateKey] = useState(0);

  const [isAddingFactura, setIsAddingFactura] = useState(false);
  const [isAddingContract, setIsAddingContract] = useState(false);
  const [isSavingContract, setIsSavingContract] = useState(false);

  const [contractData, setContractData] = useState({
    nrContract: '',
    dataContract: null,
    valoareContract: '',
  });
  const [facturi, setFacturi] = useState([]);
  const [facturaData, setFacturaData] = useState({
    numarFactura: '',
    dataFactura: null, 
    numarPlata: '',
    dataPlata: null, 
    valoarePlata: '',
    tipPlata: 'op',
  });

    const [openFacturaDialog, setOpenFacturaDialog] = useState(false);
    const [openAddFacturaDialog, setOpenAddFacturaDialog] = useState(false);
    const [openEditFacturaDialog, setOpenEditFacturaDialog] = useState(false);
    const [currentFactura, setCurrentFactura] = useState(null);
    const [currentDate, setCurrentDate] = useState(new Date().toISOString().split('T')[0]);
    const [totalValoareFacturi, setTotalValoareFacturi] = useState(0);

    const { token } = verificareToken();
    const isFormValid = contractData.nrContract.trim() !== '' && String(contractData.valoareContract).trim() !== '';


  const fetchContracts = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}contracte/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error fetching contracts');
      }

      const data = await response.json();
      if (data && data.data && data.data.contracte) {
        setContracts(data.data.contracte);
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchContracts();
  }, [id, token]);

  useEffect(() => {
    if (selectedContract) {
      setLoading(true);
      const fetchFacturi = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}facturiContract/${selectedContract.id}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          if (!response.ok) {
            if (response.status === 404) {
              setFacturi([]); 
              setTotalValoareFacturi(0);
            } else {
              throw new Error('Error fetching invoices');
            }
          } else {
            const data = await response.json();
            if (data && data.data && data.data.facturi) {
              setFacturi(data.data.facturi);
  
              const sum = data.data.facturi.reduce((total, factura) => total + parseFloat(factura.valoareplata), 0);
  
              setTotalValoareFacturi(sum);
            }
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchFacturi();
    }
  }, [selectedContract, token]);


  const fetchFacturi = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}facturiContract/${selectedContract.id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Error fetching invoices');
      }
  
      const data = await response.json();
      if (data && data.data && data.data.facturi) {
        return data.data.facturi;
      }
      return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  const handleEditContract = (contract) => {
    setSelectedContract(contract);
    setContractData({
      nrContract: contract.nrcontract,
      dataContract: contract.datacontract ? new Date(contract.datacontract) : new Date(),
      valoareContract: Math.floor(contract.valoarecontract), 
    });
    setOpenDialog(true);
  };
  
  
  const handleSaveContract = async () => {
    setIsSavingContract(true);
    
    const formattedDate = contractData.dataContract.toISOString().split('T')[0];

     try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}contracte/${selectedContract.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          nrContract: contractData.nrContract,
          dataContract: formattedDate,
          valoareContract: contractData.valoareContract,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Error updating contract');
      }
  
      fetchContracts();
      handleDialogCloseAdd();
      setIsSavingContract(false);
      setSelectedContract(null);
    } catch (error) {
      console.error(error);
      setIsSavingContract(false);
    }
  };
 

  const handleDeleteContract = (contract) => {
    Swal({
      title: 'Sigur doriți ștergerea?',
      text: 'Această acțiune nu poate fi anulată!',
      icon: 'warning',
      buttons: ['Anulează', 'Da, șterge!'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteContract(contract);
      }
    });
  };
  
  const deleteContract = async (contract) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}contracte/${contract.id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Error deleting contract');
      }
  
      setSelectedContract(null);
      fetchContracts();
      
    } catch (error) {
      console.error(error);
    }
  };
// aduc date despre factura pe care o editez sa completez formularul de editare
const fetchFacturaData = async (facturaId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}facturiContractById/${facturaId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Error fetching factura data');
      }
  
      const data = await response.json();
      if (data && data.data && data.data.factura) {
        const factura = data.data.factura;
        setFacturaData({
          numarFactura: factura.numarfactura,
          dataFactura: factura.datafactura,
          numarPlata: factura.numarplata,
          dataPlata: factura.dataplata,
          valoarePlata: factura.valoareplata,
          tipPlata: factura.tipplata,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  
  const handleEditFactura = (factura) => {
    setCurrentFactura(factura);
    setOpenEditFacturaDialog(true);
    const initialDataFactura = factura.datafactura ? new Date(factura.datafactura) : new Date();
    const initialDataPlata = factura.dataplata ? new Date(factura.dataplata) : new Date();
  
    fetchFacturaData(factura.id).then(() => {
      setFacturaData(prevData => ({
        ...prevData,
        dataFactura: initialDataFactura,
        dataPlata: initialDataPlata,
        valoarePlata: Math.floor(factura.valoareplata).toString(),  
      }));
    });
  };
  
  // salvare modif factura curenta
  const handleSaveFactura = async () => {

    
    const dataFacturaFormatat = facturaData.dataFactura ? facturaData.dataFactura.toISOString().split('T')[0] : '';
    const dataPlataFormatat = facturaData.dataPlata ? facturaData.dataPlata.toISOString().split('T')[0] : '';
  

    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}facturiContract/${currentFactura.id}`;
  
      const response = await fetch(apiUrl, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          idContract: selectedContract.id,
          numarFactura: facturaData.numarFactura,
          dataFactura: dataFacturaFormatat,
          numarPlata: facturaData.numarPlata,
          dataPlata: dataPlataFormatat,
          valoarePlata: facturaData.valoarePlata,
          tipPlata: facturaData.tipPlata,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Error updating invoice');
      }
  
      // actualizez lista de facturi cu noile date
      const updatedFacturi = facturi.map((fact) => {
        if (fact.id === currentFactura.id) {
          return {
            ...fact,
            numarfactura: facturaData.numarFactura,
            datafactura: dataFacturaFormatat,
            numarplata: facturaData.numarPlata,
            dataplata: dataPlataFormatat,
            valoareplata: facturaData.valoarePlata,
            tipplata: facturaData.tipPlata,
          };
        }
        return fact;
      });
  
      setFacturi(updatedFacturi);
  
      const newTotalValoareFacturi = updatedFacturi.reduce((total, factura) => total + parseFloat(factura.valoareplata), 0);
      setTotalValoareFacturi(newTotalValoareFacturi);
  
      setOpenEditFacturaDialog(false);
    } catch (error) {
      console.error(error);
    }
  };
  
  const fetchSelectedContract = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}contracte/${selectedContract.id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Error fetching selected contract');
      }
  
      const data = await response.json();
      if (data && data.data && data.data.contracte) {
        setContracts(data.data.contracte);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleAddFacturaClick = () => {
    setOpenAddFacturaDialog(true);
  };
  

  const handleDeleteFactura = async (factura) => {
    Swal({
      title: 'Sigur doriți ștergerea?',
      text: 'Această acțiune nu poate fi anulată!',
      icon: 'warning',
      buttons: ['Anulează', 'Da, șterge!'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteFactura(factura);
      }
    });
  };
  
  const deleteFactura = async (factura) => {
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}facturiContract/${factura.id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Error deleting invoice');
      }
  
      if (selectedContract) {
        fetchFacturi();
      }
    } catch (error) {
      console.error(error);
    }
    const updatedFacturi = facturi.filter((fact) => fact.id !== factura.id);
    setFacturi(updatedFacturi);

    setTotalValoareFacturi((prevTotal) => prevTotal - parseFloat(factura.valoareplata));

      setFacturi(updatedFacturi);
            fetchContracts();
  };
  

  const handleDialogCloseAdd = () => {
    setSelectedContract(null);
    setOpenDialog(false);
  };
  const handleDialogCloseEdit = () => { 
    setOpenDialog(false);
  };

  const handleContractDataChange = (e) => {
    const { name, value } = e.target ? e.target : { name: e.name, value: e.value };
    if (name === 'valoareContract') {
      if (/^\d*$/.test(value)) {  
        setContractData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      setContractData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  
  const handleFacturaDataChange = (e) => {
    const { name, value } = e.target;
    if (name === 'valoarePlata') {
      if (/^\d*$/.test(value)) {  
        setFacturaData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      setFacturaData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  
//adaugare contract
  const handleAddContract = async () => {
    const formattedDate = contractData.dataContract.toISOString().split('T')[0];

    setIsAddingContract(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}contracte?idDosar=${id}&nrContract=${contractData.nrContract}&dataContract=${formattedDate}&valoareContract=${contractData.valoareContract}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error adding contract');
      }

 
      fetchContracts();
      handleDialogCloseAdd();
      setIsAddingContract(false);
      setSelectedContract(null);
  
    } catch (error) {
      console.error(error);
      setIsAddingContract(false);
    }
  };

  const handleAddFactura = async () => {

    const dataFacturaFormatat = facturaData.dataFactura ? facturaData.dataFactura.toISOString().split('T')[0] : '';
    const dataPlataFormatat = facturaData.dataPlata ? facturaData.dataPlata.toISOString().split('T')[0] : '';
  

    setIsAddingFactura(true); 
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}facturiContract?idContract=${selectedContract.id}&numarFactura=${facturaData.numarFactura}&dataFactura=${dataFacturaFormatat}&numarPlata=${facturaData.numarPlata}&dataPlata=${dataPlataFormatat}&valoarePlata=${facturaData.valoarePlata}&tipPlata=${facturaData.tipPlata}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Error adding invoice');
    }

    const data = await response.json();
    if (data && data.data && data.data.factura) {
      const facturaNoua = data.data.factura;
      setIsAddingFactura(false);
      setFacturi((prevFacturi) => [...prevFacturi, facturaNoua]);

      setTotalValoareFacturi((prevTotal) => prevTotal + parseFloat(facturaNoua.valoareplata));

      setOpenAddFacturaDialog(false); 
      fetchContracts();
    }
  } catch (error) {
    console.error(error);
    
    setIsAddingFactura(true);
  }
};

  const handleContractClick = (contract) => {
    setSelectedContract(contract);
    setCurrentContractId(contract.id); 
  };

  const formatDate = (date) => {
    if (!date) return '';  
    
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, '0');
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const year = d.getFullYear();
    
    return `${day}-${month}-${year}`;  
  };
  
 
  const facturiColumns = [
    { field: 'numarfactura', headerName: 'Nr. Fact.' },
    { 
      field: 'datafactura', 
      headerName: 'Emisă la',
      renderCell: (params) => formatDate(params.value),
      width: 150,
    },
    { field: 'numarplata', headerName: 'Nr. OP/Ch' },
    { field: 'valoareplata', headerName: 'Valoare OP/Ch' },
    { 
      field: 'dataplata', 
      headerName: 'Dată OP/Ch',
      renderCell: (params) => formatDate(params.value),
      width: 150,
    },
    {
      field: 'actions',
      headerName: 'Acțiuni',
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={() => handleEditFactura(params.row)}
            aria-label="Edit"
            color="danger"
            disabled={!areDrept(DreptDeModificareDosare)}
          >
             <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDeleteFactura(params.row)}
            aria-label="Delete"
            color="error"
            disabled={!areDrept(DreptDeModificareDosare)}
          >
             <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];
  

  return (
    <div id="contracte">
     <div className='mb-3' style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography style={{fontWeight: 'bold', fontSize:'1.3em'}}>Contracte dosar</Typography>
            {areDrept(DreptDeModificareDosare) ? ( 
              <Button
                disabled={!areDrept(DreptDeModificareDosare)}
                  onClick={() => {
                  setSelectedContract(null);
                  setOpenDialog(true);
                  setContractData({
                      nrContract: '',
                      dataContract: new Date(),
                      valoareContract: '',
                      });
                  }}
                  variant="contained"
                  size="small" 
                  color="secondary"
                  className='pull-right'
                  startIcon={<AddIcon />}
              >
                  Adaugare contract nou
              </Button>
            ) : null }
        </div>

        <Grid className='gridB' container spacing={4}>           
        <Grid item xs={12} sm={3}>             
        <List component="nav">
  {contracts.length > 0 ? (
    contracts.map((contract) => {
      return (
        <ListItem
                    key={contract.id}
                    button
                    ref={contract.id === selectedContract?.id ? currentContractRef : null} 
                    selected={selectedContract?.id === contract.id}
                    onClick={() => handleContractClick(contract)}
                  >
          <ListItemText
            primary={
              <Box>
                <Typography variant="subtitle1">
                    Ctr. nr. {contract.nrcontract} / {formatDate(contract.datacontract)}
                </Typography>
                <Typography variant="body2">
                  Valoare: {contract.valoarecontract}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {contract.nrFacturi === 0
                    ? 'Nu sunt facturi adăugate pe acest contract'
                    : contract.nrFacturi === 1
                    ? 'Contractul are 1 factură'
                    : `Contractul are ${contract.nrFacturi} facturi`}
                </Typography>
                {areDrept(DreptDeModificareDosare) ? ( 
                <div variant="caption" color="textSecondary" className='text-center' style={{display:'block', width:'100%'}}>
                   <Button disabled={!areDrept(DreptDeModificareDosare)} onClick={() => handleEditContract(contract)} color="primary">
                  Editare
                </Button>
                <Button disabled={!areDrept(DreptDeModificareDosare)} onClick={() => handleDeleteContract(contract)} color="error">
                  Ștergere
                </Button>
                </div>
                ) : null }
              </Box>
            }
          />
        </ListItem>
      );
    })
  ) : (
    <ListItem>
      <ListItemText
        primary={
            <Alert severity="warning" style={{ marginTop: '8px' }}>
                 Nu există contracte atașate acestui dosar.
            </Alert>
            
        }
      />
    </ListItem>
  )}
</List>
       
        </Grid>
        {/* dreapta */}
        <Grid item xs={12} sm={9}>
            {loading ? (  
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
                <CircularProgress />
                </div>
            ) : (
               <>
                {selectedContract && (
            <div className='mt-3'>
                 <Typography variant="h6" component="h2">
                    Gestionare facturi contract {selectedContract.nrcontract} / {formatDate(selectedContract.datacontract)}
                  </Typography>

                 
                <div key={updateKey}>
                   {/* facturi */}
                   <DataGridPro
                    loading={loading}
                    rows={facturi}
                    columns={facturiColumns}
                    pageSize={10}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } }, 
                    }}
                    columnVisibilityModel={{
                      actions: areDrept(DreptDeModificareDosare),
                    }}
                    localeText={{ 
                        ...roRO.components.MuiDataGrid.defaultProps.localeText, 
                      }}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    pagination
                    paginationMode="client"
                    autoHeight
                    getRowHeight={() => 'auto'}
                  />  <p className='text-right' style={{display:'block', width:'100%', textAlign:'right',  fontWeight:'bold'}}>Total valoare facturi la acest contract: {totalValoareFacturi} lei</p>
                  

                  </div>
                  {areDrept(DreptDeModificareDosare) ? ( 
                    <Button
                        disabled={!areDrept(DreptDeModificareDosare)}
                        onClick={() => {
                            setOpenAddFacturaDialog(true);
                            setFacturaData({
                            numarFactura: '',
                            dataFactura: new Date(),
                            numarPlata: '',
                            dataPlata: new Date(),
                            valoarePlata: '',
                            tipPlata: 'op',
                            });
                        }}
                        variant="contained"
                        color="secondary"
                        size='small'
                        className='pull-right mt-3'
                        >
                        Adaugă Factură Nouă
                        </Button>
                  ) : null }
              
                  {/* dialog factura noua */}
                  <Dialog
                    open={openAddFacturaDialog}
                    onClose={() => setOpenAddFacturaDialog(false)}
                >
                <DialogTitle className='titluModal'>+ Adăugare factură nouă</DialogTitle>
                <DialogContent>
                    <TextField
                    className='mt-3'
                    label="Număr Factură"
                    fullWidth
                    name="numarFactura"
                    value={facturaData.numarFactura} 
                    onChange={handleFacturaDataChange}
                    />
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
                      <DatePicker
                        label="Dată Factură"
                        value={facturaData.dataFactura}
                        onChange={(newValue) => {
                          setFacturaData((prevData) => ({
                            ...prevData,
                            dataFactura: newValue,
                          }));
                        }}
                        renderInput={(params) => <TextField {...params} fullWidth className="mt-3" />}
                        inputFormat="dd/MM/yyyy" 
                        format="dd/MM/yyyy" 
                        mask="__/__/____"
                        className='mt-2 maxWidth'
                      /> 
                    </LocalizationProvider>

                    <TextField
                    className='mt-3'
                    label="Număr OP/Chitanță"
                    fullWidth
                    name="numarPlata"
                    value={facturaData.numarPlata} 
                    onChange={handleFacturaDataChange}
                    />
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}> 
                      <DatePicker
                        label="Dată OP/Chitanță"
                        value={facturaData.dataPlata}
                        onChange={(newValue) => {
                          setFacturaData((prevData) => ({
                            ...prevData,
                            dataPlata: newValue,
                          }));
                        }}
                        renderInput={(params) => <TextField {...params} fullWidth className="mt-3" />}
                        inputFormat="dd/MM/yyyy" 
                        format="dd/MM/yyyy" 
                        mask="__/__/____"
                        className='mt-2 maxWidth'
                      />
                    </LocalizationProvider>
                    <TextField
                        className='mt-3'
                        label="Valoare OP/Chitanță"
                        fullWidth
                        name="valoarePlata"
                        value={facturaData.valoarePlata}
                        onChange={handleFacturaDataChange}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      />
                    <FormControl>
                    <FormLabel component="legend">Tip Plată</FormLabel>
                    <RadioGroup
                        name="tipPlata"
                        value={facturaData.tipPlata}
                        onChange={handleFacturaDataChange}
                    >
                        <FormControlLabel value="op" control={<Radio />} label="Ordin de plată" />
                        <FormControlLabel value="chitanta" control={<Radio />} label="Chitanță" />
                    </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddFactura} disabled={isAddingFactura} variant="contained" color="primary" size='small'>
                    Adaugare factură
                    </Button>
                    <Button onClick={() => setOpenAddFacturaDialog(false)} color="primary" size='small'>
                    Anulare
                    </Button>
                </DialogActions>
                </Dialog>
                {/*pentru editare factura dialog*/}
                <Dialog
                    open={openEditFacturaDialog}
                    onClose={() => setOpenEditFacturaDialog(false)}
                >
                    <DialogTitle className='titluModal'>Editare factură</DialogTitle>
                    <DialogContent>
                        <TextField
                        className='mt-3'
                        label="Număr Factură"
                        fullWidth
                        name="numarFactura"
                        value={facturaData.numarFactura}
                        onChange={handleFacturaDataChange}
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
                          <DatePicker
                            label="Dată Factură"
                            value={facturaData.dataFactura}
                            onChange={(newValue) => {
                              setFacturaData((prevData) => ({
                                ...prevData,
                                dataFactura: newValue,
                              }));
                            }}
                            renderInput={(params) => <TextField {...params} fullWidth className="mt-3" />}
                            inputFormat="dd/MM/yyyy" 
                            format="dd/MM/yyyy" 
                            mask="__/__/____"
                            className='mt-2 maxWidth'
                          /> 
                        </LocalizationProvider>
                        <TextField
                        className='mt-3'
                        label="Număr OP/Chitanță"
                        fullWidth
                        name="numarPlata"
                        value={facturaData.numarPlata}
                        onChange={handleFacturaDataChange}
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}> 
                        <DatePicker
                          label="Dată OP/Chitanță"
                          value={facturaData.dataPlata}
                          onChange={(newValue) => {
                            setFacturaData((prevData) => ({
                              ...prevData,
                              dataPlata: newValue,
                            }));
                          }}
                          renderInput={(params) => <TextField {...params} fullWidth className="mt-3" />}
                          inputFormat="dd/MM/yyyy" 
                          format="dd/MM/yyyy" 
                          mask="__/__/____"
                          className='mt-2 maxWidth'
                        />
                      </LocalizationProvider>
                        <TextField
                        className='mt-3'
                        label="Valoare OP/Chitanță"
                        fullWidth
                        name="valoarePlata"
                        value={facturaData.valoarePlata}
                        onChange={handleFacturaDataChange}
                        />
                        <FormControl>
                        <FormLabel component="legend">Tip Plată</FormLabel>
                        <RadioGroup
                            name="tipPlata"
                            value={facturaData.tipPlata}
                            onChange={handleFacturaDataChange}
                        >
                            <FormControlLabel value="op" control={<Radio />} label="Ordin de plată" />
                            <FormControlLabel value="chitanta" control={<Radio />} label="Chitanță" />
                        </RadioGroup>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleSaveFactura} variant="contained" color="primary" size='small'>
                        Salvare
                        </Button>
                        <Button onClick={() => setOpenEditFacturaDialog(false)} color="primary" size='small'>
                        Anulare
                        </Button>
                    </DialogActions>
                    </Dialog>

                </div>
          )}
          </>
            )}
            </Grid>
            
     
      </Grid> 

       {/* dialog editare / adaugare contract */}
       <Dialog open={openDialog} onClose={selectedContract ? handleDialogCloseEdit : handleDialogCloseAdd}>
        <DialogTitle  className='titluModal'>
            {selectedContract
            ? `Editare Contract`
            : '+ Adăugare Contract Nou'}
        </DialogTitle>
        <DialogContent>
          <TextField className='mt-3'
            label="Număr Contract"
            fullWidth
            name="nrContract"
            value={contractData.nrContract}
            onChange={handleContractDataChange}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
            <DatePicker
              label="Data Contract"
              value={contractData.dataContract}
              onChange={(newValue) => {
                handleContractDataChange({ name: 'dataContract', value: newValue });
              }}
              renderInput={(params) => <TextField {...params} fullWidth className='mt-3' />}
              inputFormat="dd/MM/yyyy" 
              format="dd/MM/yyyy" 
              mask="__/__/____"
              className='mt-2 maxWidth mb-3'
            />
          </LocalizationProvider>
          <TextField
              className='mt-3'
              label="Valoare Contract"
              fullWidth
              name="valoareContract"
              value={contractData.valoareContract}
              onChange={handleContractDataChange}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            />

        </DialogContent>
        <DialogActions>
            <Button
                onClick={() => (selectedContract ? handleSaveContract() : handleAddContract())}
                color="primary"
                disabled={!isFormValid || isAddingContract || isSavingContract}
                size='small'
            >
                {selectedContract ? 'Salvare' : 'Adăugare contract'}
            </Button> 
            <Button
                onClick={selectedContract ? handleDialogCloseEdit : handleDialogCloseAdd}
                color="primary"
                size='small'
            > Anulare </Button>
        </DialogActions>


      </Dialog>


    </div>
  );
  
}

export default ContracteDosar;
