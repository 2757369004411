import React, { useState, useEffect } from 'react';
import { Alert, Box, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography, CircularProgress, Autocomplete, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search'; 
import DrepturiUtilizator from './utilizator/drepturiUtilizator'; 
import Swal from 'sweetalert';
import DateFirma from './dosar/bpi/dateFirma';
import InsolventaFirma from './dosar/bpi/insolventaFirma';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import BusinessIcon from '@mui/icons-material/Business';
import Badge from '@mui/material/Badge';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import verificareToken from './VerificareToken';

import HelpDialog from './HelpDialog';

function ListaFirmeBPI({ isFirmeBPIOpen, closeFirmeBPI }) {
    const { token } = verificareToken();
    const [users, setUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [dates, setDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [firms, setFirms] = useState([]);
    const [loadingDates, setLoadingDates] = useState(false);
    const [loadingFirms, setLoadingFirms] = useState(false);
    const [searchText, setSearchText] = useState('');

    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedFirma, setSelectedFirma] = useState(null);
  
    const [monitorizariOpen, setMonitorizariOpen] = useState(false);
    const [firmeMonitorizate, setFirmeMonitorizate] = useState([]);
    const [loadingFirmeMonitorizate, setLoadingFirmeMonitorizate] = useState(false);

    const openMonitorizariBPI = () => {
        fetchFirmeMonitorizate();
        setMonitorizariOpen(true);
    };

    const closeMonitorizariBPI = () => {
        setMonitorizariOpen(false);
    };


    const handleOpenDialog = (cuiFirma) => {
      setSelectedFirma(cuiFirma);
      setDialogOpen(true);
    };
    
    const handleCloseDialog = () => {
      setSelectedFirma(null);
      setDialogOpen(false);
    };
    
    const [dialogOpenInsolventa, setDialogOpenInsolventa] = useState(false);
    const [selectedFirmaInsolventa, setSelectedFirmaInsolventa] = useState(null);
  
    const handleOpenDialogInsolventa = (cuiFirma) => {
      setSelectedFirmaInsolventa(cuiFirma);
      setDialogOpenInsolventa(true);
    };
    
    function handleCloseDialogInsolventa() {
      setSelectedFirmaInsolventa(null);
      setDialogOpenInsolventa(false);
    }

    const { areDrept } = DrepturiUtilizator();

    const fetchCurrentUserId = () => {
        let userId = localStorage.getItem('iduser') || sessionStorage.getItem('iduser');
    
        if (!userId) {
            const cookies = document.cookie.split('; ');
            const idUserCookie = cookies.find(row => row.startsWith('_iduserCjpc='));
            if (idUserCookie) {
                userId = idUserCookie.split('=')[1];
            }
        }
        return userId; 
    };

    useEffect(() => {
        const fetchUsers = async () => { 
            const response = await fetch(`${process.env.REACT_APP_API_URL}users`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (!response.ok) {
                console.error('Failed to fetch users');
                return;
            }
            const data = await response.json();
            setUsers(data.data.users);
    
            const userId = fetchCurrentUserId();
            const currentUser = data.data.users.find(user => user.id.toString() === userId);
            if (currentUser) {
                setSelectedUserId(currentUser.id);  
            }
        };
        fetchUsers();
    }, []);
    
    useEffect(() => {
        const fetchDates = async () => {
            if (!selectedUserId) return;
            setLoadingDates(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}bpilocalgetliste?userId=${selectedUserId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch dates');
                }
                const data = await response.json();
                if (data.status === 200) {
                    setDates(data.data.date.map(item => ({...item, numarFirme: item.firme ? item.firme.length : 0 })));
                    if (data.data.date.length > 0) {
                        setSelectedDate(data.data.date[0].data);
                    } else {
                        setSelectedDate('');
                    }
                } else {
                    throw new Error(`Status: ${data.status}`);
                }
            } catch (error) {
                console.error(error.message);
                setDates([]);  
                setSelectedDate('');  
            } finally {
                setLoadingDates(false);  
            }
        };
        fetchDates();
    }, [selectedUserId, token]);
    

    useEffect(() => {
        const fetchFirms = async () => {
            if (!selectedDate || !selectedUserId) return;
            setLoadingFirms(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}bpilocalgetliste?data=${selectedDate}&userId=${selectedUserId}`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                const { data } = await response.json();
                setLoadingFirms(false);
                if (data && data.date) {
                    setFirms(data.date); 
                } else {
                    setFirms([]); 
                }
            } catch (error) {
                console.error('Failed to fetch firms', error);
                setLoadingFirms(false);
                setFirms([]);
            }
        };
        fetchFirms();
    }, [selectedDate, selectedUserId, token]);
    
    const fetchFirmeMonitorizate = async () => {
        setLoadingFirmeMonitorizate(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}getFirmeMonitorizateForLoggedUser`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            const data = await response.json();
            setLoadingFirmeMonitorizate(false);
            if (data.status === 200) {
                setFirmeMonitorizate(data.data.allFirmeMonitorizate);
            } else {
                console.error('Failed to fetch monitored firms');
            }
        } catch (error) {
            console.error('Failed to fetch monitored firms', error);
            setLoadingFirmeMonitorizate(false);
        }
    };

    const confirmDelete = (firma) => {
        Swal({
            title: "Sigur vrei să elimini monitorizarea?",
            text: `Firma: ${firma.denumire} de la dosarul nr: ${firma.numarDosar} din BPI`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                deleteFirmaMonitorizata(firma.id);
            }
        });
    };

    const deleteFirmaMonitorizata = async (idFirma) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}stergemonitorizare/${idFirma}`, {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.ok) {
                setFirmeMonitorizate(firmeMonitorizate.filter(firma => firma.id !== idFirma));
                fetchFirmeMonitorizate();
            } else {
                console.error('Failed to delete monitored firm'); 
            }
        } catch (error) {
            console.error('Failed to delete monitored firm', error);
            fetchFirmeMonitorizate();
        }
    };
    
    return (
        <Dialog open={isFirmeBPIOpen} maxWidth="lg" fullWidth>
            <DialogTitle className='titluModal mb-2'  alignItems="center">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <div>
                  
           Listă firme monitorizate în BPI   
                <HelpDialog
                    title="VIZUALIZARE FIRME MONITORIZATE"
                    contentType="video"
                    content="https://www.ctce.ro/important/juristpronext/tutoriale/notificari-calendar.mp4" 
                    tooltip="Ajutor: Cum vizualizăm situația firmelor monitorizate în BPI?"
                /> 
           
                
                </div>
                <div>
                    <TextField
                        size="small"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        style={{  marginRight: 8, backgroundColor:'#FFFFFF', borderRadius:'5px' }}
                        placeholder="Căutare firmă..."
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <IconButton onClick={closeFirmeBPI} size="small" style={{color: '#FFFFFF'}}>
                    <CloseIcon />
                    </IconButton>
                </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={3} style={{borderRight:'1px solid #CCC'}}>
                        <Autocomplete
                            className='mt-2 mb-1'
                            disabled={!areDrept('AdaugareUtilizatori')} 
                            options={users}
                            getOptionLabel={(option) => `${option.fullname} (${option.name})`}
                            style={{ maxWidth: '250px' }}
                            size="small"
                            value={users.find(user => user.id === selectedUserId) || null} 
                            onChange={(event, newValue) => {
                                setSelectedUserId(newValue ? newValue.id : null);
                            }}
                            renderInput={(params) => <TextField {...params} label="Utilizator" />}
                        />

                        <Box style={{maxHeight:'500px', height:'100%', overflow:'auto'}}>
                        {loadingDates ? (
                            <Box display="flex" justifyContent="center" alignItems="center" minHeight={100}>
                                <CircularProgress size={24} />
                            </Box>
                        ) : dates.length === 0 ? (
                            <Alert severity="warning" style={{ width: '100%' }}>Pentru utilizatorul selectat nu sunt date în BPI.</Alert>
                        ) : (

                                dates.map(date => {
                                    const dataFormatata = new Date(date.data).toLocaleDateString('ro-RO', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                    });

                                    const numarFirme = date.numarFirme || 0;

                                    return (
                                        <Typography key={date.data} onClick={() => setSelectedDate(date.data)} 
                                                    className={`dataApi ${date.data === selectedDate ? 'dataCurentaSelectata' : ''}`}
                                                    style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            {dataFormatata}
                                            <Badge badgeContent={numarFirme} color="primary" />
                                        </Typography>
                                    );
                                })

                        )}
                        </Box>
                    </Grid>
                    <Grid item xs={9} style={{paddingLeft:'15px'}}>
                    {loadingFirms ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height={200}>
                            <CircularProgress size={30}/>
                        </Box>
                    ) : (
                        firms.filter(firm => firm.denumirefirma.toLowerCase().includes(searchText.toLowerCase()) || firm.cif.includes(searchText)).map(firm => (
                            <Box className="boxActe mt-3" key={firm.cif} border={1} borderRadius={2} padding={2} marginBottom={2} display="flex" justifyContent="space-between" alignItems="center">
                                <Typography> {firm.denumirefirma} - {firm.cif} {firm.nou ? <Badge badgeContent='Modificări noi' style={{minWidth:'90px'}} color="primary" /> : ' '}</Typography> 
                                <div>
                                    <Tooltip title={`Vizualizare date firmă: ${firm.denumirefirma}${firm.cif}`}>
                                        <IconButton onClick={() => handleOpenDialog(firm.cif)} aria-label="Vizualizere firmă">
                                        <BusinessIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={`Vizualizare firmă în BPI: ${firm.denumirefirma}${firm.cif}`}>
                                        <IconButton onClick={() => handleOpenDialogInsolventa(firm.cif)} aria-label="Vizualizere firmă în BPI">
                                        <CurrencyExchangeIcon />
                                        </IconButton>
                                    </Tooltip> 
                                </div>
                                {dialogOpen && selectedFirma === firm.cif && (
                                <DateFirma open={dialogOpen} onClose={handleCloseDialog} cui={selectedFirma} />
                                )}
                                {dialogOpenInsolventa && selectedFirmaInsolventa === firm.cif && (
                                <InsolventaFirma open={dialogOpenInsolventa} onClose={handleCloseDialogInsolventa} cui={selectedFirmaInsolventa} />
                                )}
                            </Box>
                        ))
                    )}
                    </Grid> 
                </Grid>
            </DialogContent>
            
            <DialogActions>
                <Box display="flex" justifyContent="space-between" width="100%">
                    <Box>
                        <Button size="small" onClick={openMonitorizariBPI}>Administrare monitorizări BPI</Button>
                    </Box>
                    <Box>
                        <Button size="small" onClick={closeFirmeBPI} color='error'>Închidere</Button>
                    </Box>
                </Box>
            </DialogActions>
  

            <Dialog open={monitorizariOpen} maxWidth="md" fullWidth>
                <DialogTitle className='titluModal'>Administrare monitorizări BPI</DialogTitle>
                <DialogContent className='mt-2'  style={{maxHeight:'400px', overflow:'auto'}}>
                    {loadingFirmeMonitorizate ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height={200}>
                            <CircularProgress size={30} />
                        </Box>
                    ) : (
                        <Box>
                            {firmeMonitorizate.length === 0 ? (
                                <Alert severity="info">Nu există firme monitorizate în BPI.</Alert>
                            ) : (
                                firmeMonitorizate.map(firma => (
                            <Box key={firma.id} display="flex" flexDirection="row" justifyContent="space-between" borderBottom={1} padding={1} marginBottom={2} alignItems="center">
                                <Box display="flex" flexDirection="column">
                                    <Typography variant="h6">{firma.denumire} - <i>(Dosar {firma.numarDosar})</i></Typography>
                                    <Typography variant="body2" color="textSecondary">Email Monitorizare: {firma.emailmonitorizare}</Typography>
                                    <Typography variant="body2" color="textSecondary">CUI: {firma.codcui}</Typography>
                                    <Typography variant="body2" color="textSecondary">Registrul comertului: {firma.codrecom}</Typography>
                                    <Typography variant="body2" color="textSecondary">Adresa: {firma.adresa}</Typography>
                                    <Typography variant="body2" color="textSecondary">Localitate: {firma.localitate}</Typography>
                                    <Typography variant="body2" color="textSecondary">Județ: {firma.judet}</Typography>
                                    <Typography variant="body2" color="textSecondary">Stare: {firma.stare_firma.denumire}</Typography>
                                </Box>
                                <div>
                                <Tooltip title="Eliminare monitorizare firmă BPI">
                                    <IconButton color="secondary" onClick={() => confirmDelete(firma)} style={{ marginLeft: '16px' }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>  
                                </div>
                                </Box>
                             ))
                            )}
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeMonitorizariBPI}>Închidere</Button>
                </DialogActions>
            </Dialog>

        </Dialog>
    );
    }
    
    export default ListaFirmeBPI;
    