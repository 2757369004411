import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Button, Switch, FormControlLabel, FormGroup, Divider, Grid } from '@mui/material';
import Swal from 'sweetalert';
import verificareToken from '../VerificareToken';
import { userEventEmitter } from '../EventEmitter';

function ProfilUtilizator() {
  //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
  window.dispatchEvent(new Event('authChange'));
  const [confirmParola, setConfirmParola] = useState('');

  const validateAndSubmit = () => {
    if (editParola) {
      if (user.parola === confirmParola) {
        updateProfile();
      } else {
        Swal('Atenționare', 'Parolele nu coincid!', 'warning');
      }
    } else {
      updateProfile();
    }
  };


const updateUserName = (newName) => {
    // Logica pentru actualizarea numelui...
    userEventEmitter.emit('userNameChanged', newName);
  };
    const { token } = verificareToken();
    
    const [user, setUser] = useState({
      fullname: '',
      email: '',
      telefon: '',
      parola: '',
      parola1: '',
      parola2: '',
      confirmParola: '',
      editParola: false,
    });
    const [editParola, setEditParola] = useState(false);
  
    // preluare date utilizator curent
    
    const fetchUserData = useCallback(async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}user`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });
        const data = await response.json();
        if (data.status === 200) {
          setUser({ ...data.data.user, parola: '' });
        } else {
            Swal('Atenționare', 'Datele utilizatorului dumneavoastră nu au fost găsite! Vă rugăm să reîncărcați pagina.', 'warning');
        }
      } catch (error) {
        console.error('Eroare la preluarea datelor utilizatorului:', error);
      }
    }, [token]);
  
    // actualizare date utilizator
 
    const showErrorAlert = (errorMessages) => {
        let errorMessageHtml = 'Datele nu au fost actualizate cu succes!\n';
      
        Object.keys(errorMessages).forEach((key) => {
          if (Array.isArray(errorMessages[key])) {
            errorMessages[key].forEach((message) => {
              errorMessageHtml += `\n${message}`;
            });
          } else {
            errorMessageHtml += `\n${errorMessages[key]}`;
          }
        });
      
        Swal({
          title: 'Atenționare',
          text: errorMessageHtml,
          icon: 'warning',
        });
      };
      
    const updateProfile = async () => {
      try {
        let queryParams = new URLSearchParams({
          fullname: user.fullname,
          email: user.email,
          telefon: user.telefon,
        });
    
        if (editParola) {
          queryParams.append('password', user.parola);
        }
    
        const url = `${process.env.REACT_APP_API_URL}updateUserProfile?${queryParams.toString()}`;
    
        const response = await fetch(url, {
          method: 'POST',  
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
     
        if (response.ok) {
          Swal('Succes', 'Datele au fost actualizate cu succes!', 'success');
          updateUserName(user.fullname);
          setEditParola(false);
          window.dispatchEvent(new Event('authChange'));
        } else {
            const data = await response.json(); 
            showErrorAlert(data.message);
        }
      } catch (error) {
        console.error('Eroare la actualizarea profilului:', error);
      }
    };
  
    useEffect(() => {
      fetchUserData();
    }, [fetchUserData]);
  
    return (
        <div className="card container mx-auto p-5 mt-5">
        <h3>Profil utilizator</h3> <hr />
        
        <Grid container direction="column" spacing={2} className="mt-3">
        <TextField
          label="Nume complet"
          value={user.fullname}
          onChange={(e) => setUser({ ...user, fullname: e.target.value })}
          className="mt-3"
        />
        <TextField
          label="E-mail"
          value={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
          className="mt-3"
        />
        <TextField
          label="Telefon"
          value={user.telefon}
          onChange={(e) => setUser({ ...user, telefon: e.target.value })}
          className="mt-3"
        />
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={editParola} onChange={(e) => setEditParola(e.target.checked)} />}
            label="Modificare parola"
          />
          {editParola && (
            <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextField
                label="Parolă nouă"
                type="password"
                name="parola1"
                fullWidth
                value={user.parola}
                onChange={(e) => setUser({ ...user, parola: e.target.value })}
                className="mt-3"
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                label="Confirmare parolă nouă"
                type="password"
                name="parola2"
                fullWidth
                value={confirmParola}
                onChange={(e) => setConfirmParola(e.target.value)}
                className="mt-3"
                />
            </Grid>
            </Grid>
        )}
        </FormGroup>
        <Divider />
        <Button variant="contained" color="secondary" size="small" onClick={validateAndSubmit} className="mt-3">
          Actualizare date
        </Button>

        </Grid>
      </div>
    );
  };
  
  export default ProfilUtilizator;
  