import React, { useState, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import verificareToken from '../VerificareToken';
const Stocare = () => {
  const [data, setData] = useState({}); 
    //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
    window.dispatchEvent(new Event('authChange'));
    const { token } = verificareToken();

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}dateCont`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const fetchedData = await response.json();
      
      setData(fetchedData.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  });

  const quotaPercentage = data.quotaPercentage?.percent || 0;
  const usedStorage = data.quotaPercentage?.used || '0 KB';
  const totalStorage = data.quotaPercentage?.total || '0 MB';
  const nrFirmeBPI = data.dateLicenta?.date?.nrFirmeBPI || 0;
  const nrDosareMon = data.dateLicenta?.date?.nrDosareMon || 0;

  return (
    <div className="mt-4">
      <h5>Stocare în cloud disponibilă</h5>
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '80%', mr: 1 }}>
          <LinearProgress sx={{ height: '20px' }} variant="determinate" value={parseFloat(quotaPercentage)} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="text.secondary">
            {quotaPercentage}% ({usedStorage} / {totalStorage})
          </Typography>
        </Box>
      </Box>
      <h5 className="mt-5">Număr firme monitorizate în BPI: {nrFirmeBPI}</h5>
      <h5 className="mt-3">Număr dosare monitorizate: {nrDosareMon}</h5>
      
    </div>
  );
};

export default Stocare;
