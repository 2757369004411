import React, { useState, useEffect, useRef  } from 'react';
import verificareToken from './VerificareToken';
import DrepturiUtilizator from './utilizator/drepturiUtilizator'; 
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import roLocale from './js/ro';
import Modal from '@mui/material/Modal';
import { Alert, Dialog, Autocomplete, Chip, DialogContent, InputAdornment, Tooltip, Grid, Typography, IconButton, Button, TextField, Switch, Select, MenuItem, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, FormControl, FormControlLabel, InputLabel, CircularProgress, DialogTitle, DialogActions } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Icon } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faApple } from '@fortawesome/free-brands-svg-icons';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'; 
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import usePreferinteUtilizator from './utilizator/preferinteUtilizatorHook'; 
import SearchIcon from '@mui/icons-material/Search';
import Swal from 'sweetalert';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parseISO, parse, isPast, endOfDay  } from 'date-fns';
import { ro } from 'date-fns/locale';
import { DataGridPro, useGridApiRef, GridToolbarExportContainer,  GridToolbar, GridToolbarContainer, GridToolbarExport, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton, useGridSlotComponentProps, roRO, getGridStringOperators,   GridColDef,   GridFilterOperator   } from '@mui/x-data-grid-pro'; 
import { v4 as uuidv4 } from 'uuid';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/system';
import HelpDialog from './HelpDialog';
import { ExportInExcel } from './ExportInExcel';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
 
import { callAddFont } from './fonts/DejaVuSans-normal';
const RaportEvenimente = () => {
  
  const { token } = verificareToken();
  //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
  window.dispatchEvent(new Event('authChange'));
  const [dateFieldName, setDateFieldName] = useState('any');
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    descriere: true,
    dosar: true,
    title: true,
    client: true,
    parteAdversa: true,
    complet: true,
    instanta: true,
    sala: true,
    instanta: true,
    tipsolutie: true,
    sumarsolutie: true,
    tip: true,
  });
  
const currentDate = new Date();
const formattedDate = currentDate.toLocaleDateString('ro-RO').replace(/\//g, '-');
const formattedTime = currentDate.toLocaleTimeString('ro-RO', { hour12: false }).replace(/:/g, '-');
const formattedTime2 = currentDate.toLocaleTimeString('ro-RO', {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false  
});
  const [showHeader, setShowHeader] = useState(false);
  const [showConfidential, setShowConfidential] = useState(false); 
  const [usercurentgrup, setUsercurentgrup] = useState([]);
  const [usercurentnume, setUsercurentnume] = useState([]);
  function getKeysFromLookup(obj: GridFilterState['filteredRowsLookup']) {
    const keys = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key]) {
        keys.push(key);
      }
    }
    return keys;
  }

  const fileNameExcel = `JuristPRO NEXT - Export EXCEL - ${formattedDate} - ${formattedTime}`;
  const fileNameCSV = `JuristPro NEXT - CSV Export lista dosare - ${formattedDate} - ${formattedTime}`;
  const formatUserGroups = (userGroups) => {
      if (!userGroups || userGroups.length === 0) return 'Fără grup';
      return userGroups.map(group => group.name).join(', ');
    };
   
  
  function getDenAppVers() {
    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
      return null; 
    }
  
    let denAppVers = getCookie('DenAppVers');
  
    if (!denAppVers) {
      denAppVers = localStorage.getItem('DenAppVers');
    }
  
    if (!denAppVers) {
      denAppVers = sessionStorage.getItem('DenAppVers');
    }
  
    return denAppVers || 'V2';  
  }
  
  let denAppVers = getDenAppVers(); 
const [dosareFiltrate, setDosareFiltrate] = React.useState([]);
const [sortModel, setSortModel] = useState([]);

  const [isLoading, setLoading] = useState(true); 
  const [view, setView] = useState('dayGridMonth');
  const calendarRef = useRef(null);
    
  const { areDrept } = DrepturiUtilizator(); 
  const AdaugareUtilizatoriDrept = 'AdaugareUtilizatori'; 
  const [events, setEvents] = useState([]);

  // Calculează datele pentru începutul și sfârșitul lunii curente
  const initialStartDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('T')[0];
  const initialEndDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().split('T')[0];

  // Inițializează state-ul cu valorile calculate
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  
  const handleDateChangeStart = (date) => {
    setStartDate(date ? format(date, 'yyyy-MM-dd') : null); // Păstrăm formatul ISO în state
  };

  const handleDateChangeEnd = (date) => {
    setEndDate(date ? format(date, 'yyyy-MM-dd') : null); // Păstrăm formatul ISO în state
  };


  const [groupedOptions, setGroupedOptions] = useState([]);
  const [selectedTermenEvent, setSelectedTermenEvent] = useState(null); 
  const [selectedDateFinal, setSelectedDateFinal] = React.useState(new Date());
  //culori evenimente calendar
  const { preferinte, loaded } = usePreferinteUtilizator();
  const colorCT = preferinte.colorCT;
  const colorCA = preferinte.colorCA;  
  const colorCU = preferinte.colorCU;  
  //end  
 
const stringFilterOperators = getGridStringOperators();
const removeDiacritics = (str: string) => {
  if (!str) {
    return str;
  }
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};
  useEffect(() => {
    const updateButtonClasses = () => {
      const buttons = document.querySelectorAll('.fc-button');
      buttons.forEach(button => {
        button.classList.remove('fc-button-active', 'fc-button-inactive');
      });

      const activeButtonSelector = {
        dayGridMonth: '.fc-myMonthButton-button',
        listWeek: '.fc-myWeekButton-button',
        listDay: '.fc-myDayButton-button'
      }[view];

      if (activeButtonSelector) {
        document.querySelector(activeButtonSelector)?.classList.add('fc-button-active');
      }

      buttons.forEach(button => {
        if (!button.classList.contains('fc-button-active')) {
          button.classList.add('fc-button-inactive');
        }
      });
    };

    updateButtonClasses();
  }, [view]);
   
 
const prepareDataForExport2 = (data, columns, columnVisibilityModel) => {
    return data.map((row) => {
      const formattedRow = {};
      columns.forEach((col) => {
        if (columnVisibilityModel[col.field]) {
          const displayName = col.headerName; // Denumirea din columns
          
          // Aici poți decide ce date să adaugi
          if (col.field === 'user_groups') {
            formattedRow[displayName] = formatUserGroups(row[col.field]); // Formatează grupurile utilizatorilor
          } else if (col.field === 'costuri') {
            formattedRow[displayName] = `${row[col.field] || 0} Lei`; // 0 Lei când nu există valoare
          } else {
            formattedRow[displayName] = row[col.field] || ''; // Valoarea originală pentru celelalte coloane
          }
        }
      });
      return formattedRow;
    });
  };
  
   
 
    const loadingIndicator = isLoading === 0;
 
    const FlexContainer = styled(Box)({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      });


  useEffect(() => {


    if (selectedTermenEvent?.termen?.termen.dataTermen) {
      console.log('Updated selectedTermenEvent:', selectedTermenEvent);
  
      const rawDate = selectedTermenEvent.termen.termen.dataTermen;
      const parsedDate = parse(rawDate, 'yyyy-MM-dd HH:mm:ss', new Date());
  
      setSelectedDateFinal(parsedDate);  
      console.log('selecteddateFinal', format(parsedDate, 'dd.MM.yyyy'));  
    }

    
  }, [selectedTermenEvent]);
  
 
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]); 
 
const [showTerms, setShowTerms] = useState(true);
const [showFolders, setShowFolders] = useState(true);
const [showUserNotifications, setShowUserNotifications] = useState(true);
const [dataInceputNoua, setDataInceputNoua] = useState(null);
const [dataSfarsitNoua, setDataSfarsitNoua] = useState(null);

useEffect(() => {
    const fetchUsercurent = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}user`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        
        const {data} = await response.json();
        setUsercurentgrup(data.user.groupNames);
        setUsercurentnume(data.user.fullname);
      } catch (error) {
        console.error("Eroare la preluarea utilizatorilor", error);
      }
    };
  
    fetchUsercurent();
  }, [token]);
  
  
const fetchUsers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}users?users_from_all_common_groups=1`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
  
      const data = await response.json();
      const usersData = data.data.users;
  
      // Extrag toate grupurile unice și includ 'Fără grup'
      const allGroups = { 0: 'FĂRĂ GRUP' }; // 0 pentru utilizatorii fără grup
      usersData.forEach((user) => {
        user.groups.forEach((group) => {
          allGroups[group.id] = group.name;
        });
      });
  
      // Opțiunile pentru Autocomplete
      const options = [];
  
      // Adăugăm grupurile ca opțiuni
      // Transformăm allGroups într-un array pentru a-l sorta
  const sortedGroupIds = Object.keys(allGroups).sort((a, b) => {
    return allGroups[a].localeCompare(allGroups[b]); // Sortăm după nume
  });
  
  // Adăugăm grupurile ordonate în opțiuni
  sortedGroupIds.forEach((groupId) => {
    options.push({
      type: 'group',
      id: parseInt(groupId),
      name: allGroups[groupId],
    });
  });
      // Creăm o hartă a utilizatorilor pe baza grupurilor
      const usersGroupedByGroup = {};
      const addedUserIds = new Set(); // Set pentru a evita duplicatele
  
   // Adăugăm utilizatorii la grupurile lor
  usersData.forEach((user) => {
    user.groups.forEach((group) => {
      if (!usersGroupedByGroup[group.id]) {
        usersGroupedByGroup[group.id] = [];
      }
      // Adaugă un obiect de utilizator pentru fiecare grup
      usersGroupedByGroup[group.id].push({
        type: 'user',
        id: user.id,
        name: user.fullname,
        groups: [group.id], // Adăugăm doar grupul curent
      });
    });
  
    // Adăugăm utilizatorii fără grup în grupul "Fără grup"
    if (user.groups.length === 0) {
      if (!usersGroupedByGroup[0]) {
        usersGroupedByGroup[0] = [];
      }
      usersGroupedByGroup[0].push({
        type: 'user',
        id: user.id,
        name: user.fullname,
        groups: [0], // grupul 'Fără grup'
      });
    }
  });
  
  
  // Combinăm utilizatorii în opțiuni
  Object.keys(usersGroupedByGroup).forEach((groupId) => {
    // Sortăm utilizatorii din fiecare grup după nume
    const sortedUsers = usersGroupedByGroup[groupId].sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  
    sortedUsers.forEach((user) => {
      options.push(user);
    });
  });
  
      console.log('options', options);
      setUsers(usersData);
      setGroupedOptions(options);
    } catch (error) {
      console.error('Eroare la preluarea utilizatorilor', error);
    }
  };
  

useEffect(() => {
  fetchUsers();
}, []);

const formattedStartDate2 = startDate ? format(parseISO(startDate), 'dd.MM.yyyy') : '';
const formattedEndDate2 = endDate ? format(parseISO(endDate), 'dd.MM.yyyy') : '';


  // Functia de combinare a celor 2 API-uri
  const fetchEvenimenteMultiple = async (startDate, endDate) => {
    let notificari = `${process.env.REACT_APP_API_URL}notificari?startDate=${startDate}&endDate=${endDate}`;   
    let termeneCalendar = `${process.env.REACT_APP_API_URL}termeneCalendar?startDate=${startDate}&endDate=${endDate}`;  
    
    if (selectedUsers.length > 0) {
      const userIds = selectedUsers.map(user => `userIds[]=${user.id}`).join('&');
      notificari += `&${userIds}`;
      termeneCalendar += `&${userIds}`;
    }

    try {
      const notificariResponse = await fetch(notificari, { 
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      const termeneResponse = await fetch(termeneCalendar, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!notificariResponse.ok || !termeneResponse.ok) {
        console.error('Eroare la preluare date evenimente multiple');
        return;
      }

      const notificariData = await notificariResponse.json();
      const termeneData = await termeneResponse.json();

      // Combinam datele
      const evenimenteNotificari = parseEvenimenteMultiple(notificariData.data);
      const evenimenteTermene = parseEvenimenteTermene(termeneData.data);
      const evenimente = evenimenteTermene.concat(evenimenteNotificari);
      setEvents(evenimente);
      setLoading(false);
 
      setDataInceputNoua(startDate);
      setDataSfarsitNoua(endDate);
    } catch (error) {
      console.error('Eroare la preluare date evenimente multiple: ', error);
    }
  }; 

  const parseEvenimenteTermene = (data) => {
    const evenimente = [];
    if (!data || !data.termene || !Array.isArray(data.termene)) return evenimente;

    // Parcurgem fiecare termen
    data.termene.forEach((eveniment) => {
        const start = eveniment.dataTermen
            ? moment(eveniment.dataTermen, 'DD-MM-YYYY HH:mm').toDate()
            : eveniment.panaladata
            ? moment(eveniment.panaladata, 'DD-MM-YYYY HH:mm').toDate()
            : null;

        // Inserăm termenul în lista de evenimente (partea principală pentru termen)
        const evenimentTermen = {
            id: uuidv4(), // Generăm un ID unic pentru fiecare termen
            start,
            title: `${eveniment?.client ?? ''} - ${eveniment?.parteAdversa ?? ''}`,
            dosar: `${eveniment?.numarDosar ?? ''}`,
            descriere: `${eveniment?.descriere ?? 'Termen'}`,
            tip: 'Termen',
            visible: true,
            client: `${eveniment?.client ?? ''}`,
            parteAdversa: `${eveniment?.parteAdversa ?? ''}`,
            complet: `${eveniment?.complet ?? ''}`,
            instanta: `${eveniment?.instanta ?? ''}`,
            tipsolutie: `${eveniment?.tipsolutie ?? ''}`,
            sumarsolutie: `${eveniment?.sumarsolutie ?? ''}`,
            important: eveniment?.atentionare === 1,
            recurent: eveniment?.recurent === 1,
            notificare: eveniment?.notificare ?? '',
        };

        evenimente.push(evenimentTermen);

        // Verificăm dacă există notificări pentru acest termen
        if (Array.isArray(eveniment.notificari) && eveniment.notificari.length > 0) {
            eveniment.notificari.forEach((notificare) => {
                // Forțăm notificarea să moștenească datele lipsă de la termen
                const evenimentNotificare = {
                    id: uuidv4(), // Generăm un ID unic pentru fiecare notificare
                    start,
                    title: `${notificare?.descriere ?? `${eveniment?.client ?? ''} - ${eveniment?.parteAdversa ?? ''}`}`, // Moștenire din termenul părinte dacă descrierea este goală
                    dosar: `${notificare?.numarDosar ?? eveniment?.numarDosar ?? ''}`,
                    descriere: `${notificare?.descriere ?? eveniment?.descriere ?? 'Termen'}`,
                    tip: 'Termen', // Tip specific pentru notificări
                    visible: true,
                    // Moștenire directă din termen (forțăm moștenirea)
                    client: `${eveniment?.client ?? ''}`,
                    parteAdversa: `${eveniment?.parteAdversa ?? ''}`,
                    complet: `${eveniment?.complet ?? ''}`,
                    instanta: `${eveniment?.instanta ?? ''}`,
                    tipsolutie: `${eveniment?.tipsolutie ?? ''}`,
                    sumarsolutie: `${eveniment?.sumarsolutie ?? ''}`,
                    important: eveniment?.atentionare === 1,
                    recurent: eveniment?.recurent === 1,
                    notificare: `${notificare?.notificare ?? eveniment?.notificare ?? ''}`,
                };

                // Log pentru a verifica structura notificării moștenite
                console.log("Notificare procesată (cu moștenire):", evenimentNotificare);

                evenimente.push(evenimentNotificare);
            });
        }
    });

    return evenimente;
};


  
  
  const parseEvenimenteMultiple = (data) => {
    const evenimente = [];
    if (!data || typeof data !== 'object') return evenimente;
    
    if (Array.isArray(data.notificariDosar)) {
      data.notificariDosar.forEach((eveniment) => {
        const start = moment(eveniment.dataSfarsit, 'DD-MM-YYYY HH:mm').toDate();
        const evenimentDosar = {
            id: uuidv4(),
            start,
            title: `Dosar: ${eveniment?.numarDosar ?? ''} - ${eveniment?.descriere ?? ''}`,
            dosar: `${eveniment?.numarDosar ?? ''}`,
            descriere: `${eveniment?.descriere ?? ''}`,
            tip: 'Dosar',
            visible: true,
            client: `${eveniment?.client ?? ''}`,
            parteAdversa: `${eveniment?.parteAdversa ?? ''}`,
            neluatLaCunostinta: eveniment?.dataluatlacunostinta === "", // Flag dacă notificarea nu a fost luată la cunoștință
            recurent: eveniment?.recurent === 1,
            notificare: `${eveniment?.notificare ?? ''}`,
          };
        evenimente.push(evenimentDosar);
      });
    }
  
    if (Array.isArray(data.notificariUser)) {
      data.notificariUser.forEach((eveniment) => {
        const start = moment(eveniment.dataSfarsit, 'DD-MM-YYYY HH:mm').toDate();
        const evenimentUtilizator = {
            id: uuidv4(),
            start,
            title: `Eveniment utilizator - ${eveniment?.descriere ?? ''}`,
            descriere: `${eveniment?.descriere ?? ''}`,
            tip: 'Utilizator',
            visible: true,
            neluatLaCunostinta: eveniment?.dataluatlacunostinta === "", // Flag pentru notificările neluate la cunoștință
            recurent: eveniment?.recurent === 1, 
            notificare: `${eveniment?.notificare ?? ''}`,
          };
        evenimente.push(evenimentUtilizator);
      });
    }
    return evenimente;
  };
 
  useEffect(() => {
    fetchEvenimenteMultiple(startDate, endDate);
  }, [selectedUsers, startDate, endDate]);
  
  const renderEventContent = (eventInfo) => {
    const { event, view } = eventInfo;
    let formattedTip = '';
    let backgroundColor = '';
    let additionalClass = '';
    const currentView = view.type;
    const clientVsParte = event.extendedProps.client && event.extendedProps.parteAdversa ? ` - (${event.extendedProps.client} vs ${event.extendedProps.parteAdversa})` : '';
    
    const tooltipTitle = `${event.title}${clientVsParte}`;
  
    let parteAdversa2 = event.extendedProps.parteAdversa ?? '';
    parteAdversa2 = parteAdversa2.trim() === 'undefined' ? '' : parteAdversa2;

    const parteAdv = parteAdversa2 ? ` - (${parteAdversa2})` : '';

    const tooltipTitleAdv = `${event.title}${parteAdv}`;
    const titlu = `${event.title}${parteAdv}`; 
    
    if (!event.extendedProps.visible) {
      return null; // Ascund evenimentele care nu sunt vizibile
    }
  
    switch (event.extendedProps.tip) {
      case 'termen':
        formattedTip = 'Termen';
        backgroundColor = colorCT;
        additionalClass = 'termenEveniment';
        break;
      case 'user':
        formattedTip = 'Utilizator';
        backgroundColor = colorCU;
        additionalClass = 'userEveniment';
        break;
      case 'dosar':
        formattedTip = 'Dosar';
        backgroundColor = colorCA;
        additionalClass = 'dosarEveniment';
        break;
      default:
        formattedTip = event.extendedProps.tip;
        backgroundColor = 'gray';
        additionalClass = '';
    }
  
    if (event.extendedProps.tip === 'termen') {
      // Pentru 'termen', nu afișăm 'formattedTip'
      if (titlu.length > 20 && currentView === 'dayGridMonth') {
        return (
          <Tooltip title={tooltipTitleAdv}>
            <div className={`termenCalendar ${additionalClass}`} style={{ backgroundColor }}>
              {titlu.slice(0, 20)}...
            </div>
          </Tooltip>
        );
      }
  
      return (
        <Tooltip title={tooltipTitleAdv}>
          <div className={`termenCalendar ${additionalClass}`} style={{ backgroundColor }}>
            {titlu}
          </div>
        </Tooltip>
      );
    } else {
      // Pentru celelalte tipuri, afișăm 'formattedTip'
      if (titlu.length > 20 && currentView === 'dayGridMonth') {
        return (
          <Tooltip title={`${formattedTip}: ${tooltipTitleAdv}`}>
            <div className={`termenCalendar ${additionalClass}`} style={{ backgroundColor }}>
              {titlu.slice(0, 20)}...
            </div>
          </Tooltip>
        );
      }
  
      return (
        <Tooltip title={`${formattedTip}: ${tooltipTitleAdv}`}>
          <div className={`termenCalendar ${additionalClass}`} style={{ backgroundColor }}>
            {titlu}
          </div>
        </Tooltip>
      );
    }
  };
  
 
  //functii
  const [filterModel, setFilterModel] = useState({
    items: [], 
  });
  const handleFilterModelChange = (newModel) => {
 
    const newItems = newModel.items.map((item) => {
 
      return item;
    });
  
    setFilterModel({ ...newModel, items: newItems });
  };
  
  

  const selectedRows2 = events;
  const dosareFinal = dosareFiltrate;
   
  const apiRef2 = useGridApiRef();
  const rowsToDisplay = selectedRows2 ? selectedRows2 : dosareFiltrate;


  const handleStateChange = React.useCallback(
    (state: GridState) => {
      const filteredKeys = getKeysFromLookup(state.filter.filteredRowsLookup);
      const filteredRows = filteredKeys.map((key) => apiRef2.current.getRow(key));
  
      // Sortăm rândurile filtrate în funcție de sortModel-ul curent
      const sortedFilteredRows = sortRows(filteredRows, sortModel); // sortModel trebuie să fie disponibil global sau transmis aici
  
      // Verificăm dacă rândurile s-au schimbat față de cele deja filtrate
      if (JSON.stringify(sortedFilteredRows) !== JSON.stringify(dosareFiltrate)) {
        setDosareFiltrate(sortedFilteredRows);
      }
    },
    [apiRef2, dosareFiltrate, sortModel],  // sortModel trebuie inclus în dependențe
  );
  
  
const sortNumarDosar = (a, b) => { 
  const numarA = parseInt(a.split('/')[0]);
  const numarB = parseInt(b.split('/')[0]);

  if (numarA < numarB) return -1;
  if (numarA > numarB) return 1;
  return 0;
};
const compareValues = (a, b, field, sortDirection) => {
  // Verificăm dacă este `numardosarfinal`, folosim funcția specializată
  if (field === 'numardosarfinal') {
    const result = sortNumarDosar(a[field], b[field]);
    return sortDirection === 'asc' ? result : -result;
  }

  // Pentru câmpurile care au valori `undefined` sau `null`, le punem la început
  if (a[field] === undefined || a[field] === null) return sortDirection === 'asc' ? -1 : 1;
  if (b[field] === undefined || b[field] === null) return sortDirection === 'asc' ? 1 : -1;

  // Comparare pentru string-uri
  if (typeof a[field] === 'string') {
    return sortDirection === 'asc'
      ? a[field].localeCompare(b[field])
      : b[field].localeCompare(a[field]);
  }

  // Comparare pentru numere sau date
  if (typeof a[field] === 'number' || a[field] instanceof Date) {
    return sortDirection === 'asc'
      ? a[field] - b[field]
      : b[field] - a[field];
  }

  return 0;
};

// Funcția pentru sortarea rândurilor pe baza modelului de sortare
const sortRows = (rows, sortModel) => {
if (!sortModel.length) return rows; // Dacă nu există niciun criteriu de sortare, returnează rândurile inițiale

// Extrage primul criteriu de sortare (coloană și direcție)
const { field, sort } = sortModel[0];

// Sortăm rândurile utilizând `compareValues`
return [...rows].sort((a, b) => compareValues(a, b, field, sort));
};

const handleSortModelChange = (sortModel) => {
  // Sortăm rândurile pe baza modelului de sortare
  const sortedRows = sortRows(rowsToDisplay, sortModel);

  // Actualizăm datele filtrate (acum sortate)
  setDosareFiltrate(sortedRows);

  // Stocăm modelul de sortare pentru a-l folosi în alte funcții
  setSortModel(sortModel);  // Trebuie să păstrezi modelul de sortare
};


  let formattedStartDate = '';

  if (startDate) {
    const startDateObj1 = new Date(startDate);
    formattedStartDate = startDateObj1.toLocaleDateString('ro-RO', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  } else {
      formattedStartDate = '';
  }
  
  let formattedEndDate = '';
  
  if (endDate) {
    const endDateObj = new Date(endDate);
    formattedEndDate = endDateObj.toLocaleDateString('ro-RO', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  } else {
      formattedEndDate = '';
  }
  
const wrapFilterOperator = (operator: GridFilterOperator) => {
  const getApplyFilterFn: GridFilterOperator["getApplyFilterFn"] = (
    filterItem,
    column
  ) => {
    const innerFilterFn = operator.getApplyFilterFn(
      {
        ...filterItem,
        value: removeDiacritics(filterItem.value)
      },
      column
    );
    if (!innerFilterFn) {
      return innerFilterFn;
    }

    return (params) => {
      const newParams = {
        ...params,
        value: removeDiacritics(params.value)
      };
      return innerFilterFn(newParams);
    };
  };

  return {
    ...operator,
    getApplyFilterFn
      };
  };
const columns = [
    {
    field: 'startDate', 
    headerName: 'Data', 
    width: 130, 
    valueGetter: (params) => moment(params.row.start).format('DD.MM.YYYY'), // Formatăm ca zi.luna.an
  },
  {
    field: 'startTime', 
    headerName: 'Ora', 
    width: 100, 
    valueGetter: (params) => moment(params.row.start).format('HH:mm'), // Afișăm doar ora în format hh:mm
  },
  { field: 'descriere', headerName: 'Descriere', width: 130 , filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
    { field: 'dosar', headerName: 'Nr. dosar', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
    { field: 'title', headerName: 'Info eveniment', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
    { field: 'client', headerName: 'Client', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
    { field: 'parteAdversa', headerName: 'Parte adversă', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
    { field: 'complet', headerName: 'Complet', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
    { field: 'instanta', headerName: 'Instanță', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
    { field: 'sala', headerName: 'Sala', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
    { field: 'tipsolutie', headerName: 'Tip soluție', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
    { field: 'sumarsolutie', headerName: 'Sumar soluție', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
    { field: 'tip', headerName: 'Tip eveniment', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
];

 



const handleExportXML = () => {
    const visibleColumns = columns.filter((col) => columnVisibilityModel[col.field] !== false);
  
    if (visibleColumns.length === 0 || dosareFinal.length === 0) {
      console.error('Nu există date disponibile pentru a genera XML-ul.');
      return;
    }
  
    // Construim structura XML
    let xmlContent = '<?xml version="1.0" encoding="UTF-8"?>\n';
    xmlContent += '<Dosare>\n';
  
    dosareFinal.forEach(row => {
      xmlContent += '  <Dosar>\n';
      visibleColumns.forEach(col => {
        const fieldName = col.headerName.replace(/\s+/g, '_'); // înlocuim spațiile din denumirea coloanei cu '_'
        let cellValue;
  
        // Formatarea specială pentru user_groups
        if (col.field === 'user_groups') {
          cellValue = formatUserGroups(row[col.field]); // Folosește funcția de formatare
        } else {
          cellValue = row[col.field] || '';
        }
  
        xmlContent += `    <${fieldName}>${cellValue}</${fieldName}>\n`;
      });
      xmlContent += '  </Dosar>\n';
    });
  
    xmlContent += '</Dosare>';
  
    // Creăm fișierul XML și oferim descărcarea
    const blob = new Blob([xmlContent], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'export_dosare.xml';
    link.click();
  };
  
const dateFilterType = dateFieldName;
const prepareDataForExport = (data, columns, columnVisibilityModel) => {
    const columnInfoMap = columns.reduce((acc, column) => {
      if (columnVisibilityModel[column.field] !== false) {  
        acc[column.field] = column.headerName || column.field;
      }
      return acc;
    }, {});
  
    const orderedColumnFields = columns
      .filter(col => columnVisibilityModel[col.field] !== false)
      .map(col => col.field);
  
    return data.map(row => {
      const filteredRow = {};
      orderedColumnFields.forEach(key => {
        if (key !== '__check__') {
          filteredRow[columnInfoMap[key]] = row[key];
        }
      });
      return filteredRow;
    });
  };
const dataGridRef = useRef(null);

const handleExportPDFComand = () => {

  setShowHeader(false);
  setShowConfidential(false);
  let text;

  text = `${selectedRows2.length} de rânduri vor fi exportate în PDF din tabel`;

  // Creăm un container HTML pentru switch-uri
  const container = document.createElement('div');
  container.className = 'divswal'; // Adăugăm o clasă pentru stilizare

  // Inserăm HTML pentru switch-uri personalizate stilizate
  container.insertAdjacentHTML(
    'beforeend',
    `
    <div class="switch-container">
      <label for="headerSwitch">Afișare antet</label>
      <label class="switch">
        <input type="checkbox" id="headerSwitch" ${showHeader ? 'checked' : ''}>
        <span class="slider round"></span>
      </label>
    </div>
    <div class="switch-container">
      <label for="confidentialSwitch">Afișare "Confidențial"</label>
      <label class="switch">
        <input type="checkbox" id="confidentialSwitch" ${showConfidential ? 'checked' : ''}>
        <span class="slider round"></span>
      </label>
    </div>
    `
  );

  // Afișăm modalul Swal cu elementul creat
  Swal({
    title: 'Export PDF tabel',
    text: text,
    content: container,
    icon: 'warning',
    buttons: ['Anulează', 'Da, continui!'],
    dangerMode: true,
  }).then((willContinue) => {
    if (willContinue) {
      const headerSwitch = document.getElementById('headerSwitch');
      const confidentialSwitch = document.getElementById('confidentialSwitch'); 

      handleExportPDF(headerSwitch.checked, confidentialSwitch.checked);
    }
  });
};

const handleExportPDF = (showHeader1, showConfidential1) => { 
  const visibleColumns = columns.filter((col) => columnVisibilityModel[col.field] !== false);

  if (visibleColumns.length === 0 ) {
    console.error('Nu există date disponibile pentru a genera PDF-ul.');
    return;
  }

  let pageFormat = 'a4';  // Format implicit

  if (visibleColumns.length > 5 && visibleColumns.length <= 8) {
    pageFormat = 'a3';
  } else if (visibleColumns.length > 8 && visibleColumns.length <= 12) {
    pageFormat = 'a2';
  } else if (visibleColumns.length > 12 && visibleColumns.length <= 15) {
    pageFormat = 'a1';
  } else if (visibleColumns.length > 15) {
    pageFormat = 'a0';
  }

  // Inițializăm documentul PDF
  const doc = new jsPDF({
    orientation: 'landscape',  // Orientare landscape
    format: pageFormat,  
                // Dimensiunea paginii
  });

  // Adăugăm fontul DejaVu Sans
  callAddFont(doc); // Adăugăm fontul personalizat
  doc.setFont('DejaVuSans'); // Setăm fontul pentru text

  // Adăugăm un antet, dacă este selectat
  let startY = 20;
  if (showHeader1) {
    doc.setFontSize(12);
    doc.setTextColor('#333');
    
    // Verificăm dacă `usercurentgrup` conține mai multe grupuri
    const grupuri = usercurentgrup.split(','); // separăm grupurile prin virgulă
    let grupuriText;

    if (grupuri.length > 1) {
      // Dacă avem mai multe grupuri, afișăm "pentru 2 unități" și grupurile respective
      grupuriText = `pentru ${grupuri.length} unități (${usercurentgrup})`;
    } else {
      // Dacă avem un singur grup, afișăm "pentru unitatea" și grupul respectiv
      grupuriText = `pentru unitatea ${usercurentgrup}`;
    }

    const dateFilterTypeDen = startDate ? 
    (dateFilterType === 'datacreare' ? 'data creare dosar' :
    dateFilterType === 'datatermen' ? 'data termen dosar' :
    dateFilterType === 'dataum' ? 'data actualizare dosar' : '') 
  : '';
  
    let mesaj;

     // Cazul în care nu trimiți `startDate`
     mesaj = `Documentul de export a fost generat la data ${formattedDate} la ora ${formattedTime2}, ${grupuriText}.`;
     doc.text(mesaj, 14, startY);
    if (!startDate) {
      doc.text(`Exportul a fost realizat pentru toată perioada (fără restricții referitoare la date calendaristice) din JuristPRO NEXT ${denAppVers}.`, 14, startY + 10);
    } else {
      // Cazul în care trimiți `startDate`, dar poate nu și `endDate`
      const dataSfarsit = endDate ? endDate : formattedDate;  // dacă `endDate` nu este trimis, folosește data curentă
      mesaj = `Perioada pentru care a fost realizat exportul este: ${dateFilterTypeDen} ${formattedStartDate2} - ${formattedStartDate2} din JuristPRO NEXT ${denAppVers}., ${grupuriText}.`;
      doc.text(mesaj, 14, startY + 10);
    }

  startY += 12;  // Mutăm mai jos pentru a face loc textului de confidențialitate, dacă există
  }

  // Adăugăm un mesaj confidențial, dacă este selectat
  if (showConfidential1) {
    doc.setFontSize(14);
    doc.setTextColor(255, 0, 0);  // Roșu pentru confidențial
    doc.text(`CONFIDENTIAL utilizator ${usercurentnume}`, 14, startY + 10);
    startY += 10;  // Mutăm mai jos pentru a face loc tabelului
  }

  // Creăm anteturile și rândurile tabelului
  const tableColumnHeaders = visibleColumns.map(col => col.headerName);
  const tableRows = dosareFinal.map((row) =>
  visibleColumns.map((col) => {
    if (col.field === 'user_groups') {
      return formatUserGroups(row[col.field]); // Aplica funcția de formatare
    }
    return row[col.field] || ''; // Returnează valoarea originală pentru celelalte coloane
  })
);

  if (tableRows.length === 0 || tableColumnHeaders.length === 0) {
    console.error('Nu există date pentru a genera tabelul.');
    return;
  }

  // Creăm tabelul cu `word wrap` și paginare
  doc.autoTable({
    head: [tableColumnHeaders],
    body: tableRows,
    startY: startY + 10, // Începem tabelul după antet și confidențial
    theme: 'grid',
    styles: {
      font: 'DejaVuSans',
      fontSize: 10,
      cellPadding: 3,
      overflow: 'linebreak',  // Activăm `word wrap` pentru text lung
      cellWidth: 'auto',      // Permitem auto-ajustarea celulelor
    },
    tableWidth: 'auto',  // Lăsăm tabelul să se ajusteze automat
    didDrawPage: (data) => {
      // Adăugăm paginare
      const pageCount = doc.internal.getNumberOfPages();
      doc.setFontSize(10);
      const pageStr = `Pagina ${data.pageNumber} din ${pageCount}`;
      doc.text(pageStr, data.settings.margin.left, doc.internal.pageSize.height - 10);
    },
  });
  // Salvăm PDF-ul pe dispozitivul utilizatorului
  doc.save(`Export JuristPRO - PDF - ${formattedDate} - ${formattedTime}.pdf`);

};
const handlePrintComand = (showHeader, showConfidential) => { 
    setShowHeader(false);
    setShowConfidential(false);
    let text;

    text = `Au fost selectate ${selectedRows2.length} dosare pentru a fi imprimate din tabel`;

    // Creăm un container HTML pentru switch-uri
    const container = document.createElement('div');
    container.className = 'divswal'; // Adăugăm o clasă pentru stilizare

    // Inserăm HTML pentru switch-uri personalizate stilizate
    container.insertAdjacentHTML(
      'beforeend',
      `
      <div class="switch-container">
        <label for="headerSwitch">Afișare antet</label>
        <label class="switch">
          <input type="checkbox" id="headerSwitch" ${showHeader ? '' : ''}>
          <span class="slider round"></span>
        </label>
      </div>
      <div class="switch-container">
        <label for="confidentialSwitch">Afișare "Confidențial"</label>
        <label class="switch">
          <input type="checkbox" id="confidentialSwitch" ${showConfidential ? '' : ''}>
          <span class="slider round"></span>
        </label>
      </div>
      `
    );

    // Afișăm modalul Swal cu elementul creat
    Swal({
      title: 'Imprimare tabel',
      text: text,
      content: container,
      icon: 'warning',
      buttons: ['Anulează', 'Da, continui!'],
      dangerMode: true,
    }).then((willContinue) => {
      if (willContinue) {
        const headerSwitch = document.getElementById('headerSwitch');
        const confidentialSwitch = document.getElementById('confidentialSwitch'); 
        handlePrint(headerSwitch.checked, confidentialSwitch.checked);
      }
    });
  };

  const handlePrint = (showHeader1, showConfidential1) => { 
    const visibleColumns = columns.filter((col) => columnVisibilityModel[col.field] !== false);

    if (visibleColumns.length === 0 || dosareFinal.length === 0) {
      console.error('Nu există date disponibile pentru a genera PDF-ul.');
      return;
    }

    
    let pageFormat = 'a4';  // Format implicit

    if (visibleColumns.length > 5 && visibleColumns.length <= 8) {
      pageFormat = 'a3';
    } else if (visibleColumns.length > 8 && visibleColumns.length <= 12) {
      pageFormat = 'a2';
    } else if (visibleColumns.length > 12 && visibleColumns.length <= 15) {
      pageFormat = 'a1';
    } else if (visibleColumns.length > 15) {
      pageFormat = 'a0';
    }

    // Inițializăm documentul PDF
    const doc = new jsPDF({
      orientation: 'landscape',  // Orientare landscape
      format: pageFormat,  
                  // Dimensiunea paginii
    });


        // Adăugăm fontul DejaVu Sans
        callAddFont(doc); // Adăugăm fontul personalizat
        doc.setFont('DejaVuSans'); // Setăm fontul pentru text
    
        // Adăugăm un antet, dacă este selectat
        let startY = 20;
        if (showHeader1) {
          doc.setFontSize(12);
          doc.setTextColor('#333');
          
          // Verificăm dacă `usercurentgrup` conține mai multe grupuri
          const grupuri = usercurentgrup.split(','); // separăm grupurile prin virgulă
          let grupuriText;
    
          if (grupuri.length > 1) {
            // Dacă avem mai multe grupuri, afișăm "pentru 2 unități" și grupurile respective
            grupuriText = `pentru ${grupuri.length} unități (${usercurentgrup})`;
          } else {
            // Dacă avem un singur grup, afișăm "pentru unitatea" și grupul respectiv
            grupuriText = `pentru unitatea ${usercurentgrup}`;
          }
      
          const dateFilterTypeDen = startDate ? 
            (dateFilterType === 'datacreare' ? 'data creare dosar' :
            dateFilterType === 'datatermen' ? 'data termen dosar' :
            dateFilterType === 'dataum' ? 'data actualizare dosar' : '') 
            : '';
        
          let mesaj;
    
           // Cazul în care nu trimiți `startDate`
           mesaj = `Documentul de export a fost generat la data ${formattedDate} la ora ${formattedTime2}, ${grupuriText}.`;
           doc.text(mesaj, 14, startY);
          if (!startDate) {
            doc.text(`Exportul a fost realizat pentru toată perioada (fără restricții referitoare la date calendaristice) din JuristPRO NEXT ${denAppVers}.`, 14, startY + 10);
          } else {
            // Cazul în care trimiți `startDate`, dar poate nu și `endDate`
            const dataSfarsit = endDate ? endDate : formattedDate;  // dacă `endDate` nu este trimis, folosește data curentă
            mesaj = `Perioada pentru care a fost realizat exportul este: ${dateFilterTypeDen} ${formattedStartDate2} - ${formattedStartDate2} din JuristPRO NEXT ${denAppVers}., ${grupuriText}.`;
            doc.text(mesaj, 14, startY + 10);
          }
    
        startY += 12;  // Mutăm mai jos pentru a face loc textului de confidențialitate, dacă există
        }

    // Adăugăm un mesaj confidențial, dacă este selectat
    if (showConfidential1) {
      doc.setFontSize(14);
      doc.setTextColor(255, 0, 0);  // Roșu pentru confidențial
      doc.text(`CONFIDENTIAL utilizator ${usercurentnume}`, 14, startY + 10);
      startY += 10;  // Mutăm mai jos pentru a face loc tabelului
    }

    // Creăm anteturile și rândurile tabelului
    const tableColumnHeaders = visibleColumns.map(col => col.headerName);
    const tableRows = dosareFinal.map((row) =>
    visibleColumns.map((col) => {
      if (col.field === 'user_groups') {
        return formatUserGroups(row[col.field]); // Aplica funcția de formatare
      }
      return row[col.field] || ''; // Returnează valoarea originală pentru celelalte coloane
    })
  );
  
    if (tableRows.length === 0 || tableColumnHeaders.length === 0) {
      console.error('Nu există date pentru a genera tabelul.');
      return;
    }

    // Creăm tabelul cu `word wrap` și paginare
    doc.autoTable({
      head: [tableColumnHeaders],
      body: tableRows,
      startY: startY + 10, // Începem tabelul după antet și confidențial
      theme: 'grid',
      styles: {
        font: 'DejaVuSans',
        fontSize: 10,
        cellPadding: 3,
        overflow: 'linebreak',  // Activăm `word wrap` pentru text lung
        cellWidth: 'auto',      // Permitem auto-ajustarea celulelor
      },
      tableWidth: 'auto',  // Lăsăm tabelul să se ajusteze automat
      didDrawPage: (data) => {
        // Adăugăm paginare
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(10);
        const pageStr = `Pagina ${data.pageNumber} din ${pageCount}`;
        doc.text(pageStr, data.settings.margin.left, doc.internal.pageSize.height - 10);
      },
    });
    
    // Salvăm PDF-ul pe dispozitivul utilizatorului
    doc.save(`Export JuristPRO - PDF - ${formattedDate} - ${formattedTime}.pdf`);

    // Creăm un URL temporar pentru a deschide PDF-ul într-o fereastră nouă
    const pdfBlob = doc.output('blob');
    const url = URL.createObjectURL(pdfBlob);
    window.open(url); 

  };
  
  const CustomGridToolbarContainer = ({}) => {

    return (
<GridToolbarContainer>
        <FlexContainer>
        <Box sx={{ display: 'flex', alignItems: 'left', mr: 1  }}>
        <GridToolbarColumnsButton /> 
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        
        <GridToolbarExportContainer >
            <MenuItem onClick={handlePrintComand}>
            Imprimare tabel
            </MenuItem>

            <MenuItem onClick={handleExportPDFComand}>
            Export PDF tabel
            </MenuItem>

            
            <ExportInExcel
                csvData={prepareDataForExport2(
                    selectedRows2.length > 0 ? selectedRows2 : dosareFinal,
                    columns,
                    columnVisibilityModel
                )} 
                fileName={fileNameExcel}
                showHeader={showHeader}
                showConfidential={showConfidential}
                afisamSwal={true} 
                startDate={formattedStartDate2}
                dateFilterType={dateFilterType} 
                endDate={formattedEndDate2}
                />

             
            <MenuItem onClick={handleExportXML}>
                 În format XML
            </MenuItem>

        </GridToolbarExportContainer>
                

        </Box>
        </FlexContainer>
      </GridToolbarContainer>
    );
  }; 

 
if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="10vh" className="">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="">
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }} className="mb-3"> 
{areDrept(AdaugareUtilizatoriDrept) ? (
      
       <Autocomplete
  multiple
  id="tags-outlined"
  options={groupedOptions}
  groupBy={(option) => {
    // Dacă este utilizator, trebuie să-i grupăm în fiecare grup în care se află
    if (option.type === 'user') {
      // Iterăm prin fiecare grup al utilizatorului și găsim grupul corespunzător
      const userGroups = option.groups.map(groupId => {
        const group = groupedOptions.find(opt => opt.type === 'group' && opt.id === groupId);
        return group ? group.name : 'Fără grup';
      });
      return userGroups.length > 0 ? userGroups[0] : 'Fără grup';
    } else {
      return 'Grupuri'; // Grupăm opțiunile de tip grup împreună
    }
  }}
  
  getOptionLabel={(option) => option.name}
  filterOptions={(options, state) => {
    const inputValue = state.inputValue.toLowerCase();
    return options.filter(option => option.name.toLowerCase().includes(inputValue));
  }}
  onChange={(event, newValue) => {
    const selectedUsers = [];
    const selectedUserIds = new Set();
  
    newValue.forEach(option => {
      if (option.type === 'user') {
        // Adăugăm utilizatorul dacă nu este deja selectat
        if (!selectedUserIds.has(option.id)) {
          selectedUsers.push(option);
          selectedUserIds.add(option.id);
        }
      } else if (option.type === 'group') {
        if (option.id === 0) { // ID pentru 'Fără grup'
          // Adăugăm toți utilizatorii fără grup
          const usersWithoutGroup = users.filter(user => user.groups.length === 0);
          usersWithoutGroup.forEach(user => {
            if (!selectedUserIds.has(user.id)) {
              selectedUsers.push({
                type: 'user',
                id: user.id,
                name: user.fullname,
                groups: [0], // grupul 'Fără grup'
              });
              selectedUserIds.add(user.id);
            }
          });
        } else if (users && users.length > 0) {
          // Obținem utilizatorii din grupul selectat
          const usersInGroup = users.filter(user =>
            user.groups.some(group => group.id === option.id)
          );
          console.log('Users in group:', usersInGroup);
          usersInGroup.forEach(user => {
            if (!selectedUserIds.has(user.id)) {
              selectedUsers.push({
                type: 'user',
                id: user.id,
                name: user.fullname,
                groups: user.groups.map(group => group.id),
              });
              selectedUserIds.add(user.id);
            }
          });
        }
      }
    });
  
    // Setăm utilizatorii selectați
    setSelectedUsers(selectedUsers);
  }}
 
  
  
  renderOption={(props, option) => {
    const userGroupsString = option.type === 'user' ? option.groups.join(',') : '';
    const key = option.type === 'user'
      ? `${option.id}-${userGroupsString}-${props['data-option-index']}` // Cheie unică
      : `group-${option.id}`;

    return (
      <li {...props} key={key}>
        {option.type === 'group' ? `Grup: ${option.name}` : option.name}
      </li>
    );
  }}
renderTags={(value, getTagProps) =>
  value.map((option, index) => (
    <Chip label={option.name} {...getTagProps({ index })} />
  ))
}

  renderInput={(params) => (
    <TextField
      {...params}
      label="Selectați utilizatori sau grupuri"
      placeholder="Utilizatori sau grupuri"
      fullWidth
    />
  )}
  size="small" 
  sx={{ flex: 1 }}
  className='AutocomRap'
/>

) : null }
<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
        <DatePicker
          label="De la"
          value={startDate ? parseISO(startDate) : null} // Folosim parseISO pentru a parsa formatul ISO
          onChange={handleDateChangeStart}
          renderInput={(params) => <TextField {...params} size='small' fullWidth style={{ width: '100px' }} />}
          inputFormat="dd.MM.yyyy"
          mask="__.__.____"
          className='inputMicR'
        />
      </LocalizationProvider>

      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
        <DatePicker
          label="Până la"
          value={endDate ? parseISO(endDate) : null} // Folosim parseISO pentru a parsa formatul ISO
          onChange={handleDateChangeEnd}
          renderInput={(params) => <TextField {...params} size='small' fullWidth />}
          inputFormat="dd.MM.yyyy"
          mask="__.__.____"
          className='inputMicR'
        />
      </LocalizationProvider>

      <Tooltip title="Resetare interval tip dată pentru afișare rezultate în listă"> 
      <IconButton
          style={{marginTop:'5px'}}
          color="default"
          onClick={() => {
              setStartDate(null);
              setEndDate(null);
          }}
          fullWidth
      >
          <CancelPresentationIcon />
      </IconButton>
      </Tooltip>

      </Box>
 
 <DataGridPro
            className='tabelRaport'
                loading={isLoading} 
                rows={events} 
                autoHeight
                columns={columns}
                pageSize={20}
                apiRef={apiRef2}
                onStateChange={handleStateChange}
                onFilterModelChange={handleFilterModelChange}
                onSortModelChange={handleSortModelChange} 
                filterModel={filterModel}
                onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                columnVisibilityModel={columnVisibilityModel}
                rowsPerPageOptions={[10, 20, 50, 100]}
                checkboxSelectionVisibleOnly={true}
                disableRowSelectionOnClick
                disableSelectionOnClick={true}
                getRowId={(row, index) => row.idclient || row.iduser || row.id || `row-${index}`}
                pagination
                paginationMode="client"  
                initialState={{
                    pagination: { paginationModel: { pageSize: 15 } }, 
                }}
                 
                density='compact'
                components={{ 
                    
                    headerFilterMenu: null, 
                    Toolbar: () => (
                    <>
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                      </div>
                        {isLoading ? (
                        <div style={{ width: '100%', marginBottom: 8 }}>
                            
                            <CustomGridToolbarContainer />
                        <LinearProgress /> 
                        </div>
                        ) : (
                        <div>
                        <CustomGridToolbarContainer />
                        </div>
                        
                        )}
                    </>
                    ),
                }}
                componentsProps={{
                    toolbar: {
                    csvOptions: {
                        fileName: 'nume-fisier',
                        delimiter: ',',
                        utf8WithBom: true,
                    },
                    },
                }}
                localeText={{ 
                    ...roRO.components.MuiDataGrid.defaultProps.localeText, 
                    headerFilterOperatorContains: 'Filtrare rapidă',
                    filterPanelRemoveAll: 'Resetare filtre',
                    toolbarExport: 'Export',
                    toolbarExportLabel: 'Export',
                    toolbarExportCSV: 'În format CSV',
                    toolbarExportPrint: 'Imprimare',
                    toolbarExportExcel: 'În format Excel',
                }}
            />
    </div>
  );
};

export default RaportEvenimente;  