import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ShakaPlayer from 'shaka-player-react';
import 'shaka-player/dist/controls.css';  
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Alert from '@mui/material/Alert';

// Functie pentru a elimina diacriticele
const removeDiacritics = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

const faqData = [
  {
    order: '1',
    question: 'Cum se caută dosare online și cum se importă?',
    answer: 'https://www.ctce.ro/important/juristpronext/tutoriale/cautare-online.mp4',
    type: 'video',
  },
  {
    order: '4',
    question: 'Cum se previzualizează și editează un dosar?',
    answer: 'https://www.ctce.ro/important/juristpronext/tutoriale/lista-dosare-preview-creare.mp4',
    type: 'video',
  },
  {
    order: '2',
    question: 'Cum se afișează coloanele și cum se realizează filtrări în lista de dosare?',
    answer: 'https://www.ctce.ro/important/juristpronext/tutoriale/lista-dosare-coloane-filtre.mp4',
    type: 'video',
  },
  {
    order: '5',
    question: 'Cum se realizează exportul detaliat al dosarelor?',
    answer: 'https://www.ctce.ro/important/juristpronext/tutoriale/lista-dosare-export-detaliat.mp4',
    type: 'video',
  },
  {
    order: '8',
    question: 'Cum se realizeză rapoarte în cadrul aplicației?',
    answer: 'https://www.ctce.ro/important/juristpronext/tutoriale/rapoarte.mp4',
    type: 'video',
  },
  {
    order: '6',
    question: 'Cum se adaugă evenimente în calendar?',
    answer: 'https://www.ctce.ro/important/juristpronext/tutoriale/notificari-calendar.mp4',
    type: 'video',
  },
  {
    order: '7',
    question: 'Cum se atașează acte normative și jurisprudență la dosar?',
    answer: 'https://www.ctce.ro/important/juristpronext/tutoriale/integrare-ilegis.mp4',
    type: 'video',
  },
  {
    order: '3',
    question: 'Cum se sincronizează sau monitorizează dosarele?',
    answer: 'https://www.ctce.ro/important/juristpronext/tutoriale/lista-dosare-monitorizare-sincronizare.mp4',
    type: 'video',
  },
  {
    order: '9',
    question: 'Cum monitorizăm o firmă în BPI?',
    answer: 'https://www.ctce.ro/important/juristpronext/tutoriale/notificari-calendar.mp4',
    type: 'video',
  },
];

const Faq = () => {
    const [expanded, setExpanded] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
  
    // Sortează datele după `order`
    const sortedFaqData = faqData.sort((a, b) => {
      return parseInt(a.order) - parseInt(b.order);
    });
  
    // Filtrează după termenul de căutare
    const filteredFaqs = sortedFaqData.filter((faq) =>
      removeDiacritics(faq.question).toLowerCase().includes(removeDiacritics(searchTerm).toLowerCase())
    );
  
    return (
      <div style={{ width: '100%', maxWidth: '800px', margin: 'auto', padding: '20px' }} className='FAQ'>
        <TextField
          label="Căutare"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
  
        {filteredFaqs.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={handleChange(index)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography variant="h6">{faq.order}. {faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {faq.type === 'video' && (
                <>
                  <ShakaPlayer 
                    key={expanded === index ? `${faq.answer}-${index}` : undefined}
                    src={faq.answer}
                    width="100%"
                    height="100%"
                    autoPlay={expanded === index}
                    controls
                  />
                  <Alert className="mt-2" severity="info" style={{ marginBottom: '20px' }}>
                      Puteți expanda tutorialul folosind butonul
                      <span style={{ padding: '0 5px', fontFamily: 'monospace' }}>
                          [⛶]
                      </span>
                      pentru a-l viziona pe tot ecranul.
                  </Alert>
  
                </>
              )}
              {faq.type === 'text' && (
                <Typography>{faq.answer}</Typography>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    );
  };
  
  export default Faq;