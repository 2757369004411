import React, { useState, useEffect } from 'react';
import { List, Alert, ListItem, ListItemText, IconButton, CircularProgress, Typography, Snackbar, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import GetAppIcon from '@mui/icons-material/GetApp';  
import verificareToken from './VerificareToken';
import Swal from 'sweetalert';  
import CloseIcon from '@mui/icons-material/Close';
import DocPartajate from './DocPartajate'; 

const AdministrarePartajari = ({ iduser }) => {
  const [partajari, setPartajari] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);  
  const [selectedPartajare, setSelectedPartajare] = useState(null);
  const [selectedNumarPartajare, setSelectedNumarPartajare] = useState(null);
  const { token } = verificareToken();

  // Funcția de a prelua partajările din API
  const fetchPartajari = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${apiUrl}documentePartajate/${iduser}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Eroare la preluarea partajărilor');
      }
  
      const data = await response.json();
  
      if (data.message === "Nu există documente partajate pentru acest utilizator.") {
        setPartajari([]);  
        setError("Nu există partajări generate."); 
      } else {
        setPartajari(data.documente_partajate);
      }
    } catch (error) {
      setError('Nu există partajări generate.');
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchPartajari();
  }, []);

  const handleViewPartajare = (cheie, numardosar) => {
    setSelectedPartajare(cheie); 
    setSelectedNumarPartajare(numardosar); 
    setDialogOpen(true);  
  };
  // Funcția pentru a șterge o partajare
  const handleDeletePartajare = async (cheie) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    try {
      const response = await fetch(`${apiUrl}stergePartajare/${cheie}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Eroare la ștergerea partajării');
      }
  
      fetchPartajari();
      setSnackbarOpen(true);  
    } catch (error) {
      console.error('Eroare la ștergerea partajării:', error);
    }
  };
  

  // Funcție pentru confirmarea ștergerii cu Swal
  const confirmDeletePartajare = (cheie) => {
    Swal({
        title: "Sunteți sigur?",
        text: "Această acțiune va șterge partajarea permanent!",
        icon: "warning",
        buttons: {
          cancel: {
            text: "Anulare",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "Sunt sigur",
            value: true,
            closeModal: false,
          },
        },
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          handleDeletePartajare(cheie);  
          Swal("Partajarea a fost ștearsă cu succes!", {
            icon: "success",
          });
        } else {
          Swal({
            title: "",
            text: "Partajarea nu a fost ștearsă.",
            icon: "info",
          });
        }
      });
      
  };

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="warning">{error}</Alert>
      ) : (
        <>
          <Typography variant="h4" gutterBottom className="titluComponenta">
            Administrare partajări
            <hr />
          </Typography>
          <List>
            {partajari.map((partajare, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={`Partajare pentru dosarul ${partajare.numardosar}`}
                  secondary={`Data: ${new Date(partajare.datacreare).getDate().toString().padStart(2, '0')}.${(new Date(partajare.datacreare).getMonth() + 1).toString().padStart(2, '0')}.${new Date(partajare.datacreare).getFullYear()} ora ${new Date(partajare.datacreare).toLocaleTimeString()} (${partajare.fullname})`}
                />
                {/* Tooltip și buton pentru vizualizare */}
                <Tooltip title="Vizualizează partajarea">
                  <IconButton color='primary' onClick={() => handleViewPartajare(partajare.cheie, partajare.numardosar)}>
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                {/* Tooltip și buton pentru ștergere */}
                <Tooltip title="Șterge partajarea">
                  <IconButton color='error' onClick={() => confirmDeletePartajare(partajare.cheie)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        </>
      )}

      {/* Dialog pentru vizualizarea documentelor */}
      <Dialog open={dialogOpen} fullWidth maxWidth="lg">
      <DialogTitle className='titluModal'>
            Vizualizare documente partajate dosar {selectedNumarPartajare}
            <IconButton
            aria-label="close"
            onClick={() => setDialogOpen(false)}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent className='mt-3'>
            <DocPartajate cheie={selectedPartajare} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">Închidere</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar pentru mesajul de confirmare */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="Partajare ștearsă cu succes"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  );
};

export default AdministrarePartajari;
