import React, { useState, useEffect } from 'react';
import { CircularProgress, Grid, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { DataGridPro, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarDensitySelector, roRO } from '@mui/x-data-grid-pro';
import verificareToken from '../VerificareToken';
import { LicenseInfo } from '@mui/x-license-pro';
import { addDays, startOfWeek, format } from 'date-fns';

LicenseInfo.setLicenseKey(
  'ac62ac97b94addeb6dbf2b2473e35ecbTz05MjE3MSxFPTE3NDk2MjQ2NjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function getIdClient() {
  let idclient = getCookie('_iduserCjpc');
  
  if (!idclient) {
      idclient = sessionStorage.getItem('iduser');
  }

  if (!idclient) {
      idclient = localStorage.getItem('iduser');
  }

  return idclient;
}

function LogNotificariUser() {

  window.dispatchEvent(new Event('authChange'));

  const [notificariLog, setNotificariLog] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedWeek, setSelectedWeek] = useState(0);

  const idclient = getIdClient();
  const { token } = verificareToken(); 

  const calculateWeekRanges = () => {
    const weeks = [];
    const now = new Date();
    for (let i = 0; i <= 4; i++) {  // Ajustăm pentru a include și săptămâna curentă
      const start = startOfWeek(addDays(now, -7 * i), { weekStartsOn: 1 });
      const end = addDays(start, 6);
      weeks.push({
        week: i,
        start: format(start, 'dd.MM.yyyy'),
        end: format(end, 'dd.MM.yyyy')
      });
    }
    return weeks;
  };

  const weekRanges = calculateWeekRanges();

  const fetchNotificariLog = async (saptamana) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}getNotificariExterne?saptamana=${saptamana}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/plain',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Eroare preluare log notificari utilizator');
    }

    const data = await response.json();
    return data.data.notificari.Loguri || [];
  };

  useEffect(() => {
    const fetchWeekData = async () => {
      try {
        setLoading(true);
        const data = await fetchNotificariLog(selectedWeek);
        setNotificariLog(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchWeekData();
  }, [selectedWeek, idclient]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
        <CircularProgress />
      </div>
    );
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}.${formattedMonth}.${year}`;
  };

  const columns = [
    {
      field: 'data',
      headerName: 'Data',
      flex: 1,
      wordWrap: true,
      renderCell: (params) => (
        <span>{formatDate(params.value)}</span>
      ),
    },
    { field: 'mesaj', headerName: 'Activitate', flex: 1, wordWrap: true },
  ];

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  const rows = notificariLog.map((item, index) => ({
    id: index,
    ...item,
  }));

  const handleWeekChange = (event) => {
    setSelectedWeek(event.target.value);
  };

  return (
    <div id="logActivitate">
      <FormControl fullWidth>
        <InputLabel id="select-week-label">Selectați săptămâna</InputLabel>
        <Select
          labelId="select-week-label"
          id="select-week"
          value={selectedWeek}
          onChange={handleWeekChange}
          label="Selectați săptămâna"
        >
          <MenuItem value={0}>Săptămâna curentă ({weekRanges[0].start} - {weekRanges[0].end})</MenuItem>
          <MenuItem value={1}>Săptămâna trecută ({weekRanges[1].start} - {weekRanges[1].end})</MenuItem>
          <MenuItem value={2}>Acum 2 săptămâni ({weekRanges[2].start} - {weekRanges[2].end})</MenuItem>
          <MenuItem value={3}>Acum 3 săptămâni ({weekRanges[3].start} - {weekRanges[3].end})</MenuItem>
          <MenuItem value={4}>Acum 4 săptămâni ({weekRanges[4].start} - {weekRanges[4].end})</MenuItem>
        </Select>
      </FormControl>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DataGridPro
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10, 20, 50, 100]}
            pagination
            paginationMode="client"
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            autoHeight
            getRowHeight={() => 'auto'}
            components={{
              Toolbar: CustomToolbar,
            }}
            localeText={{
              ...roRO.components.MuiDataGrid.defaultProps.localeText,
              headerFilterOperatorContains: 'Filtrare rapidă',
              filterPanelRemoveAll: 'Resetare filtre',
              toolbarExport: 'Export',
              toolbarExportLabel: 'Export',
              toolbarExportCSV: 'În format CSV',
              toolbarExportPrint: 'Imprimare',
              toolbarExportExcel: 'În format Excel',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default LogNotificariUser;
