import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  MenuItem,
  Select,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Swal from 'sweetalert';
import { format } from 'date-fns';
import verificareToken from './VerificareToken';
import CloseIcon from '@mui/icons-material/Close';

// API URLs
const API_URL = `${process.env.REACT_APP_API_URL}`;
const { token } = verificareToken();
// Funcție pentru a formata data în formatul dorit
const formateazaData = (dataString) => {
    const data = new Date(dataString);
    return format(data, 'dd.MM.yyyy HH:mm');
  };

const Notite = () => {
  const [notite, setNotite] = useState([]);
  const [note, setNote] = useState('');
  const [important, setImportant] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [open, setOpen] = useState(false); // State pentru deschiderea și închiderea modalului

  // Importanță dropdown values
  const importantOptions = [
    { value: 'success', label: 'Neimportantă' },
    { value: 'primary', label: 'Mică' },
    { value: 'warning', label: 'Medie' },
    { value: 'error', label: 'Importantă' },
  ];
  useEffect(() => {
    if (!editMode) {
      setImportant(importantOptions[0].value);  
    }
  }, [editMode]);
  // Fetch notite from API (GET)
  const fetchNotite = async () => {
    try {
      const response = await fetch(`${API_URL}getNotite`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,  
        },
      });
      const { data } = await response.json();
      setNotite(data.notite);
    } catch (error) {
      console.error('Eroare la preluarea notițelor:', error);
    }
  };

  useEffect(() => {
    fetchNotite();
  }, []);

  // Adaugă o nouă notiță (POST)
  const handleAddNote = async () => {
    if (note.trim()) {
      try {
        const response = await fetch(`${API_URL}storeNotita`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,  
          },
          body: JSON.stringify({
            notita: note,
            importanta: important,
          }),
        });

        if (response.ok) {
          fetchNotite();
          setNote('');
          setImportant('');
          setOpen(false); // Închidem modalul după adăugare
          Swal('Succes!', 'Notița a fost adăugată cu succes.', 'success');
        } else {
          throw new Error('Eroare la adăugarea notiței.');
        }
      } catch (error) {
        console.error('Eroare la adăugarea notiței:', error);
      }
    }
  };
// Șterge notița (DELETE)
const handleDeleteNote = async (id) => {
    const shouldProceed = await Swal({
      title: "Confirmare",
      text: `Sigur doriți ștergerea notiței?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
  
    if (shouldProceed) {
      handleDeleteNoteOK(id); 
    }
  };
  
  const handleDeleteNoteOK = async (id) => {
    try {
      const response = await fetch(`${API_URL}deleteNotita/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,  
        },
      });

      if (response.ok) {
        fetchNotite();
        Swal('Ștearsă!', 'Notița a fost ștearsă cu succes.', 'success');
      } else {
        throw new Error('Eroare la ștergerea notiței.');
      }
    } catch (error) {
      console.error('Eroare la ștergerea notiței:', error);
    }
  };

  // Editează notița (PATCH)
  const handleEditNote = async (id) => {
    try {
      const response = await fetch(`${API_URL}updateNotita/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,  
        },
        body: JSON.stringify({
          notita: note,
          importanta: important,
        }),
      });

      if (response.ok) {
        fetchNotite();
        setNote('');
        setImportant('');
        setEditMode(false);
        setSelectedNote(null);
        setOpen(false); // Închidem modalul după editare
        Swal('Succes!', 'Notița a fost actualizată cu succes.', 'success');
      } else {
        throw new Error('Eroare la actualizarea notiței.');
      }
    } catch (error) {
      console.error('Eroare la actualizarea notiței:', error);
    }
  };

  // Deschide modalul pentru adăugare/editare
  const openModal = (notita = null) => {
    setOpen(true);
    if (notita) {
      // Dacă notita este selectată, setăm modul de editare
      setEditMode(true);
      setNote(notita.notita);
      setImportant(notita.importanta);
      setSelectedNote(notita.id);
    } else {
      // Dacă nu, este pentru adăugare
      setEditMode(false);
      setNote('');
      setImportant('');
      setSelectedNote(null);
    }
  };

  // Închide modalul
  const closeModal = () => {
    setOpen(false);
  };

  // Salvare modificări sau adăugare
  const handleSave = () => {
    if (editMode) {
      handleEditNote(selectedNote);
    } else {
      handleAddNote();
    }
  };

  return (
    <div>
 
 <div style={{ marginTop: 8, display: 'flex', justifyContent: 'flex-end' }}>
      <Button variant="contained" color="warning" size='small' onClick={() => openModal()}>
        Adăugare notiță nouă
      </Button>
</div>
      <div style={{ marginTop: 24 }}>
        {notite.map((notita) => (
        <Card key={notita.id} style={{ marginBottom: 8, position: 'relative' }} className={`notita notita-${notita.importanta}`}>
        <Tooltip title="Ștergere notiță" placement='left'>
        <IconButton
          onClick={() => handleDeleteNote(notita.id)}
          style={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        </Tooltip>
      
        <CardContent>
          {notita.notita}
      
          <div style={{ marginTop: 8, display: 'flex', justifyContent: 'flex-end' }}>
          <Tooltip title="Editare notiță" placement='left'>
            <IconButton style={{marginRight:'-10px'}}
              onClick={() => openModal(notita)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          </div>
          
          <div style={{ marginTop: 8, display: 'flex', justifyContent: 'flex-end' }}>
            <em style={{ fontSize: '12px' }}>Notiță din data {formateazaData(notita.datacreare)}</em>
          </div>
        </CardContent>
      </Card>
      
        ))}
      </div>

      {/* Modal pentru adăugare/editare notiță */}
      <Dialog open={open} onClose={closeModal} fullWidth>
        <DialogTitle className='titluModal'>{editMode ? 'Editare Notiță' : 'Adăugare Notiță'}</DialogTitle>
        <DialogContent className='mt-4'>
        <TextField
            label="Notiță"
            variant="outlined"
            fullWidth
            value={note}
            onChange={(e) => setNote(e.target.value)}
            style={{ marginBottom: 16 }}
            multiline  
            rows={4}   
            />
            <FormControl fullWidth>
            <InputLabel>Importanță</InputLabel>
          <Select
            label="Importanță"
            value={important}
            onChange={(e) => setImportant(e.target.value)}
            variant="outlined"
            fullWidth
            style={{ marginBottom: 16 }}
          >
            {importantOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="error">
            Anulare
          </Button>
          <Button onClick={handleSave} color="success">
            {editMode ? 'Salvează Modificările' : 'Adaugă Notiță'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Notite;
