import React, { useState, useEffect } from 'react';
import { Button, FormHelperText, FormLabel, List, ListItem, ListItemText, IconButton, Grid, TextField, TextareaAutosize, FormControl, InputLabel, Select, MenuItem, Input, CircularProgress, Typography } from '@mui/material';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { useParams } from 'react-router-dom';import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert';
import verificareToken from '../VerificareToken';
import DrepturiUtilizator from '../utilizator/drepturiUtilizator'; 
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ro } from 'date-fns/locale'; 

import { format as formatDate, parseISO, format } from 'date-fns';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function CampuriCustomDosar() {
    const { areDrept } = DrepturiUtilizator(); 
    const DreptDeModificareDosare = 'DreptDeModificareDosare';
    const { id } = useParams();

    const [customFields, setCustomFields] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [fieldValues, setFieldValues] = useState({});
    const apiUrl = process.env.REACT_APP_API_URL;
    const { token } = verificareToken();

    const [fileUploads, setFileUploads] = useState({});
    const [documents, setDocuments] = useState({});
    const [errors, setErrors] = useState({});

    const handleBlur = (id, value) => {
        if (isNaN(value)) {
            setErrors(prev => ({ ...prev, [id]: 'Valoarea trebuie să fie numerică' }));
        } else {
            setErrors(prev => ({ ...prev, [id]: '' }));
        }
    };

    if (!token) {
        window.location.href = '/autentificare?er=201';
    }

    const urlLaravel = process.env.REACT_APP_API_URL.replace('/api/', '');
    const fileSuffix = '/fisiere/in/';

    const fetchCustomFields = async () => { 
        setIsLoading(true);
        try {
            const response = await fetch(`${apiUrl}getDosarCustomFieldsValues/${id}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            setCustomFields(data.data.customFields);

            const initialFileUploads = {};
            const initialDocuments = {};
            const initialValues = {};

            data.data.customFields.forEach(field => {
                if (field.type === 'file') {
                    initialDocuments[field.id] = field.value || [];
                    initialFileUploads[field.id] = [];
                }
                if (field.type === 'date') {
                    initialValues[field.id] = field.value || format(new Date(), 'yyyy-MM-dd');
                } else {
                    initialValues[field.id] = field.value || '';
                }
            });

            setDocuments(initialDocuments);
            setFileUploads(initialFileUploads);
            setFieldValues(initialValues);
        } catch (error) {
            console.error('Failed to fetch custom fields:', error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchCustomFields();
    }, [id]);

    const handleChange = (id, value) => {
        setFieldValues(prev => ({ ...prev, [id]: value }));
    };

    const handleFileChange = (id, event) => {
        const files = Array.from(event.target.files);
        setFileUploads(prev => ({
            ...prev,
            [id]: [...(prev[id] || []), ...files]
        }));
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        let hasError = false;
    
        customFields.forEach((field, index) => {
            if (field.type === 'numeric' && isNaN(fieldValues[field.id])) {
                setErrors(prev => ({ ...prev, [field.id]: 'Valoarea trebuie să fie numerică' }));
                hasError = true;
            } else if (field.type !== 'file') {
                // Trimiți chiar și câmpurile goale pentru a le șterge pe server
                formData.append(`field_ids[${index}]`, field.id);
                formData.append(`values[${index}]`, fieldValues[field.id] || '');
            } else if (field.type === 'file') {
                if (fileUploads[field.id] && fileUploads[field.id].length > 0) {
                    fileUploads[field.id].forEach((file, fileIndex) => {
                        formData.append(`field_ids[${index}]`, field.id);
                        formData.append(`values[${index}][]`, file);
                    });
                } else {
                    // Trimiți câmpurile fișier fără valoare dacă nu există fișiere pentru a indica ștergerea
                    formData.append(`field_ids[${index}]`, field.id);
                    formData.append(`values[${index}]`, '');  // Semnalezi că nu există fișiere pentru acest câmp
                }
            }
        });
    
        if (hasError) return;
    
        try {
            const response = await fetch(`${apiUrl}setDosarCustomFieldsValues/${id}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData
            });
            const result = await response.json();
            if (response.ok) {
                console.log("Successfully uploaded:", result);
                fetchCustomFields(); 
            } else {
                console.error("Error response:", result);
            }
        } catch (error) {
            console.error('Failed to submit custom fields:', error);
        }
    };
    

    const handleDateChange = (id, value) => {
        const formattedDate = formatDate(value, 'yyyy-MM-dd');
        setFieldValues(prev => ({ ...prev, [id]: formattedDate }));
    };

    function getInputType(fieldType) {
        switch (fieldType) {
            case 'numeric':
                return 'number';  
            case 'text':
                return 'text';
            case 'date':
                return 'date';
            case 'textarea':
                return 'textarea';  
            case 'file':
                return 'file';
            default:
                return 'text'; 
        }
    }


    const deleteDocument = async (filename, fieldId) => {
        Swal({
            title: 'Ești sigur?',
            text: "Nu vei putea reveni asupra acestei acțiuni!",
            icon: 'warning',
            buttons: ['Anulează', 'Da, șterge!'],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                performDelete(filename, fieldId);
            }
        });
    };

    const performDelete = async (filename, fieldId) => {
        try { 
            const response = await fetch(`${apiUrl}deleteCustomFieldFile?dosar_id=${id}&field_id=${fieldId}&filename=${filename}`, {
                method: 'POST',
                headers: { Authorization: `Bearer ${token}` } 
            });

            if (response.ok) {
                Swal('Șters!', 'Documentul a fost șters.', 'success');
                fetchCustomFields();
            } else {
                Swal('Eroare', 'A apărut o eroare la ștergerea documentului.', 'warning');
            }
        } catch (error) {
            console.error('Eroare la ștergerea documentului:', error);
            Swal('Eroare', 'A apărut o eroare la ștergerea documentului.', 'warning');
        }
    };

    const handleKeyPress = (e) => {
        const charCode = e.which ? e.which : e.keyCode;
        const charStr = String.fromCharCode(charCode);
        const regex = /^[0-9]+$/;
        if (!regex.test(charStr)) {
            e.preventDefault();
        }
    };

    if (isLoading) return <CircularProgress />;

    return (
        <div>
            {customFields.map(field => (
                <FormControl key={field.id} fullWidth margin="normal">
                    <Grid container spacing={1} alignItems="center">
                        {field.type === 'textarea' ? (
                            <>
                                <Grid item xs={11}>
                                    <FormLabel>{field.label}</FormLabel>
                                    <TextareaAutosize
                                        minRows={3}
                                        value={fieldValues[field.id]}
                                        label={field.label}
                                        onChange={(e) => handleChange(field.id, e.target.value)}
                                        style={{width:'100%'}}
                                    />
                                    <FormHelperText>{field.info}</FormHelperText>
                                </Grid>
                                <Grid item xs={1}>
                                    {field.info && (
                                        <Tooltip title={field.info}>
                                            <InfoOutlinedIcon color="action" style={{ cursor: 'pointer' }} />
                                        </Tooltip>
                                    )}
                                </Grid>
                            </>
                        ) : field.type === 'file' ? (
                            <>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography className='headerDoc mb-2'>Încărcare documente noi</Typography>
                                        <input
                                            type="file"
                                            multiple
                                            onChange={(event) => handleFileChange(field.id, event)}
                                            style={{ display: 'none' }}
                                            id={`file-input-${field.id}`}
                                        />
                                        <label htmlFor={`file-input-${field.id}`}>
                                            <Button variant="outlined" fullWidth component="span"  size='small' color='warning'>
                                                Încărcare Documente <FileUploadIcon />
                                            </Button>
                                        </label>
                                        <List>
                                            {(fileUploads[field.id] || []).map((file, index) => (
                                                <ListItem key={index} className='itemUpload'>
                                                    <ListItemText primary={file.name} />
                                                    <IconButton edge="end" onClick={() => setFileUploads(prev => ({
                                                        ...prev,
                                                        [field.id]: prev[field.id].filter((_, i) => i !== index)
                                                    }))}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItem>
                                            ))}
                                        </List> 
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography className='headerDoc'>Documente încărcate</Typography>
                                        <List>
                                            {(documents[field.id] || []).map((file, index) => (
                                                <ListItem key={index} className='itemUpload'>
                                                    <ListItemText primary={file} />
                                                    <Tooltip title="Vizualizează document">
                                                        <IconButton edge="end" onClick={() => window.open(`${urlLaravel}${fileSuffix}${id}/${file}`)}>
                                                            <DocumentScannerIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <IconButton onClick={() => deleteDocument(file, field.id)} edge="end">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </Grid>
                                </Grid>
                            </>
                        ) : field.type === 'numeric' ? (
                            <>
                                <Grid item xs={11}>
                                    <TextField
                                        fullWidth
                                        value={fieldValues[field.id]}
                                        label={field.label}
                                        onChange={(e) => handleChange(field.id, e.target.value)}
                                        onBlur={(e) => handleBlur(field.id, e.target.value)}
                                        onKeyPress={handleKeyPress}
                                        error={!!errors[field.id]}
                                        helperText={errors[field.id]}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    {field.info && (
                                        <Tooltip title={field.info}>
                                            <InfoOutlinedIcon color="action" style={{ cursor: 'pointer' }} />
                                        </Tooltip>
                                    )}
                                </Grid>
                            </>
                        ) : field.type === 'text' ? (
                            <>
                                <Grid item xs={11}>
                                    <TextField
                                        type={getInputType(field.type)}
                                        value={fieldValues[field.id]}
                                        fullWidth
                                        label={field.label}
                                        onChange={(e) => handleChange(field.id, e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    {field.info && (
                                        <Tooltip title={field.info}>
                                            <InfoOutlinedIcon color="action" style={{ cursor: 'pointer' }} />
                                        </Tooltip>
                                    )}
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={11}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ro}>
                                        <FormControl key={field.id} fullWidth margin="normal">
                                            <DatePicker
                                                label={field.label}
                                                value={fieldValues[field.id] ? parseISO(fieldValues[field.id]) : null}
                                                onChange={(newValue) => handleDateChange(field.id, newValue)}
                                                renderInput={(params) => <TextField {...params} />}
                                                inputFormat="dd.MM.yyyy" 
                                                mask="__.__.____"
                                                InputLabelProps={{
                                                    shrink: true, 
                                                }}
                                                className="maxWidth"
                                            /> 
                                        </FormControl>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={1}>
                                    {field.info && (
                                        <Tooltip title={field.info}>
                                            <InfoOutlinedIcon color="action" style={{ cursor: 'pointer' }} />
                                        </Tooltip>
                                    )}
                                </Grid>
                            </>
                        )}
                    </Grid>
                </FormControl>
            ))}
            <Button onClick={handleSubmit} variant="contained" fullWidth color="secondary">Salvare date și documente noi</Button>
        </div>
    );
}

export default CampuriCustomDosar;
