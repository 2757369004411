import React, { useState, useEffect  } from 'react';
import { Alert, Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Typography, IconButton, Table, TableContainer, TableBody, TableCell, TableRow, TableHead, Box  } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import { Chart, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { utf8 } from 'jspdf';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Swal from 'sweetalert';

import BusinessIcon from '@mui/icons-material/Business';

import verificareToken from '../../VerificareToken'; 

function DateFirma({ open, onClose, cui }) {

  Chart.register(...registerables);
  const [dateFirma, setDateFirma] = useState(null);
  const [isLoading, setIsLoading] = useState(false); 

    const { token } = verificareToken();
 
 
  const handleExportPDF = () => {
    const element = document.getElementById('pdfContent');
    const pdf = new jsPDF('p', 'mm', 'a4');
  
    pdf.addFont('DejaVuSans-Bold', 'DejaVuSans-Bold', 'normal');
    pdf.setFont('DejaVuSans-Bold');
    pdf.setFontSize(12); 
    
    const fontSizeForTitle = 26; 
    pdf.setFontSize(fontSizeForTitle);
     const denumireSocContent = document.getElementById('denumireSoc').innerText;

     const xPosition = 50;

    pdf.text(denumireSocContent, xPosition, 10); 

    
    const tableData = [];
    const table = element.querySelector('table');
    if (table) {
      table.querySelectorAll('tr').forEach((row) => {
        const rowData = [];
        row.querySelectorAll('td').forEach((cell) => {
          rowData.push(cell.innerText);
        });
        tableData.push(rowData);
      });
    }
    pdf.autoTable({
        body: tableData,
        charset: 'UTF-8', 
      });
    
    const tableHead = ['An', ...chartsConfigPrintare.map(config => config.label)];
  
    const mainTableData2 = [tableHead];
    const mainTable2 = element.querySelector('#dateFirmeTabel');
    if (mainTable2) {
      mainTable2.querySelectorAll('tr').forEach((row) => {
        const rowData2 = [];
        row.querySelectorAll('td').forEach((cell) => {
          rowData2.push(cell.innerText);
        });
        mainTableData2.push(rowData2);
      });
    }
    console.log("mainTableData2:", mainTableData2);
  
    pdf.addPage('a4', 'landscape');
  
    pdf.autoTable({
      body: mainTableData2,
      charset: 'UTF-8',
      styles: {
        fontSize: 8,
      },
    });
  
    const canvasElements = element.querySelectorAll('canvas');
    let yOffset = pdf.autoTable.previous.finalY + 10;
  
    canvasElements.forEach((canvas, index) => {
      const imgData = canvas.toDataURL('image/png');
      const imageHeight = 130;
      const imageWidth = (imageHeight * canvas.width) / canvas.height;
      const pageHeight = pdf.internal.pageSize.height;
  
      if (yOffset + imageHeight > pageHeight) {
        pdf.addPage();
        yOffset = 10;
      }
  
      pdf.addImage(imgData, 'PNG', 10, yOffset, imageWidth, imageHeight);
      yOffset += imageHeight + 10;
    });
  
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
    const formattedTime = `${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}`;
    const fileName = `JuristPro - ${denumireSocContent} - Date Firma - ${formattedDate} - ${formattedTime}.pdf`;
  
    pdf.save(fileName);
  };
  
  
  useEffect(() => {
    if (open && cui) {
      fetchDateFirma();
    }
  }, [open, cui]);

  const fetchDateFirma = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}getDateFirmaByCui?cui=${cui}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setDateFirma(data.data.firma);
    } catch (error) {
      console.error("Eroare la preluarea datelor firmei: ", error);
      Swal("Atenționare", "Nu s-au putut prelua datele firmei.", "warning");
    }
    setIsLoading(false);
  };


  function transformChartData(data, chartsConfig) {
    const sortedData = data.sort((a, b) => a.RaportatPeAnul - b.RaportatPeAnul);
  
    return sortedData.map(item => {
      const transformedItem = { ...item };
      chartsConfig.forEach(config => {
        if (transformedItem[config.key] === null || transformedItem[config.key] === undefined) {
          transformedItem[config.key] = 0;  
        }
      });
      return transformedItem;
    });
  }
  

  

  const chartsConfig = [
    { label: 'Cifră Afaceri', key: 'CifraAfaceri' },
    { label: 'Număr Angajați', key: 'NumarAngajati' },
    { label: 'Venit', key: 'Venit' },
    { label: 'Pierdere Brută', key: 'PierdereBruta' },
    { label: 'Profit Brut', key: 'ProfitBrut' },
    { label: 'Total Active Imobilizate', key: 'TotalActiveImobilizate' },
    { label: 'Total Active Circulante', key: 'TotalActiveCirculante' },
    { label: 'Stocuri', key: 'Stocuri' },
    { label: 'Casă și Conturi', key: 'CasaSiConturi' },
    { label: 'Creanțe', key: 'Creante' },
    { label: 'Total Capital', key: 'TotalCapital' },
    { label: 'Capital Social', key: 'SocialCapital' },
    { label: 'Datorii Totale', key: 'DatoriiTotal' },
    { label: 'Cheltuieli Totale', key: 'CheltuieliTotale' },
    { label: 'Profit Net', key: 'ProfitNet' },
    { label: 'Pierdere Netă', key: 'PierdereNeta' },
    { label: 'Cheltuieli În Avans', key: 'CheltuieliInAvans' },
    { label: 'Venituri În Avans', key: 'VenituriInAvans' },
    { label: 'Datorii Termen Scurt', key: 'DatoriiTermenScurt' }
  ];

  
  const chartsConfigPrintare = [
    { label: 'Cifra afaceri', key: 'CifraAfaceri' },
    { label: 'Numar angajati', key: 'NumarAngajati' },
    { label: 'Venit', key: 'Venit' },
    { label: 'Pierdere bruta', key: 'PierdereBruta' },
    { label: 'Profit brut', key: 'ProfitBrut' },
    { label: 'Total active imobilizate', key: 'TotalActiveImobilizate' },
    { label: 'Total active circulante', key: 'TotalActiveCirculante' },
    { label: 'Stocuri', key: 'Stocuri' },
    { label: 'Casa si conturi', key: 'CasaSiConturi' },
    { label: 'Creante', key: 'Creante' },
    { label: 'Total capital', key: 'TotalCapital' },
    { label: 'Capital social', key: 'SocialCapital' },
    { label: 'Datorii totale', key: 'DatoriiTotal' },
    { label: 'Cheltuieli totale', key: 'CheltuieliTotale' },
    { label: 'Profit net', key: 'ProfitNet' },
    { label: 'Pierdere neta', key: 'PierdereNeta' },
    { label: 'Cheltuieli in avans', key: 'CheltuieliInAvans' },
    { label: 'Venituri in avans', key: 'VenituriInAvans' },
    { label: 'Datorii termen scurt', key: 'DatoriiTermenScurt' }
  ];

  const handlePrint = () => {
    window.print();
  }; 
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle className='titluModal'>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Typography variant="h6"> <BusinessIcon/> Date firmă</Typography>
            <Box>
            <Button className='no-print' startIcon={<PictureAsPdfIcon/>} style={{marginRight:'10px'}} size="small" variant="contained" color="secondary" onClick={handleExportPDF}>
                Export PDF
            </Button>
            <Button className='no-print' startIcon={<PrintIcon />} size="small" variant="contained" color="secondary" onClick={handlePrint}>
                Printare
            </Button>
            <IconButton onClick={onClose} style={{color:'white'}}  className='no-print'>
                <CloseIcon />
            </IconButton>
            </Box>
        </Box>
        </DialogTitle>

      <DialogContent dividers className='dateFirma' id='pdfContent'>
        {isLoading ? (
          <CircularProgress />
        ) : dateFirma ? (
          <>
            <Box id="denumireSoc" className="text-center mt-4 mb-4"><Typography variant='h4'>{dateFirma.NumeFirma}</Typography></Box>
             <Table>
                <TableBody>
                    <TableRow>
                    <TableCell>Cod fiscal:</TableCell>
                    <TableCell>{dateFirma.CodFiscal}</TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell>Denumire societate:</TableCell>
                    <TableCell>{dateFirma.NumeFirma}</TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell>Nr.Reg.Com.:</TableCell>
                    <TableCell>{dateFirma.NrInmatriculare}</TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell>Data înregistrare:</TableCell>
                    <TableCell>{dateFirma.DataInregistrare}</TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell>Stare:</TableCell>
                    <TableCell>{dateFirma.Stare}</TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell>Cod CAEN</TableCell>
                    <TableCell>{dateFirma.ClasaCaen}</TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell>Date ANAF:</TableCell>
                    <TableCell>
                        Platitor TVA: <span style={{ color: dateFirma.Anaf.PlatitorTVA ? 'green' : 'red' }}>
                                        {dateFirma.Anaf.PlatitorTVA ? 'DA' : 'NU'}
                                    </span><br />
                        Activ Anaf: <span style={{ color: dateFirma.Anaf.ActivAnaf ? 'green' : 'red' }}>
                                    {dateFirma.Anaf.ActivAnaf ? 'DA' : 'NU'}
                                    </span><br />
                        Denumire: <span style={{ color: dateFirma.Anaf.Denumire ? 'green' : 'red' }}>
                                    {dateFirma.Anaf.Denumire ? dateFirma.Anaf.Denumire : 'NU'}
                                </span>
                    </TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell>Capital social:</TableCell>
                    <TableCell>{dateFirma.CapitalSocial}</TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell>Localitate:</TableCell>
                    <TableCell>{dateFirma.Localitate}</TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell>Judet:</TableCell>
                    <TableCell>{dateFirma.Judet}</TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell>Sector:</TableCell>
                    <TableCell>{dateFirma.Sector ? dateFirma.Sector : '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell>Sediu social:</TableCell>
                    <TableCell>{dateFirma.Adresa}</TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell>Telefoane:</TableCell>
                    <TableCell>{dateFirma.Telefoane ? dateFirma.Telefoane : '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell>Fax:</TableCell>
                    <TableCell>{dateFirma.Fax ? dateFirma.Fax : '-'}</TableCell>
                    </TableRow>
                </TableBody>
                </Table>

                {dateFirma.DiagramaCifraAfaceri ? (
            <div>
                
                 {/* Adaugă tabelul cu datele din chartsConfig */}
                 <TableContainer id="">
                    <Table id="dateFirmeTabel">
                        <TableHead>
                        <TableRow>
                            <TableCell>An</TableCell>
                            {chartsConfig.map(config => (
                            <TableCell key={config.key}>{config.label}</TableCell>
                            ))}
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {dateFirma.DiagramaCifraAfaceri.map(item => (
                            <TableRow key={item.RaportatPeAnul}>
                            <TableCell>{item.RaportatPeAnul}</TableCell>
                            {chartsConfig.map(config => (
                                <TableCell key={config.key}>{item[config.key]}</TableCell>
                            ))}
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>

                    {dateFirma.DiagramaCifraAfaceri && chartsConfig.map(config => {
                    const transformedData = transformChartData(dateFirma.DiagramaCifraAfaceri, chartsConfig);

                    return (
                      <Line 
                        data={{
                          labels: transformedData.map(item => `An ${item.RaportatPeAnul}`),
                          datasets: [{
                            label: config.label,
                            data: transformedData.map(item => item[config.key]),  
                            backgroundColor: 'rgba(255, 255, 0, 0.2)', 
                            borderColor: '#4169E1', 
                          }]
                        }}
                        options={{
                          scales: {
                            x: {
                              type: 'category',
                              title: {
                                display: true,
                                text: 'Anul'
                              }
                            },
                            y: {
                              title: {
                                display: true,
                                text: config.label
                              }
                            }
                          }
                        }}
                      />
                    );
                  })}
 
            </div>
          ) : (
            <Alert severity="warning" className='mt-2 mb-2'>Nu există date financiare pentru firma {dateFirma.NumeFirma}.</Alert>
          )}
          </>
        ) : (
          <Typography>Nu există date disponibile pentru firma solicitată.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" className='no-print'>Închidere</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DateFirma;
