import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Dialog, Checkbox, FormControlLabel, Button, Select, MenuItem, CircularProgress, Alert, DialogTitle, DialogContent, DialogActions, LinearProgress, Typography, Box, Grid } from '@mui/material';
import { DataGridPro, useGridApiRef, useGridApiContext, GridToolbarContainer, GridToolbarExportContainer, GridPrintExportMenuItem, GridCsvExportMenuItem, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton, roRO, getGridStringOperators,   GridColDef,   GridFilterOperator, GridPrintGetRowsToExportParams, gridFilteredSortedRowIdsSelector, selectedGridRowsSelector, GridRowId,   } from '@mui/x-data-grid-pro'; 
import { ExportInExcel } from './ExportInExcel';
import Swal from 'sweetalert';
import verificareToken from './VerificareToken';
import SyncIcon from '@mui/icons-material/Sync';
import { LicenseInfo } from '@mui/x-license-pro'; 
import { styled } from '@mui/system';

LicenseInfo.setLicenseKey('ac62ac97b94addeb6dbf2b2473e35ecbTz05MjE3MSxFPTE3NDk2MjQ2NjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');


const SincronizareImediata = () => {    
    //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
    window.dispatchEvent(new Event('authChange'));
    
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        nrCrt: true,
        numardosar: true,
        actualizatPortal: true,
        dosarActualizat: true,
        actualizatICCJ: true 
    });

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('ro-RO').replace(/\//g, '-');
    const formattedTime = currentDate.toLocaleTimeString('ro-RO', { hour12: false }).replace(/:/g, '-');
    const fileNameExcel = `JuristPRO - Export EXCEL lista sincronizare dosare - ${formattedDate} - ${formattedTime}`;
    const fileNameCSV = `JuristPro - CSV Export lista sincronizare dosare - ${formattedDate} - ${formattedTime}`;
    
    const csvOptions = {
        fileName: fileNameCSV,
        delimiter: ',',
        utf8WithBom: true,
    };
    
    const FlexContainer = styled(Box)({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    });

    const { token } = verificareToken();
    const [dosare, setDosare] = useState([]);
    const [sincronizarePortal, setSincronizarePortal] = useState(false);
    const [sincronizareICCJ, setSincronizareICCJ] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [progressPortal, setProgressPortal] = useState(0);
    const [progressICCJ, setProgressICCJ] = useState(0);
    const [isSynchronizing, setIsSynchronizing] = useState(false);
    const [modala, setModala] = useState(true);
    const [currentStep, setCurrentStep] = useState(0);
    const totalSteps = [sincronizarePortal, sincronizareICCJ].filter(Boolean).length;

    const isButtonDisabled = !sincronizarePortal && !sincronizareICCJ;

    useEffect(() => {
        fetchDosare();
    }, []);

    const fetchDosare = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}getDosareAdaugateLaSincronizare`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (data.status === 200) {
                const updatedDosare = data.data.dosare.map((dosar, index) => ({
                    ...dosar,
                    nrCrt: index + 1 + '.',
                    actualizatPortal: '-',
                    actualizatICCJ: '-',
                    dosarActualizat: '-'
                }));
                setDosare(updatedDosare);
            }
        } catch (error) {
            console.error('Eroare la încărcarea dosarelor:', error);
        }
        setIsLoading(false);
    };

    const handleStartSincronizare = async () => {
        setIsSynchronizing(true);
        setProgressPortal(0);
        setProgressICCJ(0);
        let results = [];

        if (sincronizarePortal) {
            const result = await sincronizeazaDosare('sincronizareDosarCuPortalulJustitiei', setProgressPortal, 'actualizatPortal');
            results.push(`${result} dosare au fost sincronizate cu Portalul Justiției`);
            setCurrentStep(prev => prev + 1);
        }

        if (sincronizareICCJ) {
            const result = await sincronizeazaDosare('sincronizareDosarCuICCJ', setProgressICCJ, 'actualizatICCJ');
            results.push(`${result} dosare au fost sincronizate cu Înalta Curte de Casație și Justiție`);
            setCurrentStep(prev => prev + 1);
        }

        setIsSynchronizing(false);
        setModala(false);

        Swal({
            title: 'Sincronizare completă',
            text: results.join('\n'),
            icon: 'success',
        });
    };

    const sincronizeazaDosare = async (apiPath, setProgress, fieldToUpdate) => {
        const chunkSize = 10;
        let totalSuccess = 0;
        let processedCount = 0;

        for (let i = 0; i < dosare.length; i += chunkSize) {
            const chunk = dosare.slice(i, i + chunkSize);
            const responses = await Promise.all(chunk.map(dosar =>
                fetch(`${process.env.REACT_APP_API_URL}${apiPath}/${dosar.id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }).then(res => {
                    processedCount++;
                    const progress = Math.round((processedCount / dosare.length) * 100);
                    setProgress(progress);

                    let updatedDosar = { ...dosar };
                    if (res.status === 200) {
                        totalSuccess++;
                        updatedDosar[fieldToUpdate] = '✓';
                    } else {
                        updatedDosar[fieldToUpdate] = 'X';
                    }

                    updatedDosar.dosarActualizat =
                        (updatedDosar.actualizatPortal === '✓' || updatedDosar.actualizatICCJ === '✓') ? '✓' : 'X';

                    setDosare(prevDosare => prevDosare.map(d => {
                        if (d.id === dosar.id) {
                            const existingDosar = prevDosare.find(existing => existing.id === dosar.id);
                            return {
                                ...existingDosar,
                                [fieldToUpdate]: updatedDosar[fieldToUpdate],
                                dosarActualizat: (existingDosar.actualizatPortal === '✓' || existingDosar.actualizatICCJ === '✓' || updatedDosar[fieldToUpdate] === '✓') ? '✓' : 'X'
                            };
                        }
                        return d;
                    }));
                    return updatedDosar;
                })
            ));
        }

        return totalSuccess;
    };

    const resetState = () => {
        setSincronizarePortal(false);
        setSincronizareICCJ(false);
        setIsLoading(false);
        setProgressPortal(0);
        setProgressICCJ(0);
        setIsSynchronizing(false);
        setCurrentStep(0); 
        setModala(true);
    };

    const inchidModala = () => { 
        setModala(false);
    };

    const columns = [
        {
            field: 'nrCrt',
            headerName: 'Nr. Crt',
            width: 100,
            disableColumnMenu: true, hideable: false
        },
        { field: 'numardosar', headerName: 'Nr. Dosar', width: 150, disableColumnMenu: true, hideable: false },
        { field: 'dataum', headerName: 'Data ultimei modificări', width: 180, disableColumnMenu: true, hideable: false },
        { field: 'ultima_verificare', headerName: 'Ultima verificare', width: 180, disableColumnMenu: true, hideable: false },
        {
            field: 'actualizatPortal',
            headerName: 'Actualizat Portal',
            width: 140,
            renderCell: (params) => (
                <span style={{ color: params.value === '✓' ? 'green' : params.value === 'X' ? 'red' : 'black' }}>
                    {params.value}
                </span>
            ), disableColumnMenu: true, hideable: false
        },
        {
            field: 'actualizatICCJ',
            headerName: 'Actualizat ICCJ',
            width: 140,
            renderCell: (params) => (
                <span style={{ color: params.value === '✓' ? 'green' : params.value === 'X' ? 'red' : 'black' }}>
                    {params.value}
                </span>
            ), disableColumnMenu: true, hideable: false
        },
        {
            field: 'dosarActualizat',
            headerName: 'Dosar Actualizat',
            width: 140,
            renderCell: (params) => {
                const color = params.value === '✓' ? 'green' : 'red';
                return (
                    <span style={{ color }}>
                        {params.value}
                    </span>
                );
            }, disableColumnMenu: true, hideable: false
        },
    ];

    const CustomGridToolbarContainer = ({ printOptions }) => {
        const prepareDataForExport = (data, columns, columnVisibilityModel) => {
            const columnInfoMap = columns.reduce((acc, column) => {
                if (columnVisibilityModel[column.field] !== false) {
                    acc[column.field] = column.headerName || column.field;
                }
                return acc;
            }, {});

            const orderedColumnFields = columns
                .filter(col => columnVisibilityModel[col.field] !== false)
                .map(col => col.field);

            return data.map(row => {
                const filteredRow = {};
                orderedColumnFields.forEach(key => {
                    if (key !== '__check__') {
                        filteredRow[columnInfoMap[key]] = row[key];
                    }
                });
                return filteredRow;
            });
        };

        return (
            <>
                <GridToolbarContainer>
                    <FlexContainer style={{ paddingBottom: '10px', paddingTop: '5px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'left', mr: 1 }}>
                            <GridToolbarFilterButton />
                            <GridToolbarDensitySelector />
                            <GridToolbarExportContainer>
                                <GridPrintExportMenuItem options={printOptions} />
                                <ExportInExcel
                                    csvData={prepareDataForExport(
                                        dosare,
                                        columns,
                                        columnVisibilityModel
                                    )}
                                    fileName={fileNameExcel}
                                />
                                <GridCsvExportMenuItem options={csvOptions} />
                            </GridToolbarExportContainer>
                        </Box>
                    </FlexContainer>
                </GridToolbarContainer>
                {isLoading ? (
                    <div style={{ width: '100%', marginBottom: 8 }}>
                        <LinearProgress />
                    </div>
                ) : (
                    <></>
                )}
            </>
        );
    };

    const getSelectedRowsToExport = ({ apiRef }) => {
        const selectedRowIds = selectedGridRowsSelector(apiRef);
        if (selectedRowIds.size > 0) {
            return Array.from(selectedRowIds.keys());
        }
        return gridFilteredSortedRowIdsSelector(apiRef);
    };

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
            className="container card p-3 mt-3">

            <Box display="flex" justifyContent="space-between" alignItems="center">
                <h5 className='p-2 text-uppercase mb-3'>Sincronizare dosare</h5>
                <Button
                    variant="text"
                    color="primary"
                    onClick={resetState}
                >
                    Sincronizare nouă
                </Button>
            </Box>

            <Grid container spacing={2}>
                <Dialog open={isSynchronizing} fullWidth maxWidth="sm">
                    <DialogTitle id="row-details-dialog">
                        <SyncIcon className="rotating" style={{ color: 'white' }} /> Sincronizare în curs
                        {totalSteps > 1 && ` (${currentStep + 1}/${totalSteps})`}
                    </DialogTitle>
                    <DialogContent>
                        <Alert severity="warning" style={{ marginBottom: '20px' }} className='mt-3'>
                            <Box
                                dangerouslySetInnerHTML={{
                                    __html: (
                                        currentStep === 0 && sincronizarePortal &&
                                        "<b>Sincronizare cu Portalul Justiției în progres. </b><br />Vă rugăm să așteptați până la finalizare."
                                    ) || (
                                        currentStep === 1 && sincronizareICCJ &&
                                        "<b>Sincronizare cu Înalta Curte de Casație și Justiție în progres.</b> <br />Vă rugăm să așteptați până la finalizare."
                                    )
                                }}
                            />
                        </Alert>
                        {sincronizarePortal && currentStep === 0 && <>
                            <Typography variant="body1">Sincronizare cu Portalul Justiției: {progressPortal}%</Typography>
                            <LinearProgress
                                variant="determinate"
                                value={progressPortal}
                                style={{ height: '20px' }}
                                color={progressPortal === 100 ? 'success' : 'primary'}
                            />
                        </>}
                        {sincronizareICCJ && currentStep === 1 && <>
                            <Typography variant="body1">Sincronizare cu Înalta Curte de Casație și Justiție: {progressICCJ}%</Typography>
                            <LinearProgress
                                variant="determinate"
                                value={progressICCJ}
                                style={{ height: '20px' }}
                                color={progressICCJ === 100 ? 'success' : 'primary'}
                            />
                        </>}
                    </DialogContent>
                </Dialog>
                <Dialog open={modala} fullWidth maxWidth="md">
                    <DialogTitle className='titluModal'>Sincronizare imediată</DialogTitle>
                    <DialogContent className='mt-3' style={{ position: 'relative' }}>
                        {isLoading && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                    zIndex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backdropFilter: 'blur(5px)'
                                }}
                            >
                                <CircularProgress />
                                <Typography variant="body1" style={{ marginTop: '20px' }}>
                                    Vă rugăm să așteptați...
                                </Typography>
                            </Box>
                        )}
                        <Box>
                            <FormControlLabel
                                control={<Checkbox checked={sincronizarePortal} onChange={(e) => setSincronizarePortal(e.target.checked)} />}
                                label="Sincronizare cu Portalul Justiției"
                                disabled={isLoading}
                            />
                        </Box>
                        <Box>
                            <FormControlLabel
                                control={<Checkbox checked={sincronizareICCJ} onChange={(e) => setSincronizareICCJ(e.target.checked)} />}
                                label="Sincronizare cu Înalta Curte de Casație și Justiție"
                                disabled={isLoading}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                variant="text"
                                color="error"
                                onClick={inchidModala}
                            >
                                Renunțare
                            </Button>
                            <Button
                                onClick={handleStartSincronizare}
                                color='warning'
                                variant="contained"
                                disabled={isButtonDisabled}
                            >
                                Sincronizați acum
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
                <DataGridPro
                    className='m-2'
                    rows={dosare}
                    columns={columns}
                    pageSize={150}
                    density="compact"
                    loading={isLoading}
                    onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                    columnVisibilityModel={columnVisibilityModel}
                    slots={{
                        headerFilterMenu: null,
                        toolbar: CustomGridToolbarContainer
                    }}
                    slotProps={{
                        toolbar: {
                            printOptions: { getRowsToExport: getSelectedRowsToExport },
                            csvOptions: {
                                fileName: 'nume-fisier',
                                delimiter: ',',
                                utf8WithBom: true,
                            },
                        },
                    }}
                    localeText={{
                        ...roRO.components.MuiDataGrid.defaultProps.localeText,
                        headerFilterOperatorContains: 'Filtrare rapidă',
                        filterPanelRemoveAll: 'Resetare filtre',
                        toolbarExport: 'Export',
                        toolbarExportLabel: 'Export',
                        toolbarExportCSV: 'În format CSV',
                        toolbarExportPrint: 'Imprimare tabel',
                        toolbarExportExcel: 'În format Excel',
                        filterOperatorContains: 'conține',
                        filterOperatorIsAnyOf: 'este una din valorile',
                        filterOperatorIsEmpty: 'este necompletat',
                        filterOperatorIsNotEmpty: 'este completat',

                    }}

                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
                    }
                />
            </Grid>
        </div>
    );
};

export default SincronizareImediata;