import React, { useEffect, useState, useCallback } from 'react'; 
import verificareToken from './VerificareToken';
import { useSound } from './sunete';
import usePreferinteUtilizator from './utilizator/preferinteUtilizatorHook';
import Swal from 'sweetalert';
import { CircularProgress } from '@mui/material'; 
import { Card, CardActionArea, CardContent, Typography, Grid } from '@mui/material';
import { ViewList, Search, CalendarToday, Settings, SettingsApplications, Assessment  } from '@mui/icons-material';
import { Link } from 'react-router-dom';  
import DrepturiUtilizator from './utilizator/drepturiUtilizator';  
import FereastraNotificari from './FereastraNotificari'; 
import SyncIcon from '@mui/icons-material/Sync';
import GavelIcon from '@mui/icons-material/Gavel';

export const Panou = () => {   
  
    const { token } = verificareToken();

  //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
  window.dispatchEvent(new Event('authChange'));
  
  function getUltimulActionat() {
    let ultimulActionat = sessionStorage.getItem('ultimulActionat') === 'true';
  
    if (!ultimulActionat) {
        ultimulActionat = localStorage.getItem('ultimulActionat') === 'true';
    }

    if (!ultimulActionat) {
        const cookies = document.cookie.split('; ');
        const ultimulActionatCookie = cookies.find(row => row.startsWith('ultimulActionat='));
        if (ultimulActionatCookie) {
            ultimulActionat = ultimulActionatCookie.split('=')[1] === 'true';
        }
    }

    return ultimulActionat;
}


  function getOk2Go() {
    let ok2go = sessionStorage.getItem('ok2go');
  
    if (ok2go === null) {
      ok2go = localStorage.getItem('ok2go');
    }
    if (ok2go === null) {
      const cookies = document.cookie.split('; ');
      const ok2goCookie = cookies.find(row => row.startsWith('ok2go='));
      if (ok2goCookie) {
        ok2go = ok2goCookie.split('=')[1];
      }
    }
    return ok2go === 'true';
  }
  const ok2go = getOk2Go();
  const [showNotificare, setShowNotificare] = useState(false);
  const { preferinte, loaded } = usePreferinteUtilizator();
  const openlastopened = preferinte.openlastopened;
  const afisare_notificari = preferinte.afisare_notificari;
  const afisare_notificari_azi = preferinte.afisare_notificari_azi;
 
  
  const { playSound } = useSound();

  useEffect(() => {
    if (afisare_notificari && afisare_notificari_azi && loaded) { 
        setShowNotificare(true);  
        playSound('notificare');
    }
  }, [afisare_notificari, afisare_notificari_azi, loaded]);

  const handleCloseNotificare = () => {
      setShowNotificare(false);
  };

  const [isLoading, setLoading] = useState(true);
  
  //aduc hook-ul de drepturi
  const { areDrept } = DrepturiUtilizator();
  //lista de drepturi necesare pe pagina curenta
/* 
  const DreptDeAdaugareDocumenteLaDosar = 'DreptDeAdaugareDocumenteLaDosar';
  const DreptDeAdaugareDosare = 'DreptDeAdaugareDosare';
  const DreptDeImportAutomatDosare = 'DreptDeImportAutomatDosare';
  const DreptDeImportManualDosare = 'DreptDeImportManualDosare';
  const DreptDeModificareDosare = 'DreptDeModificareDosare';
  const DreptDeRealocareDosareLaUnAltUtilizator = 'DreptDeRealocareDosareLaUnAltUtilizator';
  const DreptDeStergereDosare = 'DreptDeStergereDosare';
  const VizualizareDosare = 'VizualizareDosare';
  const AdaugareGrupuriUtilizatori = 'AdaugareGrupuriUtilizatori';
  const EditareGrupuriUtilizatori = 'EditareGrupuriUtilizatori';
  const StergereGrupuriUtilizatori = 'StergereGrupuriUtilizatori';
  const VizualizareGrupuriUtilizatori = 'VizualizareGrupuriUtilizatori';
  const AdaugareGrupuriDeDosare = 'AdaugareGrupuriDeDosare';
  const EditareGrupuriDeDosare = 'EditareGrupuriDeDosare';
  const StergereGrupuriDeDosare = 'StergereGrupuriDeDosare';
  const VizualizareGrupuriDeDosare = 'VizualizareGrupuriDeDosare';
  const AdaugareRoluri = 'AdaugareRoluri';
  const EditareRoluri = 'EditareRoluri';
  const StergereRoluri = 'StergereRoluri';
  const VizualizareRoluri = 'VizualizareRoluri';
  const AccesLaToataAplicatia = 'AccesLaToataAplicatia';
  const AdaugareUtilizatori = 'AdaugareUtilizatori';
  const EditareUtilizatori = 'EditareUtilizatori';
  const StergereUtilizatori = 'StergereUtilizatori';
  const VizualizareUtilizatori = 'VizualizareUtilizatori';
  const VizualizareActeDosar = 'VizualizareActeDosar';
  const VizualizareActivitateDosar = 'VizualizareActivitateDosar';
  const VizualizareAtentionariDosar = 'VizualizareAtentionariDosar';
  const VizualizareContinutDosar = 'VizualizareContinutDosar';
  const VizualizareContracteDosar = 'VizualizareContracteDosar';
  const VizualizareCosturiStudiuDosare = 'VizualizareCosturiStudiuDosare';
  const VizualizareDosarePrealabil = 'VizualizareDosarePrealabil';
  const VizualizareLegislatieDosar = 'VizualizareLegislatieDosar';
  const VizualizareObiecteDosar = 'VizualizareObiecteDosar';
  const VizualizarePartiStadiuDosar = 'VizualizarePartiStadiuDosar';
  const VizualizareStatiiDosar = 'VizualizareStatiiDosar';
  const VizualizareTermeneStadiuDosar = 'VizualizareTermeneStadiuDosar';
  const Notificari = 'Notificari';
  */
  const RaportParti = 'RaportParti';
  const RaportActivitate = 'RaportActivitate';
  const RaportEvenimente = 'RaportEvenimente';
  const RaportPivot = 'RaportPivot'; 
  const Notificari = 'Notificari';

  const [data, setData] = useState(null);
// Suprascrie console.error pentru a filtra erorile 404
const suppressError = () => {
  const originalConsoleError = console.error;
  console.error = (...args) => {
    if (!args.some(arg => typeof arg === 'string' && arg.includes('404'))) {
      originalConsoleError(...args);
    }
  };
  return () => {
    console.error = originalConsoleError;
  };
};

const fetchDosar = useCallback(async () => {
  if (loaded && openlastopened) {
    // Activează suprimarea erorilor 404
    const restoreConsoleError = suppressError();

    const res = await fetch(`${process.env.REACT_APP_API_URL}getUltimulDosarDeschis`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (res.status === 404) {
      console.log("Nu există un ultim dosar deschis");
    } else {
      const { data } = await res.json();
      setData(data);
    }

    // Restaurează comportamentul inițial al console.error
    restoreConsoleError();
  }
  setLoading(false);
}, [token, openlastopened, loaded]);

useEffect(() => {
  if (loaded) {
    fetchDosar();
  }
}, [loaded, fetchDosar]);

 
  useEffect(() => {
    const ultimulActionat = getUltimulActionat();
    
    const handleSwal = () => { 
 
      if (data) {
        const showSweetAlert = async () => {
          const swalResult = await Swal({
            title: "Ultimul dosar deschis",
            text: ``,
            content: {
              element: "div",
              attributes: {
                innerHTML: `
                    <div  style="font-size:1.2em;">Dosarul <b>${data.dateDosar.numar || data.dateDosar.numar_internte}</b>, a clientului <b>${data.dateDosar.client}</b>.</div>
                    <div style="text-align:left; padding:10px; background:#eee;margin-top:10px;">
                      ${data.dateDosar.stadiu ? `<strong>Stadiul dosarului:</strong> ${data.dateDosar.stadiu}<br>` : ''}
                      ${data.dateDosar.obiect ? `<strong>Obiectul dosarului:</strong> ${data.dateDosar.obiect}<br>` : ''}
                      ${data.dateDosar.instanta ? `<strong>Instanța:</strong> ${data.dateDosar.instanta}<br>` : ''}
                      ${data.dateDosar.materie ? `<strong>Materia:</strong> ${data.dateDosar.materie}` : ''}
                    </div>`

              }
            },
            icon: "info",
            buttons: {
              cancel: "Refuz",
              confirm: "Reveniți la dosar"
            },
            dangerMode: true, 
            closeOnClickOutside: false  
          });
    
          if (swalResult) {
            //daca am actionat merg la dosar, setes ultimulActionat true ca sa nu mai redeschid fereastra popup cu ultimul dosar deschis pana la relogare
            localStorage.setItem('ultimulActionat', 'true');
            sessionStorage.setItem('ultimulActionat', 'true');
            document.cookie = "ultimulActionat=true; path=/";

            window.location.href = `/editare-dosar/${data.idDosar}`;
          } else {
            const deleteRes = await fetch(`${process.env.REACT_APP_API_URL}setUltimulDosarDeschis?idDosar=`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
              },
            });
    
            const deleteData = await deleteRes.json();
    
            if (deleteData.status === 404) {
              console.log(deleteData.message);
            }
          }
        };
    
        showSweetAlert();
      } else if (data && data.status === 404) {
        console.log(data.message);
      }
    }; 
     
    if (!isLoading && !ultimulActionat) {
      handleSwal();
    }
  }, [isLoading, data, token]);

  
const handleClickFaraDrept = () => {  
  Swal("Acces interzis!", "Nu aveți dreptul de a accesa acestă secțiune.", "error");
}  

  if (isLoading) {
    return <div className="card container mx-auto p-5 mt-5" style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '10vh',
    width: '100% !important' 
    }}> 
    <CircularProgress />
    </div>;  
}  

  return (
    <div>
    {ok2go ? (
      <div className="card container mx-auto p-5 mt-5">
        <h4 className="text-center"> Panou utilizator </h4>
        <hr></hr>

        <Grid className='gridB' container spacing={4}>
          <Grid item xs={12} sm={6} md={4} className='cardPanou'>
            <Card component="a" href="/lista-dosare" className='cardPanouCont'>
              <CardActionArea>
                <CardContent className="cardApp" >
                  <ViewList className="secondaryC IconCardApp" />
                  <Typography variant="h6" component="h2">
                    Lista dosare
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid className='gridB' item xs={12} sm={6} md={4}>
          <Card
              {...(areDrept(Notificari)
                ? { component: "a", href: "/calendar" }
                : { onClick: handleClickFaraDrept })}
            >
              <CardActionArea>
                <CardContent className="cardApp" >
                  <CalendarToday className="secondaryC IconCardApp" />
                  <Typography variant="h6" component="h2">
                    Calendar
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          
          <Grid className='gridB' item xs={12} sm={6} md={4}>
          <Card
              {...(areDrept(RaportParti) || areDrept(RaportActivitate) || areDrept(RaportEvenimente) || areDrept(RaportPivot)
                ? { component: "a", href: "/rapoarte" }
                : { onClick: handleClickFaraDrept })}
            >
              <CardActionArea>
                <CardContent className="cardApp" >
                  <Assessment className="secondaryC IconCardApp"  />
                  <Typography variant="h6" component="h2">
                    Rapoarte
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          
          <Grid className='gridB' item xs={12} sm={6} md={4}>
            <Card component="a" href="/cautare-online">
              <CardActionArea>
                <CardContent className="cardApp" >
                  <Search className="secondaryC IconCardApp"  />
                  <Typography variant="h6" component="h2">
                    Cautare online
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          
          <Grid className='gridB' item xs={12} sm={6} md={4}>
            <Card component="a" href="/sincronizare-dosare">
              <CardActionArea>
                <CardContent className="cardApp" >
                  <SyncIcon className="secondaryC IconCardApp"  />
                  <Typography variant="h6" component="h2">
                    Sincronizare instantă
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
 
 
          <Grid className='gridB' item xs={12} sm={6} md={4}>
            <Card component="a" href="/setari">
              <CardActionArea>
                <CardContent className="cardApp" >
                  <SettingsApplications className="secondaryC IconCardApp"  />
                  <Typography variant="h6" component="h2">
                    Setari
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
 
        </Grid>
        {areDrept(Notificari) ? (  
         <FereastraNotificari open={showNotificare} onClose={handleCloseNotificare} />
        ) : null }
      </div> 
    ) : (
      <div  className="card container mx-auto p-5 mt-5">
        <h3 className="text-center"> Nu s-a reușit autentificarea </h3>
      </div> 
    )}
  </div>
  );
};
