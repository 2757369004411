import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Tooltip, IconButton } from '@mui/material';
import QRCode from 'qrcode.react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress, Box, Drawer, Typography  } from '@mui/material';
import QrCodeIcon from '@mui/icons-material/QrCode'; 

import SyncIcon from '@mui/icons-material/Sync';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import usePreferinteUtilizator from './utilizator/preferinteUtilizatorHook';
import NotificariAplicatie from './NotificariAplicatie';
import SchimbareLicenta from './SchimbareLicenta';
import ListaFirmeBPI from './ListaFirmeBPI';
import Activitati from './Activitati';
import Notite from './Notite';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import DrepturiUtilizator from './utilizator/drepturiUtilizator'; 
import { userEventEmitter } from './EventEmitter'; 
import SettingsIcon from '@mui/icons-material/Settings';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CloseIcon from '@mui/icons-material/Close';
import NoteIcon from '@mui/icons-material/Note';

const Menu = () => {
  
  const { areDrept } = DrepturiUtilizator();
  const EditareUtilizatori = 'EditareUtilizatori';
  const VizualizareFirmeBPIDosar = 'VizualizareFirmeBPIDosar';
  const Notificari = 'Notificari';
  const EditareGrupuriDeDosare = 'EditareGrupuriDeDosare';
  const AccesLaToataAplicatia = 'AccesLaToataAplicatia';
  const RaportParti = 'RaportParti';
  const RaportActivitate = 'RaportActivitate';
  const RaportEvenimente = 'RaportEvenimente';
  const RaportPivot = 'RaportPivot';

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenNote, setIsOpenNote] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState('60%');
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpen(open);
  };

  const toggleDrawerNote = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsOpenNote(open);
  };

  function getComplementaryColor(hex) {
    let rgb = hexToRgb(hex).split(", ").map(Number);
    let complementaryRgb = rgb.map(component => 255 - component);
    return rgbToHex(`rgb(${complementaryRgb.join(", ")})`);
  }

  function adjustLuminance(hex, percent) {
    let rgb = hexToRgb(hex).split(", ").map(Number);
    let adjustedRgb = rgb.map(component => {
      let newComponent = component * (percent / 100) + component;
      return Math.min(255, Math.max(0, newComponent));
    });
    return rgbToHex(`rgb(${adjustedRgb.join(", ")})`);
  }
  
 
  function hexToRgb(hex) {
    hex = hex.replace("#", "");
    
    var r = parseInt(hex.substring(0, 2), 16);
    var g = parseInt(hex.substring(2, 4), 16);
    var b = parseInt(hex.substring(4, 6), 16);
    
    return `${r}, ${g}, ${b}`; 
  }
  
  function rgbToHex(rgb) {
    let [r, g, b] = rgb.match(/\d+/g).map(x => parseInt(x).toString(16).padStart(2, '0'));
    return `#${r}${g}${b}`;
  }
  
    //firme bpi
    const [isFirmeBPIOpen, setIsFirmeBPIOpen] = useState(false);
    const openFirmeBPI = () => setIsFirmeBPIOpen(true);
    const closeFirmeBPI = () => setIsFirmeBPIOpen(false);



  const [loading, setLoading] = useState(false);
  const { preferinte, loaded } = usePreferinteUtilizator();
  const culoareAplicatie1 = preferinte.culoareAplicatie; //culoare generala aplicatie
  
  const [culoareAplicatie, setCuloareAplicatie] = useState(culoareAplicatie1);
  useEffect(() => {
    if (loaded && preferinte.culoareAplicatie) {
      setCuloareAplicatie(preferinte.culoareAplicatie);
    }
  }, [preferinte.culoareAplicatie, loaded]);
  
 // console.log(culoareAplicatie);
  useEffect(() => {
    if (culoareAplicatie) { 
      const rgbColor = hexToRgb(culoareAplicatie);
      document.documentElement.style.setProperty('--background-color', rgbColor);
      document.documentElement.style.setProperty('--background-opacity', '0.89');
    }
  }, [culoareAplicatie]);

  const [showMenu, setShowMenu] = useState(false);
  const [showMenuUser, setShowMenuUser] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [searchIconHovered, setSearchIconHovered] = useState(false);
  const [userName, setUserName] = useState('');
  const [denumireUser, setDenumireUser] = useState('');
  const [emailUser, setEmailUser] = useState('');
 
    //modala Licenta
    const [openDialogLicenta, setOpenDialogLicenta] = useState(false);
    const handleOpenDialogLicenta = () => setOpenDialogLicenta(true);
    const handleCloseDialogLicenta = () => setOpenDialogLicenta(false);

    // caut url curent
    const currentPath = window.location.pathname;

    // determin daca url curent e egal cu href pe care am dat click
    const isMenuItemActive = (menuItemPath) => {
      return currentPath === menuItemPath;
    };
  

  const handleSearchIconMouseEnter = () => {
    setSearchIconHovered(true);
  };

  const handleSearchIconMouseLeave = () => {
    setSearchIconHovered(false);
  };
  const handleMouseEnter = () => {
    setShowMenuUser(true);
  };

  const handleMouseLeave = () => {
    setShowMenuUser(false);
  };


  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const token = localStorage.getItem('token') || sessionStorage.getItem('token');
  const fetchUserData = async () => {
    try {

      const response = await fetch(`${process.env.REACT_APP_API_URL}user`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const userData = await response.json();
        setUserName(userData.data.user.fullname);
        setDenumireUser(userData.data.user.name);
        setEmailUser(userData.data.user.email);
        //console.log('userData', userData);
      } else {
        console.error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error while fetching user data:', error);
    }
  };


  useEffect(() => {
    const handleUserNameChange = (newName) => {
      setUserName(newName);
      fetchUserData();
    };
    const handleAuthChange = () => {
      const loggedInLocalStorage = localStorage.getItem('isLoggedIn') === 'true';
      const loggedInSessionStorage = sessionStorage.getItem('isLoggedIn') === 'true';
      const loggedInCookie = getCookie('_isLoggedInCjpc') === 'true';
    
      const loggedIn = loggedInLocalStorage || loggedInSessionStorage || loggedInCookie;
      setIsLoggedIn(loggedIn);
    };

     // Funcție helper pentru a citi un anumit cookie
     function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
      return null;
    }
  
    userEventEmitter.on('userNameChanged', handleUserNameChange);
    userEventEmitter.on('userNameChanged', handleAuthChange);
    window.addEventListener('authChange', handleAuthChange);

    return () => {
      userEventEmitter.off('userNameChanged', handleUserNameChange);
      userEventEmitter.off('userNameChanged', handleAuthChange);
      window.removeEventListener('authChange', handleAuthChange);
    };
  }, []);

  useEffect(() => {
    if (token) {
      fetchUserData();
    } else {
      console.log('Utilizatorul nu este autentificat!');
    }
  }, []); 
  
 // actualizare culoare noua aplicatie
 const updateCuloareAplicatie = async (culoareNoua) => {
  
  console.log('culoarenoua1', culoareNoua);
  try {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (!token) {
      console.log('Utilizatorul nu este autentificat!');
      return;
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}updateUserprefs?culoareAplicatie=${encodeURIComponent(culoareNoua)}`, {
      method: 'POST', 
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (response.ok) { 
      console.log('Culoare aplicatie actualizata cu succes!'); 
      console.log('culoarenoua', culoareNoua);
        setCuloareAplicatie(culoareNoua);
        const rgbColor = hexToRgb(culoareNoua);
        const complementaryColorHex = getComplementaryColor(culoareNoua);
        const luminoasa = adjustLuminance(culoareNoua, 10);
        const intunecata = adjustLuminance(culoareNoua, -10);
        document.documentElement.style.setProperty('--background-color', rgbColor);
        document.documentElement.style.setProperty('--background-opacity', '0.89');
        document.documentElement.style.setProperty('--complementaryColorHex', complementaryColorHex);  
        document.documentElement.style.setProperty('--luminoasa', luminoasa);  
        document.documentElement.style.setProperty('--intunecata', intunecata);  
    } else {
      console.error('Eroare la actualizarea culorii aplicatiei');
    }
  } catch (error) {
    console.error('Eroare la apelul API:', error);
  }
};

const handleCuloareClick = (e) => {
  const culoareSelectata = getComputedStyle(e.target).backgroundColor;
  const culoareHex = rgbToHex(culoareSelectata);
  updateCuloareAplicatie(culoareHex);
};
 
//pentru qr
const [qrCodeData, setQrCodeData] = useState('');
const [openQRCodeDialog, setOpenQRCodeDialog] = useState(false);

const handleOpenQRCodeDialog = () => {
  generateQRCodeData();
  setOpenQRCodeDialog(true);
};

const handleCloseQRCodeDialog = () => {
  setOpenQRCodeDialog(false);
};

const generateQRCodeData = async () => {
  setLoading(true);
  const userResponse = await fetch(`${process.env.REACT_APP_API_URL}user`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  const licenseResponse = await fetch(`${process.env.REACT_APP_API_URL}getLicense`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  if (userResponse.ok && licenseResponse.ok) {
    const userData = await userResponse.json();
    const licenseData = await licenseResponse.json();

    const combinedData = {
      company: licenseData.data.licenta.company,
      owner: licenseData.data.licenta.owner,
      serialno: licenseData.data.licenta.serialno,
      fullname: userData.data.user.fullname,
      telefon: userData.data.user.telefon,
      email: userData.data.user.email,
      urlapi: `${process.env.REACT_APP_API_URL}`,
      username: userData.data.user.name,
      token: `${token}`,
    };

    setQrCodeData(JSON.stringify(combinedData));
  }
  setLoading(false);
};

  // Funcție care calculează și setează lățimea panoului la 60% din lățimea ferestrei
  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth * 0.6; // 60% din lățimea ferestrei
      setDrawerWidth(`${newWidth}px`);
    };

    handleResize(); // Apelează funcția imediat ce componenta este montată
    window.addEventListener('resize', handleResize); // Actualizează lățimea la redimensionarea ferestrei

    return () => {
      window.removeEventListener('resize', handleResize); // Curăță evenimentul la demontare
    };
  }, []);

  return (
    <>
      <div className="container-xl meniu">

        <a className="navbar-brand" href="/">
          <img className="logojuristpro" src="https://www.ctce.ro/mail/juristpro/logo.png" alt="Image" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleMenu}
          aria-expanded={showMenu}
          aria-label="Meniu"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse${showMenu ? ' show' : ''}`}
        >
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">


            {isLoggedIn && (
              <li className="nav-item">
                <a className={`nav-link ${isMenuItemActive('/panou') ? 'active' : ''}`} href="/panou">
                  <i className="fa fa-home" style={{ paddingRight: '5px' }}></i> Panou
                </a>
              </li>
            )}

            {isLoggedIn && (
              <li className="nav-item">
                <a className={`nav-link ${(isMenuItemActive('/lista-dosare') || isMenuItemActive('/lista-dosare-arhivate')) ? 'active' : ''}`} href="/lista-dosare">
                  <i className="fa fa-list" style={{ paddingRight: '5px' }}></i> Listă dosare
                </a>
              </li>
            )}

            {isLoggedIn && (
              <>
                {areDrept(Notificari) ? (
                  <li className="nav-item">
                    <a className={`nav-link ${isMenuItemActive('/calendar') ? 'active' : ''}`} href="/calendar">
                      <i className="fa fa-calendar-alt" style={{ paddingRight: '5px' }}></i> Calendar
                    </a>
                  </li>
                ) : null}
              </>
            )}

            {isLoggedIn && (
              <>
                {(areDrept(RaportParti) || areDrept(RaportActivitate) || areDrept(RaportEvenimente) || areDrept(RaportPivot)) ? (
                  <li className="nav-item">
                    <a className={`nav-link ${(isMenuItemActive('/rapoarte') || isMenuItemActive('/raport-pivot')) ? 'active' : ''}`} href="/rapoarte">
                      <i className="fa fa-chart-bar" style={{ paddingRight: '5px' }}></i> Rapoarte
                    </a>
                  </li>
                ) : null}
              </>
            )}


            {isLoggedIn && (
              <li className="nav-item">
                <a
                  href="/cautare-online"
                  className={`nav-link ${isMenuItemActive('/cautare-online') ? 'active' : ''}`}
                >
                  <i className="fa fa-search" style={{ paddingRight: '5px' }}></i> Căutare Online
                </a>
              </li>
            )}


            {isLoggedIn && (
              <li className="nav-item">
                <a
                  href="/sincronizare-dosare"
                  className={`nav-link ${isMenuItemActive('/sincronizare-dosare') ? 'active' : ''}`}
                >
                  <SyncIcon style={{ fontSize: '18px' }} /> Sincronizare instantă
                </a>
              </li>
            )}
          </ul>

          <hr className="d-lg-none text-white-50" />


          <ul className="navbar-nav flex-row flex-wrap ms-md-auto">
            {!isLoggedIn && (
              <li className="nav-item">
                <a className={`nav-link ${isMenuItemActive('/autentificare') ? 'active' : ''}`} href="/autentificare">
                  Autentificare
                </a>
              </li>
            )}
            {isLoggedIn && (



              <>
                {areDrept(VizualizareFirmeBPIDosar) ? (
                  <li className="nav-item" style={{ borderRadius: '50%' }}>
                    <Tooltip title="Situație firme monitorizate în BPI">
                      <IconButton onClick={openFirmeBPI}>
                        <PriceChangeIcon />
                      </IconButton>
                    </Tooltip>
                    <ListaFirmeBPI isFirmeBPIOpen={isFirmeBPIOpen} closeFirmeBPI={closeFirmeBPI} />
                  </li>
                ) : null}

                {areDrept(Notificari) ? (
                  <li className="nav-item" style={{ borderRadius: '50%' }}>
                    <NotificariAplicatie />
                  </li>
                ) : null}

                <li className="nav-item" style={{ borderRadius: '50%' }}>
                  <a href="/setari" underline="none">
                    <IconButton>
                      <SettingsIcon />
                    </IconButton>
                  </a>
                </li>

                <li className="user-profile"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="nav-link user-profile-button" style={{ display: 'flex', cursor: 'pointer' }}>
                    <div><i className="fa fa-user-circle" style={{ fontSize: '1.5rem' }}></i>  </div>
                    <div style={{ paddingBottom: '-10px' }}>
                      <span style={{ paddingLeft: '5px' }}>{userName ? userName : 'Utilizator'}</span>
                      <i style={{ paddingLeft: '10px' }} className={`fa fa-caret-${showMenuUser ? 'up' : 'down'}`}></i> {/* Caret icon */}
                    </div>
                  </div>
                  <div className={`dropdown-menu${showMenuUser ? ' show' : ''} meniuDropdown`}>
                    {(denumireUser || emailUser) && (
                      <p className='denumireUser'>
                        {denumireUser ? `Autentificat ca: ${denumireUser}` : ''} <br />
                        {emailUser ? `Email: ${emailUser}` : ''}
                      </p>
                    )}

                    <a href="/setari" className={`dropdown-item ${isMenuItemActive('/setari') ? 'active' : ''}`} underline="none">
                      <i className="fa fa-cog" style={{ paddingRight: '5px' }}></i> Întreținere aplicație
                    </a>

                    {areDrept(AccesLaToataAplicatia) ? (
                      <a href="/backup-restaurare" className={`dropdown-item ${isMenuItemActive('/backup-restaurare') ? 'active' : ''}`} underline="none">
                        <CloudSyncIcon style={{ fontSize: '18px', margin: '0', marginRight: '5px', padding: '0' }} /> Backup și restaurare
                      </a>
                    ) : null}

                    {areDrept(AccesLaToataAplicatia) ? (
                      <>
                        <a className='dropdown-item' onClick={handleOpenDialogLicenta} underline="none" style={{ cursor: 'pointer' }}>
                          <i className="fa fa-key" style={{ paddingRight: '5px' }}></i>Modificare licență
                        </a>
                        <SchimbareLicenta
                          open={openDialogLicenta}
                          handleClose={handleCloseDialogLicenta}
                        />
                      </>
                    ) : null}
                    {areDrept(EditareUtilizatori) ? (
                      <>
                        <a href="/administrare-utilizatori" className={`dropdown-item ${isMenuItemActive('/administrare-utilizatori') ? 'active' : ''}`} underline="none">
                          <i className="fa fa-users-cog" style={{ paddingRight: '5px' }}></i> Administrare utilizatori
                        </a>
                      </>
                    ) : null}

                    {areDrept(EditareGrupuriDeDosare) ? (
                      <a href="/grupuri-dosare" className={`dropdown-item ${isMenuItemActive('/grupuri-dosare') ? 'active' : ''}`} underline="none">
                        <i className="fa fa-folder" style={{ paddingRight: '5px' }}></i> Administrare grupuri dosare
                      </a>
                    ) : null}

                    <a href="/preferinte-utilizator" className={`dropdown-item ${isMenuItemActive('/preferinte-urilizatori') ? 'active' : ''}`} underline="none">
                      <i className="fa fa-user-cog" style={{ paddingRight: '5px' }}></i> Preferințe Utilizator
                    </a>
                    <a href="/profil-utilizator" className={`dropdown-item ${isMenuItemActive('/profil-utilizator') ? 'active' : ''}`} underline="none">
                      <i className="fa fa-user-circle" style={{ paddingRight: '5px' }}></i> Profil utilizator
                    </a>

                    <a className="dropdown-item" underline="none" onClick={handleOpenQRCodeDialog} style={{ cursor: 'pointer' }}>
                      <QrCodeIcon style={{ paddingRight: '5px' }} /> Conectare pe alte dispozitive
                    </a>

                    <a href="/logout" className="dropdown-item" underline="none">
                      <i className="fa fa-sign-out-alt" style={{ paddingRight: '5px' }}></i> Îeșire din aplicație
                    </a>


                    <div className='definireCuloareApp' style={{ borderTop: '1px solid #CCC' }}>
                      <span>Temă aplicație</span>
                      <div className='elementeCuloareApp'>
                        <div className='culoare cul1' onClick={handleCuloareClick}></div>
                        <div className='culoare cul2' onClick={handleCuloareClick}></div>
                        <div className='culoare cul3' onClick={handleCuloareClick}></div>
                        <div className='culoare cul4' onClick={handleCuloareClick}></div>
                        <div className='culoare cul5' onClick={handleCuloareClick}></div>
                        <div className='culoare cul6' onClick={handleCuloareClick}></div>
                        <div className='culoare cul7' onClick={handleCuloareClick}></div>
                        <div className='culoare cul8' onClick={handleCuloareClick}></div>
                      </div>
                    </div>
                  </div>
                </li>
              </>
            )}
          </ul>
        </div>

        <Dialog open={openQRCodeDialog} onClose={handleCloseQRCodeDialog}>
          <DialogTitle className='text-center titluModal'><QrCodeIcon /> Cod QR pentru conectare aplicație mobilă</DialogTitle>
          <DialogContent className='mt-3'>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
              {loading ? (
                <CircularProgress />
              ) : (
                qrCodeData && <QRCode value={qrCodeData} size={206} />
              )}
            </Box>
            <Box className="mt-3">
              <div>
                Deschideți aplicația mobilă <b>JuristPRO Next</b> și scanați codul QR pentru o conectare rapidă.
              </div>
            </Box>
            {/* Datele QR decodate  
    
    {!loading && qrCodeData && (
      <pre>{JSON.stringify(JSON.parse(qrCodeData), null, 2)}</pre>
    )}
    
    */}

          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseQRCodeDialog} color="primary">
              Închidere
            </Button>
          </DialogActions>
        </Dialog>


        <style>
          {` 
  .culoareDefault {color: ${culoareAplicatie};}
  .separator {background-color: rgba(var(--background-color), 0.61) !important;}
  .nr,
  #row-details-dialog, .titluModal,
  .headerActe,
  .titluModal,
  .MuiAccordionSummary-root,
  .dosare-counter,
  .userSelectatActiv,
  .notificare-status,
  .dropdown-item.active, .dropdown-item:active,
  .badge,
  .dataCurentaSelectata,
  .dosarAsociatSelectat,
  .MuiBadge-colorSecondary {
    background-color: rgba(var(--background-color), 1);
  }
  
  .pulsating-dots span {
    color: ${culoareAplicatie} !important;
  }

  .secondaryC {color: ${culoareAplicatie} !important;}

  .taburi-notificari .activ {background-color: rgba(var(--background-color), 1) !important;}
  .taburi-notificari .nr {background-color: ${culoareAplicatie} !important; color:#FFF; width:12px; height:12px; text-aling:center; margin-left:3px; }
  .taburi-notificari .activ .nr {background-color: #FFF !important; color:${culoareAplicatie};}
  .taburi-notificari li:hover {background-color: rgba(var(--background-color), 0.61) !important;}
  
  .userSelectat:hover, .dataApi:hover, .dosarAsociat:hover {
    background-color: rgba(var(--background-color), 0.5) !important;
  }

  .MuiIconButton-colorSecondary {
    color: rgba(var(--background-color), var(--background-opacity));
  }

  .badgeBPI .MuiBadge-badge  {
    background-color: rgba(var(--background-color), var(--background-opacity));
  }

  .MuiButton-containedSecondary {
    background-color: rgba(var(--background-color), var(--background-opacity));
    border-bottom-color: var(--background-color);
  }

  .MuiButton-containedSecondary:hover {
    background-color: rgba(var(--background-color), 1);
  }
  
  .cul1 {background-color:#9c27b0}
  .cul2 {background-color:#20c997}
  .cul3 {background-color:#fd7e14}
  .cul4 {background-color:#d63384}
  .cul5 {background-color:#6c757d}
  .cul6 {background-color:#428bca}
  .cul7 {background-color:#4d5b69}
  .cul8 {background-color:#BF2F29 }

  #menu {
    border-top: 3px solid ${culoareAplicatie} !important;
  }
  :root {
    --culoare-aplicatie: ${culoareAplicatie};  
  }
  `}
        </style>
      </div>

      <Tooltip title="Activități generale și pe dosare" placement='left'>
        <IconButton 
          className="custom-icon-button"
          size='small'
          style={{
            position: 'fixed',
            top: '20%',
            right: 0,
            transform: 'translateY(-50%)',
            zIndex: 1000,  
          }}
          onClick={toggleDrawer(true)}
        >
          <AssignmentTurnedInIcon />
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer(false)}
      >
        {/* Conținutul panoului */}
        <div
          style={{ width: drawerWidth, padding: 20 }}
          role="presentation"
        >

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography style={{ fontWeight: 'bold', fontSize: '1.3em' }} className='mb-2'>
              <AssignmentTurnedInIcon /> Activități generale și pe dosare
            </Typography>
            
           <Tooltip title="Închidere activități" placement='left'>
           <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
           </Tooltip>
          </div>

          <hr></hr>
          <Activitati />
        </div>
      </Drawer>

      <Tooltip title="Notițele mele" placement='left'>
        <IconButton 
          className="custom-icon-button"
          size='small'
          style={{
            position: 'fixed',
            top: '25%',
            right: 0,
            transform: 'translateY(-50%)',
            zIndex: 1000,  
          }}
          onClick={toggleDrawerNote(true)}
        >
          <NoteIcon />
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        open={isOpenNote}
        onClose={toggleDrawerNote(false)}
      >
        <div style={{ width: drawerWidth, padding: 0, height: '100%', display: 'flex', flexDirection: 'column' }} role="presentation">
          {/* Titlul și închiderea fixă */}
          <div style={{
            position: 'fixed',
            width: drawerWidth,
            backgroundColor: '#fff',
            zIndex: 1,
            padding: '20px',
            borderBottom: '1px solid #ccc',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Typography style={{ fontWeight: 'bold', fontSize: '1.3em' }} className='mb-2'>
              <NoteIcon /> Notițele mele
            </Typography>
            <Tooltip title="Închidere activități" placement='left'>
              <IconButton onClick={toggleDrawerNote(false)}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>

          {/* Conținutul notițelor cu padding pentru a evita suprapunerea */}
          <div style={{ marginTop: '91px', padding: '20px', overflowY: 'auto', flexGrow: 1 }}>
            <Notite />
          </div>
        </div>
      </Drawer>

    </>
  );
};

export default Menu;
