import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { List, ListItem, ListItemText, CircularProgress, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box, Typography, Alert } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';  
import Tooltip from '@mui/material/Tooltip';
import Swal from 'sweetalert';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import DrepturiUtilizator from '../utilizator/drepturiUtilizator'; 

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ro } from 'date-fns/locale'; 

import verificareToken from '../VerificareToken';

function DosarElectronic() {
    const { areDrept } = DrepturiUtilizator(); 
    const DreptDeModificareDosare = 'DreptDeModificareDosare';
    
    const { id } = useParams();
    const [documente, setDocumente] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [currentDoc, setCurrentDoc] = useState(null);  
    const [formState, setFormState] = useState({ descriereFisier: '', dataFisier: '', fisier: null });
    const [isAddingOrEditing, setIsAddingOrEditing] = useState(false);  


    const handleVizualizare = (doc) => {
        const urlLaravel = process.env.REACT_APP_API_URL.replace('/api/', '');
        const fileSuffix = '/fisiere/in/';
    
        if (doc && doc.id) {
            const fileUrl = `${urlLaravel}${fileSuffix}${id}/electronic/${encodeURIComponent(doc.numeFisier)}`;
            window.open(fileUrl, '_blank');
        }
    };

    const apiUrl = process.env.REACT_APP_API_URL;
      const { token } = verificareToken();

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}getDosareElectroniceForDosar/${id}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            setDocumente(data.data.dosarElectronic);
        } catch (error) {
            console.error('A apărut o eroare!', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id, token, apiUrl]);

    const handleOpen = (doc) => {
        if (doc) {
            const dataDocumentAsDate = doc.dataFisier ? new Date(doc.dataFisier) : new Date();
            setFormState({ descriereFisier: doc.descriereFisier, dataFisier: dataDocumentAsDate, fisier: doc.numeFisier });
        } else {
            const currentDate = new Date();
            setFormState({ descriereFisier: '', dataFisier: currentDate, fisier: null });
        }
        setCurrentDoc(doc);
        setOpen(true);
    };
    

    const handleClose = () => {
        setCurrentDoc(null);
        setFormState({ descriereFisier: '', dataFisier: '', fisier: null });  
        setOpen(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        setFormState(prevState => ({
            ...prevState,
            fisier: e.target.files[0]
        }));
    };

    function formatDate(dateString) {
        const dateParts = dateString.split('-');  
        return `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`;  
    }

const handleAdaugaSauEditeaza = async () => {
    setIsAddingOrEditing(true);  
    const formattedDataDocument = formState.dataFisier instanceof Date
    ? `${formState.dataFisier.getFullYear()}-${(formState.dataFisier.getMonth() + 1).toString().padStart(2, '0')}-${formState.dataFisier.getDate().toString().padStart(2, '0')}`
    : formState.dataFisier;

    const formData = new FormData();
    formData.append('idDosar', id);
    formData.append('descriereFisier', formState.descriereFisier);
    formData.append('dataFisier', formattedDataDocument);
    if (formState.fisier) {
        formData.append('fisier', formState.fisier);
    }

    try {
        if (currentDoc) {
            const response = await fetch(`${apiUrl}updateDosarElectronic/${currentDoc.id}`, {
                method: 'POST',  
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });
            const data = await response.json();
            setDocumente(documente.map(doc => doc.id === currentDoc.id ? data : doc));
        } else {
            const response = await fetch(`${apiUrl}storeDosarElectronic`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });
            const data = await response.json();
            setDocumente([...documente, data]);
        }
        handleClose();  
        fetchData(); 
    } catch (error) {
        console.error('A apărut o eroare!', error);
    } finally {
        setIsAddingOrEditing(false);  
    }
};

const handleSterge = async (docId) => {
    setIsAddingOrEditing(true);  

    Swal({
        title: 'Sigur doriți ștergerea?',
        text: 'Această acțiune nu poate fi anulată!',
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
    .then(async (willDelete) => {
        if (willDelete) {
            try {
                await fetch(`${apiUrl}deleteDosarElectronic/${docId}`, {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setDocumente(documente.filter(doc => doc.id !== docId));  
                Swal("Șters!", "Documentul a fost șters.", "success");
                fetchData();  
            } catch (error) {
                console.error('A apărut o eroare la ștergere!', error);
            } finally {
                setIsAddingOrEditing(false);  
            }
        } else {
            setIsAddingOrEditing(false);  
        }
    });
};


    if (loading) {
        return <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}><CircularProgress /></Box>;
    }

    return (
        <div>
        <div className='mb-3' style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography style={{fontWeight: 'bold', fontSize:'1.3em'}}>Dosar electronic</Typography>
            {areDrept(DreptDeModificareDosare) ? ( 
                <Button
                disabled={!areDrept(DreptDeModificareDosare)}
                onClick={() => handleOpen(null)}
                variant="contained"
                size="small" 
                color="secondary"
                className='pull-right'
                startIcon={<AddIcon />}
            >
                Adaugare document nou
            </Button>           
            ) : null } 
        </div>
        {documente.length === 0 ? (  
            <Alert severity="info">Nu există documente atașate dosarului electronic.</Alert>
        ) : (
            
            <List>
                {documente.map((doc, index) => (
                    <ListItem
                        key={doc.id}
                        className={index % 2 === 0 ? 'list-item striped' : 'list-item'}
                    >
                    <ListItemText
                            primary={doc.numeFisier}
                            secondary={[
                                doc.dataFisier ? `Data: ${formatDate(doc.dataFisier)}` : '',
                                doc.descriereFisier ? `Descriere: ${doc.descriereFisier}` : '',
                                //doc.denumireInstanta ? `Denumire instanță: ${doc.denumireInstanta}` : ''
                            ].filter(Boolean).join('\n')}
                            secondaryTypographyProps={{ style: { whiteSpace: 'pre-line' } }}
                        />


                        <Tooltip title="Vizualizare">
                            <IconButton aria-label="vizualizare" onClick={() => handleVizualizare(doc)}>
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>
                        {areDrept(DreptDeModificareDosare) ? ( 
                            <>
                                <Tooltip title="Editare">
                                    <IconButton disabled={!areDrept(DreptDeModificareDosare)} aria-label="editeaza" onClick={() => handleOpen(doc)}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Ștergere">
                                    <IconButton disabled={!areDrept(DreptDeModificareDosare)} aria-label="sterge" onClick={() => handleSterge(doc.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                           </>
                        ) : null } 

                    </ListItem>
                ))}
            </List>
        )}
            {/* dialog  de adaugare si editare */}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle  className='titluModal' id="form-dialog-title">{currentDoc ? 'Editare document' : 'Adăugare document'}</DialogTitle>
                <DialogContent className='mt-3'>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="descriereFisier"
                        label="Descriere"
                        type="text"
                        fullWidth
                        value={formState.descriereFisier}
                        onChange={handleInputChange}
                        name="descriereFisier"
                        required
                    /> 
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Data Document"
                                value={formState.dataFisier}
                                onChange={(newValue) => {
                                    setFormState(prevState => ({ ...prevState, dataFisier: newValue }));
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                inputFormat="dd/MM/yyyy" 
                                format="dd.MM.yyyy" 
                                mask="__.__.____"
                                className='mt-2 maxWidth mb-3'
                            />
                        </LocalizationProvider>
                    <input
                        accept="image/*,.pdf"
                        style={{ display: 'none' }}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={handleFileChange}
                    />
                      {formState.fisier && (
                        <Typography variant="body2" color="textSecondary">
                            Numele documentului: {formState.fisier.name}
                        </Typography>
                    )}
                    <input
                        accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.txt,.zip,.rar,.ppt,.pptx,.mp3,.wav,.mp4,.avi,.mov,.csv"
                        style={{ display: 'none' }}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={handleFileChange}
                    />
                    <label htmlFor="contained-button-file">
                        <Button variant="outlined" component="span" size='small' className='mt-2 maxWidth mb-3'>
                            Încărcare document
                        </Button>
                    </label>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleAdaugaSauEditeaza}
                        color="success"
                        disabled={isAddingOrEditing || !formState.descriereFisier || formState.descriereFisier.trim() === '' || (!formState.fisier && !formState.numeFisier)}
                        >
                        {isAddingOrEditing ? 'Se procesează...' : (currentDoc ? 'Salvare' : 'Adăugare')}
                    </Button>
                    
                    <Button onClick={handleClose} color="error">
                        Anulare
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DosarElectronic;
