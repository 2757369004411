import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import verificareToken from './VerificareToken';
import { Tooltip, Alert, IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

const FereastraNotificari = ({ open, onClose }) => {
    const { token } = verificareToken();

    const handleNuMaiAfisaAzi = async () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}updateUserprefs?afisare_notificari_azi=0`;
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',  
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            console.log("Preferințele utilizatorului au fost actualizate cu succes.");
        } catch (error) {
            console.error("A apărut o eroare la actualizarea preferințelor utilizatorului: ", error);
        }

        onClose(); 
    };

    const [notificari, setNotificari] = useState({
        termene: [],
        notificariDosar: [],
        notificariTermen: [],
        notificariUser: []
      });
    
      const [tabCurent, setTabCurent] = useState('termene');
      const [showDropdown, setShowDropdown] = useState(false);
      const [timerId, setTimerId] = useState(null);
      const dropdownRef = useRef(null); 
      const handleMouseEnter = () => {
        // Anulează timer-ul atunci când mouse-ul intră în dropdown
        if (timerId) {
          clearTimeout(timerId);
          setTimerId(null);
        }
        setShowDropdown(true);
      };
    
      const handleMouseLeave = () => {
        // Setează un timer pentru a închide dropdown-ul
        const id = setTimeout(() => {
          setShowDropdown(false);
        }, 100);
        setTimerId(id);
      };
    
    
      const handleMouseMoveOutside = (event) => {
        // Verifică dacă mouse-ul este în afara dropdown-ului
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setShowDropdown(false);
        }
      };
     
      const fetchNotificari = async () => {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}notificariAplicatie`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
    
          if (response.ok) {
            const data = await response.json();
            setNotificari(data.data);
          } else {
            console.error('Failed to fetch notifications');
          }
        } catch (error) {
          console.error('Error while fetching notifications:', error);
        }
      };
    
      useEffect(() => {
        fetchNotificari();
      }, []);
    
      const handleNotificareClick = async (idNotificare, tipNotificari) => {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        const tip = tipNotificari === 'termene' ? 'termen' : 'notificare';
      try {
        await fetch(`${process.env.REACT_APP_API_URL}setClick?idNotificare=${idNotificare}&tip=${tip}`, {
            method: 'PATCH',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
    
          fetchNotificari();
        } catch (error) {
          console.error('Error while updating notification:', error);
        }
      };

      function extrageDataFaraOra(dataCuOra) {
        const dataFaraOra = dataCuOra.split(' ')[0];
        const dataCuPuncte = dataFaraOra.replace(/-/g, '.');
        return dataCuPuncte;
      }

      const renderNotificari = (tipNotificari) => {
        if (notificari[tipNotificari].length === 0) {
            return (
              <Alert severity="warning">
                Nu există notificări în această categorie.
              </Alert>
            );
          }
    
        return notificari[tipNotificari].map(notificare => (
    <div
              key={notificare.id}
              className={`notificare ${!notificare.dataluatlacunostinta ? 'necitita' : ''}`}
              onClick={() => handleNotificareClick(notificare.id, tipNotificari)}
            >
              <div className="notificare-content">
                {renderContentBasedOnType(notificare, tipNotificari)}
                {!notificare.dataluatlacunostinta && <div className="notificare-status"></div>}
              </div>
            </div>
        ));
      };
      
      const renderContentBasedOnType = (notificare, tipNotificari) => {
        switch (tipNotificari) {
         
            // pentru termene
            case 'termene':
            return (
              <div className="notificare-detalii">
                <p><strong>Nr. dosar:</strong> {notificare.nrdosar}</p>
                <p><strong>Data termen:</strong> {new Date(notificare.datatermen).toLocaleString('ro-RO')}</p>
              </div>
            );
          case 'notificariDosar':
            // pentru notificările dosar
            return (
              <div className="notificare-detalii">
                <p><strong>Client:</strong> {notificare.client}</p>
                <p><strong>Info:</strong> {notificare.descriere}</p>
                <p><strong>Nr. dosar:</strong> {notificare.numarDosar}</p>
              </div>
            );
          case 'notificariTermen':
            // pentru notificările termen
            return (
              <div className="notificare-detalii">
                <p><strong>Client:</strong> {notificare.client}</p>
                <p><strong>Info:</strong> {notificare.descriere}</p>
                <p><strong>Nr. dosar:</strong> {notificare.numarDosar}</p>
                <p><strong>Data termen:</strong> {extrageDataFaraOra(notificare.dataTermen)}</p> 
              </div>
            );
          case 'notificariUser':
            // pentru notificările utilizator
            return (
              <div className="notificare-detalii">
                <p><strong>Info:</strong> {notificare.descriere}</p>
                <p><strong>Data:</strong> {extrageDataFaraOra(notificare.dataSfarsit)}</p> 
              </div>
            );
          default:
            return <div>Tip de notificare necunoscut</div>;
        }
      };
      
      
      const countNecitite = (tipNotificari) => {
        return notificari[tipNotificari].filter(n => !n.dataluatlacunostinta).length;
      };
      const totalNecitite = Object.keys(notificari).reduce((total, tip) => total + countNecitite(tip), 0);

      
    return (
        <Dialog open={open} fullWidth maxWidth="md">
            <DialogTitle className='titluModal mb-3'>
                Notificări aplicație
                    <IconButton
                      aria-label="close"
                      onClick={() => onClose(false)}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon style={{ color: 'white' }} />
                    </IconButton>
            </DialogTitle>
            <DialogContent>
                <div >
               
                    <div ref={dropdownRef}>
                    <ul className="taburi-notificari">
                        <li className={tabCurent === 'termene' ? 'activ' : ''} onClick={() => setTabCurent('termene')}>
                        Termene <span className='nr'>{countNecitite('termene')}</span>
                        </li>
                        <li className={tabCurent === 'notificariDosar' ? 'activ' : ''} onClick={() => setTabCurent('notificariDosar')}>
                        Evenimente dosare <span className='nr'>{countNecitite('notificariDosar')}</span>
                        </li>
                        <li className={tabCurent === 'notificariTermen' ? 'activ' : ''} onClick={() => setTabCurent('notificariTermen')}>
                        Evenimente notificări termene <span className='nr'>{countNecitite('notificariTermen')}</span>
                        </li>
                        <li className={tabCurent === 'notificariUser' ? 'activ' : ''} onClick={() => setTabCurent('notificariUser')}>
                        Evenimente proprii <span className='nr'>{countNecitite('notificariUser')}</span>
                        </li>
                    </ul>

                    <div className="continut-notificari">
                        {renderNotificari(tabCurent)}
                    </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{justifyContent: 'space-between'}}>
                <Button onClick={handleNuMaiAfisaAzi} color="warning" size='small' variant='contained'>
                    Nu mai afișa azi notificările
                </Button>
                <Button color='error' size='small' onClick={onClose}>Închidere</Button>
            </DialogActions>
        </Dialog>
    );

};

export default FereastraNotificari;
