import { useState, useEffect } from 'react';
import verificareToken from '../VerificareToken';

function DrepturiUtilizator() {
  const [isLoading, setIsLoading] = useState(true);
   const [permisions, setPermissions] = useState([]);
    const { token } = verificareToken();

  const getUserID = () => {
    const user_id_local = localStorage.getItem('iduser');
    const user_id_session = sessionStorage.getItem('iduser');
    const user_id = user_id_local || user_id_session || null;
    //console.log("user_id:", user_id);  
    return user_id;
  };

  useEffect(() => {
    const user_id = getUserID();
    //console.log("URL cerere API:", `${process.env.REACT_APP_API_URL}getUserAllPermissionsAccess?user_id=${user_id}`);
    async function fetchPermissions() {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}getUserAllPermissionsAccess?user_id=${user_id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Eroare conexiune');
        }

        const data = await response.json();
        if (data && data.data && data.data.permisions) {
            setPermissions(data.data.permisions);
          }
      } catch (error) {
        console.error('Eroare la preluare drepturi:', error);
      }
    }

    if (user_id) {
      fetchPermissions();
    }
  }, [token]);

  useEffect(() => {
    if (permisions.length > 0 || permisions.length === 0) {
      setIsLoading(false);
    }
  }, [permisions]);

  // Verificăm dacă un drept este activ sau nu pentru utilizator
  //const areDrept = (denumireDrept) => {
    //console.log("Valoarea lui denumireDrept:", denumireDrept);

   // console.log("Cautare drept pentru:", denumireDrept);
   // console.log("Lista permisiuni:", permisions);
    //const permission = permisions.find((p) => p.right === denumireDrept);
   // console.log("Rezultatul căutării:", permission);
    //return permission ? permission.acces : false;
  //};
  

  const areDrept = (denumireDrept) => {
    const permission = permisions.find((p) => p.right === denumireDrept);
    return permission ? permission.acces : false;
  };
//  console.log("Drept Rapoarte:", areDrept("Rapoarte"));
return { areDrept, isLoading };
}

export default DrepturiUtilizator;
