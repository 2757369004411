import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { List, FormControl, ListItem, ListItemText, CircularProgress, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextareaAutosize, Button, Box, Typography, Alert, Select, MenuItem, } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert';
import verificareToken from '../VerificareToken';
import DrepturiUtilizator from '../utilizator/drepturiUtilizator'; 

function InfoObiecteDosar() {
  const { areDrept } = DrepturiUtilizator(); 
  const DreptDeModificareDosare = 'DreptDeModificareDosare';
  
    const { id } = useParams();
    const apiUrl = process.env.REACT_APP_API_URL; 
    const { token } = verificareToken();
    const [obiecteDosar, setObiecteDosar] = useState([]);
    const [obiecte, setObiecte] = useState([]);
    const [materii, setMaterii] = useState([]); 
    const [idMaterie, setIdMaterie] = useState(null);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [currentObiectDosar, setCurrentObiectDosar] = useState(null);
    const [loadingObiecte, setLoadingObiecte] = useState(false);
    const [idObiect, setIdObiect] = useState('');
    const [adminModalOpen, setAdminModalOpen] = useState(false);
    const [currentAdminObiect, setCurrentAdminObiect] = useState(null);
    const [textareaValue, setTextareaValue] = useState("");
    const [filtru, setFiltru] = React.useState("");
    
    const [reRandare, SetReRandare] = useState(false);

  const [formState, setFormState] = useState({
    idobiect: '',
  });
  const [isAddingOrEditing, setIsAddingOrEditing] = useState(false);

  const [adminOpen, setAdminOpen] = useState(false);

  const currentDate = new Date().toISOString().split('T')[0];

  const fetchData = async () => {
    setLoading(true);
    try {
      const obiecteDosarResponse = await fetch(`${apiUrl}infoObiecteDosar/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const obiecteDosarData = await obiecteDosarResponse.json();

      const obiecteResponse = await fetch(`${apiUrl}obiecte/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const obiecteData = await obiecteResponse.json();

      setObiecteDosar(obiecteDosarData.data.obiecteDosar);
      setObiecte(obiecteData.data.obiecte);
    } catch (error) {
      console.error('A apărut o eroare!', error);
    } finally {
      setLoading(false);
      
      SetReRandare(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id, apiUrl]);





  useEffect(() => {
 
    // incarc materiile din api
    fetch(`${process.env.REACT_APP_API_URL}materii`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => setMaterii(data.data.materii))
      .catch(error => console.error('Error:', error));
  }, [token]);

  useEffect(() => {
    if (idMaterie) {
      // incarc obiecte in functie de materie id
      fetch(`${process.env.REACT_APP_API_URL}obiecte/materie/${idMaterie}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => response.json())
        .then(data => setObiecte(data.data.obiecte))
        .catch(error => console.error('Error:', error));
    }
  }, [idMaterie, token]);




  const handleOpen = (obiectDosar) => {

    if (obiectDosar) {
      setFormState((prevState) => ({
        ...prevState,
        idobiect: obiectDosar.idobiect || '',
      }));
      
      setIdMaterie(obiectDosar.idmaterie);
      setIdObiect(obiectDosar.idobiect);
  
      setCurrentObiectDosar(obiectDosar);

    setCurrentObiectDosar(obiectDosar);
    } else {
      setIdMaterie('');
      setIdObiect('');
      setFormState({
        idobiect: '',
      });
      
    setCurrentObiectDosar(null);
    }
    setOpen(true);
    
  };
  

  const handleClose = () => {
    setOpen(false);
    setIdMaterie('');
    setIdObiect('');
    setCurrentObiectDosar(null);
    setFormState({
      idobiect: '',
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAdaugaSauEditeaza = async () => {
    setIsAddingOrEditing(true);

    const queryParams = new URLSearchParams({
      idobiect: formState.idobiect,
    });

    const handleAdminModalOpen = (obiect) => {
        if (obiect) {
          setCurrentAdminObiect(obiect);
          setTextareaValue(obiect.denumire || "");
        } else {
          setCurrentAdminObiect(null);
          setTextareaValue("");
        }
        setAdminModalOpen(true);
      };
      
      const handleAdminModalClose = () => {
        setCurrentAdminObiect(null);
        setTextareaValue("");
        setAdminModalOpen(false);
      };
      
      const handleTextareaChange = (e) => {
        setTextareaValue(e.target.value);
      };

    try {
      if (currentObiectDosar) {
        const response = await fetch(`${apiUrl}obiecteDosar/${currentObiectDosar.id}?iddosar=${id}&${queryParams}`, {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setObiecteDosar((prevObiecteDosar) => {
          return prevObiecteDosar.map((obiectDosarItem) => {
            if (obiectDosarItem.id === currentObiectDosar.id) {
              return data.data.obiectDosar;
            }
            return obiectDosarItem;
          });
        });
      } else {
        const response = await fetch(`${apiUrl}obiecteDosar?iddosar=${id}&${queryParams}`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setObiecteDosar((prevObiecteDosar) => [...prevObiecteDosar, data.data.obiectDosar]);
      }
      handleClose();
      fetchData();
    } catch (error) {
      console.error('A apărut o eroare!', error);
    } finally {
      setIsAddingOrEditing(false);
      setCurrentObiectDosar(null);
      fetchData();
    }
  };

  const handleSterge = async (obiectDosarId) => {
    setIsAddingOrEditing(true);

    Swal({
        title: 'Sigur doriți ștergerea?',
        text: 'Această acțiune nu poate fi anulată!',
              icon: 'warning',
              buttons: true,
              dangerMode: true,
          }).then(async (willDelete) => {
              if (willDelete) {
                  try {
                    await fetch(`${apiUrl}obiecteDosar/${obiectDosarId}`, {
                        method: 'DELETE',
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      });
                      setObiecteDosar(obiecteDosar.filter((item) => item.id !== obiectDosarId));
                      Swal('Șters!', 'Obiectul dosarului a fost șters.', 'success');
                      fetchData();
                  } catch (error) {
                      console.error('A apărut o eroare la ștergere!', error);
                  } finally {
                    setIsAddingOrEditing(false);
                  }
              } else {
                setIsAddingOrEditing(false);
              }
          });   
    };

    const handleStergeObiectAdmin = async (obiectDosarId) => {
        setIsAddingOrEditing(true);
    
        Swal({
            title: 'Sigur doriți ștergerea?',
            text: 'Această acțiune nu poate fi anulată!',
                  icon: 'warning',
                  buttons: true,
                  dangerMode: true,
              }).then(async (willDelete) => {
                  if (willDelete) {
                      try {
                        await fetch(`${apiUrl}obiecte/${obiectDosarId}`, {
                            method: 'DELETE',
                            headers: {
                              Authorization: `Bearer ${token}`,
                            },
                          });
                          setObiecteDosar(obiecteDosar.filter((item) => item.id !== obiectDosarId));
                          Swal('Șters!', 'Obiectul a fost șters.', 'success');
                          fetchData();
                      } catch (error) {
                          console.error('A apărut o eroare la ștergere!', error);
                      } finally {
                        setIsAddingOrEditing(false);
                      }
                  } else {
                    setIsAddingOrEditing(false);
                  }
              });   
        };

  const handleAdminOpen = () => {
    setAdminOpen(true);
  };

  const handleAdminClose = () => {
    setAdminOpen(false);
  };

  useEffect(() => {
    console.log('idMaterie actualizat:', idMaterie);
  }, [idMaterie]);

  const handleSaveAdminObiect = async () => {
    const apiUrl = currentAdminObiect ? `${process.env.REACT_APP_API_URL}obiecte/${currentAdminObiect.id}` : `${process.env.REACT_APP_API_URL}obiecte`;
    const requestOptions = {
      method: currentAdminObiect ? 'PATCH' : 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
    };
  
    try {
        const response = await fetch(`${apiUrl}?denumire=${textareaValue}&idMaterie=${idMaterie}&iddosar=${id}`, requestOptions);
        if (response.ok) {
        const data = await response.json();
        fetchData(); 
        SetReRandare(true);
      } else {
        console.error('A apărut o eroare la salvarea datelor');
      }
    } catch (error) {
      console.error('A apărut o eroare la salvarea datelor', error);
    } finally {
      handleAdminModalClose();
    }
  };

  
  const handleAdminModalOpen = (obiect) => {
    setIdMaterie(null);
    if (obiect) {
      setCurrentAdminObiect(obiect);
      setTextareaValue(obiect.denumire || "");
    } else {
      setCurrentAdminObiect(null);
      setTextareaValue("");
    }
    setAdminModalOpen(true);
  };
  
  const handleAdminModalClose = () => {
    setCurrentAdminObiect(null);
    setTextareaValue("");
    setAdminModalOpen(false);
  };
  
  const handleTextareaChange = (e) => {
    setTextareaValue(e.target.value);
  };

  
  return (
    <div>
      <div className='mb-3' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography style={{ fontWeight: 'bold', fontSize: '1.3em' }}>Obiecte dosar</Typography>
        <div>
          {areDrept(DreptDeModificareDosare) ? ( 
            <>
              <Button
                disabled={!areDrept(DreptDeModificareDosare)}
                onClick={() => handleOpen()}
                variant="contained"
                size="small"
                color="secondary"
                className='mr-2' 
                startIcon={<AddIcon />}
                style={{marginRight:'20px'}}
                >
                Adăugare obiect nou
                </Button>
                <Button
                disabled={!areDrept(DreptDeModificareDosare)}
                onClick={handleAdminOpen}
                variant="contained"
                size="small"
                color="primary"
                className='ml-2' 
                >
                Administrare obiecte
                </Button>
            </>
          ) : null } 
        </div>
       </div>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}><CircularProgress /></Box>
      ) : (
        <List>
          {obiecteDosar.length === 0 ? (
            <Alert severity="info">Nu există obiecte atașate dosarului.</Alert>
          ) : (
            obiecteDosar.map((obiectDosarItem, index) => (
              <ListItem
                key={obiectDosarItem.id}
                className={index % 2 === 0 ? 'list-item striped' : 'list-item'}
              >
                <ListItemText
                  primary={`${obiectDosarItem.denumire}`}
                  secondary={
                    <Typography variant="body2">
                      {/* Afișați aici detalii despre obiectul dosarului */}
                    </Typography>
                  }
                />
                {areDrept(DreptDeModificareDosare) ? (
                  <>
                    <IconButton aria-label="editeaza" disabled={!areDrept(DreptDeModificareDosare)} onClick={() => handleOpen(obiectDosarItem)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton  disabled={!areDrept(DreptDeModificareDosare)}aria-label="sterge" onClick={() => handleSterge(obiectDosarItem.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </>
                ) : null } 
              </ListItem>
            ))
          )}
        </List>
      )}
      {/* Dialog de adăugare și editare */}
      <Dialog maxWidth="xl" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle className='titluModal' id="form-dialog-title">
          {currentObiectDosar ? 'Editare obiect dosar' : 'Adăugare obiect dosar'}
        </DialogTitle>
        <DialogContent className='mt-3' style={{ minWidth: '300px' }}>
          <div className="input-section">
            
          <Typography>Materie dosar</Typography>
            <FormControl fullWidth>
              <Autocomplete
                  options={materii}
                  getOptionLabel={(materie) => materie.denumire}
                  size="small"
                  value={materii.find((materie) => materie.id === idMaterie) || null}
                  onChange={(_, newValue) => setIdMaterie(newValue?.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Alegeți o materie"
                      InputLabelProps={{ shrink: Boolean(idMaterie) }}
                      required
                    />
                  )}
                />
            </FormControl>
            <Typography>
              Selectați un obiect
            </Typography>
            <FormControl fullWidth>
              {loadingObiecte ? (
                <CircularProgress size={20} style={{ marginLeft: '10px' }} />
              ) : (
                <Autocomplete
                  options={obiecte}
                  size="small"
                  getOptionLabel={(obiect) => (obiect && obiect.denumire) ? obiect.denumire : ""}
                  value={obiecte.find((obiect) => obiect.id === idObiect) || null}
                  required
                  onChange={(event, newValue) => {
                    setIdObiect(newValue?.id)
                    setFormState(prevState => ({
                      ...prevState,
                      idobiect: newValue?.id || '',  
                    }));
                  }}
                  disabled={!idMaterie}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={idMaterie ? "Alegeți un obiect" : "Alegeți mai întâi o materie"}
                      InputLabelProps={{ shrink: Boolean(idObiect) }}
                      required
                    />
                  )}
                />
              )}
            </FormControl>

          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleAdaugaSauEditeaza}
            color="primary"
            disabled={!idMaterie || !idObiect || isAddingOrEditing} 
          >
            {isAddingOrEditing ? 'Se procesează...' : (currentObiectDosar ? 'Salvare' : 'Adăugare')}
          </Button>
          <Button onClick={handleClose} color="primary">
            Anulare
          </Button>
        </DialogActions>
      </Dialog>

      {/* administrare obiecte */}
      <Dialog maxWidth="md" open={adminOpen} onClose={handleAdminClose} aria-labelledby="admin-dialog-title">
        <DialogTitle className='titluModal mb-2' id="admin-dialog-title">
          Administrare obiecte
          <Button
              onClick={() => handleAdminModalOpen(null)}
              variant="contained"
              size="small"
              color="secondary"
              startIcon={<AddIcon />}
              className="pull-right"
            >
              Obiect nou
            </Button>
            <FormControl fullWidth>
            <Autocomplete
                className='mt-3 mb-1' 
                  options={materii}
                  style={{background:'#FFFFFF'}}
                  getOptionLabel={(materie) => (materie && materie.denumire) ? materie.denumire : ""}
                  size="small"
                  value={materii.find((materie) => materie.id === idMaterie) || null}
                  onChange={(_, newValue) => {
                    setIdMaterie(newValue?.id)
                    console.log('idMaterie nouă:', newValue?.id);
                  }}
                  renderInput={(params) => (
                      <TextField
                      {...params}
                      placeholder="Alegeti o materie"
                      InputLabelProps={{ shrink: Boolean(idMaterie) }}
                      required
                      />
                  )}
              />
          </FormControl>
            <TextField
                style={{background:'#FFFFFF'}}
                fullWidth
                type="text" 
                value={filtru} 
                onChange={(e) => setFiltru(e.target.value)} 
                size="small"
                placeholder="Filtrează obiectele..." 
            />
        </DialogTitle>
        <DialogContent> 
            
        <List>
          {obiecte
            .filter((obiect) => obiect && obiect.denumire && obiect.denumire.toLowerCase().includes(filtru.toLowerCase()))
            .map((obiect, index) => (
                <ListItem
                    key={obiect.id}
                    className={index % 2 === 0 ? 'list-item striped' : 'list-item'}
                >
                    <ListItemText
                    primary={obiect.denumire}
                    />
                    <IconButton
                    onClick={() => handleAdminModalOpen(obiect)}
                    variant="contained"
                    size="small"
                    color="default"
                    >
                    <EditIcon />
                    </IconButton>
                    <IconButton
                    onClick={() => handleStergeObiectAdmin(obiect.id)}
                    variant="contained"
                    color="default"
                    size='small'
                    >
                    <DeleteIcon />
                    </IconButton>
                </ListItem>
                ))}
            </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAdminClose} color="primary">
            Renunțare
          </Button>
        </DialogActions>
      </Dialog>
        {/*modal administrare obiecte editare / adaugare*/}
        <Dialog open={adminModalOpen} onClose={handleAdminModalClose}>
        <DialogTitle className='titluModal'>{currentAdminObiect ? "Editare Obiect" : "Adăugare Obiect Nou"}</DialogTitle>
        <DialogContent>
        {!currentAdminObiect ? ( 
        <>
          <Typography>Materie dosar</Typography>
          <FormControl fullWidth>
            <Autocomplete
                  options={materii}
                  getOptionLabel={(materie) => materie.denumire}
                  size="small"
                  value={materii.find((materie) => materie.id === idMaterie) || null}
                  onChange={(_, newValue) => {
                    setIdMaterie(newValue?.id)
                    console.log('idMaterie nouă:', newValue?.id);
                  }}
                  renderInput={(params) => (
                      <TextField
                      {...params}
                      placeholder="Alegeti o materie"
                      InputLabelProps={{ shrink: Boolean(idMaterie) }}
                      required
                      />
                  )}
              />
          </FormControl>
          </>
        ) : null }

            <Typography>Obiectul</Typography>
            <TextareaAutosize
            minRows={3}
            style={{ width: '100%' }}
            value={textareaValue}
            onChange={handleTextareaChange}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => handleSaveAdminObiect()} disabled={!textareaValue.trim()}
 color="primary">
            Salvare
            </Button>
            <Button onClick={handleAdminModalClose} color="primary">
            Anulare
            </Button>
        </DialogActions>
        </Dialog>

    </div>
  );
} 
export default InfoObiecteDosar;
