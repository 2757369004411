import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, Box, TextField, IconButton, Typography, CircularProgress, Grid, InputLabel, Select, Chip, MenuItem, Checkbox, FormControl, Table, TableCell, TableHead, TableBody, TableRow, TableContainer, Paper, Radio, FormControlLabel, RadioGroup, Tooltip
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert';
import verificareToken from '../VerificareToken';
import Snackbar from '@mui/material/Snackbar';
import DrepturiUtilizator from '../utilizator/drepturiUtilizator'; 

const GrupuriUtilizatori = ({ open, onClose }) => {

  const { areDrept } = DrepturiUtilizator(); 
  const EditareGrupuriUtilizatori = 'EditareGrupuriUtilizatori';
  const AdaugareGrupuriUtilizatori = 'AdaugareGrupuriUtilizatori';
  const StergereGrupuriUtilizatori = 'StergereGrupuriUtilizatori';


    const [grupuri, setGrupuri] = useState([]);
    const [grupDeschis, setGrupDeschis] = useState([]);
    const [numeGrup, setNumeGrup] = useState('');
    const [grupToEdit, setGrupToEdit] = useState(null); 
    const [editedGrup, setEditedGrup] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedGroupAccess, setSelectedGroupAccess] = useState([]);
    const [resetKey, setResetKey] = useState(0);

    const [isRightsModalOpen, setIsRightsModalOpen] = useState(false);
    const [userPermissions, setUserPermissions] = useState([]);
    const [userPermisiuni, setUserPermisiuni] = useState([]);
    const [userPermissionsTrue, setUserPermissionsTrue] = useState([]);
    
    const [radioSelections, setRadioSelections] = useState({});

    const [isDosareModalOpen, setIsDosareModalOpen] = useState(false);
 
    const [dosare, setDosare] = useState([]);  
    const [selectedDosareIds, setSelectedDosareIds] = useState([]);  

    const [grupuriDosare, setGrupuriDosare] = useState([]);  
    const [selectedGrupuriDosareIds, setSelectedGrupuriDosareIds] = useState([]);  
  
    const [isGrupuriDosareModalOpen, setIsGrupuriDosareModalOpen] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    
    const [isSelectingDosare, setIsSelectingDosare] = useState(false);
    const [isSelectingGrupuri, setIsSelectingGrupuri] = useState(false);
    const [dreptId, setDreptId] = useState('');
    const [rolUser, setRolUser] = useState('');
    const [valoareNoua, setValoareNoua] = useState('');
    const [currentSelectingRightId, setCurrentSelectingRightId] = useState(null);

    const [isNameValid, setIsNameValid] = useState(true);
    const [nameValidationMessage, setNameValidationMessage] = useState('');

    const [currentUserGroup, setCurrentUserGroup] = useState(null);
    const [groupUsers, setGroupUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [isUserDialogOpen, setIsUserDialogOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);


    const handleUserSelectChange = (event) => {
      setSelectedUser(event.target.value);
    };
    
    //preluarea tuturor userilor
    const fetchAllUsers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}users`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
    
        if (!response.ok) {
          throw new Error('Eroare la preluarea utilizatorilor');
        }
    
        const data = await response.json();
        setAllUsers(data.data.users);
      } catch (error) {
        console.error('Eroare la preluarea tuturor utilizatorilor:', error);
      }
    };

    //preluare utilizatorilor din grupul curent deschis
    const fetchGroupUsers = async (groupId) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}userGroup/${groupId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
    
        if (!response.ok) {
          throw new Error('Eroare la preluarea utilizatorilor grupului');
        }
    
        const data = await response.json();
        setGroupUsers(data.data.userGroup.users);
      } catch (error) {
        console.error('Eroare la preluarea utilizatorilor grupului:', error);
      }
    };

    //eliminarea unui user din grup
    
  const deleteUserFromGroup = (userId) => {
    Swal({
      title: 'Sigur doriți eliminarea utilizatorului din grup?',
      text: 'Această acțiune nu poate fi anulată!',
      icon: 'warning',
      buttons: ['Anulează', 'Da, elimină!'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteUserFromGroupOK(userId);
      }
    });
  };
  
  
    const deleteUserFromGroupOK = async (userId) => {
      try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}deleteUserFromGroup/?user_group_id=${currentUserGroup}&user_ids[]=${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Eroare la eliminarea utilizatorului din grup');
      } else {
        Swal('Succes', 'Utilizatorul a fost eliminat din grup cu succes!', 'success');
        fetchGroupUsers(currentUserGroup);
      }
  
      // Actualizați lista de utilizatori ai grupului
      setGroupUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
    } catch (error) {
      console.error('Eroare la eliminarea utilizatorului din grup:', error);
    }
    };

    //adaugarea unui user la grup:
    const addUserToGroup = async (userId) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}addUserToGroup/?user_group_id=${currentUserGroup}&user_ids[]=${userId.id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ user_id: userId }),
        });
    
        if (!response.ok) {
          throw new Error('Eroare la adăugarea utilizatorului în grup');
        } else {
          Swal('Succes', 'Utilizatorul a fost adăugat la grup cu succes!', 'success');
          fetchGroupUsers(currentUserGroup);
        }
    
        // Actualizarea listei de utilizatori ai grupului
        const newUser = allUsers.find(user => user.id === userId);
        if (newUser) {
          setGroupUsers(prevUsers => [...prevUsers, newUser]);
        }
      } catch (error) {
        console.error('Eroare la adăugarea utilizatorului în grup:', error);
      }
    };

    
    useEffect(() => {
      fetchAllUsers();
    }, []);
    
    const fetchDosare = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}dosare`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,  
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setDosare(data.data.dosare.map((dosar) => ({ ...dosar, selected: false })));
        } else {
          throw new Error('Eroare la obținerea listei de dosare.');
        }
      } catch (error) {
        console.error('Eroare la obținerea listei de dosare: ', error);
      }
    };
  
    useEffect(() => {
      if (isRightsModalOpen) {
        fetchDosare();
      }
    }, [isRightsModalOpen]);
    

  const handleDosarSelect = (dosarId) => {
    setDosare((prevDosare) => prevDosare.map((dosar) => {
      if (dosar && dosar.id === dosarId) {
        return { ...dosar, selected: !dosar.selected };
      }
      return dosar;
    }));
  
    setSelectedDosareIds((prevSelectedDosareIds) => {
      const isDosarSelected = prevSelectedDosareIds.includes(dosarId);
      if (isDosarSelected) {
        // daca dosarul este deja selectat il elimin din lista de dosare selectate
        return prevSelectedDosareIds.filter((id) => id !== dosarId);
      } else {
        // daca dosarul nu este selectat, il pun in lista de dosare selectate
        return [...prevSelectedDosareIds, dosarId];
      }
    });
  };
  
 
 
  const fetchGrupuriDosare = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}dosarGroups`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,  
        },
      });

      if (response.ok) {
        const data = await response.json();
        setGrupuriDosare(data.data.dosarGroups.map((grupdosar) => ({ ...grupdosar, selected: false })));
      } else {
        throw new Error('Eroare la obținerea listei de dosare.');
      }
    } catch (error) {
      console.error('Eroare la obținerea listei de dosare: ', error);
    }
  };

  const handleGrupuriDosarSelect = (grupdosarId) => {
    setGrupuriDosare((prevGrupuriDosare) => prevGrupuriDosare.map((grupdosar) => {
      if (grupdosar.id === grupdosarId) {
        return { ...grupdosar, selected: !grupdosar.selected };
      }
      return grupdosar;
    }));
  
    setSelectedGrupuriDosareIds((prevSelectedGrupuriDosareIds) => {
      const isGrupuriDosarSelected = prevSelectedGrupuriDosareIds.includes(grupdosarId);
      if (isGrupuriDosarSelected) {
        // daca grupul este deja selectat il elimin din lista de grupuri selectate
        return prevSelectedGrupuriDosareIds.filter((id) => id !== grupdosarId);
      } else {
        // daca grupul nu este selectat, il pun in lista de grupuri selectate
        return [...prevSelectedGrupuriDosareIds, grupdosarId];
      }
    });
  };

  useEffect(() => {
    fetchGrupuriDosare();
  }, []);


      const { token } = verificareToken();

    const [userRoles, setUserRoles] = useState([]); 

    const [formData, setFormData] = useState({ 
      user_role_ids: [],  
    });

    const [editFormData, setEditFormData] = useState({
      user_role_ids: [],
    });

    
  const fetchGrupuri = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}userGroups`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setGrupuri(data.data.userGroups);
    } catch (error) {
      console.error('Problema la preluare grupuri: ', error);
    }
  };

  useEffect(() => {
    fetchGrupuri();
  }, []);

  const handleAdaugareGrup = async () => {

  // verificare nume existent al grupului
  if (numeGrup === '') {
    setIsNameValid(false);
    setNameValidationMessage('Numele grupului nu poate fi gol.');
    return;
  }

  // Verifică dacă există grup cu același nume în lista de grupuri
  const exists = grupuri.some((grup) => grup.name === numeGrup);
  if (exists) {
    setIsNameValid(false);
    setNameValidationMessage('Acest nume de grup există deja în listă.');
    return;
  }

  if (formData.user_role_ids.length === 0) {
    Swal('Atenționare', 'Trebuie să selectați cel puțin un rol pentru a adăuga grupul.', 'warning');
    return;
  }

  // Dacă ajungi aici, numele grupului este valid
  setIsNameValid(true);
  setNameValidationMessage('');

    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}addUserGroup?name=${numeGrup}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      const data = await response.json();
  
      if (response.ok && data.data.userGroup && data.data.userGroup.id) {
        const groupId = data.data.userGroup.id;  
  
        const roleParams = formData.user_role_ids.map(id => `user_role_ids[]=${id}`).join('&');
  
        const rolesResponse = await fetch(`${process.env.REACT_APP_API_URL}setUserAccess?user_group_id=${groupId}&${roleParams}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
  
        if (rolesResponse.ok) {
          await Swal('Succes', 'Grupul și rolurile au fost create cu succes!', 'success');
          fetchGrupuri();
          setNumeGrup('');
          setFormData({ user_role_ids: [] });  
          setResetKey(prevKey => prevKey + 1); 
        } else {
          throw new Error('A apărut o eroare la atribuirea rolurilor.');
        }
      } else {
        throw new Error(data.message || 'Ceva nu a mers bine.');
      }
    } catch (error) {
      console.error('Problema la adaugare grup si roluri: ', error);
      Swal('Atenționare', error.toString(), 'warning');
    }
    setIsLoading(false);
  };
  

// aduc accesului grupului pentru editare - rolurile
const fetchGroupAccess = async (groupId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}userGroupAccess/${groupId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();
    if (response.ok) {
      const roleIds = data.data.userAccess.map((ur) => ur.user_role_id);
      setEditFormData({ user_role_ids: roleIds }); 
      //console.log('uite rolurile ', roleIds)
      setSelectedGroupAccess(data.data.userAccess);
    } else {
      console.error('A apărut o eroare la aducerea accesului grupului.');
    }
  } catch (error) {
    console.error('A apărut o eroare la aducerea accesului grupului: ', error);
  }
};

const handleInlineEdit = async (id, newName) => {
   // Verifică dacă numele grupului este gol
   if (newName === '') {
    Swal('Atenționare', 'Numele grupului nu poate fi gol.', 'warning');
    return;
  }

  // Verifică dacă nu sunt selectate roluri
  if (editFormData.user_role_ids.length === 0) {
    Swal('Atenționare', 'Trebuie să selectați cel puțin un rol pentru a edita grupul.', 'warning');
    return;
  }
  setIsLoading(true);
  try {
    // Pasul 1: Actualizarea numelui grupului
    const responseUpdateGroup = await fetch(`${process.env.REACT_APP_API_URL}updateUserGroup/${id}?name=${newName}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const dataUpdateGroup = await responseUpdateGroup.json();

    if (dataUpdateGroup.status !== 200) {
      throw new Error(dataUpdateGroup.message || 'Ceva nu a mers bine la actualizarea grupului.');
    }

    // Pasul 2: Actualizarea rolurilor grupului
    const roleParams = editFormData.user_role_ids.map(id => `user_role_ids[]=${id}`).join('&');

    const responseUpdateAccess = await fetch(`${process.env.REACT_APP_API_URL}setUserAccess?user_group_id=${id}&${roleParams}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!responseUpdateAccess.ok) {
      throw new Error('A apărut o eroare la actualizarea rolurilor grupului.');
    }

    // Pasul 3: Identificarea și eliminarea rolurilor debifate
    const selectedRoleIds = new Set(editFormData.user_role_ids);
    const existingRoleIds = new Set(selectedGroupAccess.map(role => role.user_role_id));

    const rolesToRemove = [...existingRoleIds].filter(id => !selectedRoleIds.has(id));

    for (let roleId of rolesToRemove) {
      await fetch(`${process.env.REACT_APP_API_URL}deleteUserAccess?user_group_id=${id}&user_role_id=${roleId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
    }

    await Swal('Succes', 'Grup și roluri actualizate cu succes!', 'success');
    fetchGrupuri();
  } catch (error) {
    console.error('Problema la editare grup și roluri: ', error);
    Swal('Atenționare', error.toString(), 'warning');
  }
  setIsLoading(false);
  handleEditClose();
};


const handleEditClick = (grup) => {
  setGrupToEdit(grup.id);
  setEditedGrup(grup.name);
  fetchGroupAccess(grup.id);  
};

const handleStergereGrup = async (id) => {
    Swal({
      title: 'Sunteți sigur?',
      text: 'Odată șters, acest grup nu va mai putea fi recuperat!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    })
    .then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}deleteUserGroup/${id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();
          if (data.status === 200) {
            Swal('Șters!', 'Grupul a fost șters.', 'success');
            fetchGrupuri();
          }
        } catch (error) {
          console.error('Problema la stergere grup: ', error);
        }
      } else {
        Swal('Anulat', 'Grupul este în siguranță!', 'warning');
      }
    });
  };

  useEffect(() => {
    if (open) {
      const fetchUserRoles = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}userRoles`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            setUserRoles(data.data.userRoles);
          } else {
            console.error('A apărut o eroare la aducerea rolurilor de utilizatori.');
          }
        } catch (error) {
          console.error('A apărut o eroare la aducerea rolurilor de utilizatori: ', error);
        }
      };

      fetchUserRoles();
    }
  }, [open]);
  
 
  const handleSelectChange = (event, field) => {
    setFormData({
      ...formData,
      [field]: event.target.value,
    });
  };

  const handleEditClose = () => {
    setGrupToEdit(null);
    setEditFormData({ user_role_ids: [] });  
  };

  const getUserID = () => {
    const user_id_local = localStorage.getItem('iduser');
    const user_id_session = sessionStorage.getItem('iduser');
    const user_id = user_id_local || user_id_session || null;
    //console.log("user_id:", user_id);  
    return user_id;
  };

    const userId = getUserID();

    const handleOpenRightsModal = async (grupId) => {
      if (isRightsModalOpen) return;
    
      let roleIds = [];
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}userGroupAccess/${grupId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
    
        const data = await response.json();
        if (response.ok) {
          roleIds = data.data.userAccess.map((ur) => ur.user_role_id);
        } else {
          console.error('A apărut o eroare la aducerea accesului grupului.');
        }
      } catch (error) {
        console.error('A apărut o eroare la aducerea accesului grupului: ', error);
      }
    
      try {
        const params = new URLSearchParams({
          user_group_id: grupId,
        });
        roleIds.forEach(id => params.append('user_role_ids[]', id));

        const url = `${process.env.REACT_APP_API_URL}getRightsDosareList?${params.toString()}`;
        //console.log('URL cerere:', url); 

        const responseRightsList = await fetch(url, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
         
        if (responseRightsList.ok) {
 
          
          const dataRightsList = await responseRightsList.json();
          setGrupDeschis(dataRightsList.data.user_group_id);
          const permissionsWithAccess = dataRightsList.data;
          setUserPermissionsTrue(permissionsWithAccess);
          setUserPermisiuni(permissionsWithAccess.userRoles);
          //console.log('permisiunile noi:', userPermisiuni);
          //console.log('userPermissions setate: ', userPermissionsTrue);
          const permissionsWithCheckedTrue = dataRightsList.data.userRoles.flatMap(role => role.rights_dosare).filter(drept => drept.checked === true);
          //console.log('cu bifa checked true: ', permissionsWithCheckedTrue);

          const newRadioSelections = {};
          let updatedRadioSelections = { ...radioSelections };
          const permissionsWithAccess2 = dataRightsList.data.userRoles.flatMap(role => role.rights_dosare).filter(drept => drept.necesita_tip_acces_dosare);

          for (let drept of permissionsWithAccess2) {
            const uniqueKey = `${drept.user_role_id}-${drept.id}`;
              try {
              const url = `${process.env.REACT_APP_API_URL}getAlteDosareByRight?user_group_id=${grupId}&user_role_id=${drept.user_role_id}&user_right_id=${drept.id}`;
              const response = await fetch(url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
                },
              });
              //console.log('drept', drept);
              const data = await response.json();
                if (drept.checked) {
                  const accessType = data.data.access[0].tip_acces_la_dosare;
                updatedRadioSelections[`${drept.user_role_id}-${drept.id}`] = accessType;
                } else {
                  // Pentru drepturile care nu sunt "checked" pun direct 'proprii'
                  updatedRadioSelections[uniqueKey] = 'proprii';
                }
               
            } catch (error) {
              console.error(`Eroare la preluarea accesului la dosare pentru dreptul cu id=${drept.id}: `, error);
            }
          }
        
          setRadioSelections(updatedRadioSelections);
        } else {
          throw new Error('Răspunsul de la server nu este ok.');
        }
    
      } catch (error) {
        console.error('Eroare la preluarea drepturilor de utilizator: ', error);
      }
    
      setIsRightsModalOpen(true);
    };
    
    useEffect(() => {
      //console.log('userPermissions actualizate: ', userPermissionsTrue);
    }, [userPermissionsTrue]);

 
    const handleRadioChange = async (userRoleId, grupIdd, rightId, newValue) => {
      const uniqueKey = `${userRoleId}-${rightId}`;
      setRadioSelections(prevRadioSelections => ({
        ...prevRadioSelections,
        [uniqueKey]: newValue,
      }));
 
      setDreptId(rightId);
      setRolUser(userRoleId);
      setValoareNoua(newValue);

      if (currentSelectingRightId === `${userRoleId}-${rightId}`) {
        setCurrentSelectingRightId(null);
        setIsSelectingDosare(false);
        setIsSelectingGrupuri(false);
      }
      if (newValue === 'alte_dosare') {
        //console.log('Caz pentru alte_dosare');
        setIsSelectingDosare(true); 
        setCurrentSelectingRightId(`${userRoleId}-${rightId}`);
        //ceva
      } else if (newValue === 'alte_grupuri_dosare') {
        setIsSelectingGrupuri(true); 
        setCurrentSelectingRightId(`${userRoleId}-${rightId}`);

        //console.log('Caz pentru alte_grupuri_dosare');
        //altceva
      } else {
        //console.log('Caz pentru orice altă valoare');
        try {
          const url = new URL(`${process.env.REACT_APP_API_URL}setUserAccessToDosare`);
          url.search = new URLSearchParams({
            user_group_id: grupDeschis,
            user_right_id: rightId,
            user_role_id: userRoleId,
            tip_acces_la_dosare: newValue
          }).toString();
    
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}` 
            }
          });
    
          if (!response.ok) {
            throw new Error('Eroare la actualizarea accesului la dosare.');
          }
    
          const data = await response.json();
          //console.log('Răspuns actualizare acces la dosare:', data);
          setSnackbarMessage("Drept actualizat cu succes!");
          setSnackbarOpen(true);
    
        } catch (error) {
          console.error('Eroare la efectuarea cererii:', error);
        }
      }
      if (newValue !== 'alte_dosare' && newValue !== 'alte_grupuri_dosare') {
        try {
          // Pasul 1: toate drepturile de acces la dosare
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}getAlteDosareByRight?user_group_id=${grupDeschis}&user_role_id=${userRoleId}&user_right_id=${rightId}`, 
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
              },
            }
          );
    
          if (response.ok) {
            const data = await response.json();
            const otherRights = data.data.access.filter(access => access.tip_acces_la_dosare !== newValue);
    
            // Pasul 2: elimin drepturile care nu corespund
            for (let access of otherRights) {
              const deleteResponse = await fetch(
                `${process.env.REACT_APP_API_URL}deleteUserAccessToDosare?user_role_id=${userRoleId}&tip_acces_la_dosare=${access.tip_acces_la_dosare}&user_right_id=${rightId}&user_group_id=${grupDeschis}`, 
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                  },
                }
              );
    
              if (!deleteResponse.ok) { 
                console.error("Eroare la eliminarea accesului la dosare care nu corespund.");
              }
            }
          } else {
            throw new Error('Eroare la obținerea accesului la dosare.');
          }
        } catch (error) {
          console.error('Eroare la procesarea drepturilor de acces la dosare:', error);
        }
      }
    };
    
    const handleDosareConfirm = async () => {
      const dosareSelectateIds = dosare.filter(dosar => selectedDosareIds.includes(dosar.id)).map(dosar => dosar.id);
     
        
      const baseUrl = process.env.REACT_APP_API_URL;  
      const endpoint = dosareSelectateIds.length > 0 ? 'setUserAccessToDosare' : 'deleteUserAccessToDosare';
      const fullUrl = `${baseUrl}${endpoint}`;
            
      const params = new URLSearchParams({
          user_group_id: grupDeschis,
          user_right_id: dreptId,
          user_role_id: rolUser,
          tip_acces_la_dosare: 'alte_dosare',
        });
    
        if (dosareSelectateIds.length > 0) {
          dosareSelectateIds.forEach(id => params.append('dosar_ids[]', id));
        }
      
        const urlWithParams = `${fullUrl}?${params.toString()}`;
      
        try {
            const response = await fetch(urlWithParams, { 
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          });
    
          if (!response.ok) {
            throw new Error('Eroare la actualizarea accesului la dosare.');
          }
    
          const data = await response.json();
          //console.log('Răspuns actualizare acces la dosare:', data);
          setSnackbarMessage("Drept actualizat cu succes!");
          setSnackbarOpen(true);
        } catch (error) {
          console.error('Eroare la efectuarea cererii:', error);
        }
 
          try {
            // Pasul 1:   toate drepturile de acces la dosare
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}getAlteDosareByRight?user_group_id=${grupDeschis}&user_role_id=${rolUser}&user_right_id=${dreptId}`, 
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                },
              }
            );
      
            if (response.ok) {
              const data = await response.json();
              const otherRights = data.data.access.filter(access => access.tip_acces_la_dosare !== valoareNoua);
      
              // Pasul 2: elimin drepturile care nu corespund
              for (let access of otherRights) {
                const deleteResponse = await fetch(
                  `${process.env.REACT_APP_API_URL}deleteUserAccessToDosare?user_role_id=${rolUser}&tip_acces_la_dosare=${access.tip_acces_la_dosare}&user_right_id=${dreptId}&user_group_id=${grupDeschis}`, 
                  {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${token}`,
                    },
                  }
                );
      
                if (!deleteResponse.ok) { 
                  console.error("Eroare la eliminarea accesului la dosare care nu corespund.");
                }
              }
            } else {
              throw new Error('Eroare la obținerea accesului la dosare.');
            }
          } catch (error) {
            console.error('Eroare la procesarea drepturilor de acces la dosare:', error);
          }
        
  
      // Resetare la starea inițială
      setIsSelectingDosare(false);
      setIsDosareModalOpen(false);
      setSelectedDosareIds([]);
    };


    const handleGrupuriDosareConfirm = async () => {
      const grupuriDosareSelectateIds = grupuriDosare.filter(grupdosar => selectedGrupuriDosareIds.includes(grupdosar.id)).map(grupdosar => grupdosar.id);
     
      const baseUrl = process.env.REACT_APP_API_URL;  
      const endpoint = grupuriDosareSelectateIds.length > 0 ? 'setUserAccessToDosare' : 'deleteUserAccessToDosare';
      const fullUrl = `${baseUrl}${endpoint}`;
      
      const params = new URLSearchParams({
          user_group_id: grupDeschis,
          user_right_id: dreptId,
          user_role_id: rolUser,
          tip_acces_la_dosare: 'alte_grupuri_dosare',
        });
    
        if (grupuriDosareSelectateIds.length > 0) {
          grupuriDosareSelectateIds.forEach(id => params.append('dosar_group_ids[]', id));
      }
      
      const urlWithParams = `${fullUrl}?${params.toString()}`;
      
      try {
          const response = await fetch(urlWithParams, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          });
    
          if (!response.ok) {
            throw new Error('Eroare la actualizarea accesului la dosare.');
          }
    
          const data = await response.json();
          //console.log('Răspuns actualizare acces la grupuri de dosare:', data);
          setSnackbarMessage("Drept actualizat cu succes!");
          setSnackbarOpen(true);
        } catch (error) {
          console.error('Eroare la efectuarea cererii:', error);
        }
 
          try {
            // Pasul 1:   toate drepturile de acces la dosare
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}getAlteDosareByRight?user_group_id=${grupDeschis}&user_role_id=${rolUser}&user_right_id=${dreptId}`, 
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                },
              }
            );
      
            if (response.ok) {
              const data = await response.json();
              const otherRights = data.data.access.filter(access => access.tip_acces_la_dosare !== valoareNoua);
      
              // Pasul 2: elimin drepturile care nu corespund
              for (let access of otherRights) {
                const deleteResponse = await fetch(
                  `${process.env.REACT_APP_API_URL}deleteUserAccessToDosare?user_role_id=${rolUser}&tip_acces_la_dosare=${access.tip_acces_la_dosare}&user_right_id=${dreptId}&user_group_id=${grupDeschis}`, 
                  {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${token}`,
                    },
                  }
                );
      
                if (!deleteResponse.ok) { 
                  console.error("Eroare la eliminarea accesului la dosare care nu corespund.");
                }
              }
            } else {
              throw new Error('Eroare la obținerea accesului la dosare.');
            }
          } catch (error) {
            console.error('Eroare la procesarea drepturilor de acces la dosare:', error);
          }
        
  
      // Resetare la starea inițială
      setIsSelectingGrupuri(false);
      setIsGrupuriDosareModalOpen(false);
      setSelectedGrupuriDosareIds([]);
    };


    useEffect(() => {
      //console.log('Actualizare radioSelections:', radioSelections);
    }, [radioSelections]);
    

    useEffect(() => {
      if (userPermissionsTrue.length > 0) {
        const newRadioSelections = userPermissionsTrue.reduce((acc, permission) => {
          const uniqueKey = `${permission.user_role_id}-${permission.id}`;
    
          acc[uniqueKey] = radioSelections[uniqueKey] || 'proprii';
    
          return acc;
        }, {});
    
        setRadioSelections(newRadioSelections);
      }
    }, [userPermissionsTrue]);
    
 
 
    const handleOpenDosareModal = async (userRoleId, rightId) => {
      setSelectedDosareIds([]);
      setIsDosareModalOpen(true);
      await fetchDosare();
      const url = `${process.env.REACT_APP_API_URL}getAlteDosareByRight`;
      const params = new URLSearchParams({
        user_group_id: grupDeschis, 
        user_role_id: userRoleId,
        user_right_id: rightId,
      });
    
      try {
        const response = await fetch(`${url}?${params}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, 
          },
        });
    
        if (response.ok) {
          const data = await response.json();
          const accessObject = data.data.access.find(obj => obj.tip_acces_la_dosare === "alte_dosare");
          
          if (accessObject && accessObject.dosare && accessObject.dosare.length > 0) {
            const selectedDosareIds = accessObject.dosare.map(dosar => dosar.id);
            setSelectedDosareIds(selectedDosareIds);
            
            setDosare(dosare => dosare.map(dosar => {
              return {
                ...dosar,
                selected: selectedDosareIds.includes(dosar.id),
              };
            }));
          }
        } else {
          throw new Error('Răspunsul de la server nu este ok.');
        }
      } catch (error) {
        console.error('Eroare la preluarea dosarelor: ', error);
      }
    };
    
    const handleClose = (event, reason) => {
      if (reason && reason == "backdropClick") 
          return;
       setIsDosareModalOpen(false);
       setSelectedDosareIds([]);
    }
    
    const handleOpenGrupuriDosareModal = async (userRoleId, rightId) => {
      setSelectedGrupuriDosareIds([]);
      setIsGrupuriDosareModalOpen(true);
      
      const url = `${process.env.REACT_APP_API_URL}getAlteDosareByRight`;
      const params = new URLSearchParams({
        user_group_id: grupDeschis, 
        user_role_id: userRoleId,
        user_right_id: rightId,
      });
    
      try {
        const response = await fetch(`${url}?${params}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, 
          },
        });
    
        if (response.ok) {
          const data = await response.json();
          const accessObject = data.data.access.find(obj => obj.tip_acces_la_dosare === "alte_grupuri_dosare");
          
          if (accessObject && accessObject.dosar_groups && accessObject.dosar_groups.length > 0) {
            const selectedGrupuriDosareIds = accessObject.dosar_groups.map(grupdosar => grupdosar.id);
            setSelectedGrupuriDosareIds(selectedGrupuriDosareIds);
            
            setGrupuriDosare(grupuriDosare => grupuriDosare.map(grupdosar => {
              return {
                ...grupdosar,
                selected: selectedGrupuriDosareIds.includes(grupdosar.id),
              };
            }));
          }
        } else {
          throw new Error('Răspunsul de la server nu este ok.');
        }
      } catch (error) {
        console.error('Eroare la preluarea grupurilor de dosare: ', error);
      }
    };
    const handleCloseGrupuriDosare = (event, reason) => {
      if (reason && reason == "backdropClick") 
          return;
       setIsGrupuriDosareModalOpen(false);
       setSelectedGrupuriDosareIds([]);
    }
    
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle className='titluModal'>Administrare grupuri utilizatori</DialogTitle>
      <DialogContent>
        
        {/* Partea pentru adăugare grup nou */}
        
        {areDrept(AdaugareGrupuriUtilizatori) ? ( 
          <Grid container alignItems="center" className="mt-3" spacing={2}>
          <Grid item>
            <TextField
              label="Adăugare grup"
              value={numeGrup}
              onChange={(e) => setNumeGrup(e.target.value)} 
              variant="outlined"
              margin="dense" 
              error={!isNameValid}
              helperText={nameValidationMessage}
            />
          </Grid>
          <Grid item xs>
            <FormControl fullWidth margin="dense">
              <InputLabel id="user-roles-label" key={resetKey}>Roluri Utilizatori</InputLabel>
              <Select
                labelId="user-roles-label"
                id="user-roles"
                name="user_role_ids"
                label="Roluri Utilizatori" 
                multiple
                value={formData.user_role_ids}
                onChange={(event) => handleSelectChange(event, 'user_role_ids')}
                renderValue={(selected) => (
                  <div>
                    {selected.map((value) => (
                      <Chip key={value} label={userRoles.find((role) => role.id === value)?.name} style={{ marginRight: '4px' }} />
                    ))}
                  </div>
                )}
              >
                {userRoles.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                    <Checkbox checked={formData.user_role_ids.indexOf(role.id) > -1} />
                    {role.name}
                </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <IconButton color="primary" onClick={handleAdaugareGrup} disabled={isLoading}>
              {isLoading ? <CircularProgress size={24} /> : <CheckIcon />}
            </IconButton>
          </Grid>
        </Grid>
        ) : null } 
        {/* Listarea grupurilor */}
        <Box>
          <Grid container spacing={2} className="text-center mt-2">
                <TableContainer component={Paper} style={{ maxHeight: '300px' }}>
        <Table stickyHeader id='tabelGrupUtilizatori'>
          <TableHead>
            <TableRow>
              <TableCell>Nume Grup</TableCell>
              <TableCell align="center"></TableCell>
              {areDrept(StergereGrupuriUtilizatori) ? ( <TableCell align="right">Acțiuni</TableCell> ) : null } 
            </TableRow>
          </TableHead>
          <TableBody>
            {grupuri.map((grup) => (
              <TableRow key={grup.id}>
                <TableCell component="th" scope="row" >
                  {grupToEdit === grup.id ? (
                    // Formular pentru editare
                    <form style={{display:'flex', width:'100%'}} onSubmit={(e) => {
                      e.preventDefault();
                      handleInlineEdit(grup.id, e.target[0].value);
                    }}>
                      <TextField
                        style={{background:'#FFFFFF', marginRight:'15px'}}
                        value={editedGrup}
                        onChange={(e) => setEditedGrup(e.target.value)}
                        variant="outlined"
                        margin="dense" 
                        disabled={!areDrept(EditareGrupuriUtilizatori)}
                      />
                      <FormControl fullWidth margin="dense" style={{background:'#FFFFFF'}}>
                        <InputLabel id="edit-user-roles-label">Roluri Utilizatori</InputLabel>
                        <Select
                          labelId="edit-user-roles-label"
                          disabled={!areDrept(EditareGrupuriUtilizatori)}
                          id="edit-user-roles"
                          name="edit_user_role_ids"
                          label="Roluri Utilizatori"
                          multiple
                          value={editFormData.user_role_ids}
                          onChange={(event) => setEditFormData({ ...editFormData, user_role_ids: event.target.value })}
                          renderValue={(selected) => (
                            <div>
                              {selected.map((value) => (
                                <Chip key={value} label={userRoles.find((role) => role.id === value)?.name} style={{ marginRight: '4px' }} />
                              ))}
                            </div>
                          )}
                        >
                          {userRoles.map((role) => (
                            <MenuItem key={role.id} value={role.id}>
                              <Checkbox checked={editFormData.user_role_ids.indexOf(role.id) > -1} />
                              {role.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </form>
                  ) : (
                    // Numele grupului
                    <Typography>{grup.name}</Typography>
                  )}
                </TableCell>
                <TableCell>
                {areDrept(EditareGrupuriUtilizatori) ? ( 
                <>
                  <Button disabled={!areDrept(EditareGrupuriUtilizatori)} onClick={() => handleOpenRightsModal(grup.id)}>Drepturi utilizator asupra dosarelor</Button>
                  <Button color="primary" onClick={() => { setCurrentUserGroup(grup.id); fetchGroupUsers(grup.id); setIsUserDialogOpen(true); }}>
                    Administrare utilizatori din grup
                  </Button>
                </>
                  ) : null } 
                </TableCell> 
                <TableCell align="right" style={{display:'flex'}}>
                  {grupToEdit === grup.id ? (
                    <>
                      <IconButton color="error" onClick={handleEditClose}>
                        <CloseIcon />
                      </IconButton>
                      <IconButton disabled={!areDrept(EditareGrupuriUtilizatori)} color="primary" onClick={() => handleInlineEdit(grup.id, editedGrup)}>
                        <CheckIcon />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton color="error" onClick={() => handleStergereGrup(grup.id)}>
                      {areDrept(StergereGrupuriUtilizatori) ? ( <DeleteIcon /> ) : null } 
                      </IconButton>
                      <IconButton color="primary" onClick={() => handleEditClick(grup)}>
                      {areDrept(EditareGrupuriUtilizatori) ? ( <EditIcon /> ) : null } 
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
              </Grid>
        </Box>
        <Dialog open={isRightsModalOpen} onClose={() => setIsRightsModalOpen(false)} maxWidth="md" fullWidth>
          <DialogTitle className='titluModal'>Administrare Drepturi Utilizator</DialogTitle>
          <DialogContent className='drepturiAdmin'>
          <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          ContentProps={{
            style: { backgroundColor: 'green' }  
          }}
        />

{userPermisiuni.map(role => (
  <div key={role.id} className='colDrepturi'>
    <Typography  style={{ marginBottom: '16px' }} className='titluColDrepturi'>
      Grup {role.name}
    </Typography>
    {role.rights_dosare.map((rights_dosare) => (
              <div key={`${rights_dosare.user_role_id}-${rights_dosare.id}`}>
                <Typography style={{fontWeight:'bold', fontSize:'16px'}}>{rights_dosare.category}: {rights_dosare.name}</Typography>
                <RadioGroup
                    value={radioSelections[`${rights_dosare.user_role_id}-${rights_dosare.id}`]}
                    //onChange={(e) => handleRadioChange(`${rights_dosare.user_role_id}-${rights_dosare.id}`, e.target.value)}
                    onChange={(e) => handleRadioChange(rights_dosare.user_role_id, grupToEdit, rights_dosare.id, e.target.value)}

                  className="drepturiUser"
                >
                  <FormControlLabel
                    control={<Radio />}
                    label="Proprii"
                    value="proprii" 
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Toate dosarele din departament"
                    value="toate_din_departament"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Toate din aplicatie"
                    value="toate_din_aplicatie"
                  />
                  <div style={{display:'flex'}}>
                    
                  <FormControlLabel
                    control={<Radio />}
                    label="Dosare proprii și altele"
                    value="alte_dosare"
                  />
                 <Button size="small" 
                  onClick={() => {
                    handleRadioChange(rights_dosare.user_role_id, grupToEdit, rights_dosare.id, 'alte_dosare')
                    handleOpenDosareModal(rights_dosare.user_role_id, rights_dosare.id)
                  }}
                  disabled={radioSelections[`${rights_dosare.user_role_id}-${rights_dosare.id}`] !== 'alte_dosare'}
                   >
                   Alege dosare
                  </Button>
                 
                  </div>
                   
                  <div style={{display:'flex'}}>
                    <FormControlLabel
                    control={<Radio />}
                    label="Alte grupuri de dosare"
                    value="alte_grupuri_dosare"
                  />
                   <Button 
                   size="small" 
                   onClick={() => {
                    fetchGrupuriDosare()
                    handleRadioChange(rights_dosare.user_role_id, grupToEdit, rights_dosare.id, 'alte_grupuri_dosare')
                    handleOpenGrupuriDosareModal(rights_dosare.user_role_id, rights_dosare.id)
                  }}

                   disabled={radioSelections[`${rights_dosare.user_role_id}-${rights_dosare.id}`] !== 'alte_grupuri_dosare'}
                   >
                      Alege grupuri
                    </Button>
                
                  </div>
                </RadioGroup>
              </div>
           ))}
           </div>
         ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsRightsModalOpen(false)} color="primary">
              Renunțare
            </Button>
          </DialogActions>
        </Dialog>
     


        <Dialog className='modalAlegeDosare' disableEscapeKeyDown  open={isDosareModalOpen} onClose={handleClose}  maxWidth="sm" fullWidth>
          <DialogTitle className='titluModal'>Alege dosare</DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              {/* Lista de dosare din dreapta */}
              <Grid item xs={6}>
                <Typography variant="h6">Dosare disponibile</Typography>
                <ul style={{maxHeight:'300px', overflow:'auto', height:'100%', padding:'0 !important'}}>
                {dosare.map((dosar) => {
                  if (!dosar) return null;  
                  return (
                <li key={dosar.id} className={dosar.selected ? "none" : ""}>
                  <Checkbox  size="small"
                    checked={dosar.selected}
                    onChange={() => handleDosarSelect(dosar.id)}
                  />
                  {dosar.numardosarfinal || dosar.numarintern} (Client {dosar.client})
                </li>
                  );
                })}
              </ul>

              </Grid>
              {/* Lista de dosare selectate în stanga */}
              <Grid item xs={6}>
                <Typography variant="h6">Dosare selectate</Typography>
                <ul style={{maxHeight:'300px', overflow:'auto', height:'100%', padding:'0 !important'}}>
                {selectedDosareIds.map((dosarId) => {
                  const dosar = dosare.find((d) => d.id === dosarId);
                  return (
                    <li key={dosar.id}>
                      <Checkbox  size="small"
                        checked
                        onChange={() => handleDosarSelect(dosar.id)}
                      />
                      {dosar.numardosarfinal || dosar.numarintern} (Client {dosar.client})
                    </li>
                  );
                })}
                </ul>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDosareModalOpen(false)} color="primary">
              Renunțare
            </Button>
            <Button onClick={handleDosareConfirm} color="primary">
              Confirmare
            </Button>
          </DialogActions>
        </Dialog>

 
      <Dialog className='modalAlegeGrupuriDosare' disableEscapeKeyDown  open={isGrupuriDosareModalOpen} onClose={handleCloseGrupuriDosare}  maxWidth="sm" fullWidth>
        <DialogTitle className='titluModal'>Alege grupuri dosare</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {/* Lista de grupuri dosare din dreapta */}
            <Grid item xs={6}>
              <Typography variant="h6">Grupuri dosare disponibile</Typography>
              <ul style={{maxHeight:'300px', overflow:'auto', height:'100%', padding:'0 !important'}}>
              {grupuriDosare.map((grupdosar) => (
              <li key={grupdosar.id} className={grupdosar.selected ? "none" : ""}>
                <Checkbox  size="small"
                  checked={grupdosar.selected}
                  onChange={() => handleGrupuriDosarSelect(grupdosar.id)}
                />
                {grupdosar.name}
              </li>
            ))}
            </ul>

            </Grid>
            {/* Lista de grupuri dosare selectate în stanga */}
            <Grid item xs={6}>
              <Typography variant="h6">Dosare selectate</Typography>
              <ul style={{maxHeight:'300px', overflow:'auto', height:'100%', padding:'0 !important'}}>
              {selectedGrupuriDosareIds.map((grupdosarId) => {
                const grupdosar = grupuriDosare.find((d) => d.id === grupdosarId);
                return (
                  <li key={grupdosar.id}>
                    <Checkbox  size="small"
                      checked
                      onChange={() => handleGrupuriDosarSelect(grupdosar.id)}
                    />
                    {grupdosar.name}
                  </li>
                );
              })}
              </ul>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsGrupuriDosareModalOpen(false)} color="primary">
            Renunțare
          </Button>
          <Button onClick={handleGrupuriDosareConfirm} color="primary">
            Confirmare
          </Button>
        </DialogActions>
        </Dialog>

        <Dialog open={isUserDialogOpen} onClose={() => setIsUserDialogOpen(false)} maxWidth="md" fullWidth>
          <DialogTitle className='titluModal'>Utilizatori din grup</DialogTitle>
          <DialogContent className='mt-4'>
            <FormControl fullWidth style={{ marginBottom: '20px' }}  className='mt-3'>
              <Autocomplete
                id="user-autocomplete"
                options={allUsers.filter(user => !groupUsers.some(groupUser => groupUser.id === user.id))}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Alegeți un utilizator" variant="outlined" />}
                onChange={(event, newValue) => {
                  setSelectedUser(newValue);
                }}
                fullWidth
                value={selectedUser}
              />
              <Button color="success" onClick={() => addUserToGroup(selectedUser)}>Adaugare utilizator la grup</Button>
            </FormControl>

            <TableContainer component={Paper} className="tabelUseriAdmin">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nume Utilizator</TableCell>
                    <TableCell align="right"> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupUsers.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell component="th" scope="row">{user.name} ({user.fullname})</TableCell>
                      <TableCell align="right">
                      <Tooltip title="Eliminare utilizator din grup">
                         <Button color="error" onClick={() => deleteUserFromGroup(user.id)}><DeleteIcon /></Button>
                      </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsUserDialogOpen(false)} color="primary">Renunțare</Button>
          </DialogActions>
        </Dialog>

        </DialogContent>
        <DialogActions>
        <Button onClick={onClose} color="primary">
          Renunțare
        </Button>
        </DialogActions>
      </Dialog>
    
  );
};

export default GrupuriUtilizatori;