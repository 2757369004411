import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert';
import {Checkbox, FormControlLabel, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import Switch from '@mui/material/Switch';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DrepturiUtilizator from '../utilizator/drepturiUtilizator'; 

import verificareToken from '../VerificareToken';

const RoluriUtilizatori = ({ open, onClose }) => {

  const { areDrept } = DrepturiUtilizator();   
  const EditareRoluri = 'EditareRoluri';
  const AdaugareRoluri = 'AdaugareRoluri';
  const StergereRoluri = 'StergereRoluri';

  const [roluri, setRoluri] = useState([]);
  const [drepturi, setDrepturi] = useState([]);
  const [numeRol, setNumeRol] = useState('');
  const [drepturiSelectate, setDrepturiSelectate] = useState([]);
  const [drepturiSelectatePentruEditare, setDrepturiSelectatePentruEditare] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [rolDeEditat, setRolDeEditat] = useState(null);
  const [numeRolEditare, setNumeRolEditare] = useState('');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isNumeRolComplet, setIsNumeRolComplet] = useState(false);

  const [accesComplet, setAccesComplet] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState({});

  const handleOpenAddModal = () => {
    setIsAddModalOpen(true);
    setNumeRol('');  
    setDrepturiSelectate([]); 
  };

  const handleCloseAddModal = () => {
    setIsAddModalOpen(false);
    setNumeRol('');  
    setDrepturiSelectate([]); 
  };

    const { token } = verificareToken();
  
  useEffect(() => {
    if (open) {
      fetchRoluri();
      fetchDrepturi();
    }
  }, [open]);
  

  const fetchRoluri = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}userRoles`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("Data primită pentru roluri:", data); 
      setRoluri(data.data.userRoles);
    } catch (error) {
      console.error('Eroare la preluarea rolurilor: ', error);
    }
  };

  const fetchDrepturi = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}userRights`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("Data drepturi:", data);
      setDrepturi(data.data.userRights);
    } catch (error) {
      console.error('Eroare la preluarea drepturilor: ', error);
    }
  };

  const adaugaRol = async () => {
    if (!numeRol) {
      Swal('Atenționare', 'Numele rolului este obligatoriu!', 'warning');
      return;
    }

    
    try {
      
        let apiUrl = `${process.env.REACT_APP_API_URL}addUserRole?name=${numeRol}`;

        if (drepturiSelectate.length > 0) {
          apiUrl += `&user_right_ids[]=${drepturiSelectate.join('&user_right_ids[]=')}`;
        }


        const response = await fetch(apiUrl, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data.status === 201) {
        Swal('Succes', 'Rol creat cu succes!', 'success');
        setIsAddModalOpen(false);
        fetchRoluri();
        setNumeRol(''); 
      } else {
        Swal('Atenționare', data.message, 'warning');
      }
    } catch (error) {
      console.error('Eroare la adăugarea rolului: ', error);
      Swal('Atenționare', 'Ceva nu a mers bine.', 'warning');
    }
  };

  const handleNumeRol = (e) => {
    const value = e.target.value;
    setNumeRol(value);
    setIsNumeRolComplet(!!value);  
  };

  const handleStergereRol = async (id) => {
    Swal({
      title: 'Sunteți sigur?',
      text: 'Odată șters, acest rol nu va mai putea fi recuperat!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    })
    .then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}deleteUserRole/${id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();
          if (data.status === 200) {
            Swal('Șters!', 'Rolul a fost șters.', 'success');
            fetchRoluri();
          }
        } catch (error) {
          console.error('Eroare la ștergerea rolului: ', error);
          Swal('Atenționare', 'Ceva nu a mers bine la ștergerea rolului.', 'warning');
        }
      } else {
        Swal('Anulat', 'Rolul este în siguranță!', 'info');
      }
    });
  };

  const handleEditareRol = async () => {
    if (rolDeEditat) {
      try {
        let apiUrl = `${process.env.REACT_APP_API_URL}updateUserRole/${rolDeEditat.id}?name=${numeRolEditare}`;
  
        if (drepturiSelectatePentruEditare.length > 0) {
          apiUrl += `&user_right_ids[]=${drepturiSelectatePentruEditare.join('&user_right_ids[]=')}`;
        }
  
        const response = await fetch(apiUrl, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (data.status === 200) {
          Swal('Succes', 'Rol actualizat cu succes!', 'success');
          fetchRoluri();
          setRolDeEditat(null);
          setIsEditModalOpen(false);
        } else {
          Swal('Atenționare', data.message, 'warning');
        }
      } catch (error) {
        console.error('Eroare la editarea rolului: ', error);
        Swal('Atenționare', 'Ceva nu a mers bine la editarea rolului.', 'warning');
      }
    }
  };
  

  const fetchRoleDetails = async (roleId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}userRole/${roleId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return data.data.userRole;
    } catch (error) {
      console.error('Eroare la preluarea detaliilor rolului: ', error);
      return null;
    }
  };

  

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setRolDeEditat(null);
    setNumeRol('');
    setDrepturiSelectatePentruEditare([]);  
  };
  
  const handleOpenEditModal = async (rol) => {
    const detailedRole = await fetchRoleDetails(rol.id);
    if (detailedRole) {
      setIsEditModalOpen(true);
      setRolDeEditat(detailedRole);
      setNumeRolEditare(detailedRole.name);  
      setDrepturiSelectatePentruEditare(
        detailedRole.rights && Array.isArray(detailedRole.rights)
          ? detailedRole.rights.map((right) => right.id)
          : []
      );
    }
  };
   


  
  const options = drepturi.map((drept) => ({ value: drept.id, label: drept.name, checked: drepturiSelectate.includes(drept.id) }));

  const [drepturiGrupate, setDrepturiGrupate] = useState({});

  useEffect(() => {
    const grupate = drepturi
      .reduce((acc, drept) => {
        // Grupați drepturile pe categorii
        if (!acc[drept.category]) {
          acc[drept.category] = { order: drept.category_order, rights: [] };
        }
        acc[drept.category].rights.push(drept);
        return acc;
      }, {});
  
    // Sortează drepturile în cadrul fiecărei categorii
    for (let category in grupate) {
      grupate[category].rights.sort((a, b) => a.right_order - b.right_order);
    }
  
    // Sortează categoriile în întregime pe baza order
    const sortedCategories = Object.keys(grupate)
      .sort((a, b) => grupate[a].order - grupate[b].order)
      .reduce((obj, key) => {
        obj[key] = grupate[key].rights;
        return obj;
      }, {});
  
    setDrepturiGrupate(sortedCategories);
  }, [drepturi]);
  
 
useEffect(() => {
  // Actualizează accesul complet bazat pe dreptul 'AccesLaToataAplicatia'
  const dreptAcces = drepturi.find(d => d.right === 'AccesLaToataAplicatia');
  if(dreptAcces) {
    setAccesComplet(drepturiSelectate.includes(dreptAcces.id));
  }
}, [drepturi, drepturiSelectate]);


const getVizualizareId = (editareId) => {
  // Aici trebuie să fie implementată logica specifică aplicației tale
  // De exemplu, dacă relația este simplă cum am menționat mai sus:
  return editareId - 1;
};

//mapare drepturi cu relatii intre el daca am editare de ex am si vizualizare automat
const editareLaVizualizareMap = {
  'EditareGrupuriUtilizatori': 'VizualizareGrupuriUtilizatori',
  'EditareUtilizatori': 'VizualizareUtilizatori',
  'EditareRoluri': 'VizualizareRoluri',
  'EditareGrupuriDeDosare': 'VizualizareGrupuriDeDosare', 
  'DreptDeModificareDosare': 'VizualizareDosare', 
};

// helper care returnează id-ul dreptului de vizualizare pentru un drept de editare dat
const getVizualizareIdPentruEditare = (editareRight) => {
  const vizualizareRight = editareLaVizualizareMap[editareRight];
  const dreptVizualizare = drepturi.find(d => d.right === vizualizareRight);
  return dreptVizualizare ? dreptVizualizare.id : null;
};
 

useEffect(() => {
  if (isEditModalOpen && rolDeEditat) {
    const areAccesComplet = rolDeEditat.rights.some(right => right.right === 'AccesLaToataAplicatia');
    setAccesComplet(areAccesComplet);
  }
}, [isEditModalOpen, rolDeEditat]);

 

  return (
    <>
     {/*Dialog gestionare roluri*/}
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
        <DialogTitle className='titluModal'>
          Gestionează Roluri Utilizator
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="Închide" sx={{   position: 'absolute',   top: '13px',   right: '20px', }}> <CloseIcon /></IconButton>
          {areDrept(AdaugareRoluri) ? ( 
            <Button startIcon={<AddIcon/>} disabled={!areDrept(AdaugareRoluri)} onClick={handleOpenAddModal} color="secondary" variant='contained' size='small' sx={{   position: 'absolute',   top: '17px',   right: '80px', }}>Adaugă Rol Nou</Button>
          ) : null } 
        </DialogTitle>
        <DialogContent> 
          <TableContainer component={Paper} className="mt-4 mb-3">
            <Table id="roluriUtilizatori">
              <TableHead>
                <TableRow>  
                  <TableCell className='headerTabel'>Denumire rol utilizator</TableCell>
                  {areDrept(EditareRoluri) ? ( 
                    <TableCell className='text-center headerTabel'>Acțiuni</TableCell>
                   ) : null } 
                </TableRow>
              </TableHead>
              <TableBody>
              {roluri.map((rol) => {
                console.log("Rol curent:", rol);  
                return (
                  <TableRow key={rol.id}> 
                    <TableCell >{rol.name}</TableCell>
                      <TableCell className='text-center'>
                        {areDrept(EditareRoluri) ? ( 
                          <IconButton color="primary" disabled={!areDrept(EditareRoluri)} onClick={() => handleOpenEditModal(rol)}>
                            <EditIcon />
                          </IconButton>
                        ) : null } 
                        {areDrept(StergereRoluri) ? ( 
                          <IconButton color="error" disabled={!areDrept(StergereRoluri)} onClick={() => handleStergereRol(rol.id)}>
                            <DeleteIcon />
                          </IconButton>
                        ) : null } 
                      </TableCell>
                  </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Renunțare</Button>
        </DialogActions>
      </Dialog>

      {/*Dialog adaugare*/}
      <Dialog open={isAddModalOpen} onClose={handleCloseAddModal} maxWidth="md" fullWidth>
        <DialogTitle className='titluModal'>
          Adaugare rol nou
          <IconButton edge="end" color="inherit" onClick={handleCloseAddModal} aria-label="Închide" sx={{   position: 'absolute',   top: '8px',   right: '20px', }}     > <CloseIcon />     </IconButton>
        </DialogTitle>
        <DialogContent> 
          <TextField  className='mt-3'
            label="Denumire rol"
            value={numeRol}
            onChange={(e) => setNumeRol(e.target.value)}
            fullWidth
          />



<div className="checkbox-grid drepturi mt-3">
  {Object.entries(drepturiGrupate).map(([category, rights]) => {
    // Verificăm dacă categoria curentă conține dreptul 'AccesLaToataAplicatia'
    const areDreptAccesComplet = rights.some(drept => drept.right === 'AccesLaToataAplicatia');

    return (
      <div key={category} style={{ display: areDreptAccesComplet || !accesComplet ? 'block' : 'none' }}>
        <Typography variant="h6">{category}</Typography>
        {rights.map((drept) => (
          <FormControlLabel
            className={drepturiSelectate.includes(drept.id) ? '' : 'switch-label-off'}
            key={drept.id}
            control={
              <Switch
                checked={drepturiSelectate.includes(drept.id)}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  const value = drept.id;
                  let newDrepturiSelectate = [...drepturiSelectate];
                
                  if (drept.right === 'AccesLaToataAplicatia') {
                    setAccesComplet(isChecked);
                    newDrepturiSelectate = isChecked ? [value] : [];
                  } else {
                    if (isChecked) {
                      newDrepturiSelectate = [...newDrepturiSelectate, value];
                
                      const vizualizareId = getVizualizareIdPentruEditare(drept.right);
                      if (vizualizareId && !newDrepturiSelectate.includes(vizualizareId)) {
                        newDrepturiSelectate.push(vizualizareId);
                      }
                    } else {
                      newDrepturiSelectate = newDrepturiSelectate.filter(id => id !== value);
                    }
                  }
                
                  setDrepturiSelectate(newDrepturiSelectate);
                }}
                
                value={drept.id}
              />
            }
            label={drept.name}
          />
        ))}
      </div>
    );
  })}
</div>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={() => adaugaRol()} disabled={!numeRol} color="success" size='small'>Adaugare rol nou</Button>
          <Button onClick={handleCloseAddModal} color="error">Anulare</Button>
        </DialogActions>
      </Dialog>

      {/*Dialog editare*/}
      <Dialog open={isEditModalOpen} onClose={handleCloseEditModal} maxWidth="md" fullWidth>
        <DialogTitle className='titluModal'>
          Editare Rol
          <IconButton edge="end" color="inherit" onClick={handleCloseEditModal} aria-label="Închide" sx={{   position: 'absolute',   top: '8px',   right: '20px', }}     > <CloseIcon />     </IconButton>
        </DialogTitle>
        <DialogContent> 
            <TextField
            className='mt-4'
            label="Nume Rol"
            value={numeRolEditare}
            onChange={(e) => setNumeRolEditare(e.target.value)}
            fullWidth
            />
       
    

<div className="checkbox-grid drepturi mt-3">
  {Object.entries(drepturiGrupate).map(([category, rights]) => {
    // Verificăm dacă categoria curentă conține dreptul 'AccesLaToataAplicatia'
    const areDreptAccesComplet = rights.some(drept => drept.right === 'AccesLaToataAplicatia');

    return (
      <div key={category} style={{ display: areDreptAccesComplet || !accesComplet ? 'block' : 'none' }}>
        <Typography variant="h6">{category}</Typography>
        {rights.map((drept) => (
          <FormControlLabel
            className={drepturiSelectatePentruEditare.includes(drept.id) ? '' : 'switch-label-off'}
            key={drept.id}
            control={
              <Switch
                checked={drepturiSelectatePentruEditare.includes(drept.id)}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  const value = drept.id;
                  let newDrepturiSelectate = [...drepturiSelectatePentruEditare];
                
                  if (drept.right === 'AccesLaToataAplicatia') {
                    setAccesComplet(isChecked);
                    newDrepturiSelectate = isChecked ? [value] : [];
                  } else {
                    if (isChecked) {
                      newDrepturiSelectate = [...drepturiSelectatePentruEditare, value];
                
                      const vizualizareId = getVizualizareIdPentruEditare(drept.right);
                      if (vizualizareId && !newDrepturiSelectate.includes(vizualizareId)) {
                        newDrepturiSelectate.push(vizualizareId);
                      }
                    } else {
                      newDrepturiSelectate = newDrepturiSelectate.filter(id => id !== value);
                    }
                  }
                
                  setDrepturiSelectatePentruEditare(newDrepturiSelectate);
                }}
                
                value={drept.id}
              />
            }
            label={drept.name}
          />
        ))}
      </div>
    );
  })}
</div>
        </DialogContent>
            <DialogActions>
                <Button onClick={() => handleEditareRol()} color="success" variant='outlined' size='small'>Salvează</Button>
                <Button onClick={() => handleCloseEditModal()} color="error" variant='outlined' size='small'>Renunțare</Button>
            </DialogActions> 
      </Dialog>
    </>
  );
};

export default RoluriUtilizatori;
