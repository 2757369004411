import React, { useState, useEffect, useCallback } from 'react'; 
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'; 
import AlertDialog from '../AlertDialog';
import { DataGridPro, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarDensitySelector, roRO } from '@mui/x-data-grid-pro';
import { Autocomplete } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Alert, Tab, Tabs, CircularProgress, Grid, Button, IconButton, Tooltip } from '@mui/material';
import verificareToken from '../VerificareToken';
import Swal from 'sweetalert';
import { Switch, FormControlLabel } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ro } from 'date-fns/locale'; 
import { parse, format, parseISO } from 'date-fns';
import { LicenseInfo } from '@mui/x-license-pro';
import TextField from '@mui/material/TextField';

LicenseInfo.setLicenseKey(
  'ac62ac97b94addeb6dbf2b2473e35ecbTz05MjE3MSxFPTE3NDk2MjQ2NjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const Sintagme = () => {
  //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
  window.dispatchEvent(new Event('authChange'));
    const { token } = verificareToken();
    
    const formatDate = (dateString) => {
      if (!dateString) return '';
      const date = new Date(dateString);
      return date.toISOString().split('T')[0];
    };
  
    const today = new Date().toISOString().split('T')[0];
 
    const maxDate = new Date();
  const [sintagme, setSintagme] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [currentSintagma, setCurrentSintagma] = useState(null);
  const [sintagmaInput, setSintagmaInput] = useState('');
  //const iduser = sessionStorage.getItem('iduser');
  //const currentDate = new Date();
  //const dataactualizare = currentDate.toISOString().slice(0, 19).replace('T', ' ');
  const [isLoading, setIsLoading] = useState(false); 

  const [dataActualizare, setDataActualizare] = useState(currentSintagma?.dataactualizare || today);

  const dataForDatePickerInainte = formatDate(dataActualizare); 
  const dataForDatePicker = parse(dataForDatePickerInainte, 'yyyy-MM-dd', new Date());
  
  const dataParte = dataActualizare.split(" ")[0];
  const dataForDatePicker2 = parse(dataParte, 'yyyy-MM-dd', new Date());
 
  const [isDescriereValid, setIsDescriereValid] = useState(true);
  const [isMonitored, setIsMonitored] = useState(false);
  const [dosareActualizate, setDosareActualizate] = useState([]);
  const [dataSelectata, setDataSelectata] = useState(new Date().toISOString().slice(0, 10));
  //const [selectedDosare, setSelectedDosare] = useState([]); 


  //const [selectedRow, setSelectedRow] = React.useState(null);
  const [selectedRows, setSelectedRows] = React.useState([]);
  //const [selectedRows2, setSelectedRows2] = React.useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  
  useEffect(() => {
    const fetchUsers = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}users`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      const users = data.data.users;
      setUsers(data.data.users);
      const currentUserID = sessionStorage.getItem('iduser') || localStorage.getItem('iduser');
      const currentUser = users.find(user => user.id.toString() === currentUserID);
      setSelectedUser(currentUser);
    };
  
    fetchUsers();
  }, [token]);
  

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };  

const handleSelectionModelChange = (newSelectionModel, columnVisibility) => {
  setSelectedRows(newSelectionModel);

  //const selectedIDs = new Set(newSelectionModel.map(String));
/*
  const selectedRowsData = dosareActualizate.filter((row) =>
    selectedIDs.has(String(row.id))
  ).map(row => {
    const visibleData = {};
    for (const key in row) {
      if (columnVisibility[key] !== false) { // daca coloana nu e setata invizibila
        visibleData[key] = row[key];
      }
    }
    return visibleData;
  });
  */
  
  //setSelectedRows2(selectedRowsData);

  //console.log("ce e selectat:", newSelectionModel);
  //console.log("datele rândurilor selectate:", selectedRowsData);
};

const handleDescriereChange = (e) => {
  const value = e.target.value;
  setSintagmaInput(value);
  setIsDescriereValid(value.length >= 5 || value.length === 0); 
};

  //preiau dosarele actualizate din data curenta sau alta data
  const fetchDataDosareActualizate = useCallback(async (selectedDate) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}dosareActualizate?data=${selectedDate}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const responseData = await response.json();
      setDosareActualizate(responseData.data.dosareActualizate);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [token]);
  
  
  
  useEffect(() => {
    if (value !== 1) {
      setLoading(false); 
      return; 
    }
    else {
      fetchDataDosareActualizate(dataSelectata);
    }
  }, [dataSelectata, value, fetchDataDosareActualizate]); 

  //sterg dosarul selectat  sau selectate

  const handleDeleteSelected = () => {
    Swal({
      title: 'Sigur doriți ștergerea?',
      text: 'Această acțiune nu poate fi anulată!',
      icon: 'warning',
      buttons: ['Anulează', 'Da, șterge!'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDeleteSelectedOk();
      }
    });
  };


  const handleDeleteSelectedOk = async () => {
    // Presupunând că selectedRowsData este un array de ID-uri
    const selectedIds = selectedRows.join('&id_dosar_actualizat[]=');
  
    if (selectedIds.length === 0) {
      console.error("Niciun dosar selectat pentru ștergere");
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}dosareActualizate?id_dosar_actualizat[]=${selectedIds}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        // Reîncarcă datele după ștergere
        fetchDataDosareActualizate(dataSelectata);
      } else {
        // Tratează cazurile în care răspunsul nu este OK (ex: eroare de server)
        console.error('Eroare la ștergerea dosarelor');
      }
    } catch (err) {
      console.error(err);
    }
  };
  
  

  // Preluare sintagme existente
  const fetchSintagme = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}sintagme`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setSintagme(data.data.sintagme);
    } catch (err) {
      console.error(err);
    }
  }, [token]); 

  
  
  useEffect(() => {
    fetchSintagme();
  }, [fetchSintagme]);


  const handleStareToggle = async (id, currentValue, descriere) => {
    const newValue = currentValue === 1 ? 0 : 1;
    const action = newValue === 1 ? 'activați' : 'dezactivați';
  
    Swal({
      title: `Sunteți sigur că doriți să ${action} această sintagmă?`,
      text: `Sintagma "${descriere}" ${newValue === 0 ? 'nu va mai putea fi folosită pentru import automat' : ''}.`,
      icon: 'warning',
      buttons: ['Anulează', 'Da, sunt sigur!'],
      dangerMode: true,
    }).then(async (willChange) => {
      if (willChange) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}sintagme/${id}?activ=${newValue}`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
  
          if (response.ok) {
            fetchSintagme();
            Swal('Succes!', `Sintagma "${descriere}" a fost ${newValue === 1 ? 'activată' : 'dezactivată'}!`, 'success');
          } else {
            console.error('Eroare la actualizarea stării');
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    });
  };
  

  const getRowClassName = (params) => {
    return params.row.activ === 0 ? 'row-inactive' : '';
  };
  

  const formatDateRomanian = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');  
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };
  
  const columns = [ 
    { field: 'descriere', headerName: 'Sintagmă', width: 200 },
    {
      field: 'iduser',
      headerName: 'Utilizator', 
      className: 'text-center',
      flex: 1,
      renderCell: (params) => {
        const user = users.find(user => user.id === params.value);
        return user ? `${user.fullname} (${user.name})` : 'Utilizator necunoscut';
      }
    },
    {
      field: 'dataactualizare',
      headerName: 'Dată actualizare', 
      className: 'text-center',
      flex: 1,
      renderCell: (params) => formatDateRomanian(params.value),
    }, 
    {
      field: 'monitorizat',
      headerName: 'Monitorizată și sincronizată',
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <Switch
          checked={toBoolean(params.value)}
          onChange={(e) => handleMonitorToggle(params.id, !e.target.checked)}
          color="primary"
        />
      ),
    },
    
    {
      className: 'text-center',
      field: 'actions',
      headerName: 'Operațiuni', 
      renderCell: (params) => {
        const handleEdit = () => {
          setCurrentSintagma(params.row);
          setSintagmaInput(params.row.descriere);
          setDataActualizare(params.row.dataactualizare); 
          setIsMonitored(params.row.monitorizat);
          setOpenEditModal(true);
        };
        const handleDelete = () => { 
          handleDeleteConfirm(params.id, params.row.descriere);
        };
        return (
          <>
            <Tooltip title="Editare sintagmă">
              <IconButton onClick={handleEdit} color="secondary" size='small'>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ștergere sintagmă">
              <IconButton onClick={handleDelete} color="error" size='small'>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: 'activ',
      headerName: 'Stare',
      width: 150,
      align: "center",
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
           <Switch
            checked={params.value === 1}
            onChange={() => handleStareToggle(params.id, params.value, params.row.descriere)}
            color="primary"
          />
          <span style={{ marginLeft: '8px', color: params.value === 1 ? 'green' : 'red' }}>
            {params.value === 1 ? 'Activă' : 'Inactivă'}
          </span>
        </Box>
      ),
    },
  ];

  const handleMonitorToggle = async (id, monitorizat) => {
    const newValue = monitorizat ? '0' : '1';
    
    const response = await fetch(`${process.env.REACT_APP_API_URL}sintagme/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ monitorizat: newValue }),
    });
  
    if (response.ok) {
      Swal({
        title: "Succes!",
        text: 'Sintagma a fost actualizată cu succes',
        icon: "success",
        button: "OK",
      });
      fetchSintagme();
    } else {
      console.error('Eroare la actualizarea monitorizării');
    }
  };

  
  // Creare sintagme noi
  const handleCreate = async () => {
    const iduser = selectedUser ? selectedUser.id : null;
    const descriere = sintagmaInput;
    const dataActualizareFormatted = format(parseISO(dataActualizare), 'yyyy-MM-dd'); 
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}sintagme`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          iduser,
          descriere,
          dataactualizare: dataActualizareFormatted,
          monitorizat: isMonitored ? '1' : '0',
        }),
      });
  
      if (response.ok) {
        setOpenCreateModal(false);
        fetchSintagme();
      } else {
        const errorResponse = await response.json(); 
        if (response.status === 422) {
          Swal({
            title: "Operațiune nerealizată!",
            text: errorResponse.message.descriere[0],
            icon: "warning",
            button: "OK",
          });
        } else {
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  

  // Editare sintame existente
  const handleUpdate = async () => {
    
  const descriere = sintagmaInput;
  const dataActualizareFormatted = format(parseISO(dataActualizare), 'yyyy-MM-dd');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}sintagme/${currentSintagma}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          descriere,
          dataactualizare: dataActualizareFormatted,
          monitorizat: isMonitored ? '1' : '0',
        }),
      });
      if (response.ok) {
        //const responseData = await response.json();
        //const updatedSintagma = responseData.data.sintagma; // scot sintagma actualizata din raspuns
        fetchSintagme();
        setOpenEditModal(false);
      } else {
        const errorResponse = await response.json(); 
        if (response.status === 422) {
          Swal({
            title: "Operațiune nerealizată!",
            text: errorResponse.message.descriere[0],
            icon: "warning",
            button: "OK",
          });
        } else {
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  
  // Stergere sintagme
  const handleDeleteConfirm = (id, descriere) => {
    setCurrentSintagma({ id, descriere });
    Swal({
      title: 'Sigur doriți ștergerea?',
      text: 'Această acțiune nu poate fi anulată!',
      icon: 'warning',
      buttons: ['Anulează', 'Da, șterge!'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDelete(id);
      }
    });
  };
  
  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}sintagme/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        fetchSintagme();
        setOpenDeleteAlert(false);
        setCurrentSintagma(null);
      } else {
        console.error('Error deleting sintagma');
      }
    } catch (error) {
      console.error('Error:', error);
    }

  };

 
//<a href={`/editare-dosar/${params.row.idDosar}`} target="_blank" rel="noopener noreferrer">
// {params.value}
//</a>
     
  const columnsDosareActualizate = [
    {
      field: 'numarDosar',
      headerName: 'Număr Dosar',
      width: 150,
      renderCell: (params) => (
        params.value
      ),
    },
    {
      field: 'dataDosar',
      className: 'text-center',
      headerName: 'Dată dosar',
      width: 150,
      renderCell: (params) => {
        if (params.value) {
          return params.value.replace(/-/g, '.');
        }
        return '';  
      }
    },
    { field: 'instanta', className: 'text-center', headerName: 'Instanță', width: 300 },
    { field: 'sintagma', className: 'text-center', headerName: 'Sintagmă', width: 150 },
    { field: 'utilizator', className: 'text-center', headerName: 'Utilizator', width: 100 },
  ];

 
  const [activityLog, setActivityLog] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    
    if (value !== 2)  {
    setLoading(false); 
    return; 
  }
  else {
    const fetchActivityLog = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}activityLog?tip=sintagma`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Error fetching activity log');
        }

        const data = await response.json();
        if (data && data.data && data.data.activities) {
          setActivityLog(data.data.activities);
        }

        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchActivityLog();
  }
  }, [value, token]); 



  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh' }}>
        <CircularProgress />
      </div>
    );
  }
  function toBoolean(value) {
    return value === 1;
  }
 
  const columnsIstoric = [
  
    { field: 'description', headerName: 'Operație', flex: 1, wordWrap: true },
    { field: 'actiune',  valueGetter: (params) => params.row.properties?.actiune || '', headerName: 'Descriere', flex: 1, wordWrap: true },
    //{ field: 'subject_type', headerName: 'Subject Type' }, 
      {
      field: 'event',
      headerName: 'Eveniment',
      flex: 1,
      wordWrap: true,
      renderCell: (params) => {
        if (params.value === 'created') {
          return 'Creare';
        } else if (params.value === 'updated') {
          return 'Editare';
        } else if (params.value === 'deleted') {
          return 'Ștergere';
        } else {
          return params.value;   
        }
      }
    },
   
    {
      field: 'created_at',
      headerName: 'Dată',
      flex: 1,
      wordWrap: true,
      renderCell: (params) => {
        const date = new Date(params.value);
        return format(date, 'dd.MM.yyyy');
      }
    },
    {
      field: 'time',
      headerName: 'Oră',
      flex: 1,
      valueGetter: (params) => {
        const date = new Date(params.row.created_at);
        return format(date, 'HH:mm:ss');
      }
    },
    //{ field: 'updated_at', headerName: 'Updated At' },
  ];
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  const onRowClickHandler = (params, event) => {
    setCurrentSintagma(params.row.id);  
  };
 
 
  return (
  
    <>
      <Box sx={{ display: 'flex', height: '100%' }}> 
          <Tabs
            orientation="vertical"
            variant="scrollable"  
            aria-label="Sintagme"
            value={value}
            onChange={handleChange}
            sx={{
              borderRight: 1,
              borderColor: 'divider', 
              top: '10px',  
            }}
          >  
          <Tab key={0}  label="Sintagme" value={0} />
          <Tab key={1}  label="Raport import" value={1} />
          <Tab key={2}  label="Istoric setări sintagme" value={2} />
          </Tabs>
              <TabPanel value={value} index={0}>
                <Box component="div">
                <div style={{ textAlign: 'right', marginBottom:'10px' }}>
                    <Button
                      size='small'
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setSintagmaInput('');
                        setOpenCreateModal(true);
                      }}
                    >
                      Adăugare sintagmă nouă
                    </Button>
                  </div>

                          <DataGridPro 
                            rows={sintagme}
                            getRowId={(row) => row.id}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            onCellClick={onRowClickHandler}
                            getRowClassName={getRowClassName}
                            components={{
                              Toolbar: GridToolbarContainer,
                            }}
                            componentsProps={{
                              toolbar: {
                                densitySelectorComponent: GridToolbarExport,
                              },
                            }}
                            localeText={roRO.components.MuiDataGrid.defaultProps.localeText}
                            initialState={{
                              columns: {
                                columnVisibilityModel: {
                                  id: false, 
                                },
                              },
                            }}

                          />
                        {/* modal creare sintagma */}
                        <Dialog open={openCreateModal} >
                          <DialogTitle className='titluModal'>
                            Creare sintagmă nouă
                            <IconButton
                              aria-label="close"
                              onClick={() => setOpenCreateModal(false)}
                              sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <CloseIcon style={{ color: 'white' }} />
                            </IconButton>
                          </DialogTitle>
                          <DialogContent>
                          <TextField
                            autoFocus
                            margin="dense"
                            label="Denumire"
                            value={sintagmaInput}
                            onChange={handleDescriereChange}
                            fullWidth
                            error={!isDescriereValid}
                            helperText={!isDescriereValid && "Sintagma trebuie să aibă minim 5 caractere"}
                            className='mt-3 mb-3'
                          />
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro} >
                              <DatePicker
                                label="Data actualizare"
                                value={dataForDatePicker}
                                onChange={(newValue) => {
                                  const formattedDate = format(newValue, 'yyyy-MM-dd');
                                  setDataActualizare(formattedDate);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                inputFormat="dd.MM.yyyy" 
                                format="dd.MM.yyyy" 
                                mask="__.__.____"
                                InputLabelProps={{
                                  shrink: true, 
                                }}
                                maxDate={maxDate} 
                                className='maxWidth mb-3'
                              />
                              </LocalizationProvider>

                            <Autocomplete
                            options={users || []}
                            getOptionLabel={(option) => `${option.fullname} (${option.name})`} 
                            value={selectedUser}
                            onChange={(event, newValue) => {
                              setSelectedUser(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} label="Selectați un utilizator" />}
                          />


                            <FormControlLabel
                              control={<Switch checked={isMonitored} onChange={(e) => setIsMonitored(e.target.checked)} />}
                              label="Monitorizați dosarele importate după sintagma"
                            />
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={() => setOpenCreateModal(false)} color="error" size='small'>
                              Anulare
                            </Button>
                            <Button onClick={handleCreate} color="primary" size='small' disabled={!isDescriereValid || sintagmaInput.trim().length === 0}>
                              Salvare
                            </Button>
                          </DialogActions>
                        </Dialog>

                        {/* modal editare sintagma */}
                        <Dialog open={openEditModal} onClose={() => setOpenEditModal(false)}>
                          <DialogTitle className='titluModal'>
                            Editare Sintagmă
                            <IconButton
                              aria-label="close"
                              onClick={() => setOpenEditModal(false)}
                              sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                              }}
                            >
                              <CloseIcon style={{ color: 'white' }} />
                            </IconButton>
                          </DialogTitle>
                          <DialogContent>
                          <TextField
                              autoFocus
                              margin="dense"
                              label="Denumire"
                              value={sintagmaInput}
                              onChange={handleDescriereChange}
                              fullWidth
                              error={!isDescriereValid} 
                              helperText={!isDescriereValid && "Sintagma trebuie să aibă minim 5 caractere"} 
                              className='mt-3'
                            /> 
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro} >
                              <DatePicker
                                label="Data actualizare"
                                value={dataForDatePicker2}
                                onChange={(newValue) => {
                                  const formattedDate = format(newValue, 'yyyy-MM-dd');
                                  setDataActualizare(formattedDate);
                                }}
                                renderInput={(params) => <TextField {...params}  />}
                                inputFormat="dd.MM.yyyy" 
                                format="dd.MM.yyyy" 
                                mask="__.__.____"
                                InputLabelProps={{
                                  shrink: true, 
                                }}
                                maxDate={maxDate} 
                                className='maxWidth mb-3 mt-3'
                              />
                              </LocalizationProvider>

                            <FormControlLabel
                              control={<Switch checked={isMonitored} onChange={(e) => setIsMonitored(e.target.checked)} />}
                              label="Monitorizați dosarele importate după sintagma"
                            />

                          </DialogContent>
                          <DialogActions>
                            <Button onClick={() => setOpenEditModal(false)} color="error" size='small'>
                              Anulare
                            </Button>
                            <Button onClick={handleUpdate} color="primary" size='small' disabled={!isDescriereValid || !sintagmaInput}>
                              Salvare
                            </Button>
                          </DialogActions>
                        </Dialog>
                    </Box>
              </TabPanel>
              
              <TabPanel value={value} index={1}>
                <Box component="div">
                <Grid container spacing={2} alignItems="center"  justifyContent="center" className='mb-2'>
                <Grid item xs={8}>
                    <Grid>
                      <span style={{ lineHeight: '36px', marginRight:'10px' }}>Selectați data la care s-a efectuat importul automat:</span>
                      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
                        <DatePicker
                          label="Selectați data"
                          value={dataSelectata ? parseISO(dataSelectata) : null}
                          onChange={(newValue) => {
                            setDataSelectata(format(newValue, 'yyyy-MM-dd'));  
                          }}
                          renderInput={(params) => <TextField {...params} size='small' />}
                          inputFormat="dd.MM.yyyy"
                          className='inputMic'
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleDeleteSelected}
                        disabled={selectedRows.length === 0}
                      >
                        Șterge Dosarele Selectate
                      </Button>
                    </Grid>
                  </Grid>

                    <DataGridPro   
                      rows={dosareActualizate}
                      columns={columnsDosareActualizate}
                      getRowId={(row) => row.id}
                      loading={isLoading}
                            
                          pageSize={10}
                          rowsPerPageOptions={[10, 20, 50, 100]} 
                          pagination
                          paginationMode="client"
                          initialState={{
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                      
                      components={{
                        Toolbar: CustomToolbar
                      }} 
                    localeText={{ 
                      ...roRO.components.MuiDataGrid.defaultProps.localeText, 
                      headerFilterOperatorContains: 'Filtrare rapidă',
                      filterPanelRemoveAll: 'Resetare filtre',
                      toolbarExport: 'Export',
                      toolbarExportLabel: 'Export',
                      toolbarExportCSV: 'În format CSV',
                      toolbarExportPrint: 'Imprimare',
                      toolbarExportExcel: 'În format Excel',
                    }}
                    checkboxSelection  
                  checkboxSelectionVisibleOnly={false}
                  disableSelectionOnClick={true}
                  onSelectionModelChange={(ids) => {
                    const selectedIDs = new Set(ids);
                    const selectedRows = dosareActualizate.rows.filter((row) =>
                      selectedIDs.has(row.id),
                    ); 
                    setSelectedRows(selectedRows); 
                    
                    //console.log('dosare selectate:', selectedRows);
                  }}
                  
                  onRowSelectionModelChange  ={handleSelectionModelChange} 
                    />
                  </Box>
                </TabPanel>
                {/* modal stergere sintagma */}
                <AlertDialog
                  open={openDeleteAlert}
                  setOpen={setOpenDeleteAlert}
                  title="Ștergere sintagmă"
                  message={`Sunteți sigur că doriți ștergerea sintagmei: ${currentSintagma?.descriere}?`}
                  onConfirm={() => handleDelete(currentSintagma?.id)}
                />


            <TabPanel value={value} index={2}>
            <DataGridPro
                    rows={activityLog}
                    columns={columnsIstoric}
                    pageSize={10}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    pagination
                    paginationMode="client"
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    autoHeight
                    getRowHeight={() => 'auto'}
                    
                    components={{
                      Toolbar: CustomToolbar
                    }} 
                  localeText={{ 
                    ...roRO.components.MuiDataGrid.defaultProps.localeText, 
                    headerFilterOperatorContains: 'Filtrare rapidă',
                    filterPanelRemoveAll: 'Resetare filtre',
                    toolbarExport: 'Export',
                    toolbarExportLabel: 'Export',
                    toolbarExportCSV: 'În format CSV',
                    toolbarExportPrint: 'Imprimare',
                    toolbarExportExcel: 'În format Excel',
                  }}
                  />
          </TabPanel>
            {/*
            <pre style={{ fontSize: 10 }}>
              {JSON.stringify(selectedRows, null, 4)}
            </pre>
            */}
            
      </Box>
      <Box>
        <Alert severity="warning" className='mt-3' style={{fontSize:'11px'}} >
          <b>Atenție!</b> Importul automat al dosarelor se face în funcție de denumirile corecte ale părților. În situația în care entitatea care este introdusă în secțiunea import automat figurează pe portal just.ro și iccj.ro și cu alte tipuri de denumiri, cum ar fi: abrevieri, denumiri inexacte și incomplete, utilizatorul are obligația să le introducă în secțiunea import automat sau să le comunice prestatorului aplicației (CTCE) pentru a fi luate în considerare la efectuarea importului automat, conform prevederilor contractuale.
        </Alert>
      </Box>
    </>
    
  );

};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <>
    <div
    
    role="tabpanel"
    hidden={value !== index}
    id={`vertical-tabpanel-${index}`}
    aria-labelledby={`vertical-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box sx={{ p: 3 }}>
        {children}
      </Box>
    )}
  </div>

 </>
  );
 
}

export default Sintagme;