import React, { useState, useEffect, useRef } from 'react'; 
import verificareToken from './VerificareToken';
import { DataGridPro, useGridApiRef, GridCsvExportMenuItem, GridToolbar, GridToolbarContainer, GridToolbarExport, GridToolbarExportContainer, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton, useGridSlotComponentProps, roRO, getGridStringOperators,   GridColDef,   GridFilterOperator   } from '@mui/x-data-grid-pro'; 
import { LicenseInfo } from '@mui/x-license-pro'; 
import { RadioGroup, CircularProgress, Button, FormControlLabel, Tooltip, Radio, Select, MenuItem, TextField, FormControl, InputLabel, Typography, Alert, IconButton } from '@mui/material'; 
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import Swal from 'sweetalert';
import DrepturiUtilizator from './utilizator/drepturiUtilizator'; 
import HelpDialog from './HelpDialog';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ro } from 'date-fns/locale';
import { ExportInExcel } from './ExportInExcel';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

import RapoarteEvenimente from './RapoarteEvenimente'; 

import { callAddFont } from './fonts/DejaVuSans-normal';


import { PieChart } from '@mui/x-charts/PieChart';
import { CircleLoader } from 'react-spinners';

LicenseInfo.setLicenseKey('ac62ac97b94addeb6dbf2b2473e35ecbTz05MjE3MSxFPTE3NDk2MjQ2NjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

function Rapoarte() { 
 
  //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
  window.dispatchEvent(new Event('authChange'));
  
  const { areDrept } = DrepturiUtilizator();
     //aduc hook-ul de drepturi
     const [seVerificaDrepturile, setSeVerificaDrepturile] = useState(true);
     const RaportParti = 'RaportParti';
     const RaportActivitate = 'RaportActivitate';
     const RaportEvenimente = 'RaportEvenimente';
     const RaportPivot = 'RaportPivot'
     const [reportType, setReportType] = useState('raportClient');
     const [hasRights, setHasRights] = useState(true);
     useEffect(() => {
      const checkDrepturi = async () => {
        let hasRights = true;  
    
        if (areDrept(RaportParti)) {
          setReportType('raportClient');
          hasRights = true;
        } else if (areDrept(RaportActivitate)) {
          setReportType('raportActivitate');
          hasRights = true;
        } else if (areDrept(RaportEvenimente)) {
          setReportType('raportEveniment');
          hasRights = true;
        } else if (areDrept(RaportPivot)) {
          window.location.href = '/raport-pivot';
          setReportType('raportPivot');
          hasRights = true;
        } else {
          setReportType('raportClient');
        }
    
        setSeVerificaDrepturile(true);  
        setHasRights(hasRights);  
      };
    
      checkDrepturi();
    }, [seVerificaDrepturile]);
    
     
  function getDenAppVers() {
    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
      return null; 
    }
  
    let denAppVers = getCookie('DenAppVers');
  
    if (!denAppVers) {
      denAppVers = localStorage.getItem('DenAppVers');
    }
  
    if (!denAppVers) {
      denAppVers = sessionStorage.getItem('DenAppVers');
    }
  
    return denAppVers || 'V2';  
  }
  
  let denAppVers = getDenAppVers(); 
const [dosareFiltrate, setDosareFiltrate] = React.useState([]);
const [sortModel, setSortModel] = useState([]);
const currentDate = new Date();
const formattedDate = currentDate.toLocaleDateString('ro-RO').replace(/\//g, '-');
const formattedTime = currentDate.toLocaleTimeString('ro-RO', { hour12: false }).replace(/:/g, '-');
const formattedTime2 = currentDate.toLocaleTimeString('ro-RO', {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false  
});
  const [showHeader, setShowHeader] = useState(false);
  const [showConfidential, setShowConfidential] = useState(false); 
  const [usercurentgrup, setUsercurentgrup] = useState([]);
  const [usercurentnume, setUsercurentnume] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    client: true,
    calitateclientdosar: true,
    numardosar: true,
    numarintern: true,
    deninstanta: true,
    denumirestadiu: true,
    denumireobiect: true,
    materiedosar: true,
    staredosar: true,
    costuri: true,
    dosar_groups: true,
    consilier: true,
    user_groups: true,

    username:  true,
    nrtotaldosare: true,
    nrdosarefinalizate: true,
    nrdosarenefinalizate: true,
    piechart: true,

    client: true,
    nrtotaldosare: true,
    nrdosarefinalizate: true,
    nrdosarenefinalizate: true,

  });
  function getKeysFromLookup(obj: GridFilterState['filteredRowsLookup']) {
    const keys = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key]) {
        keys.push(key);
      }
    }
    return keys;
  }



     useEffect(() => {
      let isMounted = true; 
    
      const checkDrepturi = async () => {
        const areDrepturi = await (areDrept(RaportParti) || areDrept(RaportActivitate) || areDrept(RaportEvenimente) || areDrept(RaportPivot));
        if (isMounted) {
          if (areDrepturi) {
            setSeVerificaDrepturile(true);
          } else {
            setSeVerificaDrepturile(false);
          }
        }
      };
    
      checkDrepturi();
    
      return () => {
        isMounted = false;
      };
    }, [areDrept]);
      
      const { token } = verificareToken(); 

    
      
    const [clienti, setClienti] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    //const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1).toISOString().slice(0, 10));
    //const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), 11, 31).toISOString().slice(0, 10));
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [data, setData] = useState([]);
    const userId = localStorage.getItem('iduser');
    const [columns, setColumns] = useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [showGrid, setShowGrid] = useState(false);
    const [dateFieldName, setDateFieldName] = useState('any');

    const clientiPredefiniti = [
        { idclient: null, client: "Toate părțile" }
      ];
    const clientiCombinati = [...clientiPredefiniti, ...clienti];
    const [activityReportType, setActivityReportType] = useState("detaliat"); //setez implicit detaliat daca se selecteaza raport activitate
    //const [activityReportType, setActivityReportType] = useState(null); //setez null daca las utilizatorul sa selecteze la incarcare
   
    const isRaportDetaliat = reportType === 'raportActivitate' && activityReportType === 'detaliat';
    
    
    const handleRedirectEveniment = () => {
        window.location.href = '/raport-evenimente';
      };

    const handleRedirect = () => {
        window.location.href = '/raport-pivot';
      };
      useEffect(() => {
        const fetchUsercurent = async () => {
          try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}user`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              }
            });
            
            const {data} = await response.json();
            setUsercurentgrup(data.user.groupNames);
            setUsercurentnume(data.user.fullname);
          } catch (error) {
            console.error("Eroare la preluarea utilizatorilor", error);
          }
        };
      
        fetchUsercurent();
      }, [token]);
      
      
  const stringFilterOperators = getGridStringOperators();
  const removeDiacritics = (str) => {
    if (str) {
      if (typeof str === 'string') {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      } else {
        return str;  
      }
    } else {
      return ''; 
    }
  };
  
  const wrapFilterOperator = (operator: GridFilterOperator) => {
    const getApplyFilterFn: GridFilterOperator["getApplyFilterFn"] = (
      filterItem,
      column
    ) => {
      const innerFilterFn = operator.getApplyFilterFn(
        {
          ...filterItem,
          value: removeDiacritics(filterItem.value)
        },
        column
      );
      if (!innerFilterFn) {
        return innerFilterFn;
      }
  
      return (params) => {
        // Verificăm dacă params.value este un array
        let valueToFilter;
        if (Array.isArray(params.value)) {
          // Extragem toate valorile 'name' din array și le concatenăm într-un string
          valueToFilter = params.value.map(item => item.name).join(', ');
        } else {
          // Dacă nu e un array, îl convertim direct la string
          valueToFilter = params.value ? removeDiacritics(params.value.toString()) : '';
        }
  
        console.log('Filtrare:', filterItem.value, 'Valoare concatenată pentru filtrare:', valueToFilter);
        
        return innerFilterFn({
          ...params,
          value: valueToFilter,
        });
      };
    };
  
    return {
      ...operator,
      getApplyFilterFn
    };
  };
  
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}clienti`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        })
        .then(response => response.json())
        .then(data => setClienti(data.data.clienti))
        .catch(error => console.error('There was an error!', error));
    }, []);

    useEffect(() => {
        let apiUrl = process.env.REACT_APP_API_URL;
        
        if (reportType === 'raportClient') {
            apiUrl += `raportClient?userId=${userId}`;
            
            // Adaugă doar dacă datele nu sunt null
            if (startDate) {
                apiUrl += `&startDate=${startDate.toISOString().slice(0, 10)}`;
            }
            if (endDate) {
                apiUrl += `&endDate=${endDate.toISOString().slice(0, 10)}`;
            }
    
            if (selectedClient) {
                apiUrl += `&clientId=${selectedClient}`;
            }
    
            if (dateFieldName !== 'any') {
                apiUrl += `&dateFieldName=${dateFieldName}`;
            }
    
            setColumns(dosareColumns);         
        } else if (reportType === 'raportActivitate' && activityReportType) {
            apiUrl += activityReportType === 'detaliat' ? 'raportActivitateUser' : 'raportActivitateClient';
            setColumns(activityReportType === 'detaliat' ? usersColumns : clientiColumns);
        }
    
        if(apiUrl !== process.env.REACT_APP_API_URL) {
            setIsLoading(true);
            fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then(response => {
                if (response.status === 404) {
                    throw new Error('Not Found');
                }
                return response.json();
            })
            .then(data => {
                setShowGrid(true); 
                if(reportType === 'raportClient') {
                    setData(data.data.dosare);
                } else if(activityReportType === 'detaliat') {
                    setData(data.data.users);
                } else {
                    setData(data.data.clienti);
                }
            })
            .catch(error => {
                if (error.message === 'Not Found') {
                    setShowGrid(true);
                    setData([]);
                    /*Swal({
                        title: 'Atenționare',
                        text: 'Nu au fost găsite rezultate după selecția efectuată',
                        icon: 'warning',
                        confirmButtonText: 'Închide'
                    });*/
                } else {
                    console.error('A apărut o eroare!', error);
                }
            }) 
            .finally(() => {
                setIsLoading(false);
            });
        }
    }, [reportType, selectedClient, startDate, endDate, dateFieldName, activityReportType]);
    

    //pe clienti
    const dosareColumns = [
        { field: 'client', headerName: 'Parte', width: 330, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
        { field: 'calitateclientdosar', headerName: 'Calitate parte', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
        { field: 'numardosar', headerName: 'Numar Dosar', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
        { field: 'numarintern', headerName: 'Numar Intern', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
        { field: 'deninstanta', headerName: 'Denumire instanță', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
        { field: 'denumirestadiu', headerName: 'Stadiu', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
        { field: 'denumireobiect', headerName: 'Obiect', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
        { field: 'materiedosar', headerName: 'Materie', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
        { field: 'staredosar', headerName: 'Stare dosar', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
        {
            field: 'costuri',
            headerName: 'Costuri dosar',
            width: 130,
            renderCell: (params) => `${params.value} Lei`, 
            filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator))
        },
        { field: 'dosar_groups', headerName: 'Grupuri dosar', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
        { field: 'consilier', headerName: 'Utilizator', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
        {
            field: 'user_groups',
            headerName: 'Grupuri utilizator',
            width: 130,
            renderCell: (params) => formatUserGroups(params.row.user_groups),  
            filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator))
        }
    ];
    //detaliat
    const usersColumns = [
        { field: 'username', headerName: 'Utilizator', width: 130, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
        { field: 'nrtotaldosare', headerName: 'Total dosare', width: 130 },
        { field: 'nrdosarefinalizate', headerName: 'Dosare finalizate', width: 130 },
        { field: 'nrdosarenefinalizate', headerName: 'Dosare nefinalizate', width: 130 },
        {
            field: 'piechart',
            headerName: ' ',
            width: 450,
            renderCell: (params) => (
                <PieChart
                    series={[
                        {
                            data: [
                                { id: 0, value: params.row.nrtotaldosare, label: 'Total dosare' },
                                { id: 1, value: params.row.nrdosarefinalizate, label: 'Dosare finalizate' },
                                { id: 2, value: params.row.nrdosarenefinalizate, label: 'Dosare nefinalizate' },
                            ],
                        },
                    ]}
                    width={300}
                    height={150}
                />
            )
        }
    ];
    //pe societatea
    const clientiColumns = [
        { field: 'client', headerName: 'Client', width: 330, filterOperators: stringFilterOperators.map((operator) => wrapFilterOperator(operator)) },
        { field: 'nrtotaldosare', headerName: 'Total dosare', width: 130 },
        { field: 'nrdosarefinalizate', headerName: 'Dosare finalizate', width: 130 },
        { field: 'nrdosarenefinalizate', headerName: 'Dosare nefinalizate', width: 130 },
    ];
    const loadingIndicator = isLoading === 0;
 
    const FlexContainer = styled(Box)({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      });

 

let formattedStartDate = '';

if (startDate) {
  const startDateObj1 = new Date(startDate);
  formattedStartDate = startDateObj1.toLocaleDateString('ro-RO', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
} else {
    formattedStartDate = '';
}

let formattedEndDate = '';

if (endDate) {
  const endDateObj = new Date(endDate);
  formattedEndDate = endDateObj.toLocaleDateString('ro-RO', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
} else {
    formattedEndDate = '';
}
 
const sortNumarDosar = (a, b) => { 
  const numarA = parseInt(a.split('/')[0]);
  const numarB = parseInt(b.split('/')[0]);

  if (numarA < numarB) return -1;
  if (numarA > numarB) return 1;
  return 0;
};
const compareValues = (a, b, field, sortDirection) => {
  // Verificăm dacă este `numardosarfinal`, folosim funcția specializată
  if (field === 'numardosarfinal') {
    const result = sortNumarDosar(a[field], b[field]);
    return sortDirection === 'asc' ? result : -result;
  }

  // Pentru câmpurile care au valori `undefined` sau `null`, le punem la început
  if (a[field] === undefined || a[field] === null) return sortDirection === 'asc' ? -1 : 1;
  if (b[field] === undefined || b[field] === null) return sortDirection === 'asc' ? 1 : -1;

  // Comparare pentru string-uri
  if (typeof a[field] === 'string') {
    return sortDirection === 'asc'
      ? a[field].localeCompare(b[field])
      : b[field].localeCompare(a[field]);
  }

  // Comparare pentru numere sau date
  if (typeof a[field] === 'number' || a[field] instanceof Date) {
    return sortDirection === 'asc'
      ? a[field] - b[field]
      : b[field] - a[field];
  }

  return 0;
};

// Funcția pentru sortarea rândurilor pe baza modelului de sortare
const sortRows = (rows, sortModel) => {
if (!sortModel.length) return rows; // Dacă nu există niciun criteriu de sortare, returnează rândurile inițiale

// Extrage primul criteriu de sortare (coloană și direcție)
const { field, sort } = sortModel[0];

// Sortăm rândurile utilizând `compareValues`
return [...rows].sort((a, b) => compareValues(a, b, field, sort));
};

const handleSortModelChange = (sortModel) => {
  // Sortăm rândurile pe baza modelului de sortare
  const sortedRows = sortRows(rowsToDisplay, sortModel);

  // Actualizăm datele filtrate (acum sortate)
  setDosareFiltrate(sortedRows);

  // Stocăm modelul de sortare pentru a-l folosi în alte funcții
  setSortModel(sortModel);  // Trebuie să păstrezi modelul de sortare
};


    const CustomGridToolbarContainer = ({}) => {
        return (
          <GridToolbarContainer>
            <FlexContainer>
            <Box sx={{ display: 'flex', alignItems: 'left', mr: 1  }}>
            <GridToolbarColumnsButton /> 
            {(isRaportDetaliat) ? '' : <GridToolbarFilterButton /> }
            {(isRaportDetaliat) ? '' : <GridToolbarDensitySelector />}
            
            <GridToolbarExportContainer >
                <MenuItem onClick={handlePrintComand}>
                Imprimare tabel
                </MenuItem>

                <MenuItem onClick={handleExportPDFComand}>
                Export PDF tabel
                </MenuItem>

                
                <ExportInExcel
                    csvData={prepareDataForExport2(
                        selectedRows2.length > 0 ? selectedRows2 : dosareFinal,
                        columns,
                        columnVisibilityModel
                    )} 
                    fileName={fileNameExcel}
                    showHeader={showHeader}
                    showConfidential={showConfidential}
                    afisamSwal={true} 
                    startDate={formattedStartDate}
                    dateFilterType={dateFilterType} 
                    endDate={formattedEndDate}
                    />

                 
                <MenuItem onClick={handleExportXML}>
                     În format XML
                </MenuItem>

            </GridToolbarExportContainer>
                    

            </Box>
            </FlexContainer>
          </GridToolbarContainer>
        );
      };
      const detaliatProps = isRaportDetaliat ? { 
        getRowHeight: () => 'auto',  
    } : {};


///functii
const [filterModel, setFilterModel] = useState({
    items: [], 
  });
  const handleFilterModelChange = (newModel) => {
 
    const newItems = newModel.items.map((item) => {
 
      return item;
    });
  
    setFilterModel({ ...newModel, items: newItems });
  };
  
  
const [isExportDosareOpen, setIsExportDosareOpen] = useState(false);
const [isExportDosareExcelOpen, setIsExportDosareExcelOpen] = useState(false); 
const [isTipExport, setIsTipExport] = useState(''); 
const handleOpenExportDosare = () => {
    let text;
  
    if (selectedRows2.length > 10) {
      text = `Sunteți sigur că doriți imprimarea celor ${selectedRows2.length} dosare selectate? Acest proces poate dura mai mult timp.`;
    } else if (selectedRows2.length > 0) {
      text = `Sunteți sigur că doriți imprimarea celor ${selectedRows2.length} dosare selectate?`;
    } else {
      text = `Nu există dosare selectate! Generarea listei cu ${dosareFinal.length} dosare poate dura câteva minute. Continuați?`;
    }
  
    Swal({
      title: 'Imprimare detaliată',
      text: text,
      icon: 'warning',
      buttons: ['Anulează', 'Da, continui!'],
      dangerMode: true,
    }).then((willContinue) => {
      if (willContinue) {
        handleOpenExportDosareOK();
      }
    });
  };
  
  const handleOpenExportDosareOK = () => {
    setIsExportDosareOpen(true);
  };

  const handleCloseExportDosare = () => {
    setIsExportDosareOpen(false);
  };

  const handleExporturiGenerate = () => {
    window.location.href = "/export-dosare";
  };
const fileNameExcel = `JuristPRO NEXT - Export EXCEL - ${formattedDate} - ${formattedTime}`;
const fileNameCSV = `JuristPro NEXT - CSV Export lista dosare - ${formattedDate} - ${formattedTime}`;
const formatUserGroups = (userGroups) => {
    if (!userGroups || userGroups.length === 0) return 'Fără grup';
    return userGroups.map(group => group.name).join(', ');
  };
 



  const handleExportXML = () => {
    const visibleColumns = columns.filter((col) => columnVisibilityModel[col.field] !== false);
  
    if (visibleColumns.length === 0 || dosareFinal.length === 0) {
      console.error('Nu există date disponibile pentru a genera XML-ul.');
      return;
    }
  
    // Construim structura XML
    let xmlContent = '<?xml version="1.0" encoding="UTF-8"?>\n';
    xmlContent += '<Dosare>\n';
  
    dosareFinal.forEach(row => {
      xmlContent += '  <Dosar>\n';
      visibleColumns.forEach(col => {
        const fieldName = col.headerName.replace(/\s+/g, '_'); // înlocuim spațiile din denumirea coloanei cu '_'
        let cellValue;
  
        // Formatarea specială pentru user_groups
        if (col.field === 'user_groups') {
          cellValue = formatUserGroups(row[col.field]); // Folosește funcția de formatare
        } else {
          cellValue = row[col.field] || '';
        }
  
        xmlContent += `    <${fieldName}>${cellValue}</${fieldName}>\n`;
      });
      xmlContent += '  </Dosar>\n';
    });
  
    xmlContent += '</Dosare>';
  
    // Creăm fișierul XML și oferim descărcarea
    const blob = new Blob([xmlContent], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'export_dosare.xml';
    link.click();
  };
  
const dateFilterType = dateFieldName;
const prepareDataForExport = (data, columns, columnVisibilityModel) => {
    const columnInfoMap = columns.reduce((acc, column) => {
      if (columnVisibilityModel[column.field] !== false) {  
        acc[column.field] = column.headerName || column.field;
      }
      return acc;
    }, {});
  
    const orderedColumnFields = columns
      .filter(col => columnVisibilityModel[col.field] !== false)
      .map(col => col.field);
  
    return data.map(row => {
      const filteredRow = {};
      orderedColumnFields.forEach(key => {
        if (key !== '__check__') {
          filteredRow[columnInfoMap[key]] = row[key];
        }
      });
      return filteredRow;
    });
  };
const dataGridRef = useRef(null);

const handleExportPDFComand = () => {

  setShowHeader(false);
  setShowConfidential(false);
  let text;

  text = `${selectedRows2.length} de rânduri vor fi exportate în PDF din tabel`;

  // Creăm un container HTML pentru switch-uri
  const container = document.createElement('div');
  container.className = 'divswal'; // Adăugăm o clasă pentru stilizare

  // Inserăm HTML pentru switch-uri personalizate stilizate
  container.insertAdjacentHTML(
    'beforeend',
    `
    <div class="switch-container">
      <label for="headerSwitch">Afișare antet</label>
      <label class="switch">
        <input type="checkbox" id="headerSwitch" ${showHeader ? 'checked' : ''}>
        <span class="slider round"></span>
      </label>
    </div>
    <div class="switch-container">
      <label for="confidentialSwitch">Afișare "Confidențial"</label>
      <label class="switch">
        <input type="checkbox" id="confidentialSwitch" ${showConfidential ? 'checked' : ''}>
        <span class="slider round"></span>
      </label>
    </div>
    `
  );

  // Afișăm modalul Swal cu elementul creat
  Swal({
    title: 'Export PDF tabel',
    text: text,
    content: container,
    icon: 'warning',
    buttons: ['Anulează', 'Da, continui!'],
    dangerMode: true,
  }).then((willContinue) => {
    if (willContinue) {
      const headerSwitch = document.getElementById('headerSwitch');
      const confidentialSwitch = document.getElementById('confidentialSwitch'); 

      handleExportPDF(headerSwitch.checked, confidentialSwitch.checked);
    }
  });
};

const handleExportPDF = (showHeader1, showConfidential1) => { 
  const visibleColumns = columns.filter((col) => columnVisibilityModel[col.field] !== false);

  if (visibleColumns.length === 0 ) {
    console.error('Nu există date disponibile pentru a genera PDF-ul.');
    return;
  }

  let pageFormat = 'a4';  // Format implicit

  if (visibleColumns.length > 5 && visibleColumns.length <= 8) {
    pageFormat = 'a3';
  } else if (visibleColumns.length > 8 && visibleColumns.length <= 12) {
    pageFormat = 'a2';
  } else if (visibleColumns.length > 12 && visibleColumns.length <= 15) {
    pageFormat = 'a1';
  } else if (visibleColumns.length > 15) {
    pageFormat = 'a0';
  }

  // Inițializăm documentul PDF
  const doc = new jsPDF({
    orientation: 'landscape',  // Orientare landscape
    format: pageFormat,  
                // Dimensiunea paginii
  });

  // Adăugăm fontul DejaVu Sans
  callAddFont(doc); // Adăugăm fontul personalizat
  doc.setFont('DejaVuSans'); // Setăm fontul pentru text

  // Adăugăm un antet, dacă este selectat
  let startY = 20;
  if (showHeader1) {
    doc.setFontSize(12);
    doc.setTextColor('#333');
    
    // Verificăm dacă `usercurentgrup` conține mai multe grupuri
    const grupuri = usercurentgrup.split(','); // separăm grupurile prin virgulă
    let grupuriText;

    if (grupuri.length > 1) {
      // Dacă avem mai multe grupuri, afișăm "pentru 2 unități" și grupurile respective
      grupuriText = `pentru ${grupuri.length} unități (${usercurentgrup})`;
    } else {
      // Dacă avem un singur grup, afișăm "pentru unitatea" și grupul respectiv
      grupuriText = `pentru unitatea ${usercurentgrup}`;
    }

    const dateFilterTypeDen = startDate ? 
    (dateFilterType === 'datacreare' ? 'data creare dosar' :
    dateFilterType === 'datatermen' ? 'data termen dosar' :
    dateFilterType === 'dataum' ? 'data actualizare dosar' : '') 
  : '';
  
    let mesaj;

     // Cazul în care nu trimiți `startDate`
     mesaj = `Documentul de export a fost generat la data ${formattedDate} la ora ${formattedTime2}, ${grupuriText}.`;
     doc.text(mesaj, 14, startY);
    if (!startDate) {
      doc.text(`Exportul a fost realizat pentru toată perioada (fără restricții referitoare la date calendaristice) din JuristPRO NEXT ${denAppVers}.`, 14, startY + 10);
    } else {
      // Cazul în care trimiți `startDate`, dar poate nu și `endDate`
      const dataSfarsit = endDate ? endDate : formattedDate;  // dacă `endDate` nu este trimis, folosește data curentă
      mesaj = `Perioada pentru care a fost realizat exportul este: ${dateFilterTypeDen} ${startDate} - ${dataSfarsit} din JuristPRO NEXT ${denAppVers}., ${grupuriText}.`;
      doc.text(mesaj, 14, startY + 10);
    }

  startY += 12;  // Mutăm mai jos pentru a face loc textului de confidențialitate, dacă există
  }

  // Adăugăm un mesaj confidențial, dacă este selectat
  if (showConfidential1) {
    doc.setFontSize(14);
    doc.setTextColor(255, 0, 0);  // Roșu pentru confidențial
    doc.text(`CONFIDENTIAL utilizator ${usercurentnume}`, 14, startY + 10);
    startY += 10;  // Mutăm mai jos pentru a face loc tabelului
  }

  // Creăm anteturile și rândurile tabelului
  const tableColumnHeaders = visibleColumns.map(col => col.headerName);
  const tableRows = dosareFinal.map((row) =>
  visibleColumns.map((col) => {
    if (col.field === 'user_groups') {
      return formatUserGroups(row[col.field]); // Aplica funcția de formatare
    }
    return row[col.field] || ''; // Returnează valoarea originală pentru celelalte coloane
  })
);

  if (tableRows.length === 0 || tableColumnHeaders.length === 0) {
    console.error('Nu există date pentru a genera tabelul.');
    return;
  }

  // Creăm tabelul cu `word wrap` și paginare
  doc.autoTable({
    head: [tableColumnHeaders],
    body: tableRows,
    startY: startY + 10, // Începem tabelul după antet și confidențial
    theme: 'grid',
    styles: {
      font: 'DejaVuSans',
      fontSize: 10,
      cellPadding: 3,
      overflow: 'linebreak',  // Activăm `word wrap` pentru text lung
      cellWidth: 'auto',      // Permitem auto-ajustarea celulelor
    },
    tableWidth: 'auto',  // Lăsăm tabelul să se ajusteze automat
    didDrawPage: (data) => {
      // Adăugăm paginare
      const pageCount = doc.internal.getNumberOfPages();
      doc.setFontSize(10);
      const pageStr = `Pagina ${data.pageNumber} din ${pageCount}`;
      doc.text(pageStr, data.settings.margin.left, doc.internal.pageSize.height - 10);
    },
  });
  // Salvăm PDF-ul pe dispozitivul utilizatorului
  doc.save(`Export JuristPRO - PDF - ${formattedDate} - ${formattedTime}.pdf`);

};
const handlePrintComand = (showHeader, showConfidential) => { 
    setShowHeader(false);
    setShowConfidential(false);
    let text;

    text = `Au fost selectate ${selectedRows2.length} dosare pentru a fi imprimate din tabel`;

    // Creăm un container HTML pentru switch-uri
    const container = document.createElement('div');
    container.className = 'divswal'; // Adăugăm o clasă pentru stilizare

    // Inserăm HTML pentru switch-uri personalizate stilizate
    container.insertAdjacentHTML(
      'beforeend',
      `
      <div class="switch-container">
        <label for="headerSwitch">Afișare antet</label>
        <label class="switch">
          <input type="checkbox" id="headerSwitch" ${showHeader ? '' : ''}>
          <span class="slider round"></span>
        </label>
      </div>
      <div class="switch-container">
        <label for="confidentialSwitch">Afișare "Confidențial"</label>
        <label class="switch">
          <input type="checkbox" id="confidentialSwitch" ${showConfidential ? '' : ''}>
          <span class="slider round"></span>
        </label>
      </div>
      `
    );

    // Afișăm modalul Swal cu elementul creat
    Swal({
      title: 'Imprimare tabel',
      text: text,
      content: container,
      icon: 'warning',
      buttons: ['Anulează', 'Da, continui!'],
      dangerMode: true,
    }).then((willContinue) => {
      if (willContinue) {
        const headerSwitch = document.getElementById('headerSwitch');
        const confidentialSwitch = document.getElementById('confidentialSwitch'); 
        handlePrint(headerSwitch.checked, confidentialSwitch.checked);
      }
    });
  };

  const handlePrint = (showHeader1, showConfidential1) => { 
    const visibleColumns = columns.filter((col) => columnVisibilityModel[col.field] !== false);

    if (visibleColumns.length === 0 || dosareFinal.length === 0) {
      console.error('Nu există date disponibile pentru a genera PDF-ul.');
      return;
    }

    
    let pageFormat = 'a4';  // Format implicit

    if (visibleColumns.length > 5 && visibleColumns.length <= 8) {
      pageFormat = 'a3';
    } else if (visibleColumns.length > 8 && visibleColumns.length <= 12) {
      pageFormat = 'a2';
    } else if (visibleColumns.length > 12 && visibleColumns.length <= 15) {
      pageFormat = 'a1';
    } else if (visibleColumns.length > 15) {
      pageFormat = 'a0';
    }

    // Inițializăm documentul PDF
    const doc = new jsPDF({
      orientation: 'landscape',  // Orientare landscape
      format: pageFormat,  
                  // Dimensiunea paginii
    });


        // Adăugăm fontul DejaVu Sans
        callAddFont(doc); // Adăugăm fontul personalizat
        doc.setFont('DejaVuSans'); // Setăm fontul pentru text
    
        // Adăugăm un antet, dacă este selectat
        let startY = 20;
        if (showHeader1) {
          doc.setFontSize(12);
          doc.setTextColor('#333');
          
          // Verificăm dacă `usercurentgrup` conține mai multe grupuri
          const grupuri = usercurentgrup.split(','); // separăm grupurile prin virgulă
          let grupuriText;
    
          if (grupuri.length > 1) {
            // Dacă avem mai multe grupuri, afișăm "pentru 2 unități" și grupurile respective
            grupuriText = `pentru ${grupuri.length} unități (${usercurentgrup})`;
          } else {
            // Dacă avem un singur grup, afișăm "pentru unitatea" și grupul respectiv
            grupuriText = `pentru unitatea ${usercurentgrup}`;
          }
      
          const dateFilterTypeDen = startDate ? 
            (dateFilterType === 'datacreare' ? 'data creare dosar' :
            dateFilterType === 'datatermen' ? 'data termen dosar' :
            dateFilterType === 'dataum' ? 'data actualizare dosar' : '') 
            : '';
        
          let mesaj;
    
           // Cazul în care nu trimiți `startDate`
           mesaj = `Documentul de export a fost generat la data ${formattedDate} la ora ${formattedTime2}, ${grupuriText}.`;
           doc.text(mesaj, 14, startY);
          if (!startDate) {
            doc.text(`Exportul a fost realizat pentru toată perioada (fără restricții referitoare la date calendaristice) din JuristPRO NEXT ${denAppVers}.`, 14, startY + 10);
          } else {
            // Cazul în care trimiți `startDate`, dar poate nu și `endDate`
            const dataSfarsit = endDate ? endDate : formattedDate;  // dacă `endDate` nu este trimis, folosește data curentă
            mesaj = `Perioada pentru care a fost realizat exportul este: ${dateFilterTypeDen} ${startDate} - ${dataSfarsit} din JuristPRO NEXT ${denAppVers}., ${grupuriText}.`;
            doc.text(mesaj, 14, startY + 10);
          }
    
        startY += 12;  // Mutăm mai jos pentru a face loc textului de confidențialitate, dacă există
        }

    // Adăugăm un mesaj confidențial, dacă este selectat
    if (showConfidential1) {
      doc.setFontSize(14);
      doc.setTextColor(255, 0, 0);  // Roșu pentru confidențial
      doc.text(`CONFIDENTIAL utilizator ${usercurentnume}`, 14, startY + 10);
      startY += 10;  // Mutăm mai jos pentru a face loc tabelului
    }

    // Creăm anteturile și rândurile tabelului
    const tableColumnHeaders = visibleColumns.map(col => col.headerName);
    const tableRows = dosareFinal.map((row) =>
    visibleColumns.map((col) => {
      if (col.field === 'user_groups') {
        return formatUserGroups(row[col.field]); // Aplica funcția de formatare
      }
      return row[col.field] || ''; // Returnează valoarea originală pentru celelalte coloane
    })
  );
  
    if (tableRows.length === 0 || tableColumnHeaders.length === 0) {
      console.error('Nu există date pentru a genera tabelul.');
      return;
    }

    // Creăm tabelul cu `word wrap` și paginare
    doc.autoTable({
      head: [tableColumnHeaders],
      body: tableRows,
      startY: startY + 10, // Începem tabelul după antet și confidențial
      theme: 'grid',
      styles: {
        font: 'DejaVuSans',
        fontSize: 10,
        cellPadding: 3,
        overflow: 'linebreak',  // Activăm `word wrap` pentru text lung
        cellWidth: 'auto',      // Permitem auto-ajustarea celulelor
      },
      tableWidth: 'auto',  // Lăsăm tabelul să se ajusteze automat
      didDrawPage: (data) => {
        // Adăugăm paginare
        const pageCount = doc.internal.getNumberOfPages();
        doc.setFontSize(10);
        const pageStr = `Pagina ${data.pageNumber} din ${pageCount}`;
        doc.text(pageStr, data.settings.margin.left, doc.internal.pageSize.height - 10);
      },
    });
    
    // Salvăm PDF-ul pe dispozitivul utilizatorului
    doc.save(`Export JuristPRO - PDF - ${formattedDate} - ${formattedTime}.pdf`);

    // Creăm un URL temporar pentru a deschide PDF-ul într-o fereastră nouă
    const pdfBlob = doc.output('blob');
    const url = URL.createObjectURL(pdfBlob);
    window.open(url); 

  };
  


  const filteredDosare = data.filter((dosar) => {
    const dosarDateStr = dosar[dateFilterType] ? dosar[dateFilterType].split(' ')[0] : null;
  
    return dosarDateStr;
  });
   
const selectedRows2 = data;
const dosareFinal = dosareFiltrate;
 
const prepareDataForExport2 = (data, columns, columnVisibilityModel) => {
    return data.map((row) => {
      const formattedRow = {};
      columns.forEach((col) => {
        if (columnVisibilityModel[col.field]) {
          const displayName = col.headerName; // Denumirea din columns
          
          // Aici poți decide ce date să adaugi
          if (col.field === 'user_groups') {
            formattedRow[displayName] = formatUserGroups(row[col.field]); // Formatează grupurile utilizatorilor
          } else if (col.field === 'costuri') {
            formattedRow[displayName] = `${row[col.field] || 0} Lei`; // 0 Lei când nu există valoare
          } else {
            formattedRow[displayName] = row[col.field] || ''; // Valoarea originală pentru celelalte coloane
          }
        }
      });
      return formattedRow;
    });
  };
  
  
      const csvOptions = {
        // Alte opțiuni CSV, dacă este necesar
        fileName: fileNameExcel,
        utf8WithBom: true,
        data: prepareDataForExport2(
          selectedRows2.length > 0 ? selectedRows2 : dosareFinal,
          columns,
          columnVisibilityModel
        ),
      };
      
      

  const apiRef2 = useGridApiRef();
  const rowsToDisplay = selectedRows2 ? selectedRows2 : dosareFiltrate;

  const handleStateChange = React.useCallback(
    (state: GridState) => {
      const filteredKeys = getKeysFromLookup(state.filter.filteredRowsLookup);
      const filteredRows = filteredKeys.map((key) => apiRef2.current.getRow(key));
  
      // Sortăm rândurile filtrate în funcție de sortModel-ul curent
      const sortedFilteredRows = sortRows(filteredRows, sortModel); // sortModel trebuie să fie disponibil global sau transmis aici
  
      // Verificăm dacă rândurile s-au schimbat față de cele deja filtrate
      if (JSON.stringify(sortedFilteredRows) !== JSON.stringify(dosareFiltrate)) {
        setDosareFiltrate(sortedFilteredRows);
      }
    },
    [apiRef2, dosareFiltrate, sortModel],  // sortModel trebuie inclus în dependențe
  );
  
  
  useEffect(() => {
    if (!hasRights) {
      window.location.href = '/panou?lipsaAcces=Rapoarte'; 
    }
  }, [hasRights]);

    return (
            <div>
            {seVerificaDrepturile  ? (
            <div
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
            className="container card p-3 mt-3" id='listaDosare'
            >
            {!showGrid && (
                <Alert severity="info">Selectați tipul de raport pentru a putea genera un raport.</Alert>
            )}
            <div> 
            
                    <div>
                            <FormControl component="fieldset" fullWidth className='mt-2'>
                               
                        <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center" 
                        >
                         <>
                          {seVerificaDrepturile && (
                            <RadioGroup row value={reportType} onChange={(e) => { setReportType(e.target.value); setData([]); }} style={{marginLeft:'10px'}} className='mb-3'>
                              {areDrept('RaportParti') && (
                                <FormControlLabel value="raportClient" control={<Radio />} label="Raport părți" />
                              )}
                              {areDrept('RaportActivitate') && (
                                <FormControlLabel value="raportActivitate" control={<Radio />} label="Raport activitate" />
                              )}
                              {areDrept('RaportEvenimente') && (
                                <FormControlLabel value="raportEveniment" control={<Radio />} label="Raport evenimente" />
                              )}
                              {areDrept('RaportPivot') && (
                                <FormControlLabel value="raportPivot" control={<Radio />} label="Raport pivot" onClick={handleRedirect} />
                              )}
                            </RadioGroup>
                          )}
                        </>
                        
      
                        <HelpDialog
                            title="TIPURI DE RAPOARTE"
                            contentType="video"
                            content="https://www.ctce.ro/important/juristpronext/tutoriale/rapoarte.mp4" 
                            tooltip="Ajutor: Cum se realizeză rapoarte în cadrul aplicației?"
                        /> 
                        </Box>  
                        
                        <hr />
                                { (reportType === 'raportClient' || reportType === 'raportActivitate') && ( 
                                    <div> 
                                {reportType === 'raportActivitate' ? (
                                    <RadioGroup row value={activityReportType} onChange={(e) => setActivityReportType(e.target.value)}>
                                        <FormControlLabel value="detaliat" control={<Radio />} label="pe utilizatori" />
                                        <FormControlLabel value="peSocietate" control={<Radio />} label="pe client" />
                                    </RadioGroup>
                                ) : (
                                    <div>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                                <Autocomplete
                                                    style={{ minWidth: '250px' }}
                                                    options={clientiCombinati}
                                                    label="Parte"
                                                    getOptionLabel={(option) => option.client}
                                                    value={clientiCombinati.find(client => client.idclient === selectedClient) ?? null}
                                                    onChange={(event, newValue) => {
                                                        setSelectedClient(newValue ? newValue.idclient : null);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Parte" variant="outlined" fullWidth />
                                                    )}
                                                />
                                            </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="date-filter-label">Filtrare după data</InputLabel>
                                                    <Select
                                                        labelId="date-filter-label"
                                                        value={dateFieldName}
                                                        onChange={(e) => setDateFieldName(e.target.value)}
                                                        label="Filtrează după"
                                                    >
                                                        <MenuItem value="any">Oricare</MenuItem>
                                                        <MenuItem value="datacreare">După data creare</MenuItem>
                                                        <MenuItem value="dataum">După data modificare</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={2}>
                                              <LocalizationProvider dateAdapter={AdapterDateFns} locale={ro}>
                                                  <DatePicker
                                                      label="Dată început"
                                                      value={startDate}
                                                      onChange={(newValue) => {
                                                          if (newValue === null || newValue === "" || isNaN(newValue)) {
                                                              setStartDate(null);  
                                                          } else {
                                                              setStartDate(newValue);
                                                          }
                                                      }}
                                                      onError={(error) => {
                                                          if (error === "invalidDate") {
                                                              setStartDate(null);  
                                                          }
                                                      }}
                                                      inputFormat="dd.MM.yyyy" 
                                                      format="dd.MM.yyyy" 
                                                      mask="__.__.____"
                                                      renderInput={(params) => (
                                                          <TextField
                                                              {...params}
                                                              fullWidth
                                                              onKeyDown={(e) => {
                                                                  if (e.key === "Backspace" || e.key === "Delete") {
                                                                      setStartDate(null);  
                                                                  }
                                                              }}
                                                          />
                                                      )}
                                                      className="maxWidth"
                                                  />
                                              </LocalizationProvider>
                                          </Grid>
                                          <Grid item xs={2}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ro}>
                                                    <DatePicker
                                                        label="Dată final"
                                                        value={endDate}
                                                        onChange={(newValue) => {
                                                            if (newValue === null || newValue === "" || isNaN(newValue)) {
                                                                setEndDate(null);  // Setăm null dacă se șterge
                                                            } else {
                                                                setEndDate(newValue);
                                                            }
                                                        }}
                                                        onError={(error) => {
                                                            if (error === "invalidDate") {
                                                                setEndDate(null);  // Setăm null în caz de eroare de tip invalidDate
                                                            }
                                                        }}
                                                        inputFormat="dd.MM.yyyy" 
                                                        format="dd.MM.yyyy" 
                                                        mask="__.__.____"
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                fullWidth
                                                                onKeyDown={(e) => {
                                                                    if (e.key === "Backspace" || e.key === "Delete") {
                                                                        setEndDate(null);  // Resetăm la null când ștergi din tastatură
                                                                    }
                                                                }}
                                                            />
                                                        )}
                                                        className="maxWidth"
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Tooltip title="Resetare interval tip dată pentru afișare rezultate în listă"> 
                                                <IconButton
                                                    style={{marginTop:'5px'}}
                                                    color="default"
                                                    onClick={() => {
                                                        setStartDate(null);
                                                        setEndDate(null);
                                                    }}
                                                    fullWidth
                                                >
                                                   <CancelPresentationIcon />
                                                </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    
                                )}
                        </div>
                        )}
                            </FormControl>
                        </div>
                    
      

            { reportType === 'raportEveniment' ? (
                <RapoarteEvenimente />
            ) : (
            showGrid && ( 

                <DataGridPro   ref={dataGridRef}
                className='tabelRaport'
                    loading={isLoading} 
                    rows={rowsToDisplay} 
                    autoHeight
                    columns={columns}
                    pageSize={20}
                    apiRef={apiRef2}
                    onStateChange={handleStateChange}
                    onFilterModelChange={handleFilterModelChange}
                    onSortModelChange={handleSortModelChange} 
                    filterModel={filterModel}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    checkboxSelectionVisibleOnly={true}
                    disableRowSelectionOnClick
                    disableSelectionOnClick={true}
                    getRowId={(row, index) => row.idclient || row.iduser || row.id || `row-${index}`}
                    pagination
                    onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                    columnVisibilityModel={columnVisibilityModel}
                    paginationMode="client"  
                    initialState={{
                        pagination: { paginationModel: { pageSize: 15 } }, 
                    }}
                    {...detaliatProps} 
                    density={(isRaportDetaliat) ? 'standard' : 'compact'}
                    components={{ 
                        
                        headerFilterMenu: null, 
                        Toolbar: () => (
                        <>
                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                        </div>
                            {isLoading ? (
                            <div style={{ width: '100%', marginBottom: 8 }}>
                                
                                <CustomGridToolbarContainer />
                            <LinearProgress /> 
                            </div>
                            ) : (
                            <div>
                            <CustomGridToolbarContainer />
                            </div>
                            
                            )}
                        </>
                    ),
                }}
                componentsProps={{
                    toolbar: {
                    csvOptions: {
                        fileName: 'nume-fisier',
                        delimiter: ',',
                        utf8WithBom: true,
                    },
                    },
                }}
                localeText={{ 
                    ...roRO.components.MuiDataGrid.defaultProps.localeText, 
                    headerFilterOperatorContains: 'Filtrare rapidă',
                    filterPanelRemoveAll: 'Resetare filtre',
                    toolbarExport: 'Export',
                    toolbarExportLabel: 'Export',
                    toolbarExportCSV: 'În format CSV',
                    toolbarExportPrint: 'Imprimare',
                    toolbarExportExcel: 'În format Excel',
                }}
            />

            )
        )} 
            </div> 
            </div>
            ) : (
                <div style={{ height: '100%', display: 'flex', flexDirection: 'column', textAlign:'center' }} className="container card p-3 mt-3" id='listaDosare'>
                   {hasRights ? ( 
                        <>
                          <Box>
                            <CircularProgress />
                          </Box>
                        </>
                      ) : null} {/* Nu mai facem redirecționarea aici */}
                  </div>
              )}
            </div>
    );
}

export default Rapoarte;