import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert';
import { useParams } from 'react-router-dom';

const ResetareParola = () => {
  const [email, setEmail] = useState('');
  const [user, setUser] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); 
  const [message, setMessage] = useState('');

  const { token } = useParams();  

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleResetPassword = async () => {
    if (!validateEmail(email)) {
      setMessage('Adresa de email nu este validă.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}recoverPasswordSendEmail?email=${encodeURIComponent(email)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        Swal('Resetare parolă', 'Un email de resetare a parolei a fost trimis la adresa specificată.', 'success');
      } else if (response.status === 422) {
        const data = await response.json();
        if (data.message && data.message.email && data.message.email[0] === "Câmpul email selectat nu este valid.") {
          Swal('Atenționare', 'Nu există nici un cont pentru această adresă de email.', 'warning');
        } else {
          Swal('Atenționare', 'A apărut o eroare la trimiterea emailului de resetare.', 'warning');
        }
      } else {
        Swal('Atenționare', 'A apărut o eroare la trimiterea emailului de resetare.', 'warning');
      }
    } catch (error) {
      setMessage('Eroare la conectarea la server.');
    }
  };

  useEffect(() => {
    if (token) {
      verifyToken(token);
    }
  }, [token]);

  const verifyToken = async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}recoverPasswordVerifyToken?token=${encodeURIComponent(token)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      if (response.ok) {
        setUser(data.data.user);  
      } else {
        Swal('Atenționare', data.message, 'warning');
        window.location.href = `/resetare-parola`;
      }
    } catch (error) {
      Swal('Atenționare', 'Eroare la conectarea la server.', 'warning');
    }
  };
 

  const handleResetPasswordSubmit = async () => {
    if (newPassword !== confirmPassword || newPassword.length < 6) {
      Swal('Atenționare', 'Parolele nu coincid sau sunt prea scurte.', 'warning');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}recoverPasswordUpdateNewPassword?user_id=${user.id}&password=${newPassword}&password_confirmation=${confirmPassword}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        Swal('Succes', 'Parola a fost resetată cu succes.', 'success');
        window.location.href = `/autentificare`;
      } else {
        Swal('Atenționare', 'Nu s-a putut reseta parola.', 'warning');
      }
    } catch (error) {
      Swal('Atenționare', 'Eroare la conectarea la server.', 'warning');
    }
  };

  const renderResetForm = () => (
    <div className="wrap-login100">
    <h2 className='mb-2 mt-2' style={{color:'#FFFFFF', textAlign:'center'}}>Introduceți noua parolă</h2>
    <p className='mb-3 mt-3'  style={{color:'#FFFFFF'}}>Vă rugăm să introduceți o nouă parolă.</p>
  
      <div className="wrap-input100 validate-input">
        <input
          className="input100"
          type="password"
          placeholder="Parolă nouă"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <span className="focus-input100">
          <FontAwesomeIcon icon={faLock} className="mr-2" />
        </span>
      </div>
      <div className="wrap-input100 validate-input">
        <input
          className="input100"
          type="password"
          placeholder="Confirmare parolă"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <span className="focus-input100">
          <FontAwesomeIcon icon={faLock} className="mr-2" />
        </span>
      </div>
      <div className="container-login100-form-btn">
        <button className="login100-form-btn" onClick={handleResetPasswordSubmit}>
          Schimbare parolă
        </button>
      </div>
    </div>
  );

  return ( 
    <div className="limiter">
    <div className="container-login100">
    {!user && (
         <div className="wrap-login100">
         <h2 className='mb-2 mt-2' style={{color:'#FFFFFF', textAlign:'center'}}>Resetare parolă</h2>
           <p className='mb-3 mt-3'  style={{color:'#FFFFFF'}}>Vă rugăm să introduceți adresa de email pentru a primi instrucțiunile de resetare a parolei.</p>
           <div className="wrap-input100 validate-input">
             <input
               className="input100"
               type="email"
               placeholder="Adresa de email"
               id="name"
               value={email} 
               onChange={handleEmailChange} 
               required
             />
             <span className="focus-input100">
               <FontAwesomeIcon icon={faUser} className="mr-2" />
             </span>
           </div>
       
             {message && <p>{message}</p>}
           <div className="container-login100-form-btn">
             <button className="login100-form-btn" onClick={handleResetPassword}> 
               Resetare parolă
             </button>
           </div>
     
           <div className="text-center p-t-90">
             <br />
             <a href='autentificare' style={{color:'#FFFFFF', textDecoration:'none'}}>V-ați amintit parola?</a>
           </div>
       </div>
    )}
      {user && renderResetForm()}
      
      </div>
    </div> 
    
    
      );
};

export default ResetareParola;
