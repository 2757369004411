import React, { useEffect, useState } from 'react'; 
import { Box, Autocomplete, Tab, Tabs, Button, Checkbox, TextField, LinearProgress, FormControlLabel, Typography, Alert } from '@mui/material';
import verificareToken from '../VerificareToken'; 
import { LicenseInfo } from '@mui/x-license-pro';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ReplayIcon from '@mui/icons-material/Replay';

LicenseInfo.setLicenseKey(
  'ac62ac97b94addeb6dbf2b2473e35ecbTz05MjE3MSxFPTE3NDk2MjQ2NjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const fetchCurrentUserId = () => {
  let userId = localStorage.getItem('iduser') || sessionStorage.getItem('iduser');

  if (!userId) {
    const cookies = document.cookie.split('; ');
    const idUserCookie = cookies.find(row => row.startsWith('_iduserCjpc='));
    if (idUserCookie) {
      userId = idUserCookie.split('=')[1];
    }
  }
  return userId; 
};

const ImportManual = () => {
  window.dispatchEvent(new Event('authChange'));
  const { token } = verificareToken();
  const [value, setValue] = useState(0);
  const [step, setStep] = useState(1);
  const [numarDosare, setNumarDosare] = useState('');
  const [dosareList, setDosareList] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [importedList, setImportedList] = useState([]);
  const [progress, setProgress] = useState(0);

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUsersList, setSelectedUsersList] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}users?users_from_all_common_groups=1`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        
        const {data} = await response.json();
        setUsers(data.users);
      } catch (error) {
        console.error("Eroare la preluarea utilizatorilor", error);
      }
    };
 
    fetchUsers();
 }, [token]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  const handleNextStep = () => {
    if (step === 1) {
      const regex = /^(\d+\/\d+\/\d+(?:\*|(?:\/\w+(?:\.\d+)?(?:\*)?))?(?:\/\w+(?:\.\d+)?|\*\*\/\w+|\*\*|\/\w+\*\*?)?)$/;
      const list = numarDosare.split('\n')
        .map(num => num.trim())
        .filter(num => regex.test(num));
      setDosareList(list);
      setCheckedList(Array(list.length).fill(true));
      setSelectedUsersList(Array(list.length).fill(null));
    }
    if (step === 2) {
      setProgress(0);
      importDosare();
    }
    setStep(step + 1);
  };
  
  

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handleReset = () => {
    setStep(1);
    setNumarDosare('');
    setDosareList([]);
    setCheckedList([]);
    setImportedList([]);
    setProgress(0);
    setSelectedUser(null);
    setSelectedUsersList([]);
  };

  const handleCheckboxChange = (index) => {
    const updatedCheckedList = [...checkedList];
    updatedCheckedList[index] = !updatedCheckedList[index];
    setCheckedList(updatedCheckedList);
  };

  const handleUserChange = (event, newValue, index = null) => {
    if (index === null) {
      setSelectedUser(newValue);
      setSelectedUsersList(Array(dosareList.length).fill(newValue));
    } else {
      const updatedSelectedUsersList = [...selectedUsersList];
      updatedSelectedUsersList[index] = newValue;
      setSelectedUsersList(updatedSelectedUsersList);
    }
  };

  const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  const importDosare = async () => {
    const totalDosare = dosareList.length;
    const chunkedDosare = chunkArray(dosareList, 50);
    const chunkedChecked = chunkArray(checkedList, 50);
    let imported = [];
    let totalProcessed = 0;
  
    const currentUserId = fetchCurrentUserId();

    for (let i = 0; i < chunkedDosare.length; i++) {
      const chunk = chunkedDosare[i];
      const chunkChecked = chunkedChecked[i];
      
      for (let j = 0; j < chunk.length; j++) {
        if (chunkChecked[j]) {
          const numarDosar = chunk[j];
          const idUserDosar = selectedUsersList[i * 50 + j]?.id || currentUserId;
          
          try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}importByNumarDosar?numarDosar=${numarDosar}&idUserDosar=${idUserDosar}`, {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${token}`, 
              },
            });
            const success = response.status === 200;
            imported.push({ numarDosar, success });
          } catch {
            imported.push({ numarDosar, success: false });
          }
          totalProcessed++;
          setProgress(Math.floor((totalProcessed / totalDosare) * 100));
          setImportedList([...imported]);
        }
      }
    }
  };
    
  

  return (
    <>
      <Box sx={{ display: 'flex', height: '100%' }}> 
        <Tabs
          orientation="vertical"
          variant="scrollable"  
          aria-label="Import manual dosare"
          value={value}
          onChange={handleChange}
          sx={{
            borderRight: 1,
            borderColor: 'divider', 
            top: '10px',  
          }}
        >  
          <Tab key={0} label="Import după număr" value={0} />
          <Tab key={1} label="Import din Excel" value={1} disabled />
        </Tabs>
        <TabPanel value={value} index={0}>
        {step === 1 && (
  <Box component="div">
    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
      <TextField
        label="Introduceți numerele de dosare (unul pe linie)"
        placeholder="Exemplu:\n12345\n67890\n11223"
        multiline
        rows={10}
        variant="outlined"
        fullWidth
        value={numarDosare}
        onChange={(e) => setNumarDosare(e.target.value)}
        sx={{ flex: 7 }}
      />
      <Box sx={{ flex: 3, bgcolor: 'whitesmoke', p: 2, borderRadius: 1 }}>
        <Typography variant="subtitle1" gutterBottom>
          <u>Exemplu:</u>
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: 'gray', userSelect: 'none', pointerEvents: 'none', whiteSpace: 'pre-line' }}
        >
          12345/22/2002{'\n'}
          67890/31/2020{'\n'}
          11223/12/2015{'\n'}
          44556/20/2023{'\n'}
          78901/11/2021{'\n'}
          23456/23/2024{'\n'}
          34567/19/2010{'\n'}
          45678/16/2021{'\n'}
          56789/13/2010{'\n'}
          67890/30/2023
        </Typography>
      </Box>
    </Box>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
      <Button color="secondary" variant="contained" onClick={handleNextStep} disabled={!numarDosare.trim()}>
        Continuare <ArrowForwardIcon />
      </Button>
    </Box>
  </Box>
)}

{step === 2 && (
  <Box component="div">
    {dosareList.length > 0 ? (
      <>
        <Alert severity="info" sx={{ mb: 2 }}>
          Au fost identificate {dosareList.length} dosare.
        </Alert>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedList.every(Boolean)}
                onChange={() => {
                  const allChecked = checkedList.every(Boolean);
                  setCheckedList(checkedList.map(() => !allChecked));
                }}
              />
            }
            label="Toate"
          />
          <Autocomplete
            options={users}
            getOptionLabel={(option) => option.name}
            value={selectedUser}
            onChange={(event, newValue) => handleUserChange(event, newValue)}
            renderInput={(params) => <TextField {...params} label="Selectați utilizatorul pentru toate dosarele" variant="outlined" />}
            size='small'
            sx={{ ml: 2, flex: 1 }}
          />
        </Box>
      </>
    ) : (
      <Alert severity="error" sx={{ mb: 2 }}>
        Nu au fost identificate dosare, vă rugăm sa verificați numerele introduse!
      </Alert>
    )}
     
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
      {dosareList.map((numar, index) => (
        <Box
          className="dosarImp"
          key={index}
          sx={{ 
            width: 'calc(33.333% - 8px)', 
            display: 'flex', 
            alignItems: 'center',
            bgcolor: index % 2 === 0 ? 'white' : 'whitesmoke',
            p: 1,
            borderRadius: 1 
          }}
        >
          <Checkbox
            checked={checkedList[index]}
            onChange={() => handleCheckboxChange(index)}
          />
          <span style={{ flex: 1 }}>{numar}</span>
          <Autocomplete
            options={users}
            getOptionLabel={(option) => option.name}
            value={selectedUsersList[index]}
            onChange={(event, newValue) => handleUserChange(event, newValue, index)}
            renderInput={(params) => <TextField {...params} label="Selectați utilizatorul" variant="outlined" />}
            size='small'
            sx={{ ml: 2, flex: 1 }}
          />
        </Box>
      ))}
    </Box>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, alignItems: 'center' }}>
      <Button color='warning' variant="contained" onClick={handlePrevStep}>
        <ArrowBackIcon /> Înapoi
      </Button>
      {dosareList.length > 0 ? (
        <>
          <Alert severity="warning">
            <b>{checkedList.filter(Boolean).length}</b> dosare selectate
          </Alert>
          <Button variant="contained" onClick={handleNextStep} disabled={!checkedList.some(Boolean)}>
            Importare <SaveAltIcon />
          </Button>
        </>
      ) : null}
    </Box>
  </Box>
)}
{step === 3 && (
  <Box component="div">
    {progress < 100 ? 
      <Alert severity="warning" style={{ marginBottom: '20px' }} className='mt-3'>Importul după număr este în curs de realizare, vă rugăm să așteptați...</Alert>
      : 
      <Alert severity="success" style={{ marginBottom: '20px' }} className='mt-3'>Importul după număr a fost finalizat</Alert>
    }
    <Typography variant="body1">
      {progress < 100 ? 'Se realizează importul după număr: '  : 'Import finalizat ' }
      {importedList.length} / {dosareList.length} ({progress}%)
    </Typography>
    <LinearProgress 
      variant="determinate" 
      value={progress} 
      style={{ height: '20px' }}
      color={progress === 100 ? 'success' : 'primary'} 
    />
    {progress === 100 && (
      <Box sx={{ mt: 2 }}>
        {importedList.map((item, index) => (
          <Box key={index} display="flex" alignItems="center">
            <span>{item.numarDosar}</span>
            <span style={{ color: item.success ? 'green' : 'red', marginLeft: '10px' }}>
              {item.success ? `✔️ Importul s-a realizat cu succes${selectedUsersList[index] ? ` pentru utilizatorul ${selectedUsersList[index].fullname} (${selectedUsersList[index].name})` : ''}` : '❌ Dosarul nu există pe portal sau este invalid.'}
            </span>
          </Box>
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button color='warning' variant="contained" onClick={handlePrevStep}>
            <ArrowBackIcon /> Înapoi 
          </Button>
          <Button color="primary" variant="contained" onClick={handleReset}>
            Realizare import nou <ReplayIcon />
          </Button>
          <Button color="success" variant="contained"  href="/lista-dosare">
            Listă dosare
          </Button>
        </Box>
      </Box>
    )}
  </Box>
)}


        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box component="div">
            bbbbb
          </Box>
        </TabPanel> 
      </Box> 
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    </>
  );
}

export default ImportManual;
