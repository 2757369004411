import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { TextField, Button, MenuItem, Select, FormControl, InputLabel, CircularProgress, Grid, Typography, Alert } from '@mui/material';
import { Autocomplete, createFilterOptions } from '@mui/material';
import Swal from 'sweetalert';
import verificareToken from '../VerificareToken';

function ConexareDosar() {
  const { id } = useParams();
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedidDosar, setSelectedidDosar] = useState('');
  const [legaturiData, setLegaturiData] = useState([]);
  const [dosareData, setDosareData] = useState([]);
  const [selectedDosar, setSelectedDosar] = useState('');
  const [selectedLegatura, setSelectedLegatura] = useState('');
  const [reloadLegaturi, setReloadLegaturi] = useState(false); 

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => `${option.numarintern || option.numardosarfinal} ${option.client}`,
  });

    const { token } = verificareToken();

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    setSelectedLegatura('');
    setSelectedidDosar('');
    console.log('selectedOption: ', selectedOption);
  };

  const handleEfectuareConexare = () => {

      const idDosar2 = selectedDosar;
      console.log('idDosar2: ', idDosar2);
      const apiUrlCreate = `${process.env.REACT_APP_API_URL}legaturiDosare?idDosar=${id}&idDosar2=${idDosar2}`;
      const requestOptionsCreate = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      fetch(apiUrlCreate, requestOptionsCreate)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          const newLegaturaId = data.id;
          const newLegatura = {
            id: newLegaturaId,
            numarDosar: selectedDosar.numardosarfinal,
          };
          // Actualizează legaturiData pentru a adăuga legatura nouă
          setLegaturiData([...legaturiData, newLegatura]);
          // Actualizează dosareData pentru a exclude dosarul conexat
          setDosareData(dosareData.filter((dosar) => dosar.id !== selectedDosar.id)); 
          
          setReloadLegaturi(true); 
          setSelectedDosar('');
          setSelectedLegatura('');
          setSelectedOption(1);
        })
        .catch((error) => {
          console.error('Eroare la cererea POST:', error);
        });
    }

  const handleEfectuare = () => {
    if (selectedOption === 1) {
      // Deschide dosarul
      if (selectedLegatura !== null) {
        const link = `/editare-dosar/${selectedLegatura}`;
        window.open(link, '_blank');
      }
    } else if (selectedOption === 2) {
      // Disjunge dosarul
      if (selectedLegatura) {
        Swal({
          title: 'Sigur doriți disjungerea acestui dosar?',
          text: 'Această acțiune nu poate fi anulată, după confirmare!',
          icon: 'warning',
          buttons: ['Anulează', 'Da, disjunge!'],
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            const legaturaId = selectedLegatura.id;
            const apiUrlDelete = `${process.env.REACT_APP_API_URL}legaturiDosare/${legaturaId}`;
            const requestOptionsDelete = {
              method: 'DELETE',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            };
            fetch(apiUrlDelete, requestOptionsDelete)
              .then((response) => {
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                // Actualizează legaturiData pentru a elimina legatura
                setLegaturiData((prevLegaturiData) =>
                  prevLegaturiData.filter((legatura) => legatura.id !== legaturaId)
                );
                // Actualizează dosareData pentru a include din nou dosarul disjuns
                setDosareData([...dosareData, selectedLegatura]);  
                setReloadLegaturi(true);
                setSelectedLegatura(''); 
                setSelectedDosar('');
                setSelectedLegatura('');
              })
              .catch((error) => {
                console.error('Eroare la cererea DELETE:', error);
              });
          }
        });
      }
    } else if (selectedOption === 3) {
      // Disjunge toate dosarele
      Swal({
        title: 'Sigur doriți disjungerea tuturor legăturilor?',
        text: 'Această acțiune nu poate fi anulată!',
        icon: 'warning',
        buttons: ['Anulează', 'Da, disjunge!'],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          const apiUrlDeleteAll = `${process.env.REACT_APP_API_URL}legaturiDosare/idDosar/${id}`;
          const requestOptionsDeleteAll = {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          };
          fetch(apiUrlDeleteAll, requestOptionsDeleteAll)
            .then((response) => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              // Actualizează legaturiData pentru a fi un array gol
              setLegaturiData([]);
              // Actualizează dosareData pentru a include din nou toate dosarele
              setDosareData([...dosareData, ...legaturiData]); 
              setSelectedOption('');
              setSelectedDosar('');
              setSelectedLegatura('');
            })
            .catch((error) => {
              console.error('Eroare la cererea DELETE:', error);
            });
        }
      });
    } else if (selectedOption === 4 && selectedDosar) {
      // Conexează alt dosar
      const idDosar2 = selectedDosar;
      console.log('idDosar2: ', idDosar2);
      const apiUrlCreate = `${process.env.REACT_APP_API_URL}legaturiDosare?idDosar=${id}&idDosar2=${selectedDosar}`;
      const requestOptionsCreate = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      fetch(apiUrlCreate, requestOptionsCreate)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          const newLegaturaId = data.id;
          const newLegatura = {
            id: newLegaturaId,
            numarDosar: selectedDosar.numardosarfinal,
          };
          // Actualizează legaturiData pentru a adăuga legatura nouă
          setLegaturiData([...legaturiData, newLegatura]);
          // Actualizează dosareData pentru a exclude dosarul conexat
          setDosareData(dosareData.filter((dosar) => dosar.id !== selectedDosar.id)); 
           
          setSelectedDosar('');
          setSelectedLegatura('');
        })
        .catch((error) => {
          console.error('Eroare la cererea POST:', error);
        });
    }
    setReloadLegaturi(true);
  };

  const apiUrlLegatura = `${process.env.REACT_APP_API_URL}legaturiDosare/${id}`;

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    // Reîncarcă legăturile doar dacă reloadLegaturi este true sau la încărcarea componentei
    fetch(apiUrlLegatura, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log('Datele primite de la API (legaturi):', data);
        setLegaturiData(data.data.legaturi);
        // Dezactivează reîncărcarea legăturilor după ce acestea sunt actualizate
        setReloadLegaturi(false);
      })
      .catch((error) => {
        console.error('Eroare la cererea GET (legaturi):', error);
      });
  }, [reloadLegaturi, apiUrlLegatura, token]);

  useEffect(() => {
    const apiUrlDosare = `${process.env.REACT_APP_API_URL}dosare`;
    const requestOptionsDosare = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    fetch(apiUrlDosare, requestOptionsDosare)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log('Datele primite de la API (dosare):', data);
        setDosareData(data.data.dosare);
      })
      .catch((error) => {
        console.error('Eroare la cererea GET (dosare):', error);
      });
  }, []);
  
  useEffect(() => {
    console.log('setSelectedDosar a fost actualizat și acum este:', selectedDosar);
  }, [selectedDosar]); 


  console.log("selectedOption:", selectedOption, "selectedLegatura:", selectedLegatura, "selectedDosar:", selectedDosar);
  return (
    <>
    {legaturiData.length === 0 ? (
          
          <Grid container spacing={0} alignItems="center" style={{justifyContent:'flex-end', paddingRight:'20px'}}>
          <Grid item xs={3}>
            <Typography style={{fontWeight:'bold'}}>Conexare / Disjungere</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>Conexați un dosar</Typography>
          </Grid>
          
                <Grid item xs={4}>   
                <Autocomplete 
                  name="autNicioLeg"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      const legaturaId = newValue ? newValue.id : null;
                      setSelectedLegatura(legaturaId);
                      setSelectedDosar(legaturaId); 
                    } else {
                      setSelectedLegatura('');  
                      setSelectedDosar(''); 
                    }
                  }}
                  options={dosareData}
                  getOptionLabel={(option) => {
                    if (option.numardosarfinal && option.numardosarfinal.trim() !== '') {
                      return option.numardosarfinal;
                    } else if (option.numarintern) {
                      return option.numarintern;
                    } else {
                      return '';  
                    }
                  }}
                  
                  onInputChange={(event, value, reason) => {
                    if (reason === 'clear') {
                      setSelectedLegatura('');
                      setSelectedDosar('');
                    }
                  }}        
                  filterOptions={filterOptions}
                  renderOption={(props, option) => (
                    <li {...props}>
                      {option.numardosarfinal && option.numardosarfinal.trim() !== '' ? option.numardosarfinal : option.numarintern} ({option.client})
                    </li>
                  )}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} label="Căutare dosar sau client" variant="outlined" />
                  )}
                />

                </Grid>
          <Grid item xs={1}>
            <Button
              onClick={handleEfectuareConexare}
              disabled={
                (!selectedLegatura)  
              }
            >
              Efectuare
            </Button>
          </Grid>
        </Grid>

     
        ) : (
          
          <Grid container spacing={1} alignItems="center" style={{justifyContent:'flex-end', paddingRight:'20px'}}>
          <Grid item xs={3}>
            <Typography style={{fontWeight:'bold'}}>Conexare / Disjungere</Typography>
            <Alert className='alertaConexare' severity='info' style={{fontSize:'12px', fontWeight:'bold', padding:'1px'}}>Există dosare conexate!</Alert>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" size='small' style={{ width: 160 }}>
              <InputLabel htmlFor="listaActiuniConexe">Acțiune</InputLabel>
              <Select
                label="Acțiune"
                value={selectedOption}
                onChange={handleChange}
                inputProps={{
                  name: 'listaActiuniConexe',
                  id: 'listaActiuniConexe',
                }}
              >
                <MenuItem value={1}>Deschide dosarul</MenuItem>
                <MenuItem value={2}>Disjunge dosarul</MenuItem>
                <MenuItem value={3}>Disjunge toate dosarele</MenuItem>
                <MenuItem value={4}>Conexează alt dosar</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          
          {selectedOption !== 3 && (
          <Grid item xs={4}>
                {selectedOption === 1 && (
                    <Autocomplete
                    name="autComDeschD"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        const legaturaId = newValue ? newValue.idDosarConex : null;
                        setSelectedLegatura(legaturaId);
                        setSelectedidDosar(legaturaId);
                      } else {
                        setSelectedLegatura('');  
                        setSelectedidDosar(''); 
                      }
                    }}
                    onInputChange={(event, value, reason) => {
                      if (reason === 'clear') {
                        setSelectedLegatura('');
                        setSelectedidDosar('');
                      }
                    }}

                    size='small'
                    options={legaturiData}
                    getOptionLabel={(option) => option.numarDosar ? option.numarDosar : ''} 
                    renderInput={(params) => <TextField {...params} label="Legaturi Dosar" variant="outlined" />}
                  />
                )}
                {selectedOption === 2 && (
                    <Autocomplete
                    name="autDisjD"
                    onChange={(event, newValue) => {
                        if (newValue) {
                          setSelectedLegatura(newValue);
                        } else {
                          setSelectedLegatura('');
                        }
                      }}
                    size='small'
                    onInputChange={(event, value, reason) => {
                      if (reason === 'clear') {
                        setSelectedLegatura(''); 
                      }
                    }}
                    options={legaturiData}
                    getOptionLabel={(option) => option.numarDosar ? option.numarDosar : ''} 
                    renderInput={(params) => <TextField {...params} label="Legaturi Dosar" variant="outlined" />}
                    />
                )}
                {selectedOption === 3 && (
                    <div>
                    {/* in cazul asta nu afisez nimic */}
                    </div>
                )}
                {selectedOption !== 1 && selectedOption !== 2 && selectedOption !== 3 && (<>
                <Autocomplete
                  name="autCompCuleg"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        const legaturaId = newValue ? newValue.id : null;
                        setSelectedLegatura(legaturaId);
                        setSelectedDosar(newValue.id);
                      } else {
                        setSelectedLegatura('');  
                        setSelectedDosar(''); 
                      }
                    }}
                  onInputChange={(event, value, reason) => {
                    if (reason === 'clear') {
                      setSelectedLegatura('');
                      setSelectedidDosar('');
                    }
                  }}
                    options={dosareData}
                    getOptionLabel={(option) => option.numardosarfinal && option.numardosarfinal.trim() !== '' ? option.numardosarfinal : option.numarintern}
                      filterOptions={filterOptions}
                      renderOption={(props, option) => (
                        <li {...props}>
                          {option.numardosarfinal && option.numardosarfinal.trim() !== '' ? option.numardosarfinal : option.numarintern} ({option.client})
                        </li>
                      )}
                    size='small'
                    renderInput={(params) => (
                      <TextField {...params} label="Căutare dosar sau client" variant="outlined" />
                    )}
                  /></>
                )}
                </Grid>
                )}
          <Grid item xs={1}>
          <Button
            onClick={handleEfectuare}
            disabled={
              (selectedOption === 4 && !selectedLegatura) || 
              (selectedOption === 1 && !selectedLegatura) ||
              (selectedOption === 2 && !selectedLegatura)
            }
          >
            Efectuare
          </Button>
          </Grid>
        </Grid>

         )}
        </>
  );
}

export default ConexareDosar;
