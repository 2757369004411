import React, { useState, useEffect, useCallback } from 'react';
import { Radio, RadioGroup, FormLabel, FormControlLabel, Button, MenuItem, Select, FormControl, InputLabel, Grid, CircularProgress, TextField, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert';
import verificareToken from '../VerificareToken';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import DrepturiUtilizator from '../utilizator/drepturiUtilizator'; 
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ro } from 'date-fns/locale'; 
import { Label } from '@mui/icons-material';

export default function StareDosar({ selectedRowId }) {

  const { areDrept } = DrepturiUtilizator(); 
  const DreptDeModificareDosare = 'DreptDeModificareDosare';
  const [addLoading, setAddLoading] = useState(false);
  const [idstare, setIdstare] = useState('');
  const [stari, setStari] = useState([]);
  const [loadingStari, setLoadingStari] = useState(true);
  const [loadingInfo, setLoadingInfo] = useState(true);
  const { id: urlId } = useParams(); 
  const [favorabil, setFavorabil] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [stareToEdit, setStareToEdit] = useState(null);
  const [newDenumire, setNewDenumire] = useState('');
  const [newDenumireEdit, setNewDenumireEdit] = useState('');
  const [error, setError] = useState('');
  const [dataInitialaDinApi, setDataInitialaDinApi] = useState(null);
  const [dataFinalizare, setDataFinalizare] = useState(null);
  const [solutie, setSolutie] = useState('');
  const [descriereSolutie, setDescriereSolutie] = useState('');

  const [loading, setLoading] = useState(false);
    const { token } = verificareToken();

    const id = selectedRowId || urlId; 

  const handleSolutieChange = (event) => {
    setSolutie(event.target.value);
  };

  const handleOpenDialog = (stare = null) => {
    setOpenDialog(true);
    setEditMode(!!stare);
    setNewDenumire(stare ? stare.denumire : '');
  };

  const handleDenumireChange = useCallback((event) => {
    setNewDenumireEdit(event.target.value);
  }, []);
  
  const handleEditare = (stare = null) => {
    setEditMode(!!stare);
    setStareToEdit(stare);
    setNewDenumireEdit(stare ? stare.denumire : '');
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setStareToEdit(null);
    setNewDenumireEdit('');
    setError('');
  };
 
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditMode(false);
    setStareToEdit(null);
    setError('');
  };

  const handleAddStare = async () => {
    if (!newDenumire.trim()) {
      setError('Denumirea nu poate fi goală.');
      return;
    }
    setAddLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}addStare?denumire=${newDenumire}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const {data} = await response.json();
      if (response.ok) {
        setStari([...stari, { id: data.stare.id, denumire: data.stare.denumire}]); 
        setNewDenumire('');  
      } else {
        setError(data.message || 'A apărut o eroare la adăugarea stării.');
      }
    } catch (error) {
      console.error('Eroare:', error);
      setError('A apărut o eroare la adăugarea stării.');
    }
    setAddLoading(false);
  };

  const handleAddOrUpdateStare = async () => {
    if (!newDenumireEdit.trim()) {
      setError('Denumirea nu poate fi goală.');
      return;
    }
    const url = editMode ? `updateStare/${stareToEdit.id}` : 'addStare';
    const method = editMode ? 'PATCH' : 'POST';
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}${url}?denumire=${newDenumireEdit}`, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        Swal('Succes', `Starea a fost ${editMode ? 'actualizată' : 'adăugată'} cu succes!`, 'success');
        fetchStari(); 
        handleCancelEdit();
      } else {
        setError('A apărut o eroare la actualizarea stării.');
      }
    } catch (error) {
      console.error('Eroare:', error);
      setError('A apărut o eroare la actualizarea stării.');
    }
  };

  const handleDeleteStare = (userId) => {
    Swal({
      title: 'Sigur doriți ștergerea aceste stări?',
      text: 'Această acțiune nu poate fi anulată!',
      icon: 'warning',
      buttons: ['Anulează', 'Da, șterge!'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleDeleteStareOK(userId);
      }
    });
  };
  
  
  const handleDeleteStareOK = async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}deleteStare/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        Swal('Succes', 'Starea a fost ștearsă cu succes!', 'success');
        fetchStari();  
      } else {
        setError('A apărut o eroare la ștergerea stării.');
      }
    } catch (error) {
      console.error('Eroare:', error);
      setError('A apărut o eroare la ștergerea stării.');
    }
  };

    const fetchStari = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}stari`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setStari(data.data.stari);
        setLoadingStari(false);
      } catch (error) {
        console.error('Error fetching stari:', error);
      }
    };

  useEffect(() => {
    fetchStari();
  }, [token]);
 
  useEffect(() => {
    const fetchInfoDosar = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}infoDosar/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (data.data.stare) {
          setIdstare(data.data.stare.id);
          setFavorabil(data.data.favorabil); 
          if (data.data.data_finalizare) {
            setDataFinalizare(new Date(data.data.data_finalizare));
            setDataInitialaDinApi(new Date(data.data.data_finalizare)); 
          } else {
            setDataInitialaDinApi(null); 
          }
          
          if (data.data.solutie) {
            setSolutie(data.data.solutie);
          }
          if (data.data.descriereSolutie) {
            setDescriereSolutie(data.data.descriereSolutie);
          }
        } else {
          setIdstare(0); 
        }
        setLoadingInfo(false);
      } catch (error) {
        console.error('Eroare preluare date dosar la infoDosar:', error);
      }
    };
    fetchInfoDosar();
  }, [id, token]);

  const handleChange = (event) => {
    setIdstare(event.target.value);
  };

  const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
  };
  

  const handleSalvareStare = async () => {
    const dataFinalizareFormatted = dataFinalizare
    ? `${dataFinalizare.getFullYear()}-${(dataFinalizare.getMonth() + 1).toString().padStart(2, '0')}-${dataFinalizare.getDate().toString().padStart(2, '0')}`
    : '';
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}dosare/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          idStare: idstare,
          dataFinalizare: dataFinalizareFormatted,
          solutie: solutie,
          descriereSolutie: descriereSolutie,
          favorabil: favorabil,
        }),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        Swal({
          title: 'Succes',
          text: 'Starea dosarului a fost actualizată cu succes!',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      } else {
        const errorData = await response.json();
        Swal({
          title: 'Atenționare',
          text: errorData.message || 'A apărut o eroare la actualizarea stării dosarului.',
          icon: 'warning',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      console.error('Eroare:', error);
      Swal({
        title: 'Atenționare',
        text: 'A apărut o eroare la actualizarea stării dosarului.',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    }
  };
  
  useEffect(() => {
    const esteFinalizat = stari.find(stare => stare.id === idstare)?.denumire.toLowerCase().includes('finalizat');
  
    if (esteFinalizat && !dataFinalizare) {
      setDataFinalizare(new Date());
    } else if (!esteFinalizat) {
      if (!dataInitialaDinApi) {
        setDataFinalizare(null);
      }
    }
  }, [idstare, stari, dataFinalizare]);
  
  const fetchUltimulTermen = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}ultimulTermen/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        if (data.data && data.data.ultimul_termen) {
          setDescriereSolutie(data.data.ultimul_termen.sumarsolutie);
          Swal('Succes', 'Soluția a fost preluată cu succes!', 'success');
        } else {
          Swal('Informare', 'Momentan nu există o soluție pentru acest dosar.', 'info');
        }
      } else {
        throw new Error(data.message || 'Eroare la preluarea soluției.');
      }
    } catch (error) {
      console.error('Fetch error:', error);
      Swal('Informare', 'Momentan nu există o soluție pentru acest dosar.', 'warning');
    }
    setLoading(false);
  };
  
  
  if (loadingStari || loadingInfo) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '10vh' 
      }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <Grid container spacing={2} className="mb-3 mt-1"> 
        <Grid item xs={10}>
            <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="stare-dosar">Stare dosar</InputLabel>
                <Select
                disabled={!areDrept(DreptDeModificareDosare)}
                label="Stare dosar"
                value={idstare}
                onChange={handleChange}
                inputProps={{
                    name: 'stare-dosar',
                    id: 'stare-dosar',
                }}
                >
                <MenuItem key={0} value={0} disabled>
                  Alegeți o stare
                </MenuItem>
                {stari.map((stare) => (
                    <MenuItem key={stare.id} value={stare.id}>
                        {capitalizeFirstLetter(stare.denumire)}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={2}>
            <Button  onClick={() => handleOpenDialog()} style={{marginTop:'5px'}} fullWidth variant="outlined" color="secondary" size='large' disabled={!areDrept(DreptDeModificareDosare)}>
                Stări
            </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro} >
          <DatePicker
            label="Data finalizare"
            value={dataFinalizare}
            onChange={(newValue) => {
              setDataFinalizare(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
            inputFormat="dd/MM/yyyy" 
            format="dd/MM/yyyy" 
            mask="__/__/____"
            InputLabelProps={{
              shrink: true, 
            }}
            disabled={!idstare || !stari.find(stare => stare.id === idstare)?.denumire.toLowerCase().includes('finalizat')}
            className='maxWidth'
          />
        </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="solutie-label">Soluție</InputLabel>
            <Select
              labelId="solutie-label"
              id="solutie"
              value={solutie}
              label="Soluție"
              disabled={!idstare || !stari.find(stare => stare.id === idstare)?.denumire.toLowerCase().includes('finalizat')}
              onChange={handleSolutieChange}
            >
              <MenuItem value="admis">Admis</MenuItem>
              <MenuItem value="respins">Respins</MenuItem>
              <MenuItem value="admis partial">Admis parțial</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={fetchUltimulTermen}
            disabled={!idstare || !stari.find(stare => stare.id === idstare)?.denumire.toLowerCase().includes('finalizat') || loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Preia soluția'}
          </Button>
        </Grid>
        
        <Grid item xs={12}>
          <TextField
            label="Descriere soluție dosar"
            multiline
            rows={4}
            value={descriereSolutie}
            disabled={!idstare || !stari.find(stare => stare.id === idstare)?.denumire.toLowerCase().includes('finalizat')}
            onChange={(e) => setDescriereSolutie(e.target.value)}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center' }}>
            {idstare && stari.find(stare => stare.id === idstare)?.denumire.toLowerCase().includes('finalizat') && (
              <FormControl component="fieldset" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <FormLabel component="legend" style={{ marginRight: '16px' }}>Dosarul a fost finalizat:</FormLabel>
                <RadioGroup
                  row
                  aria-label="favorabil"
                  name="favorabil"
                  value={favorabil}
                  onChange={(e) => setFavorabil(parseInt(e.target.value))}
                  style={{ flexDirection: 'row' }}
                >
                  <FormControlLabel value={1} control={<Radio />} label="Favorabil" />
                  <FormControlLabel value={0} control={<Radio />} label="Nefavorabil" />
                </RadioGroup>
              </FormControl>
            )}
          </Grid>



      </Grid>
      <Grid item xs={12} style={{alignItems:'flex-end', textAlign:'right'}} className="mt-3">
            <Button onClick={handleSalvareStare} variant="contained" color="secondary" size='large' disabled={!areDrept(DreptDeModificareDosare)}>
                Actualizare stare
            </Button>
      </Grid>


      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="xs" fullWidth>
        <DialogTitle className='titluModal'>Administrare stări dosare</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={10}>
              <TextField
                margin="dense"
                label="Adăugare tip stare"
                type="text"
                fullWidth
                variant="outlined"
                value={newDenumire}
                onChange={(e) => setNewDenumire(e.target.value)}
                error={!!error}
                helperText={error}
                size="small"
                onKeyPress={(e) => { if (e.key === 'Enter') handleAddStare(); }}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={handleAddStare} color="primary">
                {addLoading ? <CircularProgress size={24} /> : <CheckIcon />}
              </IconButton>
            </Grid>
          </Grid>
          <TableContainer component={Paper} style={{ maxHeight: 440 }}>
            <Table stickyHeader className='tabelStari'>
              <TableHead>
                <TableRow>
                  <TableCell>Denumire</TableCell>
                  <TableCell>Acțiune</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stari.map((stare) => (
                  <TableRow key={stare.id}>
                    <TableCell>
                      {editMode && stareToEdit.id === stare.id ? (
                        <TextField
                          margin="dense"
                          value={newDenumireEdit} 
                          error={!!error}
                          helperText={error}
                          fullWidth
                          onKeyPress={(e) => { if (e.key === 'Enter') handleAddOrUpdateStare(); }}
                          onChange={handleDenumireChange}
                        />
                      ) : (
                        capitalizeFirstLetter(stare.denumire)
                      )}
                    </TableCell>
                    <TableCell>
                    {editMode && stareToEdit.id === stare.id ? (
                          <>
                            <IconButton onClick={handleAddOrUpdateStare} color="primary">
                              <CheckIcon />
                            </IconButton>
                            <IconButton onClick={handleCancelEdit} color="secondary">
                              <CloseIcon /> 
                            </IconButton>
                          </>
                        ) : (
                        <>
                          <IconButton onClick={() => handleEditare(stare)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={() => handleDeleteStare(stare.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Închidere
          </Button>
        </DialogActions>
      </Dialog>


    </div>
  );
}
