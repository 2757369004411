import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import verificareToken from '../VerificareToken'; 
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Select, MenuItem, TextField, List, ListItem, IconButton, CircularProgress, InputLabel, FormControl, Typography, Alert } from  '@mui/material';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Swal from 'sweetalert';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { DataGridPro, roRO } from '@mui/x-data-grid-pro';   
import DrepturiUtilizator from '../utilizator/drepturiUtilizator'; 
import CalculateIcon from '@mui/icons-material/Calculate';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { ro } from 'date-fns/locale';

import { LicenseInfo } from '@mui/x-license-pro'; 
LicenseInfo.setLicenseKey('ac62ac97b94addeb6dbf2b2473e35ecbTz05MjE3MSxFPTE3NDk2MjQ2NjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

  function CosturiDosar() {
    const { areDrept } = DrepturiUtilizator(); 
    const DreptDeModificareDosare = 'DreptDeModificareDosare';
    
      const { id: idDosar } = useParams();
        const { token } = verificareToken();
      const dataCurenta = new Date().toISOString().split('T')[0];
      // State-uri pentru componentă
      const [costuri, setCosturi] = useState([]);
      const [stadii, setStadii] = useState([]);
      const [denumiriCosturi, setDenumiriCosturi] = useState([]);
      const [idStadiu, setIdStadiu] = useState(null);
      const [idDenumireCost, setIdDenumireCost] = useState(null);
      const [data, setData] = useState(new Date());
      const [valoare, setValoare] = useState('');
      const [modalDeschis, setModalDeschis] = useState(false);
      const [denumireNoua, setDenumireNoua] = useState('');
      const [modalEditareDeschis, setModalEditareDeschis] = useState(false);
      const [costEditat, setCostEditat] = useState(null);
      const [isTaxaTimbruDialogOpen, setTaxaTimbruDialogOpen] = useState(false);
      const [selectedCost, setSelectedCost] = useState(null);
      const [taxe, setTaxe] = useState({ prima: 0, apel: 0, recurs: 0 });

      const [isEditingDenumire, setIsEditingDenumire] = useState(null);
      const [editedDenumire, setEditedDenumire] = useState('');
      const [loading, setLoading] = useState(false);
      const [loadingDenumire, setLoadingDenumire] = useState(false);

      const [stadiu, setStadiu] = useState('');
      const [denumireCost, setDenumireCost] = useState(''); 
      const [isDialogOpen, setDialogOpen] = useState(false);
     
         const openDialog = () => {
            setIdDenumireCost(1);
            setValoare('');
            setData(new Date());   
            setDialogOpen(true);
            setTaxe({ prima: 0, apel: 20, recurs: 100 });
        };
    
        
        const closeDialog = () => {
            setDialogOpen(false);
        }
    

        const handleChange = (e) => {
            const { value } = e.target;
            if (/^\d*$/.test(value)) {  
                setValoare(value);
            }
        };
                
        const handleKeyPress = (e) => {
          if (!/^\d$/.test(e.key)) {
            e.preventDefault();
          }
        };

      const [total, setTotal] = useState(0);
      useEffect(() => {
        const sumaTotala = costuri.reduce((acc, cost) => {
            // daca valoarea este un numar valid
            const valoareCost = parseFloat(cost.valoare);
            return isNaN(valoareCost) ? acc : acc + valoareCost;
        }, 0);
        setTotal(sumaTotala.toFixed(2)); // rotunjesc 2 zecimale
    }, [costuri]);
    
        
      useEffect(() => {
        incarcaCosturi();
        incarcaStadii();
        incarcaDenumiriCosturi();
    }, [idDosar]);

    useEffect(() => {
        if (stadii.length > 0) {
            setIdStadiu(stadii[0].id);
        }
    }, [stadii]);
  
      const efectueazaCerere = async (url, metoda = 'GET', corp) => {
          const raspuns = await fetch(url, {
              method: metoda,
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify(corp)
          });
          return await raspuns.json();
      };
  
      const incarcaCosturi = async () => {
        setLoading(true);
          const rezultat = await efectueazaCerere(`${process.env.REACT_APP_API_URL}costuriDosar/${idDosar}`);
          setCosturi(rezultat.data.costuriDosar);
        setLoading(false);
      };
  
      const incarcaStadii = async () => {
          const rezultat = await efectueazaCerere(`${process.env.REACT_APP_API_URL}infoStadiiDosar/${idDosar}`);
          setStadii(rezultat.data.stadiiDosar);
      };
  
      const incarcaDenumiriCosturi = async () => {
          const rezultat = await efectueazaCerere(`${process.env.REACT_APP_API_URL}denumiriCosturi`);
          setDenumiriCosturi(rezultat.data.denumiriCosturi);
      };

      const reseteazaCampuri = () => {
        setIdStadiu(null);
        setIdDenumireCost(null);
        setData('');
        setValoare('');
    };

     const adaugaCost = async () => {
        
        if (!valoare) return; 
        
        const formattedDate = formatDate(data);
        setLoading(true);
        await efectueazaCerere(`${process.env.REACT_APP_API_URL}costuriStadiuDosar?idstadiudosar=${idStadiu}&iddenumirecost=${idDenumireCost}&data=${formattedDate}&valoare=${valoare}`, 'POST');
        incarcaCosturi();
        Swal('Cu succes!', 'Costul a fost adăugat cu succes.', 'success');
        reseteazaCampuri();
        setDialogOpen(false);
        setLoading(false);
    };

 
const formatDate = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
  
    return [year, month, day].join('-');
  };
  
       
    const editeazaCost = async (id, nouaValoare) => {
        const formattedDateEditat = formatDate(nouaValoare.data);
        setLoading(true);
        await efectueazaCerere(`${process.env.REACT_APP_API_URL}costuriStadiuDosar/${id}?idstadiudosar=${nouaValoare.idstadiudosar}&iddenumirecost=${nouaValoare.iddenumirecost}&data=${formattedDateEditat}&valoare=${nouaValoare.valoare}`, 'PATCH');
        incarcaCosturi();
        setLoading(false);
    };
  
      const stergeCost = (id) => {
        Swal({
            title: 'Sunteți sigur?',
            text: "Nu mai puteți reveni asupra acestei decizii!",
            icon: 'warning',
            buttons: ['Anulează', 'Șterge'],
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                fetch(`${process.env.REACT_APP_API_URL}costuriStadiuDosar/${id}`, {
                    method: "DELETE",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                })
                .then(() => {
                    incarcaCosturi();
                    Swal('Șters!', 'Costul a fost șters.', 'success');
                })
                .catch(eroare => {
                    Swal('Atenție!', 'A apărut o eroare la ștergerea costului.', 'warning');
                    console.error('A apărut o eroare!', eroare);
                });
            } else {
                Swal('Costul nu a fost șters!');
            }
        });
    };
  
      // denumire costuri
      const adaugaDenumireCost = async () => {
        setLoadingDenumire(true);
          await efectueazaCerere(`${process.env.REACT_APP_API_URL}denumiriCosturi?denumire=${denumireNoua}`, 'POST');
          incarcaDenumiriCosturi();
        setLoadingDenumire(false);
      };
  
      const editeazaDenumireCost = async (id, nouaDenumire) => {
        setLoadingDenumire(true);
          await efectueazaCerere(`${process.env.REACT_APP_API_URL}denumiriCosturi/${id}?denumire=${nouaDenumire}`, 'PATCH');
          incarcaDenumiriCosturi();
        setLoadingDenumire(false);
      };
  
      const stergeDenumireCost = async (id) => {
        setLoadingDenumire(true);
          await efectueazaCerere(`${process.env.REACT_APP_API_URL}denumiriCosturi/${id}`, 'DELETE');
          incarcaDenumiriCosturi();
        setLoadingDenumire(false);
      };
  
      const deschideModalEditare = cost => {
        setCostEditat(cost);
        setIdStadiu(cost.idstadiudosar);   
        setIdDenumireCost(cost.iddenumirecost); 
        let dataOriginala = cost.data;
        const dataFormatata = new Date(dataOriginala);
        setData(new Date(cost.data));
        setValoare(Math.floor(cost.valoare).toString()); 
        setModalEditareDeschis(true);
    };
    

    const inchideModalEditare = () => {
        setCostEditat(null);
        setModalEditareDeschis(false);
    };

    // editare cost
    const finalizeazaEditare = async () => {
        
        if (!valoare) return; 
    
        const formattedDateEditat = formatDate(data);
        await editeazaCost(costEditat.id, {
          idstadiudosar: idStadiu,
          iddenumirecost: idDenumireCost,
          data: formattedDateEditat,
          valoare: Math.floor(valoare).toString()  
        });
        inchideModalEditare();
      };
      
    
    const startEditing = (id, denumire) => {
        setIsEditingDenumire(id);
        setEditedDenumire(denumire);
    };

    const handleDenumireChange = e => {
        setEditedDenumire(e.target.value);
    };

    const handleEditSave = async id => {
        await editeazaDenumireCost(id, editedDenumire);
        setIsEditingDenumire(null);
    };

    const currencyFormatter = new Intl.NumberFormat('ro-RO', {
        style: 'currency',
        currency: 'RON',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
      
      const columns: GridColDef[] = [
       
        { 
            field: 'denumirecost', 
            headerName: 'Denumire Cost', 
            width: 200 
        },
        {
            field: 'valoare',
            headerName: 'Valoare',
            type: 'number',
            width: 150,
            align: 'center',
            valueFormatter: ({ value }) => {
              if (!value) {
                return value;
              }
              return currencyFormatter.format(value);
            },
        },
        { 
            field: 'denumirestadiu', 
            headerName: 'Stadiu', 
            width: 150 ,
            align: 'center'
        },
        { 
            field: 'data', 
            headerName: 'Dată cost', 
            width: 150,
            align: 'center',
            valueFormatter: ({ value }) => value ? new Date(value).toLocaleDateString('ro-RO') : '',
        },
        {
            field: 'actions',
            headerName: 'Acțiuni',
            width: 150,
            align: 'center',
            hideable: !areDrept(DreptDeModificareDosare),
            renderCell: (params) => (
                <>
                    <Tooltip title="Editare cost"><IconButton disabled={!areDrept(DreptDeModificareDosare)} onClick={() => deschideModalEditare(params.row)}><EditIcon /></IconButton></Tooltip>
                    <Tooltip title="Ștergere cost"><IconButton disabled={!areDrept(DreptDeModificareDosare)} onClick={() => stergeCost(params.row.id)}><DeleteIcon /></IconButton></Tooltip>
                </>
            ),
        }, 
        {
            field: 'calculator',
            headerName: '',
            width: 180,
            align: 'center',
            renderCell: (params) => (
               params.row.denumirecost === "Taxă de timbru" ? (
                    <Button  
                        size='small'
                        color="primary" 
                        onClick={() => openTaxaTimbruDialog(params.row)}
                    >
                        Calcul taxă de timbru
                    </Button>
                ) : null
            ),
        }
    ];
    
    const getDenumireCost = (id) => {
        const cost = denumiriCosturi.find(cost => cost.id === id);
        return cost ? cost.denumire : '';
    };

    // Recalculare taxe atunci când se schimbă valoarea sau denumirea costului
    useEffect(() => {
        const denumireCostSelectata = getDenumireCost(idDenumireCost);
        if (denumireCostSelectata === "Taxă de timbru") {
            calculeazaTaxa();
        } else {
            setTaxe({ prima: 0, apel: 0, recurs: 0 });
        }
    }, [idDenumireCost, valoare]);

    // Calcul taxe
    const calculeazaTaxa = () => {
        let val = parseFloat(valoare) || 0;
        let taxa = 0;

        if (val > 250000) {
            taxa = (val - 250000) * 0.01 + 6105;
        } else if (val > 50000) {
            taxa = (val - 50000) * 0.02 + 2105;
        } else if (val > 25000) {
            taxa = (val - 25000) * 0.03 + 1355;
        } else if (val > 5000) {
            taxa = (val - 5000) * 0.05 + 355;
        } else if (val > 500) {
            taxa = (val - 500) * 0.07 + 40;
        } else if (val > 0) {
            taxa = val * 0.08;
        }

        if (taxa < 20 && val > 0) {
            taxa = 20;
        }

        taxa = Math.round(taxa * 100) / 100;
        const taxaRecurs = Math.max(Math.round(taxa / 2 * 100) / 100, 100);
        const taxaApel = Math.max(Math.round(taxa / 2 * 100) / 100, 20);

        setTaxe({ prima: taxa, apel: taxaApel, recurs: taxaRecurs });
    };
    const calculeazaTaxa2 = (valoareCost) => {
        let val = parseFloat(valoareCost) || 0;
        let taxa = 0;

        if (val > 250000) {
            taxa = (val - 250000) * 0.01 + 6105;
        } else if (val > 50000) {
            taxa = (val - 50000) * 0.02 + 2105;
        } else if (val > 25000) {
            taxa = (val - 25000) * 0.03 + 1355;
        } else if (val > 5000) {
            taxa = (val - 5000) * 0.05 + 355;
        } else if (val > 500) {
            taxa = (val - 500) * 0.07 + 40;
        } else if (val > 0) {
            taxa = val * 0.08;
        }

        if (taxa < 20 && val > 0) {
            taxa = 20;
        }

        taxa = Math.round(taxa * 100) / 100;
        const taxaRecurs = Math.max(Math.round(taxa / 2 * 100) / 100, 100);
        const taxaApel = Math.max(Math.round(taxa / 2 * 100) / 100, 20);

        setTaxe({ prima: taxa, apel: taxaApel, recurs: taxaRecurs });
    };

    const openTaxaTimbruDialog = (cost) => {
        setSelectedCost(cost);
        calculeazaTaxa2(cost.valoare);
        setTaxaTimbruDialogOpen(true);
    };

    const closeTaxaTimbruDialog = () => setTaxaTimbruDialogOpen(false);

      return (
 <div>

        
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography style={{fontWeight: 'bold', fontSize:'1.3em'}}>Valoare totală cheltuieli dosar: {total} RON</Typography>
                {areDrept(DreptDeModificareDosare) ? ( 
                    <Button color="secondary" size="small" variant="contained" startIcon={<AddIcon />} onClick={openDialog}>
                        Adăugare cost dosar
                    </Button>
                 ) : null } 
            </div>

        <Box m={4} fullWidth>
            {loading && <CircularProgress />} 
            {loadingDenumire && <CircularProgress />} 
 
            <Dialog open={isDialogOpen} onClose={closeDialog} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
                <DialogTitle id="form-dialog-title" className='titluModal mb-3'>+ Adăugare cost dosar</DialogTitle>
                <DialogContent >
                    {/* Restul formularului aici */}
                    <Box mb={3} display="flex" flexDirection="column">
                        <InputLabel id="stadiu-label">Stadiu</InputLabel>
                        <Select
                            labelId="stadiu-label"
                            value={idStadiu}
                            onChange={e => setIdStadiu(e.target.value)}
                            size="small"
                        >
                            {stadii.map(stadiu => (
                                <MenuItem key={stadiu.id} value={stadiu.id}>
                                    {stadiu.numeStadiu}
                                </MenuItem>
                            ))}
                        </Select>

                        <Box mt={2} display="flex" alignItems="center">
                            <Box flex={1}>
                                <InputLabel id="denumire-cost-label">Denumire Cost</InputLabel>
                                <Select
                                    labelId="denumire-cost-label"
                                    value={idDenumireCost}
                                    onChange={e => setIdDenumireCost(e.target.value)}
                                    size="small"
                                    fullWidth
                                >
                                    {denumiriCosturi.map(denumire => (
                                        <MenuItem key={denumire.id} value={denumire.id}>
                                            {denumire.denumire}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>

                            <Tooltip title="Administrare denumiri costuri">
                                <IconButton className='mt-3' onClick={() => setModalDeschis(true)}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>

                        <Box mt={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
                            <DatePicker
                                label="Dată cost"
                                value={data}
                                onChange={(newValue) => {
                                setData(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                inputFormat="dd/MM/yyyy" 
                                format="dd/MM/yyyy" 
                                mask="__/__/____"
                                className='maxWidth'
                            />
                            </LocalizationProvider> 
                        </Box>

                        <Box mt={2}>
                        <TextField
                            type="text"
                            label="Valoare"
                            value={valoare}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            size="small"
                            fullWidth
                            required
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            />

                        </Box>

                       {/* Afișarea calculatorului doar când este selectată "Taxă de timbru" */}
                        {getDenumireCost(idDenumireCost) === "Taxă de timbru" && (
                            <Box mt={3}>
                                <table cellspacing="1" cellpadding="2" border="0" width="100%">
                                    <tbody>
                                        <tr>
                                            <td colspan="2" align="center" valign="middle" style={{ borderBottom: '1px solid darkgray' }}>
                                                <span>Calculator taxă de timbru</span>
                                                <br />
                                                <font color="#a9a9a9" size="2"><i>Conform OUG nr. 80/2013 art. 3 alin. 1, art. 23 (apel) si art. 24 (recurs)</i></font>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="50%" align="left" valign="middle">
                                                Prima instanță
                                            </td>
                                            <td width="50%" align="left" valign="middle">
                                                <span>{taxe.prima} RON</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="50%" align="left" valign="middle">
                                                Apel
                                            </td>
                                            <td width="50%" align="left" valign="middle">
                                                <span>{taxe.apel} RON</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="50%" align="left" valign="middle">
                                                Recurs
                                            </td>
                                            <td width="50%" align="left" valign="middle">
                                                <span>{taxe.recurs} RON</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Alert severity='info'>
                                    Pentru calculul valorii altor taxe judiciare de timbru consultați <a href='https://www.ilegis.ro/oficiale/index/act/149314' target='_blank'>OUG 80/2013</a>
                                </Alert>
                            </Box>
                        )}


                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        Renunțare
                    </Button>
                    <Button onClick={adaugaCost} variant="text" color="success" disabled={!areDrept(DreptDeModificareDosare) || !valoare}>
                        Adăugare cost
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog open={modalEditareDeschis} onClose={inchideModalEditare}>
                <DialogTitle className='titluModal mb-3'>Editare cost</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth className='mt-3'>
                        <InputLabel>Stadiu</InputLabel>
                        <Select value={idStadiu} onChange={e => setIdStadiu(e.target.value)} size="small" label="Stadiu">
                            {stadii.map(stadiu => <MenuItem key={stadiu.id} value={stadiu.id}>{stadiu.numeStadiu}</MenuItem>)}
                        </Select>
                    </FormControl>

                    <Box display="flex" alignItems="center" className='mt-3 mb-3'>
                        <FormControl style={{flex: 11, width: '80%'}}>
                            <InputLabel>Denumire cost</InputLabel>
                            <Select value={idDenumireCost} onChange={e => setIdDenumireCost(e.target.value)} size="small" label="Denumire cost">
                                {denumiriCosturi.map(denumire => 
                                    <MenuItem key={denumire.id} value={denumire.id}>
                                        {denumire.denumire}
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <Tooltip title="Administrare denumiri costuri" style={{flex: 1}}>
                            <IconButton onClick={() => setModalDeschis(true)}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ro}>
                            <DatePicker
                                label="Dată cost"
                               
                                value={data}
                                onChange={(newValue) => {
                                setData(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                inputFormat="dd/MM/yyyy" 
                                format="dd/MM/yyyy" 
                                mask="__/__/____"
                                className='maxWidth mt-3 mb-3'
                            />
                        </LocalizationProvider>  
                    <TextField
                        type="text"  
                        label="Valoare"
                        value={valoare}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                        size="small"
                        fullWidth
                        required
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        />
                        {/* Afișarea calculatorului doar când este selectată "Taxă de timbru" */}
                        {getDenumireCost(idDenumireCost) === "Taxă de timbru" && (
                                <Box mt={3}>
                                    <table cellspacing="1" cellpadding="2" border="0" width="100%">
                                        <tbody>
                                            <tr>
                                                <td colspan="2" align="center" valign="middle" style={{ borderBottom: '1px solid darkgray' }}>
                                                    <span>Calculator taxă de timbru</span>
                                                    <br />
                                                    <font color="#a9a9a9" size="2"><i>Conform OUG nr. 80/2013 art. 3 alin. 1, art. 23 (apel) si art. 24 (recurs)</i></font>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="50%" align="left" valign="middle">
                                                    Prima instanță
                                                </td>
                                                <td width="50%" align="left" valign="middle">
                                                    <span>{taxe.prima} RON</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="50%" align="left" valign="middle">
                                                    Apel
                                                </td>
                                                <td width="50%" align="left" valign="middle">
                                                    <span>{taxe.apel} RON</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="50%" align="left" valign="middle">
                                                    Recurs
                                                </td>
                                                <td width="50%" align="left" valign="middle">
                                                    <span>{taxe.recurs} RON</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <Alert severity='info'>
                                        Pentru calculul valorii altor taxe judiciare de timbru consultați <a href='https://www.ilegis.ro/oficiale/index/act/149314' target='_blank'>OUG 80/2013</a>
                                    </Alert>
                                </Box>
                            )}


                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={inchideModalEditare}>Renunțare</Button>
                    <Button color="success" onClick={finalizeazaEditare}  disabled={!valoare}>Salvare</Button>
                </DialogActions>
            </Dialog>

            {/* Dialog pentru calcul taxă de timbru */}
            <Dialog open={isTaxaTimbruDialogOpen} onClose={closeTaxaTimbruDialog}>
                <DialogTitle className='titluModal'><CalculateIcon /> Calculator taxă de timbru</DialogTitle>
                <DialogContent className='mt-3'>
                    <table cellspacing="1" cellpadding="2" border="0" width="100%">
                        <tbody>
                            <tr>
                                <td colspan="2" align="center" valign="middle" style={{ borderBottom: '1px solid darkgray' }}>
                                    <span>Calculator taxă de timbru</span>
                                    <br />
                                    <font color="#a9a9a9" size="2"><i>Conform OUG nr. 80/2013 art. 3 alin. 1, art. 23 (apel) si art. 24 (recurs)</i></font>
                                </td>
                            </tr>
                            <tr>
                                <td width="50%" align="left" valign="middle">
                                    Prima instanță
                                </td>
                                <td width="50%" align="left" valign="middle">
                                    <span>{taxe.prima} RON</span>
                                </td>
                            </tr>
                            <tr>
                                <td width="50%" align="left" valign="middle">
                                    Apel
                                </td>
                                <td width="50%" align="left" valign="middle">
                                    <span>{taxe.apel} RON</span>
                                </td>
                            </tr>
                            <tr>
                                <td width="50%" align="left" valign="middle">
                                    Recurs
                                </td>
                                <td width="50%" align="left" valign="middle">
                                    <span>{taxe.recurs} RON</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Alert severity='info'>
                        Pentru calculul valorii altor taxe judiciare de timbru consultați <a href='https://www.ilegis.ro/oficiale/index/act/149314' target='_blank'>OUG 80/2013</a>
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeTaxaTimbruDialog} color="primary">Închidere</Button>
                </DialogActions>
            </Dialog>

                          
            {/* lista costurilor */}
            <DataGridPro
                rows={costuri}
                columns={columns}columnVisibilityModel={{
                    actions: areDrept(DreptDeModificareDosare),
                  }}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                disableSelectionOnClick
                disableMultipleSelection
                initialState={{
                    aggregation: {
                    model: {
                        valoare: 'sum',
                    },
                    },
                }}
                localeText={{ 
                    ...roRO.components.MuiDataGrid.defaultProps.localeText, 
                  }}
            />


            {/* dialog pentru denumiri costuri */}
            <Dialog open={modalDeschis} onClose={() => setModalDeschis(false)}>
                <DialogTitle className='titluModal'>Gestionare denumiri</DialogTitle>
                <DialogContent style={{display: 'flex', flexDirection: 'column', maxHeight: 350}} className='mt-3'>
                    {/* Partea de adăugare */}
                    <Box display="flex" flexDirection="column" alignItems="stretch">
                        <TextField fullWidth size='small' type="text" placeholder="Denumire cost nou" value={denumireNoua} onChange={e => setDenumireNoua(e.target.value)} />
                    </Box>
                    
                    <Box flexGrow={1} overflow="auto" mt={2}>
                        <List>
                            {denumiriCosturi.map(denumire => (
                                <ListItem key={denumire.id}>
                                    {isEditingDenumire === denumire.id ? (
                                        <>
                                            <TextField value={editedDenumire} onChange={handleDenumireChange} />
                                            <IconButton onClick={() => handleEditSave(denumire.id)}><CheckIcon /></IconButton>
                                            <IconButton onClick={() => setIsEditingDenumire(null)}><CloseIcon /></IconButton>
                                        </>
                                    ) : (
                                        <>
                                            {denumire.denumire}
                                            <IconButton onClick={() => startEditing(denumire.id, denumire.denumire)}><EditIcon /></IconButton>
                                            <IconButton onClick={() => stergeDenumireCost(denumire.id)}><DeleteIcon /></IconButton>
                                        </>
                                    )}
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={adaugaDenumireCost}>Adăugare denumire</Button>
                    <Button color="secondary" onClick={() => setModalDeschis(false)}>Renunțare</Button>
                </DialogActions>
            </Dialog>

        </Box>

        </div>
    );
}

export default CosturiDosar;