import React, { useState, useEffect } from 'react';
import { TextField, Switch, Box, FormControlLabel, MenuItem, Grid, Tooltip, CircularProgress } from '@mui/material';
import useUserPrefService from './preferinteUtilizatorServiciu.js';
import Button from '@mui/material/Button';
import Swal from 'sweetalert'; 
import Divider from '@mui/material/Divider';
import verificareToken from '../VerificareToken';
import { NotificationImportant } from '@mui/icons-material';


  function UserPrefs() {

  //reactualizez starea lui authChange - verific starea lui isLoggedIn sa vad daca mai e logat
  window.dispatchEvent(new Event('authChange'));
  
    const { token } = verificareToken();

    const [isLoading, setIsLoading] = useState(false);

    const userPrefsData = useUserPrefService();
  
    const [colorDCL, setColorDCL] = useState("");
    const [colorDCF, setColorDCF] = useState("");
    const [colorDIL, setColorDIL] = useState("");
    const [colorDIF, setColorDIF] = useState("");
    const [colorDA, setColorDA] = useState("");
    const [colorCT, setColorCT] = useState("");
    const [colorCA, setColorCA] = useState("");
    const [colorCU, setColorCU] = useState("");
    const [openinnewwindow, setOpeninnewwindow] = useState(false);
    const [openlastopened, setOpenlastopened] = useState(false);
    const [intervalpopup, setIntervalpopup] = useState("");
    const [notificariTermene, setNotificariTermene] = useState(false);
    const [uianimations, setUianimations] = useState(false);
    const [allowsounds, setAllowsounds] = useState(false);
    const [afisare_notificari, setAfisare_notificari] = useState(false); 
    const [showpreview, setShowpreview] = useState(false);
    const [calendarlabels, setCalendarlabels] = useState("");
    //const [dosarepepagina, setDosarepepagina] = useState("0");
    const [alertapublicare, setAlertapublicare] = useState(0);
  
   
    const [send_notificari_push, setSend_notificari_push] = useState(false);
    const [send_notificari_email, setSend_notificari_email] = useState(false);
    const [send_notificari_sms, setSend_notificari_sms] = useState(false); 

    useEffect(() => {
      if (userPrefsData && userPrefsData.length > 0) {
        const userData = userPrefsData[0];
        updateSoundSettings(!!userData.allowsounds);
        
        setColorDCL(userData.colorDCL || "");
        setColorDCF(userData.colorDCF || "");
        setColorDIL(userData.colorDIL || "");
        setColorDIF(userData.colorDIF || "");
        setColorDA(userData.colorDA || "");
        setColorCT(userData.colorCT || "");
        setColorCA(userData.colorCA || "");
        setColorCU(userData.colorCU || "");
        setOpeninnewwindow(!!userData.openinnewwindow);
        setOpenlastopened(!!userData.openlastopened);
        setIntervalpopup(userData.intervalpopup || "");
        setNotificariTermene(!!userData.notificari_termene);
        setUianimations(!!userData.uianimations);
        setAllowsounds(!!userData.allowsounds);
        setAfisare_notificari(!!userData.afisare_notificari); 
        setShowpreview(!!userData.showpreview);
        setCalendarlabels(userData.calendarlabels || "");
        //setDosarepepagina(userData.dosarepepagina || "");
        setAlertapublicare(userData.alertapublicare || 0);
        
        setSend_notificari_push(!!userData.send_notificari_push);
        setSend_notificari_email(!!userData.send_notificari_email);
        setSend_notificari_sms(!!userData.send_notificari_sms);
      }
    }, [userPrefsData]);
    
   
  

    const handleColorChange = (event, setter) => {
      setter(event.target.value);
    };
  
    const [autoUpdate, setAutoUpdate] = useState(false); 
  
    useEffect(() => {
      setIsLoading(true);
      const fetchAutoUpdate = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}getActualizareAutomata`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
    
          if (response.status === 200) {
            const { data } = await response.json();
            setAutoUpdate(data.value === "1");
          } else {
            console.error("Eroare la preluarea valorii. Status HTTP:", response.status);
          }
        } catch (error) {
          console.error("Eroare la update preluare valori: ", error);
        } finally {
          setIsLoading(false);
        }
      };
    
      fetchAutoUpdate();
    }, [token]);
    
    const updateSoundSettings = (value) => {
      const paramName = 'permitSunete';
      const paramValue = value ? 'true' : 'false';
      
      //sessionStorage.setItem(paramName, paramValue);
      //localStorage.setItem(paramName, paramValue);
      document.cookie = `${paramName}=${paramValue}; path=/`;
    };
    

    
    const handleSave = async () => {
        setIsLoading(true);
        try {
            const responseUserPrefs = await fetch(`${process.env.REACT_APP_API_URL}updateUserprefs`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                  openinnewwindow: openinnewwindow ? 1 : 0,
                  openlastopened: openlastopened ? 1 : 0,
                  uianimations: uianimations ? 1 : 0,
                  afisare_notificari: afisare_notificari ? 1 : 0,
                  allowsounds: allowsounds ? 1 : 0,
                  notificari_termene: notificariTermene ? 1 : 0,
                  intervalpopup,
                  showpreview: showpreview ? 1 : 0,
                  colorDCL,
                  colorDCF,
                  colorDIL,
                  colorDIF,
                  colorDA,
                  colorCT,
                  colorCA,
                  colorCU,
                  intervalpopup,
                  calendarlabels,
                  dosarepepagina : 0,
                  alertapublicare,
                  send_notificari_push: send_notificari_push ? 1 : 0,
                  send_notificari_email: send_notificari_email ? 1 : 0,
                  send_notificari_sms: send_notificari_sms ? 1 : 0,
                })
              });
            
      
          const responseAutoUpdate = await fetch(`${process.env.REACT_APP_API_URL}setActualizareAutomata?value=${autoUpdate ? 1 : 0}`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
          
          const dataUserPrefs = await responseUserPrefs.json();
          const dataAutoUpdate = await responseAutoUpdate.json();
          console.log(dataUserPrefs.message);
          console.log(responseUserPrefs.data);
          
          console.log(dataAutoUpdate.message);
          console.log(responseAutoUpdate.data);

          
          updateSoundSettings(allowsounds);

        } catch (error) {
          console.error("Problema API setActualizareAutomata - preferinte: ", error);
        }
        setIsLoading(false);
      };
      
      const handleIntervalChange = (event) => {
        let inputValue = event.target.value;
        if (inputValue > 30) {
            Swal({
                icon: 'warning',
                title: 'Atenționare',
                text: 'Nu se pot seta mai mult de 30 zile pentru Avertizări la termen.',
              });
          inputValue = 30;
        } else if (inputValue < 3) {
          inputValue = 3;
        }
        setIntervalpopup(inputValue);
      };
      
      const handleAlertChange = (event) => {
        let inputValue = event.target.value;
        if (inputValue > 360) {
            Swal({
                icon: 'warning',
                title: 'Atenționare',
                text: 'Nu se pot seta mai mult de 360 zile pentru alerte la data publicării unui dosar..',
              });
          inputValue = 360;
        } else if (inputValue < 20) {
          inputValue = 20;
        }
        setAlertapublicare(inputValue);
      };

      const handleResetDefaults = () => {
        setColorDCL("#C44040");
        setColorDCF("#2BFF00");
        setColorDIL("#FE0101");
        setColorDIF("#D87641");
        setColorDA("#D2BB89");
        setColorCT("#5C90B7");
        setColorCA("#8C3B8A");
        setColorCU("#40783F");
        setOpeninnewwindow(true);
        setOpenlastopened(true); 
        setUianimations(true);
        setIntervalpopup("5");
        setNotificariTermene(true);
        setAllowsounds(false);
        setAfisare_notificari(true);
        setShowpreview(true); 
        setAlertapublicare(0);
        setAutoUpdate(true);
        setSend_notificari_push(true);
        setSend_notificari_email(true);
        setSend_notificari_sms(true);

        Swal({
          icon: 'warning',
          title: 'Atenționare',
          text: 'Setările implicite au fost restabilite, pentru a le memora trebuie să le salvați!',
        });
      };

      
      if (isLoading) {
        return (
          <div className="card container mx-auto p-5 mt-5" style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '10vh',
            width: '100% !important' 
          }}>
            <CircularProgress />
          </div>
        );
      }
      
    return (
        <div className="card container mx-auto p-5 mt-5">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ marginBottom: 2 }}  
          >
            <h3>Preferințe Utilizator</h3> 
            <Button  
              size='small'
              color="error"
              onClick={handleResetDefaults}
            >
              Reveniți la setările implicite
            </Button>
          </Box>    
            <Divider />

            <Grid container direction="column" spacing={2} className="mt-3">
                <Grid item container direction={["row"]} spacing={2}>
                    {/*<Grid item xs={6}><FormControlLabel control={<Switch checked={openinnewwindow} onChange={(event) => setOpeninnewwindow(event.target.checked)} />} label="Deschideți în fereastră nouă" /></Grid>
                   */}
                    <Grid item xs={3}><FormControlLabel control={<Switch checked={openlastopened} onChange={(event) => setOpenlastopened(event.target.checked)} />} label="Afișare ultimul dosar deschis" /></Grid>
                    <Grid item xs={3}><FormControlLabel control={<Switch checked={afisare_notificari} onChange={(event) => setAfisare_notificari(event.target.checked)} />} label="Fereastră notificări aplicație" /></Grid>
                    <Grid item xs={3}><FormControlLabel control={<Switch checked={allowsounds} onChange={(event) => setAllowsounds(event.target.checked)} />} label="Sunete aplicație" /></Grid>
                    <Grid item xs={3}><FormControlLabel control={<Switch checked={autoUpdate} onChange={(event) => setAutoUpdate(event.target.checked)} />} label="Import automat" /></Grid>
                    {/*<Grid item xs={6}><FormControlLabel control={<Switch checked={showpreview} onChange={(event) => setShowpreview(event.target.checked)} />} label="Arată previzualizare" /></Grid>
                    */} 
                    </Grid>
                 {/*
                <Grid item container direction={["row"]} spacing={2}>
                <Grid item xs={4}>
                <Tooltip title="Se poate seta o valoare între 3 și 30 de zile">
                    <TextField  
                        fullWidth
                        id="intervalpopup" 
                        type="number" 
                        inputProps={{ 
                            min: 3,
                            max: 30
                            }}
                        value={intervalpopup} 
                        onChange={handleIntervalChange} 
                        label="Avertizări la termen înainte cu (zile)" 
                    />
                </Tooltip>
                </Grid>
                <Grid item xs={4}>
                    <TextField 
                        fullWidth
                        id="calendarlabels"
                        label="Etichete calendar"
                        select
                        value={calendarlabels}
                        onChange={(event) => setCalendarlabels(event.target.value)} 
                        >
                        <MenuItem value="CLIENT">Client dosar</MenuItem>
                        <MenuItem value="PARTEADV">Parte adversă</MenuItem>
                        <MenuItem value="NRDOSAR">Număr dosar</MenuItem>
                        <MenuItem value="DETALIIEV">Detalii eveniment</MenuItem>
                        <MenuItem value="JURIST">Jurist</MenuItem>
                    </TextField>
 
                    </Grid>

                    <Grid item xs={4}>
                        <Tooltip title="Se poate seta o valoare între 20 și 360 de zile">
                            <TextField 
                                fullWidth
                                id="alertapublicare" 
                                type="number" 
                                inputProps={{ 
                                min: 20,
                                max: 360
                                }}
                                value={alertapublicare} 
                                onChange={handleAlertChange}
                                label="Alertă de la dată publicare dosar (zile)" 
                            />
                        </Tooltip>
                    </Grid>
                </Grid>
                */}
                 <Grid item container direction={["row"]} spacing={2} className="mt-2 mb-2">
                      <b style={{marginLeft: '20px'}}  className='hr mt-3'>
                        Notificări generale aplicație
                      </b>  
                </Grid>
                <Grid item container direction={["row"]} spacing={2}>
                  <Grid item xs={3}><FormControlLabel control={<Switch checked={send_notificari_sms} onChange={(event) => setSend_notificari_sms(event.target.checked)} />} label="Notificări SMS" /></Grid>
                  <Grid item xs={3}><FormControlLabel control={<Switch checked={send_notificari_email} onChange={(event) => setSend_notificari_email(event.target.checked)} />} label="Notificări E-mail" /></Grid>
                  <Grid item xs={3}><FormControlLabel control={<Switch checked={send_notificari_push} onChange={(event) => setSend_notificari_push(event.target.checked)} />} label="Notificări PUSH" /></Grid>
                </Grid>
                <Grid item container direction={["row"]} spacing={2} className="mt-2 mb-2">
                      <b style={{marginLeft: '20px'}}  className='hr mt-3'>
                        Configurare notificări termene
                      </b>  
                </Grid>
                <Grid item container direction={["row"]} spacing={2}>
                  <Grid item xs={3}><FormControlLabel control={<Switch checked={notificariTermene} onChange={(event) => setNotificariTermene(event.target.checked)} />} label="Notificări termene" /></Grid>
                  <Grid item xs={3}>
                    <Tooltip title={`${!notificariTermene? 'Activați notificările pentru termene dacă doriți să setați cu căte zile înainte să fiți notificat' : 'Se poate seta o valoare între 3 și 30 de zile' }`}>
                        <TextField  
                            fullWidth
                            id="intervalpopup" 
                            type="number" 
                            inputProps={{ 
                                min: 3,
                                max: 30
                                }}
                            value={intervalpopup} 
                            onChange={handleIntervalChange} 
                            disabled={!notificariTermene}
                            label="Avertizări la termen înainte cu (zile)" 
                        />
                    </Tooltip>
                  </Grid>  
                </Grid>

                <Grid item container direction={["row"]} spacing={2} className="mt-2 mb-2">
                      <b style={{marginLeft: '20px'}}  className='hr mt-3'>
                        Configurare culori listă dosare
                      </b>  
                </Grid>
                <Grid item container direction={["row"]} spacing={2}>
                    <Grid item xs={4}><TextField id="colorDCL" type="color" fullWidth value={colorDCL} onChange={(event) => handleColorChange(event, setColorDCL)} label="Dosare Consultanță în lucru" /></Grid>
                    <Grid item xs={4}><TextField id="colorDCF" type="color" fullWidth value={colorDCF} onChange={(event) => handleColorChange(event, setColorDCF)} label="Dosare Consultanță finalizate" /></Grid>
                    <Grid item xs={4}><TextField id="colorDIL" type="color" fullWidth value={colorDIL} onChange={(event) => handleColorChange(event, setColorDIL)} label="Dosare Instanță în lucru" /></Grid>
                    <Grid item xs={4}><TextField id="colorDIF" type="color" fullWidth value={colorDIF} onChange={(event) => handleColorChange(event, setColorDIF)} label="Dosare Instanță finalizate" /></Grid>
                    <Grid item xs={4}><TextField id="colorDA" type="color" fullWidth value={colorDA} onChange={(event) => handleColorChange(event, setColorDA)} label="Dosare cu accesorii" /></Grid>
                 </Grid>
                 <Grid item container direction={["row"]} spacing={2} className="mt-2 mb-2">
                      <b style={{marginLeft: '20px'}}  className='hr mt-3'>
                        Configurare culori calendar evenimente
                      </b>  
                </Grid>
                <Grid item container direction={["row"]} spacing={2}>
                    <Grid item xs={4}><TextField id="colorCT" type="color" fullWidth value={colorCT} onChange={(event) => handleColorChange(event, setColorCT)} label="Evenimente termene pe calendar" /></Grid>
                    <Grid item xs={4}><TextField id="colorCA" type="color" fullWidth value={colorCA} onChange={(event) => handleColorChange(event, setColorCA)} label="Evenimente dosare pe calendar" /></Grid>
                    <Grid item xs={4}><TextField id="colorCU" type="color" fullWidth value={colorCU} onChange={(event) => handleColorChange(event, setColorCU)} label="Evenimente utilizator pe calendar" /></Grid>
                </Grid>
                <Grid item>
                <Button
                  variant="outlined"
                  fullWidth
                  color="success" 
                  onClick={handleSave}
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress size={24} /> : "Salvare preferințe"}
                </Button>

                </Grid>
            </Grid>
        </div>
    );
}
  export default UserPrefs;
  